import { buffers } from 'redux-saga';
import { actionChannel, call, put, take, select } from 'redux-saga/effects';
import * as actionType from '../../components/AppDraft/actions/types.js';
import * as API from '../../components/AppDraft/util/API';
import { SYNC_VALUE } from './customField.js';

export const SUBMIT_FORM = 'fieldsValidation/SUBMIT_FORM';
export const SUBMIT_FORM_VALIDATION_FAILURE =
    'fieldsValidation/SUBMIT_FORM_VALIDATION_FAILURE';
export const SUBMIT_FORM_SUCCESS = 'fieldsValidation/SUBMIT_FORM_SUCCESS';
export const SUBMIT_FORM_FAILURE = 'fieldsValidation/SUBMIT_FORM_FAILURE';

const initialState = {
    errors: {},
    dirty: {},
    isLoading: {},
    isSubmitting: false,
    tabErrors: {},
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionType.ADD_CONTEXT:
            return {
                ...initialState,
            };

        case actionType.SET_DIRTY:
            var newState = {
                ...state,
                dirty: {
                    ...state.dirty,
                },
            };
            if (action.languageCode) {
                newState.dirty[action.languageCode] = {
                    ...state.dirty[action.languageCode],
                    [action.fieldName]: true,
                };
            } else {
                newState.dirty[action.fieldName] = true;
            }
            return newState;

        case actionType.SET_NOT_DIRTY:
            return {
                ...state,
                dirty: {},
            };

        case actionType.SET_PRISTINE:
            var newState = {
                ...state,
                dirty: {
                    ...state.dirty,
                },
            };
            if (action.languageCode) {
                newState.dirty[action.languageCode] = {
                    ...state.dirty[action.languageCode],
                    [action.fieldName]: false,
                };
            } else {
                newState.dirty[action.fieldName] = false;
            }
            return newState;

        case actionType.SET_ERROR:
            var newState = {
                ...state,
                errors: {
                    ...state.errors,
                },
            };
            if (action.languageCode) {
                newState.errors[action.languageCode] = {
                    ...state.errors[action.languageCode],
                    [action.fieldName]: action.errorMessage,
                };
            } else {
                newState.errors[action.fieldName] = action.errorMessage;
            }
            return newState;

        case actionType.SET_CORRECT:
            var newState = {
                ...state,
                errors: {
                    ...state.errors,
                },
            };
            if (action.languageCode) {
                newState.errors[action.languageCode] = {
                    ...state.errors[action.languageCode],
                    [action.fieldName]: false,
                };
            } else {
                newState.errors[action.fieldName] = false;
            }
            return newState;

        case actionType.IS_LOADING:
            var newState = {
                ...state,
                isLoading: {
                    ...state.isLoading,
                },
            };
            if (action.languageCode) {
                newState.isLoading[action.languageCode] = {
                    ...state.isLoading[action.languageCode],
                    [action.fieldName]: true,
                };
                if (typeof action.languageCode === 'string') {
                    newState.tabErrors = {
                        ...state.tabErrors,
                        localizable_metadata: undefined,
                    };
                }
            } else {
                newState.isLoading[action.fieldName] = true;
            }
            return newState;

        case actionType.IS_NOT_LOADING:
            var newState = {
                ...state,
                isLoading: {
                    ...state.isLoading,
                },
            };
            if (action.languageCode) {
                newState.isLoading[action.languageCode] = {
                    ...state.isLoading[action.languageCode],
                    [action.fieldName]: false,
                };
            } else {
                newState.isLoading[action.fieldName] = false;
            }
            return newState;

        case SUBMIT_FORM:
            return {
                ...state,
                isSubmitting: true,
            };
        case SUBMIT_FORM_VALIDATION_FAILURE: {
            const fieldErrors = Object.values(action.payload.errors)
                .reduce((a, b) => a.concat(b), []) // flatten the array
                .reduce((errors, error) => ({ ...errors, ...error }), {});
            const tabErrors = Object.entries(action.payload.errors).reduce(
                (errors, [key, value]) => ({
                    ...errors,
                    [key]:
                        value.length > 0 ||
                        (value.constructor === Object &&
                            Object.keys(value).length > 0),
                }),
                {}
            );
            return {
                ...state,
                isSubmitting: false,
                errors: fieldErrors, // TBD whether old errors should be kept here
                tabErrors,
            };
        }
        case SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                errors: {},
                tabErrors: {},
                isSubmitting: false,
            };
        case SUBMIT_FORM_FAILURE:
            return {
                ...state,
                isSubmitting: false,
            };
        case actionType.CHANGE_TEXT_INPUT:
        case actionType.CHANGE_SELECT_INPUT:
        case actionType.CHANGE_RADIO_TABLE_INPUT:
            return {
                ...state,
                tabErrors: {
                    ...state.tabErrors,
                    [action.tabName]: undefined,
                },
            };
        case actionType.CHANGE_CONTENT_RATING_CATEGORY:
            return {
                ...state,
                tabErrors: {
                    ...state.tabErrors,
                    content_rating: undefined,
                },
            };
        case actionType.CHANGE_LOCALIZABLE_TEXT_INPUT:
            return {
                ...state,
                tabErrors: {
                    ...state.tabErrors,
                    localizable_metadata: undefined,
                },
            };
        case SYNC_VALUE:
            return {
                ...state,
                tabErrors: {
                    ...state.tabErrors,
                    custom_fields: undefined,
                },
            };
        default:
            return state;
    }
}

export const submitForm = () => ({
    type: SUBMIT_FORM,
});

export const submitFormSuccess = () => ({
    type: SUBMIT_FORM_SUCCESS,
});

export const submitFormValidationFailure = errors => ({
    type: SUBMIT_FORM_VALIDATION_FAILURE,
    payload: {
        errors,
    },
    error: true,
});

export const submitFormFailure = error => ({
    type: SUBMIT_FORM_FAILURE,
    payload: {
        error,
    },
    error: true,
});

function* watchSubmit() {
    const submitActionChannel = yield actionChannel(SUBMIT_FORM);

    while (true) {
        yield take(submitActionChannel, buffers.expanding(10));
        yield call(submitSaga);
    }
}

function* submitSaga() {
    try {
        const csrfToken = yield select(store => store.context.csrf_token);
        const appVersion = yield select(store => store.context.app_version);

        const response = yield call(API.sendSignRequest, {
            appVersion,
            csrfToken,
        });
        if (hasValidationErrors(response.data)) {
            yield put(submitFormValidationFailure(response.data));
        } else {
            yield put(submitFormSuccess());
        }
    } catch (e) {
        yield put(submitFormFailure(new Error(e.response.data.error)));
    }
}

function* watchNavigateToOverview() {
    const submitSuccessActionChannel = yield actionChannel(SUBMIT_FORM_SUCCESS);

    while (true) {
        yield take(submitSuccessActionChannel, buffers.expanding(10));
        const appId = yield select(store => store.context.app_id);
        const appVersion = yield select(store => store.context.app_version);
        const hasTermsAndConditions = yield select(
            store => store.context.has_terms_and_conditions_for_developers
        );
        if (hasTermsAndConditions) {
            // TODO get rid of jquery when modals are reimplemented in React
            $('#terms-and-conditions-for-developers-modal').modal({
                backdrop: 'static',
                keyboard: false,
            });
        } else {
            location.replace(`/apps/${appId}/versions/${appVersion}/sign`);
        }
    }
}

export const sagas = [watchSubmit, watchNavigateToOverview];

const _hasError = tab => {
    if (Array.isArray(tab)) {
        return tab.length > 0;
    } else {
        return Object.values(tab).some(errs => Object.keys(errs).length > 0);
    }
};

const hasValidationErrors = tabErrors =>
    Object.values(tabErrors).some(tab => _hasError(tab));
