import React, { Fragment } from 'react';
import { Button, Row } from 'react-bootstrap';
import AppStoreGraphicAssetsTab from './AppStoreGraphicAssetsTab.jsx';
import ButtonWithModal from './inputs/ButtonWithModal.jsx';

const AppStoreAssetsOnlyLocalizableLanguageMetadata = ({
    multiple_languages,
    supports_iphone,
    metadata,
    validation,
    readonly,
    diff,
    options,
    supports_ipad,
    supports_watch,
    handleSetCorrect,
    ...props
}) => {
    const languageCode = metadata.language.code;
    return (
        <Fragment>
            {!readonly && !metadata.is_default && (
                <div>
                    <ButtonWithModal
                        buttonText="Copy from default language"
                        modalText="This change will overwrite all settings for this language"
                        onClick={() =>
                            props.handleLoadFromDefaultLanguage(
                                props.app_version,
                                languageCode
                            )
                        }
                    />
                    <Button
                        bsStyle="link"
                        onClick={() =>
                            props.handleSetDefaultLanguage(
                                props.csrf_token,
                                props.app_version,
                                languageCode
                            )
                        }>
                        Set as default language
                    </Button>
                </div>
            )}
            {!readonly && metadata.is_default && multiple_languages && (
                <ButtonWithModal
                    buttonText="Copy from default language to other languages"
                    modalText="This change will overwrite all settings for all added languages"
                    onClick={() => props.handleLoadFromDefaultLanguageToOthers(props.app_version)}
                />
            )}
            <Row>
                <AppStoreGraphicAssetsTab
                    metadata={metadata}
                    validation={validation}
                    supports_iphone={supports_iphone}
                    supports_ipad={supports_ipad}
                    supports_watch={supports_watch}
                    readonly={readonly}
                    diff={diff}
                    options={options}
                    handleSetCorrect={handleSetCorrect}
                    {...props}
                />
            </Row>
        </Fragment>
    );
};

export default AppStoreAssetsOnlyLocalizableLanguageMetadata;
