import React, { Component } from 'react';
import AssetDropzone, { ASSET_TYPE_IMAGE } from './AssetDropzone';
import HelpTooltip from '../HelpTooltip';
import { Col, Row } from 'react-bootstrap';

export default class AssetField extends Component {

    //TODO: Refactor dimensions && rangeDimensions to some tooltip template
    getTooltipMessage(){
        const {
            name,
            assetType,
            dimensions,
            rangeDimensions,
        } = this.props;
        let secondParagraphContent = '';
        const typeAssetMessage = assetType === ASSET_TYPE_IMAGE ? 'screenshots' : 'previews';

        if (dimensions){
            const dimensionsContent = dimensions.reduce(
                (acc, cur) =>
                    `${acc}\n${cur.width} x ${
                        cur.height
                    }`,
                ''
            );
            secondParagraphContent = `
            ${name} ${typeAssetMessage} must 
            have one of the following 
            dimensions: ${dimensionsContent}.
            Up to 8 MB per screenshot.
            `;
        } else if (dimensions === undefined && rangeDimensions){
            secondParagraphContent = `
            ${name} ${typeAssetMessage} must have length 
            for any side between 
            ${rangeDimensions.min_width} px
            and ${rangeDimensions.max_width} px.
            Up to 8 MB per screenshot.
            `;
        }

        return (
            <>
                <p>
                    For non default language either
                    upload no {typeAssetMessage} at all or
                    upload 2 or more {typeAssetMessage}.
                    PNG or JPEG
                </p>
                <p>
                    {secondParagraphContent}
                </p>
            </>
        );
    }

    getStatusMessage(){
        const {
            assets,
            assetType,
            maxAssetsCount,
        } = this.props;
        const typeAssetMessage = assetType === ASSET_TYPE_IMAGE ? 'Screenshots' : 'Previews';

        return `${assets.length}/${maxAssetsCount} ${typeAssetMessage}`;
    }

    handleError = () => {
        const {
            assetType,
            onError,
        } = this.props;
        assetType === ASSET_TYPE_IMAGE ? onError(new Error('Too many images')) : onError(new Error('Too many videos'));
    }

    render() {
        const {
            hint,
            assets,
            readonly,
            maxAssetsCount,
            expectedWidth,
            expectedHeight,
            scale,
            onChange,
            onDelete,
            assetType,
        } = this.props;

        return (
            <Row className="screenshot-field">
                <div>
                    <div className="screenshots-status-header pull-left">
                        {hint}
                    </div>
                    <div className="screenshots-status-header pull-right">
                        <span>
                            {this.getStatusMessage()}
                            <HelpTooltip message={this.getTooltipMessage()}
                                placement="left"
                                size="20px"
                                id={`${name} question tooltip`}
                                preLine
                            />

                        </span>
                    </div>
                </div>
                <Col md={12}>
                    <AssetDropzone
                        assets={assets}
                        readonly={readonly}
                        maxAssetsCount={maxAssetsCount}
                        expectedWidth={expectedWidth}
                        expectedHeight={expectedHeight}
                        scale={scale}
                        onChange={onChange}
                        onError={this.handleError}
                        placeholderMessage={assetType === ASSET_TYPE_IMAGE ? 'No pictures uploaded' : 'No videos uploaded'}
                        onDelete={onDelete}
                        assetType={assetType}
                    />
                </Col>
            </Row>
        );
    }
}
