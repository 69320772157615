// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import AppDataListElement from './AppDataListElement';

const AppDataComponent = ({
    bundleId,
    platformVerbose,
    environmentVerbose,
    environment,
    targetType,
    targets,
    owners,
    projectName,
    businessUnit,
    canEditBaseAppData,
    collectEnvironment,
    collectBusinessUnit,
    businessUnitsEndpoint,
    endpoint,
}) => {
    const open_modal = canEditBaseAppData => {
        // When a user clicks on the App Owner, this function is called. It mimics a user clicking on the
        // edit button on the permissions tab, and then clicking on the owners cog (the 2nd scoped element)
        if (canEditBaseAppData) {
            $('#assign-new-role').click();
            setTimeout(() => {
                $('.scoped-role')
                    .eq(2)
                    .click();
            }, 200);
        }
    };

    const listElementProps = canEditBaseAppData
        ? {
            projectName: {
                post_key: 'projectName',
                value: projectName,
                endpoint: endpoint,
                autocomplete_endpoint: null,
            },
            businessUnit: {
                post_key: 'businessUnit',
                value: businessUnit,
                endpoint: endpoint,
                autocomplete_endpoint: businessUnitsEndpoint,
            },
            environment: {
                post_key: 'environment',
                value: environmentVerbose,
                endpoint: endpoint,
                autocomplete_endpoint: null,
                options: [
                  { api_value: 'prod', name: 'Production' },
                  { api_value: 'test', name: 'Test' }
                ]
            },
        }
        : {};
    return (
        <div>

            {bundleId && (
                <div>
                    <ReactCSSTransitionGroup
                        transitionName="generic-fade"
                        transitionAppear={true}
                        transitionAppearTimeout={500}
                        transitionEnter={false}
                        transitionLeave={false}>
                        <ul className="list-unstyled details">
                            <AppDataListElement
                                label="Name"
                                isBig={true}
                                data={listElementProps['projectName']}>
                                {projectName}
                            </AppDataListElement>
                            <AppDataListElement
                                label="App Owners"
                                onClick={() =>
                                    open_modal(canEditBaseAppData)
                                }>
                                {owners || 'None'}
                            </AppDataListElement>
                            {collectBusinessUnit && (
                                <AppDataListElement
                                    label="Business Unit"
                                    data={listElementProps['businessUnit']}>
                                    {businessUnit}
                                </AppDataListElement>
                            )}
                            <AppDataListElement label="Bundle ID">
                                {bundleId}
                            </AppDataListElement>
                            <AppDataListElement label="Target Type">
                                {targetType}
                            </AppDataListElement>
                            <AppDataListElement label="Targets">
                                {targets.join(', ')}
                            </AppDataListElement>
                            <AppDataListElement label="OS">
                                {platformVerbose}
                            </AppDataListElement>
                            {collectEnvironment && (
                                <AppDataListElement
                                  label="Environment"
                                  data={listElementProps['environment']}>
                                    {environmentVerbose}
                                </AppDataListElement>
                            )}
                        </ul>
                    </ReactCSSTransitionGroup>
                </div>
            )}
        </div>
    );
};

export default AppDataComponent;
