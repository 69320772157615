import React, { Fragment } from 'react';
import UsesEncryption from './ExportCompliance/UsesEncryption';
import IsExempt from './ExportCompliance/IsExempt';
import ProprietaryCryptography from './ExportCompliance/ProprietaryCryptography';
import ThirdPartyCryptography from './ExportCompliance/ThirdPartyCryptography';
import AvailableOnFrenchStore from './ExportCompliance/AvailableOnFrenchStore';
import EncryptionUpdated from './ExportCompliance/EncryptionUpdated';

const ExportCompliance = ({
    validation,
    reviewInformation,
    readonly,
    props,
}) => {
    return (
        <Fragment>
            <UsesEncryption
                validation={validation}
                reviewInformation={reviewInformation}
                readonly={readonly}
                props={props}
            />
            <IsExempt
                validation={validation}
                reviewInformation={reviewInformation}
                readonly={readonly}
                props={props}
            />
            <ProprietaryCryptography
                validation={validation}
                reviewInformation={reviewInformation}
                readonly={readonly}
                props={props}
            />
            <ThirdPartyCryptography
                validation={validation}
                reviewInformation={reviewInformation}
                readonly={readonly}
                props={props}
            />
            <AvailableOnFrenchStore
                validation={validation}
                reviewInformation={reviewInformation}
                readonly={readonly}
                props={props}
            />
            <EncryptionUpdated
                validation={validation}
                reviewInformation={reviewInformation}
                readonly={readonly}
                props={props}
            />
        </Fragment>
    );
};

export default ExportCompliance;
