import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import progress from './spinner.svg';

class ButtonWithModal extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.toggleDisable = this.toggleDisable.bind(this);

        this.state = {
            show: false,
            disabled: false,
        };
    }

    handleClose() {
        this.setState({ show: false });
        this.setState({ disabled: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    toggleDisable() {
        this.setState({ disabled: !this.state.disabled });
    }

    render() {
        return (
            <React.Fragment>
                <Button bsStyle="link" onClick={this.handleShow}>
                    {this.props.buttonText}
                </Button>

                <Modal
                    className="warning-modal-dialog"
                    show={this.state.show}
                    onHide={this.state.disabled ? () => {
                    } : this.handleClose}>
                    <Modal.Header>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.modalText}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            onClick={
                                this.state.disabled
                                    ? () => {
                                    }
                                    : this.handleClose
                            }
                            className="hollow-modal-button"
                            disabled={this.state.disabled}>
                            Cancel
                        </Button>
                        <Button
                            bsStyle="success"
                            className="primary-modal-button"
                            onClick={
                                !this.state.disabled
                                    ? () => {
                                        this.toggleDisable();
                                        this.props.onClick().finally(this.handleClose);
                                    }
                                    : () => {
                                    }
                            }>
                            {this.state.disabled ? (
                                <img
                                    className="progress-feedback-icon"
                                    src={progress}
                                />
                            ) : (
                                'Paste metadata'
                            )}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ButtonWithModal;
