import React from 'react';
import { Sector } from 'recharts';
import {
    Blue,
    Gray,
    LightBlue,
    Yellow,
    Red,
    DateWithDays,
} from './constants.jsx';

import moment from 'moment';

export const renderActiveShape = props => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const daysDiff = millisecondsToDays(
        getTimeDiffUntilToday(payload.certificate_valid_until)
    );
    const isUrgent =
        getCellColorBasedOnExpirationDate(payload.certificate_valid_until) ===
        Red;
    const isWarning =
        getCellColorBasedOnExpirationDate(payload.certificate_valid_until) ===
        Yellow;

    return (
        <g>
            // This sector controls the "hovering" of a Cell
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={getColor(isUrgent, isWarning, 'hover')}
            />
            // This sector controls the "legend" (and second circle) displayed
            while hovering a Cell
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={getColor(isUrgent, isWarning, 'hover')}
            />
            // This is "arrow" going from the second circle to the legend
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={getColor(isUrgent, isWarning, 'hover')}
                fill="none"
            />
            // This is the "final point" going to the legend
            <circle
                cx={ex}
                cy={ey}
                r={2}
                fill={getColor(isUrgent, isWarning, 'hover')}
                stroke="none"
            />
            <text
                className="tooltip-label"
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={-7}
                textAnchor={textAnchor}
                fill={Blue}>
                {payload.name}
            </text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={9}
                textAnchor={textAnchor}
                fill={LightBlue}>{`${value} Devices (${(percent * 100).toFixed(1)}%)`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={25}
                textAnchor={textAnchor}
                fill={getColor(isUrgent, isWarning, 'legend')}>
                {isUrgent && daysDiff > 0 && `Expires in ${daysDiff} days!`}
                {isUrgent && daysDiff === 0 && 'Expires today!'}
                {isUrgent &&
                    daysDiff < 0 &&
                    `Expired ${Math.abs(daysDiff)} ago!`}
                {!isUrgent && `Valid until: ${payload.certificate_valid_until}`}
            </text>
            {isWarning && (
                <text
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    dy={42}
                    dx={textAnchor === 'start' ? 30 : 0}
                    textAnchor={textAnchor}
                    fill={getColor(isUrgent, isWarning, 'legend')}>
                    ({daysDiff} day/s left)
                </text>
            )}
        </g>
    );
};

export function timestampToDate(timestamp) {
    return timestampToString(timestamp, 'Do MMM YYYY, HH:mm');
}

export function timestampToDateTime(timestamp) {
    return timestampToString(timestamp, 'YYYY-MM-DD HH:mm:ss');
}

function timestampToString(timestamp, format) {
    if (timestamp !== null) {
        return moment(String(timestamp), 'X').utc().format(format);
    }
}

export const getTimeDiffUntilToday = dateInTheFuture => {
    /* Get the amount of milliseconds between a date in the future
     *  and today. The `format` parameter defines how the date is going to be parsed.
     * */
    const today = moment().toDate();
    const dateInTheFutureDateObject = moment(
        dateInTheFuture,
        DateWithDays
    ).toDate();
    return dateInTheFutureDateObject.getTime() - today.getTime();
};

export const millisecondsToDays = milliseconds => {
    /* Get the amount of days based on a number of milliseconds.
     * */
    return Math.ceil(milliseconds / (1000 * 3600 * 24));
};

export const getCellColorBasedOnExpirationDate = expirationDate => {
    /* Get the right color or a Cell based on the expiration date.
     * */
    let color = Blue;
    const milliseconds = getTimeDiffUntilToday(expirationDate);
    const days = millisecondsToDays(milliseconds);

    // If in less of 30 days the certificate expires, we show a red color
    if (days < 30) {
        color = Red;
        // If in less of 90 days the certificate expires, we show a yellow color
    } else if (days < 90) {
        color = Yellow;
    }
    return color;
};

export const getColor = (isUrgent, isWarning, eventType = null) => {
    /* Get the correct cell color depending on the severity and the eventType.
     * */
    let color = Blue;
    if (isUrgent) {
        color = Red;
    } else if (isWarning) {
        color = Yellow;
    } else if (eventType === 'hover') {
        color = LightBlue;
    } else if (eventType === 'legend') {
        color = Gray;
    }

    return color;
};

export function parsePythonBoolean(value) {
    return value === 'True';
}

export function isColumnEmpty(data, columnId) {
    return data.map(row => row[columnId]).some(element => element != null);
}
