import React from 'react';
import RemoveIconButton from '../RemoveIconButton.jsx';

export default function AppleWatchPreview({ src, onDelete, readonly = false }) {
    return (
        <div className="screenshot-dropzone-screenshot-container">
            {!readonly && (
                <div className="screenshot-dropzone-header">
                    <RemoveIconButton onClick={onDelete} />
                </div>
            )}
            <div className="screenshot-dropzone-apple-watch-preview">
                <img src={src} />
            </div>
        </div>
    );
}
