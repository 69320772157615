import React from 'react';

/** This component displays the Headers for the lists, such as that which can be
 * found on the Apps overview page.
 *  @param { Array.<{value: String, label: String }> } headers - An array of
 *  objects containing info regarding the headers of the table being rendered.
 *  The objects should contain the attributes value and label. For example :
 *  {
 *    label: Name,
 *    value : name,
 *   }
 *  @param sorting { {inverse: number, sortBy: string} } An Object containing the information about sorting
 *  @param { function } sortAttributeName - Function used for sorting the columns based on the column clicked
 *  **/
const TableHeader = ({ headers, sortAttributeName, sorting, setSelectAllApps }) => {
    /** This function controls the little chevrons that appear and show which sort direction is used **/
    const sortClass = header => {
        if (!sorting) return '';
        const sortDirection = sorting.inverse;
        const sortBy = sorting.sortBy;
        if (sortDirection === 1 && sortBy === header.value) {
            return 'can-sort headerSortDown';
        } else if (sortBy === header.value) {
            return 'can-sort headerSortUp';
        } else {
            return 'can-sort';
        }
    };

    return (
        <thead className='tableHeaders'>
            <tr>
                {headers.map((header, i) => {
                    if( header.label === 'Checkbox' ) {
                        return (
                         <th key={i}>
                             <input name="selectAllApps" onChange={() => setSelectAllApps(event)} className="checkbox" type="checkbox"/>
                         </th>
                        )
                    }
                    const onClick = sortAttributeName
                        ? () => sortAttributeName(header.value)
                        : null;
                    return (
                        <th
                            className={sortClass(header)}
                            key={i}
                            onClick={onClick}>
                            {header.label}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default TableHeader;
