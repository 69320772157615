import * as actionType from './types';

export const changeTextInput = (value, inputName, tabName) => ({
    type: actionType.CHANGE_TEXT_INPUT,
    value,
    inputName,
    tabName,
});

export const changeSelectInput = (value, inputName, tabName) => ({
    type: actionType.CHANGE_SELECT_INPUT,
    value,
    inputName,
    tabName,
});

export const changeRadioTableInput = (value, inputName, tabName) => ({
    type: actionType.CHANGE_RADIO_TABLE_INPUT,
    value,
    inputName,
    tabName,
});

export const changeLocalizableMetadata = localizableMetadata => ({
    type: actionType.CHANGE_LOCALIZABLE_METADATA,
    localizableMetadata,
});

export const changeLocalizableTextInput = (value, inputName, languageCode) => ({
    type: actionType.CHANGE_LOCALIZABLE_TEXT_INPUT,
    value,
    inputName,
    languageCode,
});
