import React from 'react';

export default function Expandable({ expanded, children }) {
    return (
        <div
            className={
                expanded
                    ? 'custom-expandable custom-expandable-expanded'
                    : 'custom-expandable'
            }>
            {children}
        </div>
    );
}
