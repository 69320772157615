import React from 'react';
import { Button } from 'react-bootstrap';

const RefreshInstallationData = ({
    initializeDeviceInstallationData,
    deviceInstallationEndpoint,
}) => {
    return (
        <Button
            bsStyle="primary"
            onClick={() =>
                initializeDeviceInstallationData(deviceInstallationEndpoint)
            }>
            Refresh Installation Data
        </Button>
    );
};

export default RefreshInstallationData;
