import React from 'react';
import { connect } from 'react-redux';
import { getAppData } from '../../../redux/modules/appHeaderData';
import AppDataComponent from './AppDataComponent';

const mapStateToProps = store => {
    return {
        appHeaderData: store.appHeaderData,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getAppData: endpoint => dispatch(getAppData(endpoint)),
    };
};

@connect(
    mapStateToProps,
    mapDispatchToProps
)
class AppDataContainer extends React.Component {
    componentDidMount() {
        this.props.getAppData(this.props.endpoint);
    }
    render() {
        return (
            <AppDataComponent
                {...this.props.appHeaderData}
                endpoint={this.props.endpoint}
            />
        );
    }
}

export default AppDataContainer;
