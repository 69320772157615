import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Error from '../icons/Error.jsx';

export default function ErrorFeedback({
    message,
    style,
    id,
    additionalClassName,
    errorIconStyle,
    preLine,
}) {
    return (
        <div
            className={`error-feedback-container ${
                additionalClassName ? additionalClassName : ''
            }`}
            style={style}>
            <OverlayTrigger
                placement="right"
                overlay={
                    <Tooltip
                        placement="right"
                        className={`in ${preLine ? 'tooltip-pre-line' : ''}`}
                        id={id}>
                        {message}
                    </Tooltip>
                }>
                <Error style={errorIconStyle} className="error-feedback-icon" />
            </OverlayTrigger>
        </div>
    );
}
