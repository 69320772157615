import * as actionType from '../actions/types.js';

export default function context(state = {}, action) {
    switch (action.type) {
        case actionType.ADD_CONTEXT:
            return {
                ...action.context,
            };
        case actionType.UPDATE_CONTEXT:
            return {
                ...state,
                [action.payload.tabName]: {
                    ...state[action.payload.tabName],
                    ...action.payload.context,
                },
            };
        case actionType.CHANGE_TEXT_INPUT:
            return {
                ...state,
                [action.tabName]: {
                    ...state[action.tabName],
                    [action.inputName]: action.value,
                },
            };

        case actionType.CHANGE_SELECT_INPUT:
            return {
                ...state,
                [action.tabName]: {
                    ...state[action.tabName],
                    [action.inputName]: action.value,
                },
            };

        case actionType.CHANGE_RADIO_TABLE_INPUT:
            return {
                ...state,
                [action.tabName]: {
                    ...state[action.tabName],
                    app_content: {
                        ...state[action.tabName].app_content,
                        [action.inputName]: action.value,
                    },
                },
            };
        case actionType.CHANGE_LOCALIZABLE_METADATA:
            return {
                ...state,
                localizable_metadata: action.localizableMetadata,
            };
        case actionType.CHANGE_LOCALIZABLE_TEXT_INPUT:
            let newState = {
                ...state,
                localizable_metadata: {
                    ...state.localizable_metadata,
                    metadata: [...state.localizable_metadata.metadata],
                },
            };
            const metadata = newState.localizable_metadata.metadata.find(
                metadata => metadata.language.code === action.languageCode
            );
            if (metadata) {
                metadata[action.inputName] = action.value;
            }
            return newState;
        case actionType.CHANGE_CONTENT_RATING_CATEGORY:
            return {
                ...state,
                content_rating: {
                    ...action.data,
                },
            };
        default:
            return state;
    }
}
