import React from 'react';

import AddNewVersionRow from './AddNewVersionRow.jsx';
import VersionRow from './VersionRow.jsx';
import Spinner from '../Common/Spinner.jsx';
import TableHeader from '../Common/ListElements/TableHeader';
import { withDataFetching } from '../Common/withDataFetching';

const headers = [
    { label: 'Version', value: 'version' },
    { label: 'Status', value: 'status' },
    { label: 'Last Activity', value: 'last_activity' },
    { label: 'Expiration', value: 'expiring_in' },
    { label: 'Direct Download', value: 'direct_download' },
];
export const VersionsListRaw = ({
    data,
    loadingElements,
    can_edit_signing_details,
    new_version_visible,
    can_add_new_version,
    app_approved,
    is_retired,
}) => {
    const displayNewVersionButton =
        new_version_visible &&
        can_add_new_version &&
        app_approved &&
        !is_retired;
    return (
        <div className="tab-pane" id="versions">
            <table className="table table-hover">
                <TableHeader headers={headers} />
                <tbody>
                    {displayNewVersionButton && (
                        <AddNewVersionRow
                            can_edit_signing_details={can_edit_signing_details}
                        />
                    )}
                    {data.map((version, i) => (
                        <VersionRow
                            key={i}
                            version={version}
                            can_edit_signing_details={can_edit_signing_details}
                        />
                    ))}
                </tbody>
            </table>
            {loadingElements && <Spinner loadingLabel={'Loading Versions'} />}
        </div>
    );
};

export default withDataFetching(VersionsListRaw);
