 import React from 'react';
import { postJSON } from '../Utils/postJSON.jsx';
import getJSON from '../Utils/getJSON.jsx';
import Checkbox from './Checkbox.jsx';

export default class AssignTargetsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTargets: [],
            loading: true,
        };
    }

    onTargetSelected = event => {
        let selectedTargets = this.state.selectedTargets;
        let targetId = parseInt(event.target.id);
        if (event.target.checked) {
            if (
                selectedTargets
                    .map(item => parseInt(item.id))
                    .indexOf(targetId) < 0
            ) {
                selectedTargets.push({
                    id: targetId,
                    name: event.target.value,
                });
            }
        } else {
            if (
                selectedTargets
                    .map(item => parseInt(item.id))
                    .indexOf(targetId) > -1
            ) {
                selectedTargets.splice(
                    selectedTargets
                        .map(item => parseInt(item.id))
                        .indexOf(targetId),
                    1
                );
            }
        }
        this.setState({
            selectedTargets: selectedTargets,
        });
    };

    assignTargets = () => {
        this.setState({
            loading: true,
        });

        if (this.state.selectedTargets.length <= 0) {
            this.setState({
                loading: false,
            });
            return;
        }
        postJSON(
            this.props.endpoint,
            this.state.selectedTargets.map(item => item.id)
        ).then(() => {
            this.setState({
                loading: false,
            });
            location.reload();
        });
    };

    cancelChanges = () => {
        const selectedTargets = [];

        this.state.initialTargets.forEach(item => {
            selectedTargets.push(item);
        });

        this.setState({
            selectedTargets: selectedTargets,
        });
    };

    componentWillUnmount() {
        this.modal.removeEventListener('hidden', this.cancelChanges);
    }

    async componentDidMount() {
        const data = await getJSON(this.props.endpoint);

        const selectedTargets = [];
        const initialTargets = [];

        data.forEach(item => {
            initialTargets.push(item);
            selectedTargets.push(item);
        });

        this.modal.addEventListener('hidden', this.cancelChanges);

        this.setState({
            initialTargets: initialTargets,
            selectedTargets: selectedTargets,
            loading: false,
        });
    }

    render() {
        return (
            <div
                ref={elem => (this.modal = elem)}
                className="modal fade"
                id="assign-targets-modal">
                <div className="modal-dialog">
                    <div
                        id="assign-targets-loader"
                        className="loader"
                        style={{
                            visibility: this.state.loading
                                ? 'visible'
                                : 'hidden',
                        }}>
                        <img
                            className="spinner-alone"
                            src={`${staticsPath}/img/spinner.gif`}
                        />
                    </div>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                onClick={this.cancelChanges}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h4 className="modal-title">
                                Assign/Unassign Targets
                            </h4>
                        </div>
                        <div className="modal-body">
                            <div>
                                {this.props.allTargets.map((target, index) => (
                                    <Checkbox
                                        id={target.id}
                                        key={target.id}
                                        value={target.name}
                                        checked={
                                            this.state.selectedTargets
                                                .map(item => item.id)
                                                .indexOf(target.id) > -1
                                        }
                                        changeInput={this.onTargetSelected}
                                        checkboxText={target.name}
                                    />
                                ))}
                            </div>
                            {this.state.selectedTargets.length <= 0 && (
                                <p>
                                    <span className="text-danger">
                                        At least one target should be assigned
                                        to the app!
                                    </span>
                                </p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.cancelChanges}
                                data-dismiss="modal">
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.assignTargets}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
