import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import FieldRow from '../layout/FieldRow.jsx';
import Section from '../layout/Section.jsx';
import MultiSelectField from '../inputs/MultiSelectField.jsx';
import GooglePlayLocalizableLanguageMetadataList from '../GooglePlayLocalizableLanguageMetadataList.jsx';
import Header from '../Header.jsx';

import {
    localizableLanguagesList,
    additionalLanguagesValueRenderer,
} from '../../util/formatters';

const GooglePlayLocalizableMetadata = ({ localizable, readonly, ...props }) => {
    const defaultLanguage = (
        localizable.metadata.find(language => language.is_default) || (localizable.metadata[0] || {})
    ).language;
    const isNotDefault = language =>
        defaultLanguage ? language.code !== defaultLanguage.code : true;
    const languageOptions = localizableLanguagesList(
        localizable.options.languages.filter(isNotDefault)
    );
    const selectedLanguages = localizableLanguagesList(
        localizable.languages.filter(isNotDefault)
    );
    return (
        <Grid fluid>
            <Header>Localizable Metadata</Header>
            {!readonly && (
                <Section id="choose-language-section">
                    <Row>
                        <FieldRow
                            id="choose_languages"
                            label="Additional languages">
                            <MultiSelectField
                                md={9}
                                label="Choose languages"
                                options={languageOptions}
                                onMultiChange={value =>
                                    props.handleChangeMultiSelect(
                                        [...value, defaultLanguage.code],
                                        'languages',
                                        props.csrf_token,
                                        props.app_version
                                    )
                                }
                                selected={selectedLanguages}
                                selectAllLabel={'All languages'}
                                valueRenderer={additionalLanguagesValueRenderer}
                            />
                        </FieldRow>
                    </Row>
                </Section>
            )}
            <GooglePlayLocalizableLanguageMetadataList
                localizable={localizable}
                expandLocalizableLanguage={props.expandLocalizableLanguage}
                handleChangeTextInput={props.handleChangeTextInput}
                handleBlurTextInput={props.handleBlurTextInput}
                handleChangeScreenshots={props.handleChangeScreenshots}
                handleDeleteScreenshot={props.handleDeleteScreenshot}
                handleLoadFromDefaultLanguage={
                    props.handleLoadFromDefaultLanguage
                }
                handleLoadFromDefaultLanguageToOthers={
                    props.handleLoadFromDefaultLanguageToOthers
                }
                handleScreenshotsError={props.handleScreenshotsError}
                handleChangeGraphicAsset={props.handleChangeGraphicAsset}
                handleDeleteGraphicAsset={props.handleDeleteGraphicAsset}
                app_id={props.app_id}
                app_version={props.app_version}
                csrf_token={props.csrf_token}
                validation={props.validation}
                diff={props.diff}
                readonly={readonly}
                handleSetCorrect={props.handleSetCorrect}
            />
        </Grid>
    );
};

export default GooglePlayLocalizableMetadata;
