import React from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { Row, Col } from 'react-bootstrap';
import FieldFeedback from './FieldFeedback.jsx';
import ReadOnlyValueList from './ReadOnlyValueList.jsx';

class MultiSelectField extends React.Component {
    static defaultProps = {
        ...React.Component.defaultProps,
        readonly: false,
    };

    // Filtering of the MultiSelect component is broken,
    // it yields "Chinese" when searching for "united"
    customFilter(options, filter) {
        const optionIncludesText = option => {
            const label = option.label || '';
            return label.toLowerCase().includes(filter.toLowerCase());
        };
        return options.filter(optionIncludesText);
    }

    render() {
        const selected = this.props.selected || [];
        return (
            <Row>
                <Col
                    xs={this.props.xs}
                    sm={this.props.sm}
                    md={this.props.readonly ? 12 : this.props.md}
                    lg={this.props.lg}>
                    {this.props.readonly ? (
                        <ReadOnlyValueList
                            selected={this.props.selected}
                            label={this.props.label}
                            previousValue={this.props.previousValue}
                        />
                    ) : (
                        <div>
                            <MultiSelect
                                options={this.props.options}
                                onChange={(e) => {
                                    this.props.onMultiChange(e.map(item => item.value))
                                }}
                                value={this.props.selected}
                                valueRenderer={this.props.valueRenderer}
                                ItemRenderer={this.props.ItemRenderer}
                                selectAllLabel={this.props.selectAllLabel}
                                isLoading={this.props.isLoading}
                                disabled={this.props.disabled}
                                disableSearch={this.props.disableSearch}
                                filterOptions={this.customFilter}
                                labelledBy={''}
                            />
                            {/*This is necessary to use this component in html forms */}
                            <input
                                hidden={true}
                                name={this.props.label}
                                value={selected}
                                onChange={() => {}}
                            />
                        </div>
                    )}
                </Col>
                <FieldFeedback
                    progress={this.props.progress}
                    error={this.props.error}
                    dirty={this.props.dirty}
                    id={this.props.id}
                />
                {this.props.children}
            </Row>
        );
    }
}

export default MultiSelectField;
