import React, { PureComponent } from 'react';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Calendar from '../icons/Calendar.jsx';
import FieldFeedback from './FieldFeedback.jsx';
import ReadOnlyDiffValue from './ReadOnlyDiffValue.jsx';

const DATE_FORMAT = 'YYYY-MM-DD';

export default class CustomDatePicker extends PureComponent {
    static defaultProps = {
        sm: 7,
        readonly: false,
    };

    render() {
        const {
            sm,
            error,
            progress,
            dirty,
            id,
            selected,
            readonly,
            previousValue,
            ...props
        } = this.props;
        return (
            <Row>
                <Col sm={sm}>
                    {readonly ? (
                        <ReadOnlyDiffValue
                            value={
                                selected ? selected.format(DATE_FORMAT) : '-'
                            }
                            previousValue={
                                previousValue &&
                                moment(previousValue).format(DATE_FORMAT)
                            }
                        />
                    ) : (
                        <div className="custom-datepicker-wrapper">
                            <div className="custom-datepicker__icon">
                                <Calendar
                                    color={
                                        props.disabled ? '#999999' : '#454545'
                                    }
                                />
                            </div>
                            <DatePicker
                                dateFormat={DATE_FORMAT}
                                selected={selected}
                                {...props}
                                onChange={this.handleDateChange}
                            />
                        </div>
                    )}
                </Col>
                <FieldFeedback
                    progress={progress}
                    error={error}
                    dirty={dirty}
                    id={id}
                />
            </Row>
        );
    }

    handleDateChange = selectedDate => {
        this.props.onChange(
            selectedDate ? selectedDate.format(DATE_FORMAT) : null
        );
    };
}
