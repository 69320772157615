import React, { Component } from 'react';
import { postJSON } from '../Utils/postJSON.jsx';
import getJSON from '../Utils/getJSON.jsx';
import Checkbox from './Checkbox.jsx';

export default class CheckboxWithAPI extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uniqueId: '',
            checked: false,
            text: this.props.text,
        };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange() {
        const newCheckboxState = !this.state.checked;
        this.setState({
            checked: newCheckboxState,
        });
        postJSON(this.props.endpoint, { isChecked: newCheckboxState });
    }

    async componentDidMount() {
        const data = await getJSON(this.props.endpoint);
        this.setState({
            uniqueId: data.unique_id,
            checked: data.is_checked,
        });
    }

    render() {
        return (
            <Checkbox
                id={this.state.uniqueId}
                value={this.state.uniqueId}
                checked={this.state.checked}
                changeInput={this.handleInputChange}
                checkboxText={this.props.text}
            />
        );
    }
}
