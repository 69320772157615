import React from 'react';

const GovernmentNotification = () => {
    return (
        <div className="submission-information-info-box export-compliance-grid">
            <i className="glyphicon glyphicon-exclamation-sign submission-information-exclamation" />
            <p className="submission-information-text">
                If you are making use of ATS or making a call to HTTPS, you are
                required to submit a year-end self classification report to the
                US government.&nbsp;
                <a
                    href="https://www.bis.doc.gov/index.php/policy-guidance/encryption/4-reports-and-reviews/a-annual-self-classification"
                    target="_blank">
                    Learn More
                </a>
            </p>
        </div>
    );
};

export default GovernmentNotification;
