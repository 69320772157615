import React from 'react';

const WordCounter = ({ length, max_length }) => {
    return max_length ? (
        <div className="word-counter">{`${
            length ? length : '0'
        }/${max_length}`}</div>
    ) : null;
};

export default WordCounter;
