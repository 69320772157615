import React from 'react';

/** This is a modal that pops up when the user clicks on the "+ New App" button**/
const NewAppModal = () => {
    return (
        <div
            className="modal fade"
            id="app-modal"
            tabIndex="-1"
            role="dialog"
            aria-hidden="true">
            <div className="modal-dialog modal-lg new-app-dialog" />
        </div>
    );
};

export default NewAppModal;
