import React from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from 'react-bootstrap';
import { navigateToTab } from '../../../redux/modules/navigation';
import { submitForm } from '../../../redux/modules/fieldsValidation';
import scrollTop from '../util/scrollTop.js';
import { selectCurrentTab } from '../selectors/navigation';
import {isAnyAttributeTrue} from "../../Utils/helpers";

const FormNavigation = ({
    acl,
    currentTab,
    navigateToPrevious,
    navigateToNext,
    totalTabCount,
    isSubmitting,
    onSubmit,
    uploaded_binary,
    customFieldsAnyInProgress,
}) => (
    <div id="floating-buttons">
        <Grid>
            {currentTab > 0 && (
                <Button onClick={navigateToPrevious}>Previous</Button>
            )}
            {currentTab < totalTabCount - 1 && (
                <Button className="pull-right" onClick={navigateToNext}>
                    Next
                </Button>
            )}
            {currentTab === totalTabCount - 1 &&
            acl.request_signing_permitted ? (
                <Button
                    type="submit"
                    name="submit"
                    className="pull-right"
                    bsStyle="primary"
                    onClick={!isSubmitting ? onSubmit : null}
                    disabled={isSubmitting || !uploaded_binary || customFieldsAnyInProgress }>
                    {isSubmitting ? 'Saving...' : 'Submit'}
                </Button>
            ) : null}
        </Grid>
    </div>
);

const mapStateToProps = store => ({
    currentTab: selectCurrentTab(store),
    isSubmitting: store.validation.isSubmitting,
    acl: store.context.acl,
    appId: store.context.app_id,
    versionId: store.context.app_version,
    uploaded_binary: store.context.specification.general.uploaded_binary,
    customFieldsAnyInProgress: isAnyAttributeTrue(store.customField.progress)
});

const mergeDispatchWithProps = (
    { currentTab, appId, versionId, ...props },
    { navigateToTab, submitForm, ...actions },
    { totalTabCount }
) => {
    return {
        ...props,
        ...actions,
        currentTab,
        totalTabCount,
        navigateToPrevious: () => {
            scrollTop();
            return (
                currentTab > 0 &&
                navigateToTab(appId, versionId, currentTab - 1)
            );
        },
        navigateToNext: () => {
            scrollTop();
            return (
                currentTab < totalTabCount - 1 &&
                navigateToTab(appId, versionId, currentTab + 1)
            );
        },
        onSubmit: () => {
            scrollTop();
            return submitForm();
        },
    };
};

export default connect(
    mapStateToProps,
    { navigateToTab, submitForm },
    mergeDispatchWithProps
)(FormNavigation);
