import React from 'react';
import ReactDOM from 'react-dom';
import VersionsList from './App/VersionsList.jsx';
import UserList from './UsersList/UsersList.jsx';
import AppList from './AppsList/AppList.jsx';
import VmWareAirWatchOrganizationGroupTree from './Targets/VmWareAirWatchOrganizationGroupTree.jsx';
import PublishToVmWareAirWatchModalContainer from './Targets/PublishToVmWareAirWatchModalContainer.jsx';
import AirWatchPublishingSettingsContainer from './Targets/AirWatchPublishingSettingsContainer.jsx';
import DisplayErrors from './AppOverview/ErrorMessages/DisplayErrors.jsx';
import WillNextAppBeFeaturedCheckboxList from './App/WillNextAppBeFeaturedCheckboxList.jsx';
import AssignTargetsModal from './App/AssignTargetsModal.jsx';
import EditEntitlementsButton from './App/EditEntitlements/EditEntitlementsButton.jsx';
import RenderDifference from './AppData/Difference/RenderDifference.jsx';
import VersionAppDraft from './AppDraft/components/index.jsx';
import { SnackbarProvider } from 'notistack';
import CustomFieldContainer from './CustomFields/CustomFieldContainer.jsx';
import WelcomeModal from './Misc/WelcomeModal.jsx';
import CreateNewProvisioningProfile from './App/CreateNewProvisioningProfile.jsx';
import CheckboxWithAPI from './App/CheckboxWithAPI.jsx';
import AppVersionOverview from './AppOverview/index.jsx';
import { store } from '../redux/configureStore';
import AppDataContainer from './App/ApplicationHeader/AppDataContainer';
import DeleteUser from './User/DeleteUser';
import HelpAndSupport from './Misc/HelpAndSupport.jsx';
import AppVersionUploadButton from './App/AppVersionUploadButton';
import RejectVersionButton from './App/RejectVersionModal.jsx';
import ApproveVersionButton from './App/ApproveVersionModal.jsx';
import RejectionHistory from './App/RejectionHistory.jsx';
import NewAndroidCertificateButton from './Certificates/NewAndroidCertificateButton.jsx';
import { parsePythonBoolean } from './utils.jsx';
import DeviceInstallationsContainer from './AppVersionOverview/DeviceInstallationsContainer.jsx';
import MobileIronDeviceInstallationTab from './AppVersionOverview/MobileIronDeviceInstallationTab';
import { addContext } from '../redux/modules/appVersionOverview/appVersionPage';
import CardsInLanes from './App/CardsInLanes';
import { Provider } from 'react-redux';
import AddDjangoContextToRedux from './AppDraft/containers/AddDjangoContextToRedux';

let willNextAppBeFeatured = document.getElementById(
    'will_next_app_be_featured'
);
if (willNextAppBeFeatured) {
    let context = JSON.parse(willNextAppBeFeatured.getAttribute('context'));
    ReactDOM.render(
        <WillNextAppBeFeaturedCheckboxList context={context} />,
        willNextAppBeFeatured
    );
}

let elements = document.getElementsByClassName('per-app-vpn-by-label-only');
for (var i = 0; i < elements.length; i++) {
    let perAppVpnByLabelOnly = document.getElementById(elements[i].id);
    if (perAppVpnByLabelOnly) {
        let endpoint = perAppVpnByLabelOnly.getAttribute('endpoint');
        ReactDOM.render(
            <CheckboxWithAPI
                endpoint={endpoint}
                text="Per app VPN by label only"
            />,
            perAppVpnByLabelOnly
        );
    }
}

let perAppVPNElements = document.getElementsByClassName('per-app-vpn');
for (var i = 0; i < perAppVPNElements.length; i++) {
    let perAppVPNContainer = document.getElementById(perAppVPNElements[i].id);
    if (perAppVPNContainer) {
        let endpoint = perAppVPNContainer.getAttribute('endpoint');
        ReactDOM.render(
            <CardsInLanes endpoint={endpoint} />,
            perAppVPNContainer
        );
    }
}

let modalAssignTargets = document.getElementById('modal_assign_targets');
if (modalAssignTargets) {
    let context = JSON.parse(modalAssignTargets.getAttribute('context'));
    ReactDOM.render(
        <AssignTargetsModal
            endpoint={context.endpoint}
            allTargets={context.all_targets}
        />,
        modalAssignTargets
    );
}

let versionsListContainer = document.getElementById('versionsListContainer');
if (versionsListContainer) {
    let context = JSON.parse(versionsListContainer.getAttribute('context'));
    let can_edit_signing_details = context.acl.can_edit_signing_details;
    let is_retired = context.app.is_retired;
    let new_version_visible = context.acl.new_version_visible;
    let can_add_new_version = context.app.can_add_new_version;
    let app_approved = context.app.approved;
    let endpoint = versionsListContainer.getAttribute('dataEndpoint');
    let element = (
        <VersionsList
            can_edit_signing_details={can_edit_signing_details}
            is_retired={is_retired}
            new_version_visible={new_version_visible}
            can_add_new_version={can_add_new_version}
            app_approved={app_approved}
            endpoint={endpoint}
        />
    );

    ReactDOM.render(element, versionsListContainer);
}

let appOverviewErrors = document.getElementById('traceback-admin-errors');
if (appOverviewErrors) {
    let publicErrorText = appOverviewErrors.getAttribute('publicErrorText');
    let adminErrorText = appOverviewErrors.getAttribute('adminErrorText');
    let showAdminError = appOverviewErrors.getAttribute('showAdminError');
    ReactDOM.render(
        <DisplayErrors
            publicErrorText={publicErrorText}
            adminErrorText={adminErrorText}
            showAdminError={showAdminError}
        />,
        appOverviewErrors
    );
}

const usersList = document.getElementById('users_list');
if (usersList) {
    const endpoint = usersList.getAttribute('dataEndpoint');
    const canEditProfile = parsePythonBoolean(
        usersList.getAttribute('canEditProfile')
    );
    ReactDOM.render(
        <UserList endpoint={endpoint} canEditProfile={canEditProfile} />,
        usersList
    );
}

let autoPublishSettings = document.getElementById(
    'airwatch_publishing_settings'
);
if (autoPublishSettings) {
    let context = JSON.parse(autoPublishSettings.getAttribute('context'));

    ReactDOM.render(
        <AirWatchPublishingSettingsContainer
            publishingSettings={context.publishingSettings}
            appName={context.appName}
        />,
        autoPublishSettings
    );
}

let targetOrganizationGroupsTree = document.getElementById(
    'target_organization_groups'
);
if (targetOrganizationGroupsTree) {
    let endpoint = targetOrganizationGroupsTree.getAttribute('endpoint');
    ReactDOM.render(
        <VmWareAirWatchOrganizationGroupTree
            endpoint={endpoint}
            readonly={true}
            showSelectAllButton={false}
            showCheckboxes={false}
        />,
        targetOrganizationGroupsTree
    );
}

let publishToOrganizationGropus = document.getElementById(
    'publish_to_organization_groups'
);
if (publishToOrganizationGropus) {
    let endpoint = publishToOrganizationGropus.getAttribute('endpoint');
    ReactDOM.render(
        <VmWareAirWatchOrganizationGroupTree
            endpoint={endpoint}
            readonly={true}
            showCheckboxes={true}
        />,
        publishToOrganizationGropus
    );
}

let publishToAirWatchModalContainer = document.getElementById(
    'publish-to-vmware_airwatch-modal-container'
);
if (publishToAirWatchModalContainer) {
    let context = JSON.parse(
        publishToAirWatchModalContainer.getAttribute('context')
    );
    let version = context.version;
    let build = context.build;
    let platform = context.platform;
    let app_name = context.app_name;
    let target_name = context.target_name;
    let target_data = context.target_data;

    ReactDOM.render(
        <PublishToVmWareAirWatchModalContainer
            version={version}
            build={build}
            platform={platform}
            app_name={app_name}
            target_name={target_name}
            target_data={target_data}
        />,
        publishToAirWatchModalContainer
    );
}

let editAppBaseDataContainer = document.getElementById(
    'editAppBaseDataContainer'
);
if (editAppBaseDataContainer) {
    let endpoint = editAppBaseDataContainer.getAttribute('dataEndpoint');
    ReactDOM.render(
        <AppDataContainer store={store} endpoint={endpoint} />,
        editAppBaseDataContainer
    );
}

let appListContainer = document.getElementById('appListContainer');
if (appListContainer) {
    let context = JSON.parse(appListContainer.getAttribute('context'));

    let endpoint = context.endpoint;
    let createAppEndpoint = context.create_app_endpoint;
    let showRetiredApps = context.show_retired_apps;
    let newAppButtonVisible = context.acl.new_app_button_visible;
    let listSorting = context.list_sorting;
    let sortingEndpoint = context.sorting_endpoint;
    let isAdmin = context.is_admin;
    let displayEnv = context.display_env;

    ReactDOM.render(
      <SnackbarProvider maxSnack={3}>
          <AppList
            newAppButtonVisible={newAppButtonVisible}
            endpoint={endpoint}
            showRetiredApps={showRetiredApps}
            createAppEndpoint={createAppEndpoint}
            updateInterval={15000}
            sorting={listSorting}
            sortingEndpoint={sortingEndpoint}
            isAdmin={isAdmin}
            displayEnv={displayEnv}
        />
      </SnackbarProvider>,
        appListContainer
    );
}

let editEntitlementsModalButton = document.getElementById(
    'edit-entitlements-button-container'
);
if (editEntitlementsModalButton) {
    let endpoint = editEntitlementsModalButton.getAttribute('dataEndpoint');
    ReactDOM.render(
        <EditEntitlementsButton endpoint={endpoint} />,
        editEntitlementsModalButton
    );
}

const versionDifference = document.getElementById('version_difference');
if (versionDifference) {
    const version = versionDifference.getAttribute('version');
    ReactDOM.render(<RenderDifference version={version} />, versionDifference);
}

const appStoreDraftForm = document.getElementById(
    'app-store-draft-form-container'
);
if (appStoreDraftForm) {
    const action = appStoreDraftForm.getAttribute('action');
    const method = appStoreDraftForm.getAttribute('method');
    const className = appStoreDraftForm.getAttribute('class');
    const enctype = appStoreDraftForm.getAttribute('enctype');
    const backHref = appStoreDraftForm.getAttribute('backHref');
    const context = JSON.parse(appStoreDraftForm.getAttribute('tabs-context'));
    const csrf_token = appStoreDraftForm.getAttribute('csrf_token');
    context.backHref = backHref;
    context.action = action;
    context.csrf_token = csrf_token;

    appStoreDraftForm.removeAttribute('tabs-context');

    ReactDOM.render(
        <VersionAppDraft
            method={method}
            className={className}
            enctype={enctype}
            context={context}
            draftFormType="app-store"
        />,
        appStoreDraftForm
    );
}

const googlePlayDraftForm = document.getElementById(
    'google-play-draft-form-container'
);
if (googlePlayDraftForm) {
    const action = googlePlayDraftForm.getAttribute('action');
    const method = googlePlayDraftForm.getAttribute('method');
    const className = googlePlayDraftForm.getAttribute('class');
    const enctype = googlePlayDraftForm.getAttribute('enctype');
    const backHref = googlePlayDraftForm.getAttribute('backHref');
    const context = JSON.parse(
        googlePlayDraftForm.getAttribute('tabs-context')
    );
    const csrf_token = googlePlayDraftForm.getAttribute('csrf_token');
    context.backHref = backHref;
    context.action = action;
    context.csrf_token = csrf_token;

    googlePlayDraftForm.removeAttribute('tabs-context');

    ReactDOM.render(
        <VersionAppDraft
            method={method}
            className={className}
            enctype={enctype}
            context={context}
            draftFormType="google-play"
        />,
        googlePlayDraftForm
    );
}

const appStoreVersionOverview = document.getElementById(
    'app-store-version-overview-container'
);
if (appStoreVersionOverview) {
    const context = JSON.parse(
        appStoreVersionOverview.getAttribute('tabs-context')
    );
    const csrf_token = appStoreVersionOverview.getAttribute('csrf_token');
    context.csrf_token = csrf_token;
    context.readonly = true;

    appStoreVersionOverview.removeAttribute('tabs-context');

    ReactDOM.render(
        <AppVersionOverview context={context} draftFormType="app-store" />,
        appStoreVersionOverview
    );
}

const googlePlayVersionOverview = document.getElementById(
    'google-play-version-overview-container'
);
if (googlePlayVersionOverview) {
    const context = JSON.parse(
        googlePlayVersionOverview.getAttribute('tabs-context')
    );
    const csrf_token = googlePlayVersionOverview.getAttribute('csrf_token');
    context.csrf_token = csrf_token;
    context.readonly = true;

    googlePlayVersionOverview.removeAttribute('tabs-context');

    ReactDOM.render(
        <AppVersionOverview context={context} draftFormType="google-play" />,
        googlePlayVersionOverview
    );
}

const customFieldContainer = document.getElementById('custom-field-container');
if (customFieldContainer) {
    const context = JSON.parse(customFieldContainer.getAttribute('context'));
    if (Object.keys(context).length > 0) {
        ReactDOM.render(
            <Provider store={store}>
                <AddDjangoContextToRedux context={context}>
                    <CustomFieldContainer/>
                </AddDjangoContextToRedux>
            </Provider>,
            customFieldContainer
        );
    }
}

const approveVersionContainer = document.getElementById(
    'approve-version-container'
);
if (approveVersionContainer) {
    let text = approveVersionContainer.getAttribute('text');
    let endpoint = approveVersionContainer.getAttribute('endpoint');
    ReactDOM.render(
        <ApproveVersionButton text={text} endpoint={endpoint} />,
        approveVersionContainer
    );
}

const rejectVersionContainer = document.getElementById(
    'reject-version-container'
);
if (rejectVersionContainer) {
    let endpoint = rejectVersionContainer.getAttribute('endpoint');
    let redirectUrl = rejectVersionContainer.getAttribute('redirectUrl');
    ReactDOM.render(
        <RejectVersionButton endpoint={endpoint} redirectUrl={redirectUrl}/>,
        rejectVersionContainer
    );
}

const rejectionHistoryContainer = document.getElementById(
    'rejection-history-container'
);
if (rejectionHistoryContainer) {
    let endpoint = rejectionHistoryContainer.getAttribute('endpoint');
    let indent = rejectionHistoryContainer.getAttribute('indent');
    ReactDOM.render(
        <RejectionHistory endpoint={endpoint} indent={indent} />,
        rejectionHistoryContainer
    );
}

let welcomeContainer = document.getElementById('welcome-container');
if (welcomeContainer) {
    const serviceName = welcomeContainer.getAttribute('serviceName');
    const endpoint = welcomeContainer.getAttribute('endpoint');
    const modalBody = welcomeContainer.getAttribute('modalBody');
    ReactDOM.render(
        <WelcomeModal
            serviceName={serviceName}
            endpoint={endpoint}
            modalBody={modalBody}
        />,
        welcomeContainer
    );
}

let createProvisioningProfileContainer = document.getElementById(
    'create-provisioning-profile-container'
);
if (createProvisioningProfileContainer) {
    const endpoint = createProvisioningProfileContainer.getAttribute(
        'endpoint'
    );
    ReactDOM.render(
        <CreateNewProvisioningProfile endpoint={endpoint} />,
        createProvisioningProfileContainer
    );
}

let deleteUserContainer = document.getElementById('delete_user_container');
if (deleteUserContainer) {
    const endpoint = deleteUserContainer.getAttribute('data_endpoint');
    const csrf_token = deleteUserContainer.getAttribute('csrf_token');
    const redirectUrl = deleteUserContainer.getAttribute('redirect_url');
    const isOwnProfile =
        deleteUserContainer.getAttribute('is_own_profile') === 'True';
    ReactDOM.render(
        <DeleteUser
            endpoint={endpoint}
            csrf_token={csrf_token}
            redirectUrl={redirectUrl}
            isOwnProfile={isOwnProfile}
        />,
        deleteUserContainer
    );
}

let helpAndSupportContainer = document.getElementById(
    'help-and-support-container'
);
if (helpAndSupportContainer) {
    const modalBody = helpAndSupportContainer.getAttribute('modalBody');
    ReactDOM.render(
        <HelpAndSupport modalBody={modalBody} />,
        helpAndSupportContainer
    );
}

let itunesUploadButton = document.getElementById('app-version-uploading-status');
if (itunesUploadButton) {
    const appVersionID = itunesUploadButton.getAttribute('app_version');
    ReactDOM.render(
        <AppVersionUploadButton appVersionID={appVersionID} />,
        itunesUploadButton
    );
}

let testFlightUploadButton = document.getElementById('app-version-testflight-uploading-status');
if (testFlightUploadButton) {
    const appVersionID = testFlightUploadButton.getAttribute('app_version');
    ReactDOM.render(
        <AppVersionUploadButton appVersionID={appVersionID} />,
        testFlightUploadButton
    );
}

let newAndroidCertificateForms = document.getElementsByClassName(
    'new-android-certificate-button'
);
for (let element of newAndroidCertificateForms) {
    ReactDOM.render(<NewAndroidCertificateButton />, element);
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////// App Version Overview Components /////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// This adds context information to the AppVersion Page
let appVersionPage = document.getElementById('app-version-page');
if (appVersionPage) {
    let appID = appVersionPage.getAttribute('applicationID');
    let deviceInstallationsEndpoint = appVersionPage.getAttribute(
        'deviceInstallationsEndpoint'
    );
    store.dispatch(addContext(appID, 'appID'));
    store.dispatch(
        addContext(deviceInstallationsEndpoint, 'deviceInstallationsEndpoint')
    );
}

let mobileIronDeviceInstallationTab = document.getElementById(
    'mobileIronDeviceInstallationTab'
);
if (mobileIronDeviceInstallationTab) {
    ReactDOM.render(
        <MobileIronDeviceInstallationTab store={store} />,
        mobileIronDeviceInstallationTab
    );
}

let deviceInstallationsContainer = document.getElementById(
    'deviceInstallationsContainer'
);
if (deviceInstallationsContainer) {
    let endpoint = deviceInstallationsContainer.getAttribute('dataEndpoint');
    ReactDOM.render(
        <DeviceInstallationsContainer endpoint={endpoint} store={store} />,
        deviceInstallationsContainer
    );
}
