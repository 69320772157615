import React, { Fragment } from 'react';
import Checkmark from './icons/Checkmark.jsx';
import Error from './icons/Error.jsx';

export default function TabLabel({ title, done, error }) {
    return (
        <Fragment>
            {done && (
                <Checkmark className="pull-left" color="rgb(65, 174, 137)" />
            )}
            {error && <Error className="pull-left" color="#EB2F27" />}
            <span>{title}</span>
        </Fragment>
    );
}
