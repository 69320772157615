import axios from 'axios';

const getJSON = url => {
    return axios(url, {
        method: 'GET',
        credentials: 'same-origin',
    }).then(res => res.data);
};

export default getJSON;
