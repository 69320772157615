import React from 'react';
import _ from 'lodash';
import 'whatwg-fetch';

import { PieChart, Pie, Cell } from 'recharts';
import {
    renderActiveShape,
    getCellColorBasedOnExpirationDate,
} from '../utils.jsx';

export default class DeviceInstallationsChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(nextState, this.state);
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    render() {
        return (
            <PieChart className="piechart" width={670} height={300}>
                <Pie
                    labelLine={false}
                    activeIndex={this.state.activeIndex}
                    activeShape={renderActiveShape}
                    cx={'50%'}
                    cy={'50%'}
                    data={this.props.versions}
                    dataKey={'value'}
                    innerRadius={50}
                    outerRadius={75}
                    onMouseEnter={this.onPieEnter}>
                    {this.props.versions.map((entry, index) => (
                        <Cell
                            key={index}
                            fill={getCellColorBasedOnExpirationDate(
                                entry.certificate_valid_until
                            )}
                        />
                    ))}
                </Pie>
            </PieChart>
        );
    }
}
