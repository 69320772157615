import React from "react";

const CustomSwitch = ({ name, value, onChange, rounded, disabled }) => {
  return (
    <>
      <label className={`my-switch`}>
        <input type='checkbox' disabled={disabled} name={name} checked={value} onChange={onChange} />
        <span className={`slider ${disabled ? 'my-switch-disabled' : ''} ${rounded ? 'rounded' : ''}`}></span>
      </label>
    </>
  );
};
export default CustomSwitch;