import React from 'react';
import FieldRow from '../../../layout/FieldRow';
import MultiRadioField, {
    mapToNumericBooleanOptions,
    numericBooleanOptions,
} from '../../../inputs/MultiRadioField';
import Expandable from '../../../Expandable';
import GovernmentNotification from './GovernmentNotification';
import UploadManually from './UploadManually';
import { noIsSelected, yesIsSelected } from '../ResetOptions';
import XcodeNotification from './XcodeNotification';

const AvailableOnFrenchStore = ({
    validation,
    reviewInformation,
    readonly,
    props,
}) => {
    const contains_third_party_or_proprietary_cryptography = (
      yesIsSelected(reviewInformation.export_compliance_contains_third_party_cryptography) ||
      yesIsSelected(reviewInformation.export_compliance_contains_proprietary_cryptography)
    );
    return (
        <Expandable
            expanded={
                reviewInformation.export_compliance_uses_encryption &&
                noIsSelected(reviewInformation.export_compliance_is_exempt) &&
                contains_third_party_or_proprietary_cryptography
            }>
            <FieldRow
                id="export-compliance-available-on-french-store"
                label=""
                required>
                <div>
                    Is your app going to be available on the App Store in
                    France?
                </div>
                <MultiRadioField
                    name="export_compliance_available_on_french_store"
                    id="export-compliance-available-on-french-store"
                    error={
                        validation.errors
                            .export_compliance_available_on_french_store
                    }
                    progress={
                        validation.isLoading
                            .export_compliance_available_on_french_store
                    }
                    dirty={
                        validation.dirty
                            .export_compliance_available_on_french_store
                    }
                    selected={mapToNumericBooleanOptions(
                        reviewInformation.export_compliance_available_on_french_store
                    )}
                    readonly={readonly}
                    onChange={event =>
                        props.handleChangeRadio(
                            event,
                            null,
                            props.csrf_token,
                            props.app_version
                        )
                    }
                    options={numericBooleanOptions}
                />
                {noIsSelected(reviewInformation.export_compliance_available_on_french_store) &&
                noIsSelected(reviewInformation.export_compliance_contains_proprietary_cryptography) &&
                <div><XcodeNotification/><GovernmentNotification/></div>}

                <UploadManually
                    show={yesIsSelected(reviewInformation.export_compliance_available_on_french_store) ||
                    (noIsSelected(reviewInformation.export_compliance_available_on_french_store) &&
                      yesIsSelected(reviewInformation.export_compliance_contains_proprietary_cryptography))
                    }
                />
            </FieldRow>
        </Expandable>
    );
};

export default AvailableOnFrenchStore;
