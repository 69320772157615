import React from 'react';
import ScreenshotField from './ScreenshotField';
import TabsSection from './TabsSection';
import TitleWithError from '../icons/TitleWithError';
import Asterisk from '../Asterisk';

export default function ScreenshotTabsSectionList({
    assetsMap,
    dirty,
    progress,
    devicePropsMap,
    readonly,
    maxAssetsCount,
    rangeDimensions,
    dimensionsMap,
    sectionErrorKey,
    handleSelect,
    handleChange,
    handleDelete,
    handleError,
    errors
}) {
    const getAssetRequiredError = (assets, inputName) => {
        return errors && errors[inputName];
    };

    const getActiveTabAssetError = (id) => {
        return errors[id] && errors[id].message;
    };

    const renderSectionHeader = () => {
        return (<h3 className="tab-page-sub-header">
            {sectionErrorKey ?
                TitleWithError(<Asterisk>Screenshots</Asterisk>, errors[sectionErrorKey]) : 'Screenshots'
            }
        </h3>);
    };

    return (
        <>
            {renderSectionHeader()}
            {Object.keys(devicePropsMap).map((key) => {
                const sectionId = `${key}_screenshots`;
                return (
                    <TabsSection
                        id={sectionId}
                        key={sectionId}
                        dirty={dirty}
                        progress={progress}
                        handleSelect={() => handleSelect && handleSelect(sectionId)}>
                        {devicePropsMap[key] && devicePropsMap[key].map(({inputName, ...deviceProps}) => {
                            const assets = assetsMap[inputName];
                            const dimensions = dimensionsMap ? dimensionsMap[inputName] : undefined;
                            const assetRequiredError = getAssetRequiredError(assets, inputName);
                            const activeTabAssetError = getActiveTabAssetError(sectionId);

                            const name = deviceProps.required ?
                                <Asterisk>{deviceProps.name}</Asterisk> : deviceProps.name;
                            const title = TitleWithError(name, assetRequiredError);
                            const activeTabTitle = TitleWithError(name,
                                activeTabAssetError || assetRequiredError);

                            return (
                                <ScreenshotField
                                    key={inputName}
                                    title={title}
                                    activeTabTitle={activeTabTitle}
                                    maxAssetsCount={maxAssetsCount}
                                    readonly={readonly}
                                    assets={assets}
                                    dimensions={dimensions}
                                    rangeDimensions={rangeDimensions}
                                    onChange={files => handleChange(inputName, files, sectionId)}
                                    onDelete={(assetId) => handleDelete(assetId, sectionId)}
                                    onError={(error) => handleError(error, sectionId)}
                                    {...deviceProps}
                                />
                            );
                        })
                        }
                    </TabsSection>
                );
            })}
        </>
    );
}
