export const makeLanguageOrder = (fullLanguageList, defaultLanguage) => (
    a,
    b
) => {
    const isADefault = defaultLanguage && a.code === defaultLanguage.code;
    const isBDefault = defaultLanguage && b.code === defaultLanguage.code;
    const aIndex = fullLanguageList.findIndex(lang => lang.code === a.code);
    const bIndex = fullLanguageList.findIndex(lang => lang.code === b.code);
    if (isADefault) {
        return -1;
    } else if (isBDefault) {
        return 1;
    } else if (aIndex < bIndex) {
        return -1;
    } else if (aIndex > bIndex) {
        return 1;
    } else {
        return 0;
    }
};
