import React from 'react';
import AssetField from './AssetField';
import { ASSET_TYPE_VIDEO } from './AssetDropzone';

export default class PreviewField extends React.Component {

    render() {
        const {
            ...props
        } = this.props;

        return (
            <AssetField
                assetType={ASSET_TYPE_VIDEO}
                {...props}
            />
        );
    }
}
