import _ from 'lodash';
import { createSelector } from 'reselect';

const selectAppId = store => store.context.app_id;
const selectVersionId = store => store.context.app_version;
const selectNavigationState = store => store.navigation;

const makeNavigationStateSelector = (property, defaultValue = undefined) =>
    createSelector(
        selectNavigationState,
        selectAppId,
        selectVersionId,
        (navigationState, appId, versionId) =>
            _.get(navigationState, [appId, versionId, property], defaultValue)
    );

export const selectCurrentTab = makeNavigationStateSelector('tab', 0);

export const selectSelectedCategory = makeNavigationStateSelector(
    'selectedCategory'
);
export const selectActiveCategory = makeNavigationStateSelector(
    'activeCategory'
);
