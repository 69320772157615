import { connect } from 'react-redux';
import axios from 'axios';
import ReviewForm from '../components/tabs/ReviewForm.jsx';
import { updateContext } from '../actions/addContextToRedux';
import { changeTextInput, changeSelectInput } from '../actions/form.js';
import {
    setDirty,
    setError,
    setCorrect,
    isLoading,
    isNotLoading,
} from '../actions/validation.js';
import { previousValuesSelector } from '../selectors/diff';

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    reviewInformation: store.context.review_information,
    diff: previousValuesSelector(store),
    validation: store.validation,
    csrf_token: store.context.csrf_token,
    app_version: store.context.app_version,
});

const mapDispatchToProps = dispatch => ({
    nullifyOption: (name) => {
        dispatch(changeSelectInput('-1', name, 'review_information'));
    },
    nullifyCheckbox: (name) => {
        dispatch(changeTextInput(false, name, 'review_information'));
    },
    handleChangeRadio: (event, options, csrf_token, app_version, reset=false) => {
        let name = event.target.name;
        let intValue = Number.parseInt(event.target.value);
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        dispatch(changeSelectInput(intValue, name, 'review_information'));

        let resetParams = '';
        if (reset) {
            resetParams = `${reset.join('=reset&')}=reset`;
        }

        return axios
            .patch(
                `/api/v1/versions/${app_version}/draft/review-information?${resetParams}`,
                { [name]: intValue },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleChangeTextInput: event => {
        dispatch(
            changeTextInput(
                event.target.value,
                event.target.name,
                'review_information'
            )
        );
    },

    handleBlurTextInput: (event, csrf_token, app_version) => {
        let name = event.target.name;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/review-information`,
                { [event.target.name]: event.target.value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleChangeReleaseSettings: (event, options, csrf_token, app_version) => {
        const value = event.target.value;
        dispatch(
            changeSelectInput(value, event.target.name, 'review_information')
        );
        let name = event.target.name;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/review-information`,
                { [event.target.name]: value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(updateContext('review_information', response.data));
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleChangeDate: (date, csrf_token, app_version) => {
        dispatch(setDirty('release_auto_date'));
        dispatch(isLoading('release_auto_date'));
        dispatch(
            changeTextInput(date, 'release_auto_date', 'review_information')
        );
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/review-information`,
                { release_auto_date: date },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                // dispatch success
                dispatch(
                    updateContext('review_information', response.data)
                );
                dispatch(isNotLoading('release_auto_date'));
                dispatch(setCorrect('release_auto_date'));
            })
            .catch(error => {
                // dispatch failure
                dispatch(
                    setError(
                        'release_auto_date',
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading('release_auto_date'));
            });
    },

    handleChangeCheckbox: (event, csrf_token, app_version, reset=false) => {
        let value = event.target.checked;
        let fieldName = event.target.name;
        dispatch(setDirty(fieldName));
        dispatch(isLoading(fieldName));
        dispatch(changeTextInput(value, fieldName, 'review_information'));

        let resetParams = '';
        if (reset) {
            resetParams = `${reset.join('=reset&')}=reset`;
        }

        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/review-information?${resetParams}`,
                { [fieldName]: value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(fieldName));
                dispatch(setCorrect(fieldName));
            })
            .catch(error => {
                dispatch(
                    setError(
                        fieldName,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(fieldName));
            });
    },
    handleChangeFile: (key, file, csrf_token, app_version) => {
        const formData = new FormData();
        formData.append(key, file);
        let name = key;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .post(
                `/api/v1/versions/${app_version}/draft/review-information`,
                formData,
                {
                    'X-CSRFToken': csrf_token,
                }
            )
            .then(response => {
                dispatch(updateContext('review_information', response.data));
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleDeleteFile: (key, csrf_token, app_version) => {
        let name = key;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/review-information`,
                { [key]: null },
                {
                    'X-CSRFToken': csrf_token,
                }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ReviewForm);
