import React from 'react';

const AdminError = ({ adminTracebackText }) => {
    return (
        <div className="traceback-info">
            <div
                className="traceback-info traceback-style"
                data-toggle="collapse"
                data-target="#traceback_info"
                aria-expanded="false"
                aria-controls="tbe">
                <div className="plus glyphicon glyphicon-plus" />
                <span className="plus traceback-info"> Show error</span>
                <div className="minus glyphicon glyphicon-minus" />
                <span className="minus traceback-info"> Hide error</span>
            </div>

            <div
                id="traceback_info"
                className="collapse text-danger traceback-box">
                <pre>{adminTracebackText}</pre>
            </div>
        </div>
    );
};

export default AdminError;
