import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row } from 'react-bootstrap';
import FieldRow from '../layout/FieldRow.jsx';
import ReadOnlyValue from '../inputs/ReadOnlyValue.jsx';
import Section from '../layout/Section.jsx';
import { renderBoolean } from '../../util/formatters';
import CertificateList from './CertificateList.jsx';
import Date from '../../../Common/Date.jsx';
import Header from '../Header.jsx';
import BundleURLTypes from './BundleURLTypes';
import UploadBinaryButton from '../UploadBinaryButton';

function SpecificationForm({ readonly, acl, specification, collect, versionStatus }) {
    return (
        <Grid fluid>
            <Header>Binary Metadata</Header>
            <Section id="general-specification">
                <Row>
                    <FieldRow id="bundle-id" label="Bundle Identifier">
                        <ReadOnlyValue>
                            {specification.general.bundle_id}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="original_bundle_id"
                        label="Original Bundle Identifier">
                        <ReadOnlyValue>
                            {specification.binary_metadata.original_bundle_id}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="original_team_identifier"
                        label="Original Team Identifier">
                        <ReadOnlyValue>
                            {
                                specification.binary_metadata
                                    .original_team_identifier
                            }
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="original_team_name"
                        label="Original Team Name">
                        <ReadOnlyValue>
                            {specification.binary_metadata.original_team_name}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="bundle_url_types"
                        label="Bundle URL Type"
                        show={
                            specification.binary_metadata.bundle_url_types_exist
                        }>
                        <ReadOnlyValue>
                            <BundleURLTypes
                                bundle_url_types={
                                    specification.binary_metadata
                                        .bundle_url_types
                                }
                            />
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="original_provisioning_profile_certificate"
                        label="Original Provisioning Profile Certificate Name and Expiration">
                        <ReadOnlyValue>
                            <CertificateList
                                certificates={
                                    specification.binary_metadata
                                        .original_provisioning_profile_certificates
                                }
                            />
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="original_binary_certificate"
                        label="Original Binary Certificate Name and Expiration">
                        <ReadOnlyValue>
                            <CertificateList
                                certificates={
                                    specification.binary_metadata
                                        .original_binary_certificates
                                }
                            />
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="original_provisioning_profile_name"
                        label="Original Provisioning Profile Name">
                        <ReadOnlyValue>
                            {
                                specification.binary_metadata
                                    .original_provisioning_profile_name
                            }
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="original_provisioning_profile_type"
                        label="Original Provisioning Profile Type">
                        <ReadOnlyValue>
                            {
                                specification.binary_metadata
                                    .original_provisioning_profile_type
                            }
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="original_provisioning_profile_expiration_date"
                        label="Original Provisioning Profile Expiration Date">
                        <ReadOnlyValue>
                            <Date
                                timestamp={
                                    specification.binary_metadata
                                        .original_provisioning_profile_expiration_date
                                }
                            />
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="original_provisioning_profile_entitlements"
                        label="Original Provisioning Profile Entitlements">
                        <ReadOnlyValue>
                            {
                                specification.binary_metadata
                                    .original_provisioning_profile_entitlements
                            }
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="original_binary_entitlements"
                        label="Original Binary Entitlements">
                        <ReadOnlyValue>
                            {
                                specification.binary_metadata
                                    .original_binary_entitlements
                            }
                        </ReadOnlyValue>
                    </FieldRow>
                    {collect.business_unit && (
                        <FieldRow id="business-unit" label="Business Unit">
                            <ReadOnlyValue>
                                {specification.general.business_unit}
                            </ReadOnlyValue>
                        </FieldRow>
                    )}
                    {collect.environment && (
                        <FieldRow id="environment" label="Environment">
                            <ReadOnlyValue>
                                {specification.general.environment}
                            </ReadOnlyValue>
                        </FieldRow>
                    )}
                    {specification.binary_metadata.is_copied && (
                        <FieldRow label="Cloned from">
                            <ReadOnlyValue>
                                Version{' '}
                                {
                                    specification.binary_metadata
                                        .original_version_number
                                }{' '}
                                (
                                {
                                    specification.binary_metadata
                                        .original_build_number
                                }
                                )
                            </ReadOnlyValue>
                        </FieldRow>
                    )}
                </Row>
            </Section>
            <Section id="binary-data" title="Binary metadata">
                {!readonly && acl.upload_binary_permitted &&
                    <UploadBinaryButton
                        versionStatus={versionStatus}
                        specification={specification}
                    />
                }
                <Row>
                    <FieldRow id="binary-bundle-id" label="Bundle Identifier">
                        <ReadOnlyValue>
                            {specification.binary_metadata.bundle_id}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow id="build-number" label="Build number">
                        <ReadOnlyValue>
                            {specification.binary_metadata.build_number}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow id="min-ios-version" label="Minimum iOS version">
                        <ReadOnlyValue>
                            {specification.binary_metadata.min_os_version}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow id="support-64bit" label="64-bit support">
                        <ReadOnlyValue>
                            {renderBoolean(
                                specification.binary_metadata['64bit_support']
                            )}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow id="info-plist-icons" label="Icons in info.plist">
                        <ReadOnlyValue>
                            {specification.binary_metadata.info_plist_icons}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="binary-entitlements"
                        label="Binary Entitlements">
                        <ReadOnlyValue>
                            {specification.binary_metadata.binary_entitlements}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="profile-entitlements"
                        label="Profile Entitlements">
                        <ReadOnlyValue>
                            {specification.binary_metadata.profile_entitlements}
                        </ReadOnlyValue>
                    </FieldRow>
                </Row>
            </Section>
            <Section id="device-support">
                <Row>
                    <FieldRow id="support-iphone" label="iPhone support">
                        <ReadOnlyValue>
                            {renderBoolean(
                                specification.binary_metadata.iphone_support
                            )}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow id="support-ipad" label="iPad support">
                        <ReadOnlyValue>
                            {renderBoolean(
                                specification.binary_metadata.ipad_support
                            )}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="support-apple-watch"
                        label="Apple watch support">
                        <ReadOnlyValue>
                            {renderBoolean(
                                specification.binary_metadata.watch_support
                            )}
                        </ReadOnlyValue>
                    </FieldRow>
                </Row>
            </Section>
        </Grid>
    );
}

const select = store => ({
    readonly: store.context.readonly,
    acl: store.context.acl,
    specification: store.context.specification,
    collect: store.context.collect,
    versionStatus: store.context.version_status
});

export default connect(select)(SpecificationForm);
