import React from 'react';
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap';
import HelpTooltip from '../HelpTooltip.jsx';
import Asterisk from '../Asterisk';

export default function FieldRow({
    id,
    label,
    required,
    children,
    disabled,
    description,
    help,
    md,
    linkHref,
    linkText,
    show = true,
}) {
    return (
        <FormGroup
            className={
                show
                    ? disabled
                        ? 'form-group__disabled'
                        : null
                    : 'do-not-display'
            }
            controlId={id}>
            <Row>
                <Col md={md ? md : 3}>
                    <div style={{ position: 'relative' }}>
                        <ControlLabel>
                            {required ? <Asterisk disabled={disabled}>{label}</Asterisk> : label}
                            {help && <HelpTooltip message={help} id={id} />}
                        </ControlLabel>
                    </div>
                    {description && (
                        <span className="form-group-label-description">
                            {description}
                        </span>
                    )}
                </Col>
                <Col md={md ? 12 - md : 9}>
                    {children}
                    {linkHref && (
                        <a
                            href={linkHref}
                            className="form-group-link-text"
                            target="_blank">
                            {linkText}
                        </a>
                    )}
                </Col>
            </Row>
        </FormGroup>
    );
}
