import React from 'react';
import _ from 'lodash';
import { makeLanguageOrder } from '../util/sorters';
import PanelGroupController from './PanelGroupController.jsx';

const BaseLocalizableLanguageMetadataList = ({ localizable, children }) => {
    const options = localizable.options.languages;
    const transofrmedOptions = _.keyBy(options, 'code');
    const selected = localizable.languages;
    let languagesList = [];

    for (let item of selected) {
        if (item.hasOwnProperty('code')) {
            languagesList.push(item);
        } else {
            languagesList.push({
                code: item,
                name: transofrmedOptions[item].name,
            });
        }
    }
    const defaultLanguage = (
        localizable.metadata.find(language => language.is_default) || {}
    ).language;
    const languageOrder = makeLanguageOrder(
        localizable.options.languages,
        defaultLanguage
    );
    languagesList.sort(languageOrder);
    const metadataLanguageList = languagesList.map(language =>
        localizable.metadata.find(
            metadata => metadata.language.code === language.code
        )
    );
    return (
        <PanelGroupController
            sections={metadataLanguageList.map((metadata, index) => ({
                id: `language-tab-${metadata.language.code}`,
                title: metadata.is_default
                    ? `${metadata.language.name} – default language`
                    : metadata.language.name,
                content: children(metadata, index),
            }))}
        />
    );
};

export default BaseLocalizableLanguageMetadataList;
