import axios from "axios";

const getBLOB =  url => {
    return axios(url, {
        method: 'GET',
        responseType: 'blob',
        credentials: 'same-origin',
    }).then(res => res.data);
};

export default  getBLOB;