import * as actionType from '../../components/AppDraft/actions/types.js';

const initialState = {
    showDiff: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case actionType.RECEIVED_DIFF:
            return {
                ...state,
                ...action.data,
            };
        case actionType.TOGGLE:
            return {
                ...state,
                showDiff: !state.showDiff,
            };
        default:
            return state;
    }
}
