import React from 'react';

const FilterCheckboxGroup = (
  {
    handleChange,
    listOfOptions,
    stateFilters,
    title
  }
) => {
  return (
    <div className='form-group'>
      <div className='h5'>{title}</div>
      <div style={{marginLeft: '20px'}}>
        {
          listOfOptions.map((option) => (
            <div className="checkbox checkboxMarginBottom">
              <label>
                <input
                  checked={stateFilters[option.field] !== ''}
                  onChange={() => handleChange(event, option.option)}
                  type="checkbox"
                  name={option.option}/>
                <span className='h6'>{option.extra ? option.extra : option.option}</span>
              </label>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default FilterCheckboxGroup;