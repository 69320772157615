import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import AppleWatchPreview from './AppleWatchPreview.jsx';
import AddScreenshot from './AddScreenshot.jsx';
import FieldFeedback from './FieldFeedback.jsx';
import NoImagePreview from './NoImagePreview.jsx';

//TODO: deprecated, change to AssetDropzone component
export default class AppleWatchIconField extends React.Component {
    static defaultProps = {
        readonly: false,
    };
    constructor(props) {
        super(props);
        this.state = { selectedFile: props.initialFile };
    }

    onDrop = files => {
        if (files.length !== 1) {
            throw new Error(
                'AppleWatchIconField should receive only single file'
            );
        }
        const selectedFile = files[0];
        this.setState({ selectedFile }, () =>
            this.props.onChange(selectedFile)
        );
    };

    render() {
        const { id, progress, error, dirty, readonly } = this.props;
        return (
            <Row>
                <Col md={12}>
                    <Dropzone
                        className="apple-icon-dropzone"
                        disabledClassName="dropzone-disabled"
                        multiple={false}
                        disabled={readonly}
                        accept="image/*"
                        onDrop={this.onDrop}>
                        {this.renderPreview()}
                    </Dropzone>
                    <FieldFeedback
                        id={id}
                        progress={progress}
                        error={error}
                        dirty={dirty}
                    />
                </Col>
            </Row>
        );
    }

    renderPreview = () => {
        const { selectedFile } = this.state;
        const { initialFile, readonly } = this.props;
        if (selectedFile && selectedFile.preview) {
            return (
                <AppleWatchPreview
                    readonly={readonly}
                    src={selectedFile.preview}
                    onDelete={this.handleDelete}
                />
            );
        } else if (selectedFile) {
            return (
                <AppleWatchPreview
                    readonly={readonly}
                    src={initialFile}
                    onDelete={this.handleDelete}
                />
            );
        } else if (readonly) {
            return (
                <div className="screenshot-dropzone-screenshot-container">
                    <NoImagePreview />
                </div>
            );
        } else {
            return <AddScreenshot />;
        }
    };

    handleDelete = event => {
        event.stopPropagation();
        this.setState({ selectedFile: null });
        this.props.onDelete();
    };
}
