import React, { useState } from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import FieldRow from '../layout/FieldRow.jsx';
import CheckboxField from '../inputs/CheckboxField.jsx';
import CustomSwitch from '../inputs/CustomSwitch.jsx';
import Section from '../layout/Section.jsx';
import TextArea from '../inputs/TextArea.jsx';
import TextField from '../inputs/TextField.jsx';
import Header from '../Header.jsx';

const TestflightForm = ({
    testflightDetails,
    validation,
    readonly,
    diff,
    isBetaTestingEnabled,
    isVersionSigned,
    isFirstVersion,
    ...props
}) => {
    const [useTestflight, setUseTestflight] = useState(isBetaTestingEnabled);
    const [isSignInRequired, setIsSignInRequired] = useState(testflightDetails?.is_sign_in_required);
    const onToggle = (event) => {
        props.handleSwitchChange(event, props.csrf_token, props.app_version);
        setUseTestflight(!useTestflight);
    }
    const handleSignInChecked = (event) => {
        props.handleChangeCheckbox(
            event,
            props.csrf_token,
            props.app_version
        );
        setIsSignInRequired(!isSignInRequired);
    }
    return (
        <>
            <div className='testflightContainer'>
                <span className='testflightLabel'>Use TestFlight</span>
                <CustomSwitch disabled={isVersionSigned} name="is_beta_testing_enabled" value={useTestflight} onChange={(event) => (onToggle(event))} rounded={true} />
            </div>
            <pre className='testflightMessage'>
                Select if you want to submit this app version to TestFlight.
            </pre>
            {
                useTestflight == true &&
                <Grid fluid>
                    <Header>TestFlight Information</Header>
                    <Section id="testflight-contact-section" title="Beta App Review Information">
                        <Row>
                            <FieldRow
                                id="testflight_first_name"
                                label="First Name"
                                required
                            >
                                <TextField
                                    name="testflight_contact_first_name"
                                    md={5}
                                    error={validation.errors.testflight_contact_first_name}
                                    progress={validation.isLoading.testflight_contact_first_name}
                                    dirty={validation.dirty.testflight_contact_first_name}
                                    value={testflightDetails.testflight_contact_first_name}
                                    previousValue={diff.testflight_contact_first_name}
                                    readonly={readonly}
                                    type="text"
                                    onChange={props.handleChangeTextInput}
                                    onBlur={event =>
                                        props.handleBlurTextInput(
                                            event,
                                            props.csrf_token,
                                            props.app_version
                                        )
                                    }
                                />
                            </FieldRow>
                            <FieldRow
                                id="testflight_last_name"
                                label="Last Name"
                                required
                            >
                                <TextField
                                    name="testflight_contact_last_name"
                                    md={5}
                                    error={validation.errors.testflight_contact_last_name}
                                    progress={validation.isLoading.testflight_contact_last_name}
                                    dirty={validation.dirty.testflight_contact_last_name}
                                    value={testflightDetails.testflight_contact_last_name}
                                    previousValue={diff.testflight_contact_last_name}
                                    readonly={readonly}
                                    type="text"
                                    onChange={props.handleChangeTextInput}
                                    onBlur={event =>
                                        props.handleBlurTextInput(
                                            event,
                                            props.csrf_token,
                                            props.app_version
                                        )
                                    }
                                />
                            </FieldRow>
                            <FieldRow
                                id="testflight_phone"
                                label="Phone number"
                                required
                            >
                                <TextField
                                    name="testflight_contact_phone"
                                    md={5}
                                    error={validation.errors.testflight_contact_phone}
                                    progress={validation.isLoading.testflight_contact_phone}
                                    dirty={validation.dirty.testflight_contact_phone}
                                    value={testflightDetails.testflight_contact_phone}
                                    previousValue={diff.testflight_contact_phone}
                                    readonly={readonly}
                                    type="text"
                                    onChange={props.handleChangeTextInput}
                                    onBlur={event =>
                                        props.handleBlurTextInput(
                                            event,
                                            props.csrf_token,
                                            props.app_version
                                        )
                                    }
                                />
                            </FieldRow>
                            <FieldRow
                                id="testflight_email"
                                label="Email"
                                required
                            >
                                <TextField
                                    name="testflight_contact_email"
                                    md={5}
                                    error={validation.errors.testflight_contact_email}
                                    progress={validation.isLoading.testflight_contact_email}
                                    dirty={validation.dirty.testflight_contact_email}
                                    value={testflightDetails.testflight_contact_email}
                                    previousValue={diff.testflight_contact_email}
                                    readonly={readonly}
                                    type="email"
                                    onChange={props.handleChangeTextInput}
                                    onBlur={event =>
                                        props.handleBlurTextInput(
                                            event,
                                            props.csrf_token,
                                            props.app_version
                                        )
                                    }
                                />
                            </FieldRow>
                            <FieldRow
                                id="testflight_review_notes"
                                label="Review notes"
                                help="Additional information about your app that can help during the review process. 
                                Do not include demo account details. Review notes can’t exceed 4000 characters."
                            >
                                <TextArea
                                    name="testflight_notes"
                                    rows="8"
                                    md={12}
                                    error={validation.errors.testflight_notes}
                                    progress={validation.isLoading.testflight_notes}
                                    dirty={validation.dirty.testflight_notes}
                                    value={testflightDetails.testflight_notes}
                                    previousValue={diff.testflight_notes}
                                    length={
                                        testflightDetails.testflight_notes &&
                                        testflightDetails.testflight_notes.length
                                    }
                                    max_length={4000}
                                    readonly={readonly}
                                    onChange={props.handleChangeTextInput}
                                    onBlur={event =>
                                        props.handleBlurTextInput(
                                            event,
                                            props.csrf_token,
                                            props.app_version
                                        )
                                    }
                                />
                            </FieldRow>
                            <FieldRow
                                id="testflight_changelog"
                                label="What to Test"
                                required={!isFirstVersion}>
                                <TextArea
                                    name="testflight_changelog"
                                    rows="8"
                                    md={12}
                                    error={validation.errors.testflight_changelog}
                                    progress={validation.isLoading.testflight_changelog}
                                    dirty={validation.dirty.testflight_changelog}
                                    value={testflightDetails.testflight_changelog}
                                    previousValue={diff.testflight_changelog}
                                    length={
                                        testflightDetails.testflight_changelog &&
                                        testflightDetails.testflight_changelog.length
                                    }
                                    max_length={4000}
                                    readonly={readonly}
                                    onChange={props.handleChangeTextInput}
                                    onBlur={event =>
                                        props.handleBlurTextInput(
                                            event,
                                            props.csrf_token,
                                            props.app_version
                                        )
                                    }
                                />
                            </FieldRow>
                            <FieldRow
                                id="sign_in_information"
                                label="Sign-In Infromation"
                                help="This is a user name and password we can use to sign in to your app, so we can review all of its features.
                                If users sign in using social media, provide information for an account we can use.
                                Credentials must be valid and active for duration of review."
                            >
                            </FieldRow>
                            <CheckboxField
                                md={12}
                                name="is_sign_in_required"
                                value={isSignInRequired}
                                defaultChecked={isSignInRequired}
                                title="Sign-in required"
                                readonly={readonly}
                                onChange={(event) => (handleSignInChecked(event))}
                            />
                            <div className='testflightMessageSignin'>Provide a user name and password so we can sign in to your app.
                                We’ll need this to complete your app review.</div>
                            {
                                isSignInRequired
                                &&
                                <>
                                    <FieldRow
                                        id="demo_account_username"
                                        label="User Name"
                                        required={isSignInRequired}
                                    >
                                        <TextField
                                            name="testflight_demo_account_name"
                                            md={5}
                                            error={validation.errors.testflight_demo_account_name}
                                            progress={validation.isLoading.testflight_demo_account_name}
                                            dirty={validation.dirty.testflight_demo_account_name}
                                            value={testflightDetails.testflight_demo_account_name}
                                            previousValue={diff.testflight_demo_account_name}
                                            readonly={readonly}
                                            type="text"
                                            onChange={props.handleChangeTextInput}
                                            onBlur={event =>
                                                props.handleBlurTextInput(
                                                    event,
                                                    props.csrf_token,
                                                    props.app_version
                                                )
                                            }
                                        />
                                    </FieldRow>
                                    <FieldRow
                                        id="demo_account_password"
                                        label="Password"
                                        required={isSignInRequired}
                                    >
                                        <TextField
                                            name="testflight_demo_account_password"
                                            md={5}
                                            error={validation.errors.testflight_demo_account_password}
                                            progress={validation.isLoading.testflight_demo_account_password}
                                            dirty={validation.dirty.testflight_demo_account_password}
                                            value={testflightDetails.testflight_demo_account_password}
                                            previousValue={diff.testflight_demo_account_password}
                                            readonly={readonly}
                                            type="text"
                                            onChange={props.handleChangeTextInput}
                                            onBlur={event =>
                                                props.handleBlurTextInput(
                                                    event,
                                                    props.csrf_token,
                                                    props.app_version
                                                )
                                            }
                                        />
                                    </FieldRow>
                                </>
                            }
                        </Row>
                    </Section>
                    <Section id="testflight_app_information_section" title="Beta App Information">
                        <Row>
                            <FieldRow
                                id="testflight_app_desscription"
                                label="Beta App Description"
                                help="Provide a description of your app that highlights its features and functionality."
                            >
                                <TextArea
                                    name="testflight_beta_app_description"
                                    rows="8"
                                    md={12}
                                    error={validation.errors.testflight_beta_app_description}
                                    progress={validation.isLoading.testflight_beta_app_description}
                                    dirty={validation.dirty.testflight_beta_app_description}
                                    value={testflightDetails.testflight_beta_app_description}
                                    previousValue={diff.testflight_beta_app_description}
                                    length={
                                        testflightDetails.testflight_beta_app_description &&
                                        testflightDetails.testflight_beta_app_description.length
                                    }
                                    max_length={4000}
                                    readonly={readonly}
                                    onChange={props.handleChangeTextInput}
                                    onBlur={event =>
                                        props.handleBlurTextInput(
                                            event,
                                            props.csrf_token,
                                            props.app_version
                                        )
                                    }
                                />
                            </FieldRow>
                            <FieldRow
                                id="testflight_feedback_email"
                                label="Feedback Email"
                                required
                                help="TestFlight Beta Testers can send feedback to this email address.
                                It will also appear as the reply-to address for TestFlight invitation emails."
                            >
                                <TextField
                                    name="testflight_feedback_email"
                                    md={10}
                                    error={validation.errors.testflight_feedback_email}
                                    progress={validation.isLoading.testflight_feedback_email}
                                    dirty={validation.dirty.testflight_feedback_email}
                                    value={testflightDetails.testflight_feedback_email}
                                    previousValue={diff.testflight_feedback_email}
                                    readonly={readonly}
                                    type="email"
                                    onChange={props.handleChangeTextInput}
                                    onBlur={event =>
                                        props.handleBlurTextInput(
                                            event,
                                            props.csrf_token,
                                            props.app_version
                                        )
                                    }
                                />
                            </FieldRow>
                            <FieldRow
                                id="testflight_marketing_url"
                                label="Marketing URL"
                                help="A URL with information about the app you are adding. This will be visible to testers in the TestFlight app."
                            >
                                <TextField
                                    name="testflight_marketing_url"
                                    md={10}
                                    error={validation.errors.testflight_marketing_url}
                                    progress={validation.isLoading.testflight_marketing_url}
                                    dirty={validation.dirty.testflight_marketing_url}
                                    value={testflightDetails.testflight_marketing_url}
                                    previousValue={diff.testflight_marketing_url}
                                    readonly={readonly}
                                    type="text"
                                    onChange={props.handleChangeTextInput}
                                    onBlur={event =>
                                        props.handleBlurTextInput(
                                            event,
                                            props.csrf_token,
                                            props.app_version
                                        )
                                    }
                                />
                            </FieldRow>
                            <FieldRow
                                id="testflight_privacy_policy_url"
                                label="Privacy Policy URL"
                                help="A URL that links to your company’s privacy policy. Privacy policies are recommended
                         for all apps that collect user or device related data or as otherwise required by law."
                            >
                                <TextField
                                    name="testflight_privacy_policy_url"
                                    md={10}
                                    error={validation.errors.testflight_privacy_policy_url}
                                    progress={validation.isLoading.testflight_privacy_policy_url}
                                    dirty={validation.dirty.testflight_privacy_policy_url}
                                    value={testflightDetails.testflight_privacy_policy_url}
                                    previousValue={diff.testflight_privacy_policy_url}
                                    readonly={readonly}
                                    type="text"
                                    onChange={props.handleChangeTextInput}
                                    onBlur={event =>
                                        props.handleBlurTextInput(
                                            event,
                                            props.csrf_token,
                                            props.app_version
                                        )
                                    }
                                />
                            </FieldRow>
                        </Row>
                    </Section>
                </Grid>
            }
        </>
    );
};

export default TestflightForm;
