import React from 'react';
import { createSelector } from 'reselect';

const customTabNameSelector = store => store.customField.name;
const assetsOnlySelector = store => store.context.assets_only;

export const makeTabsSelector = ({
    tabs,
    assetsOnlyTabs,
    customTabComponent: CustomTabComponent,
}) =>
    createSelector(
        customTabNameSelector,
        assetsOnlySelector,
        (customTabName, assetsOnly) => {
            const baseTabsSet = assetsOnly ? assetsOnlyTabs : tabs;
            return customTabName
                ? [
                    ...baseTabsSet,
                    {
                        key: 'custom_fields',
                        title: customTabName,
                        component: (
                            <CustomTabComponent tabName={customTabName} />
                        ),
                    },
                ]
                : baseTabsSet;
        }
    );
