import { VERSION_TAB } from '../../../components/AppVersionOverview/constants';

const ACTIVATE_TAB = 'AppVersion/ACTIVATE_TAB';

export const activateTab = tab => {
    return {
        type: ACTIVATE_TAB,
        payload: tab,
    };
};

const activeTab = (state = VERSION_TAB, action) => {
    switch (action.type) {
        case ACTIVATE_TAB:
            return action.payload;
        default:
            return state;
    }
};

export default activeTab;
