import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import FieldRow from '../layout/FieldRow.jsx';
import Section from '../layout/Section.jsx';
import MultiSelectField from '../inputs/MultiSelectField.jsx';
import SelectField from '../inputs/SelectField.jsx';
import GooglePlayAssetsOnlyLocalizableLanguageMetadataList from '../GooglePlayAssetsOnlyLocalizableLanguageMetadataList.jsx';
import Header from '../Header.jsx';
import {
    languagesList,
    languagesValueRenderer,
    localizableLanguagesList,
} from '../../util/formatters';

const GooglePlayAssetsForm = ({
    appDetails,
    localizable,
    validation,
    readonly,
    ...props
}) => {
    return (
        <Grid fluid>
            <Header>Assets</Header>
            <Section
                id="google-play-metadata-section"
                title="Google Play Metadata">
                <Row>
                    <FieldRow
                        id="default_language"
                        label="Default language"
                        required>
                        <SelectField
                            name="default_language"
                            md={5}
                            options={languagesList(
                                appDetails.options.default_language
                            )}
                            error={validation.errors.default_language}
                            progress={validation.isLoading.default_language}
                            dirty={validation.dirty.default_language}
                            value={
                                appDetails.default_language &&
                                appDetails.default_language.name_short
                            }
                            onChange={event =>
                                props.handleChangeSelect(
                                    event,
                                    appDetails.options.default_language,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
            <Section
                id="choose-language-section"
                title="Google Play Localizable Metadata">
                <Row>
                    <FieldRow
                        id="choose_languages"
                        label="Choose languages"
                        required>
                        <MultiSelectField
                            md={9}
                            options={localizableLanguagesList(
                                localizable.options.languages
                            )}
                            onMultiChange={value =>
                                props.handleChangeMultiSelect(
                                    value,
                                    'languages',
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            selected={localizableLanguagesList(
                                localizable.languages
                            )}
                            selectAllLabel={'All languages'}
                            valueRenderer={languagesValueRenderer}
                        />
                    </FieldRow>
                </Row>
            </Section>
            <GooglePlayAssetsOnlyLocalizableLanguageMetadataList
                localizable={localizable}
                expandLocalizableLanguage={props.expandLocalizableLanguage}
                handleChangeTextInput={props.handleChangeTextInput}
                handleBlurTextInput={props.handleBlurTextInput}
                handleChangeScreenshots={props.handleChangeScreenshots}
                handleDeleteScreenshot={props.handleDeleteScreenshot}
                handleLoadFromDefaultLanguage={
                    props.handleLoadFromDefaultLanguage
                }
                handleLoadFromDefaultLanguageToOthers={
                    props.handleLoadFromDefaultLanguageToOthers
                }
                handleScreenshotsError={props.handleScreenshotsError}
                handleChangePromoVideo={props.handleChangePromoVideo}
                handleDeletePromoVideo={props.handleDeletePromoVideo}
                handleChangeGraphicAsset={props.handleChangeGraphicAsset}
                handleDeleteGraphicAsset={props.handleDeleteGraphicAsset}
                app_id={props.app_id}
                app_version={props.app_version}
                csrf_token={props.csrf_token}
                validation={validation}
                diff={props.diff}
                readonly={readonly}
                handleSetCorrect={props.handleSetCorrect}
            />
        </Grid>
    );
};

export default GooglePlayAssetsForm;
