import React, { Fragment, PureComponent } from 'react';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import RadioFieldWithDescription from './RadioFieldWithDescription.jsx';
import DatePicker from './DatePicker.jsx';
import FieldRow from '../layout/FieldRow.jsx';
import FieldFeedback from './FieldFeedback.jsx';
import ReadOnlyDiffValue from './ReadOnlyDiffValue.jsx';

const fieldFeedbackStyle = {
    right: 2,
};

const RELEASE_SETTINGS = {
    manual: {
        label: 'Manual',
        description:
            'The app goes to Pending Developer Release and must be released by clicking "Make App available" button in App Store Connect',
    },
    automatic: {
        label: 'Automatic',
        description:
            'After your app has been approved, we can release it for you immediately.',
    },
    'automatic+date': {
        label: 'Automatic, no earlier than',
        description:
            'When the date has not passed when the app is approved, it goes to Pending Developer Release, but is automatically released at the specified date',
    },
};

export default class ReleaseSettingsPicker extends PureComponent {
    static defaultProps = {
        readonly: false,
    };
    render() {
        const {
            id,
            value,
            releaseDate,
            error,
            progress,
            dirty,
            minDate,
            maxDate,
            readonly,
            previousValue,
            previousReleaseDate,
            onChange,
            onDateChange,
        } = this.props;
        const date = releaseDate ? moment(releaseDate) : undefined;
        const previousDate = previousReleaseDate
            ? moment(previousReleaseDate)
            : undefined;
        if (readonly) {
            return (
                <Row className="radio-with-description">
                    <Col md={8}>
                        <ReadOnlyDiffValue
                            value={
                                value
                                    ? RELEASE_SETTINGS[value].label
                                    : 'No release setting'
                            }
                            previousValue={
                                previousValue &&
                                RELEASE_SETTINGS[previousValue].label
                            }
                        />
                        {value && <p>{RELEASE_SETTINGS[value].description}</p>}
                        {value === 'automatic+date' && (
                            <span>
                                Release date:{' '}
                                <ReadOnlyDiffValue
                                    value={
                                        date ? date.format('YYYY-MM-DD') : '-'
                                    }
                                    previousValue={
                                        previousDate
                                            ? previousDate.format('YYYY-MM-DD')
                                            : '-'
                                    }
                                />
                            </span>
                        )}
                    </Col>
                </Row>
            );
        }
        return (
            <Fragment>
                <RadioFieldWithDescription
                    name="release_settings"
                    label={RELEASE_SETTINGS.manual.label}
                    checked={value === 'manual'}
                    value="manual"
                    onChange={onChange}
                    boldLabel
                    description={RELEASE_SETTINGS.manual.description}
                />
                <RadioFieldWithDescription
                    name="release_settings"
                    label={RELEASE_SETTINGS.automatic.label}
                    onChange={onChange}
                    checked={value === 'automatic'}
                    value="automatic"
                    boldLabel
                    description={RELEASE_SETTINGS.automatic.description}
                />
                <RadioFieldWithDescription
                    name="release_settings"
                    label={RELEASE_SETTINGS['automatic+date'].label}
                    onChange={onChange}
                    checked={value === 'automatic+date'}
                    value="automatic+date"
                    boldLabel
                    description={RELEASE_SETTINGS['automatic+date'].description}
                />
                <FieldRow
                    id="release_auto_date"
                    label="Release date"
                    required
                    disabled={value !== 'automatic+date'}
                    boldLabel>
                    <DatePicker
                        sm={8}
                        minDate={minDate}
                        maxDate={maxDate}
                        disabled={value !== 'automatic+date'}
                        placeholderText="Select date"
                        selected={date}
                        id="release_auto_date"
                        onChange={onDateChange}
                        error={error}
                        progress={progress}
                        dirty={dirty}
                    />
                </FieldRow>
                <FieldFeedback
                    progress={progress}
                    error={error}
                    dirty={dirty}
                    detached
                    style={fieldFeedbackStyle}
                    id={id}
                />
            </Fragment>
        );
    }
}
