import { connect } from 'react-redux';
import axios from 'axios';
import Toggle from '../components/Toggle.jsx';
import { receivedDiff, toggle } from '../actions/diff.js';
import {
    formatDiff,
    formatSupportedCountries,
    zippDiffList,
} from '../util/formatters.js';

const mapStateToProps = store => ({
    csrf_token: store.context.csrf_token,
    app_version: store.context.app_version,
    showDiff: Boolean(store.diff.showDiff),
    showToggle: store.diff.show_difference,
});

const mapDispatchToProps = dispatch => ({
    getDiff: (app_version, csrf_token) => {
        axios
            .get(`/api/v1/versions/diff?version=${app_version}`, {
                'X-CSRFToken': csrf_token,
            })
            .then(response => {
                dispatch(receivedDiff(zippDiffList(formatDiff(response.data))));
            });
        // .catch(error => {
        //   //   set error
        // });
    },

    onToggle: () => {
        dispatch(toggle());
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Toggle);
