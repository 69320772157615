import React from 'react';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Questionmark from './icons/Questionmark.jsx';

export default function HelpTooltip({
    message,
    placement = 'left',
    id,
    size,
    preLine,
    customClass
}) {
    return (
        <div className={`help-tooltip-container ${customClass ? customClass : null}`}>
            <OverlayTrigger
                placement={placement}
                overlay={
                    <Tooltip
                        style={
                            preLine
                                ? { whiteSpace: 'pre-line', textAlign: 'left' }
                                : {}
                        }
                        placement={placement}
                        className={`in ${preLine ? 'tooltip-pre-line' : ''}`}
                        id={id}>
                        {message}
                    </Tooltip>
                }>
                <Questionmark className="help-tooltip-icon" size={size} />
            </OverlayTrigger>
        </div>
    );
}
