import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { submitForm } from '../../../redux/modules/fieldsValidation';
import AppIcon from './AppIcon.jsx';
import TrashIconButton from './TrashIconButton.jsx';
import {isAnyAttributeTrue} from "../../Utils/helpers";

const AppDetails = ({
    acl,
    appIconUrl,
    projectName,
    platformVerbose,
    version,
    buildNumber,
    uploaded_binary,
    isSubmitting,
    onSubmit,
    customFieldsAnyInProgress,
}) => (
    <Row>
        <Col sm={10}>
            <AppIcon appIconUrl={appIconUrl} />
            <div className="app-details">
                <span className="name">
                    {projectName} {version} ({buildNumber}){' '}
                    {acl.discard_draft_permitted && (
                        <TrashIconButton id="delete-draft" />
                    )}
                </span>
                <ul className="list-unstyled details">
                    <li>{platformVerbose}</li>
                </ul>
            </div>
        </Col>
        <Col sm={2}>
            {acl.request_signing_permitted && (
                <Button
                    type="submit"
                    name="submit"
                    className="pull-right header-submit-button"
                    bsStyle="primary"
                    onClick={!isSubmitting ? onSubmit : null}
                    disabled={isSubmitting || !uploaded_binary || customFieldsAnyInProgress}>
                    {isSubmitting ? 'Saving...' : 'Submit'}
                </Button>
            )}
        </Col>
    </Row>
);

const mapStateToProps = store => ({
    isSubmitting: store.validation.isSubmitting,
    customFieldsAnyInProgress: isAnyAttributeTrue(store.customField.progress)
});

const mapDispatchToProps = {
    onSubmit: submitForm,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppDetails);
