import React from 'react';
import moment from 'moment';
import { getNumberOfDaysToExpiryText } from '../Utils/label';
import LazyLoad from 'react-lazyload';

export default class AppListRow extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const appUrl = this.props.app.endpoints.application_page;
        const appID = {
         'id': this.props.app.id,
         'name' : this.props.app.application_name
        };
        const selectedApps = this.props.selectedApps;
        const isSelected = (id) => selectedApps.findIndex(el => el.id === id) !== -1;
        const unselectApp = (arr, appId) => {
            const index = arr.findIndex(el => el.id === appId);
            if (index > -1) {
                arr.splice(index, 1);
            }
            return arr;
        }
        const selectApp = (arr, appId) => {
            arr.push(appId);
            return arr;
        }
        const handleCheckboxChange = (event, appId) => {
            !isSelected(appId.id) ?
              this.props.setSelectedApps(selectApp(selectedApps, appId)) :
              this.props.setSelectedApps(unselectApp(selectedApps, appId.id));
            this.forceUpdate();
        }
        return (
            <tr
                className="application-row"
                data-search={this.props.app.search_query}
                onClick={() => (location = appUrl)}>
                <td onClick={e => e.stopPropagation()} >
                        <input type='checkbox' className='checkbox selectAllCheckbox' onChange={() => handleCheckboxChange(event, appID)}
                               checked={isSelected(appID.id)}/>
                </td>
                <td>
                    <a href={appUrl}>
                        <div className="app-container">
                            <div className="app-icon-container">
                                <LazyLoad offset={200}>
                                    <img
                                        placeholder='/static/img/icon-placeholder.png'
                                        className="app-icon"
                                        src={
                                            this.props.app.endpoints
                                                .application_thumbnail
                                        }
                                        alt='App Icon'
                                    />
                                </LazyLoad>
                            </div>
                            <span className="app-name">
                                {this.props.app.application_name}
                            </span>
                        </div>
                    </a>
                </td>
                {this.props.headers
                    .filter(header => header.label !== 'App Name' && header.label !== 'Checkbox')
                    .map((header, i) => {
                        if (header.label === 'Status') {
                            return (
                                <td key={i}>
                                    <a href={appUrl}>
                                        {
                                            this.props.app
                                                .latest_version_status_text
                                        }
                                        {
                                            this.props.app?.targets?.split(",").length === 1 ?
                                              <span
                                                className={
                                                    this.props.app.show_warning
                                                      ? 'text-warning'
                                                      : ''
                                                }>
                                            {
                                                this.props.app
                                                  .latest_version_status
                                            }
                                            </span> : ''
                                        }
                                    </a>
                                </td>
                            );
                        }
                        if (header.label === 'Last Activity') {
                            let dateOutput = 'n/a';
                            if (this.props.app.last_activity) {
                                // This converts the date into a nice format 2nd December 2017
                                dateOutput = moment(
                                    String(this.props.app.last_activity),
                                    'X'
                                ).format('Do MMM YYYY');
                            }
                            return (
                                <td key={i}>
                                    <a href={appUrl}>{dateOutput}</a>
                                </td>
                            );
                        }
                        const numberOfDaysToExpiry = getNumberOfDaysToExpiryText(
                            this.props.app.expiring_in
                        );
                        const expirationDate = this.props.app.expiration_date
                            ? this.props.app.expiration_date
                            : 'n/a';
                        if (header.label === 'Expiration') {
                            return (
                                <td key={i}>
                                    <a href={appUrl}>
                                        <div
                                            className={
                                                this.props.app.expiring_in ===
                                                'Expired'
                                                    ? 'expiration-date'
                                                    : ''
                                            }
                                            title={`Expiration Date: ${expirationDate}`}>
                                            {numberOfDaysToExpiry}
                                        </div>
                                    </a>
                                </td>
                            );
                        }
                        return (
                            <td key={i}>
                                <a href={appUrl}>
                                    {this.props.app[header.value]}
                                </a>
                            </td>
                        );
                    })}
            </tr>
        );
    }
}
