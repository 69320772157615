import { connect } from 'react-redux';
import axios from 'axios';
import GooglePlayAppDetails from '../components/tabs/GooglePlayAppDetails.jsx';
import { changeTextInput, changeSelectInput } from '../actions/form.js';
import { updateContext } from '../actions/addContextToRedux.js';
import {
    setDirty,
    setError,
    setCorrect,
    isLoading,
    isNotLoading,
} from '../actions/validation.js';
import { previousValuesSelector } from '../selectors/diff';
import GooglePlayFinancialFeatures from "../components/tabs/GooglePlayFinancialFeatures";

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    financialFeatures: store.context.financial_features,
    diff: previousValuesSelector(store),
    validation: store.validation,
    csrf_token: store.context.csrf_token,
    app_version: store.context.app_version,
});

const mapDispatchToProps = dispatch => ({
    handleChangeMultiSelect: (
        value,
        property,
        fieldName,
        csrf_token,
        app_version
    ) => {
        dispatch(changeSelectInput(value, fieldName, 'financial_features'));
        dispatch(setDirty(fieldName));
        dispatch(isLoading(fieldName));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/financial-features`,
                { [fieldName]: value.map(item => item[property]) },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(fieldName));
                dispatch(setCorrect(fieldName));
                dispatch(
                    updateContext('financial_features', response.data)
                );
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(fieldName));
            });
    },
    handleChangeRadio: (event, options, csrf_token, app_version) => {
        let name = event.target.name;
        let boolValue = event.target.value === 'true';
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        dispatch(changeSelectInput(boolValue, name, 'financial_features'));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/financial-features`,
                { [name]: boolValue },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
                dispatch(
                    updateContext('financial_features', response.data)
                );
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GooglePlayFinancialFeatures);
