import { connect } from 'react-redux';
import axios from 'axios';
import GooglePlayAppDetails from '../components/tabs/GooglePlayAppDetails.jsx';
import { changeTextInput, changeSelectInput } from '../actions/form.js';
import { updateContext } from '../actions/addContextToRedux.js';
import {
    setDirty,
    setError,
    setCorrect,
    isLoading,
    isNotLoading,
} from '../actions/validation.js';
import { previousValuesSelector } from '../selectors/diff';

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    appDetails: store.context.app_details,
    diff: previousValuesSelector(store),
    validation: store.validation,
    csrf_token: store.context.csrf_token,
    app_version: store.context.app_version,
});

const mapDispatchToProps = dispatch => ({
    handleChangeTextInput: event => {
        dispatch(
            changeTextInput(
                event.target.value,
                event.target.name,
                'app_details'
            )
        );
    },

    handleBlurTextInput: (event, csrf_token, app_version) => {
        let name = event.target.name;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [event.target.name]: event.target.value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
                dispatch(
                    updateContext('app_details', response.data.app_details)
                );
                if (response.data.localizable_metadata) {
                    dispatch(
                        updateContext(
                            'localizable_metadata',
                            response.data.localizable_metadata
                        )
                    );
                }
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleChangeDate: (date, csrf_token, app_version) => {
        dispatch(setDirty('availability_date'));
        dispatch(isLoading('availability_date'));
        dispatch(changeTextInput(date, 'availability_date', 'app_details'));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { availability_date: date },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(
                    updateContext('app_details', response.data.app_details)
                );
                if (response.data.localizable_metadata) {
                    dispatch(
                        updateContext(
                            'localizable_metadata',
                            response.data.localizable_metadata
                        )
                    );
                }
                dispatch(isNotLoading('availability_date'));
                dispatch(setCorrect('availability_date'));
            })
            .catch(error => {
                dispatch(
                    setError(
                        'availability_date',
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading('availability_date'));
            });
    },
    handleChangeMultiSelect: (
        value,
        property,
        fieldName,
        csrf_token,
        app_version
    ) => {
        dispatch(changeSelectInput(value, fieldName, 'app_details'));
        dispatch(setDirty(fieldName));
        dispatch(isLoading(fieldName));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [fieldName]: value.map(item => item[property]) },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(fieldName));
                dispatch(setCorrect(fieldName));
                dispatch(
                    updateContext('app_details', response.data.app_details)
                );
                if (response.data.localizable_metadata) {
                    dispatch(
                        updateContext(
                            'localizable_metadata',
                            response.data.localizable_metadata
                        )
                    );
                }
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(fieldName));
            });
    },
    handleChangeAppType: (event, options, csrf_token, app_version) => {
        let name = event.target.name;
        let value = event.target.value;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        dispatch(changeSelectInput(value, name, 'app_details'));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [name]: value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
                dispatch(
                    updateContext('app_details', response.data.app_details)
                );
                if (response.data.localizable_metadata) {
                    dispatch(
                        updateContext(
                            'localizable_metadata',
                            response.data.localizable_metadata
                        )
                    );
                }
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleChangeRadio: (event, options, csrf_token, app_version) => {
        let name = event.target.name;
        let boolValue = event.target.value === 'true';
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        dispatch(changeSelectInput(boolValue, name, 'app_details'));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [name]: boolValue },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
                dispatch(
                    updateContext('app_details', response.data.app_details)
                );
                if (response.data.localizable_metadata) {
                    dispatch(
                        updateContext(
                            'localizable_metadata',
                            response.data.localizable_metadata
                        )
                    );
                }
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleChangeSelect: (event, options, csrf_token, app_version) => {
        const selectValue = options.filter(
            obj => obj.name_short == event.target.value
        )[0];
        dispatch(
            changeSelectInput(selectValue, event.target.name, 'app_details')
        );
        let name = event.target.name;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [event.target.name]: event.target.value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
                dispatch(
                    updateContext('app_details', response.data.app_details)
                );
                if (response.data.localizable_metadata) {
                    dispatch(
                        updateContext(
                            'localizable_metadata',
                            response.data.localizable_metadata
                        )
                    );
                }
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleChangeCheckbox: (event, csrf_token, app_version) => {
        let value = event.target.checked;
        let fieldName = event.target.name;
        dispatch(changeTextInput(value, fieldName, 'app_details'));
        let name = event.target.name;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [fieldName]: value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(fieldName));
                dispatch(setCorrect(fieldName));
                dispatch(
                    updateContext('app_details', response.data.app_details)
                );
                if (response.data.localizable_metadata) {
                    dispatch(
                        updateContext(
                            'localizable_metadata',
                            response.data.localizable_metadata
                        )
                    );
                }
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(fieldName));
            });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GooglePlayAppDetails);
