import React, { Component } from 'react';
import Toggle from 'react-bootstrap-toggle';

class NewToggle extends Component {
    constructor() {
        super();
    }

    componentWillMount() {
        //   make request, save in redux
        this.props.getDiff(this.props.app_version, this.props.csrf_token);
    }

    render() {
        // render if is difference
        return this.props.showToggle ? (
            <div className="custom-toggle-container">
                <div className="custom-toggle-label">
                    Show changes from previous version
                </div>
                <Toggle
                    onClick={this.props.onToggle}
                    on="ON"
                    off="OFF"
                    size="xs"
                    offstyle="danger"
                    active={this.props.showDiff}
                    height="34px"
                    width="55px"
                    onClassName="toggle-on-class"
                    offClassName="toggle-off-class"
                    handleClassName="toggle-handle-class"
                />
            </div>
        ) : null;
    }
}

export default NewToggle;
