//TODO: get expectedWidth & expectedHeight from backend dictionary
//TODO: Move each *DevicePropsMap to external catalog and handle by abstraction class
const ipadPropsList = [
    {
        inputName: 'ipad_pro_3rd',
        name: 'iPad 12.9 (3rd)',
        expectedWidth: 1200,
        expectedHeight: 1600,
        required: false,
    },
    {
        inputName: 'ipad_pro',
        name: 'iPad 12.9 (2nd)',
        expectedWidth: 1200,
        expectedHeight: 1600,
        required: false,
    },
    {
        inputName: 'ipad_105',
        name: 'iPad 10.5"',
        expectedWidth: 1200,
        expectedHeight: 1600,
        required: false,
    },
    {
        inputName: 'ipad_11',
        name: 'iPad 11"',
        expectedWidth: 1200,
        expectedHeight: 1600,
        required: false,
    },
    {
        inputName: 'ipad',
        name: 'iPad 9.7"',
        expectedWidth: 900,
        expectedHeight: 1200,
        required: false,
    },
];

const iphonePropsList = [
    {
        inputName: 'iphone_6_7_in',
        name: 'iPhone 6.7"',
        expectedWidth: 886,
        expectedHeight: 1920,
        required: false,
    },
    {
        inputName: 'iphone_6_5_in',
        name: 'iPhone 6.5"',
        expectedWidth: 886,
        expectedHeight: 1920,
        required: false,
    },
    {
        inputName: 'iphone_5_8_in',
        name: 'iPhone 5.8"',
        expectedWidth: 886,
        expectedHeight: 1920,
        required: false,
    },
    {
        inputName: 'iphone_5_5_in',
        name: 'iPhone 5.5"',
        expectedWidth: 1080,
        expectedHeight: 1920,
        required: false,
    },
    {
        inputName: 'iphone_4_7_in',
        name: 'iPhone 4.7"',
        expectedWidth: 750,
        expectedHeight: 1334,
        required: false,
    },
    {
        inputName: 'iphone_4_0_in',
        name: 'iPhone 4"',
        expectedWidth: 1080,
        expectedHeight: 1920,
        required: false,
    },
];

export function getAppPreviewDevicePropsMap(localizable){
    let appPreviewDevicePropsMap = {};
    appPreviewDevicePropsMap['iphone'] = localizable.supports_iphone && iphonePropsList;
    appPreviewDevicePropsMap['ipad'] = localizable.supports_ipad && ipadPropsList;
    return appPreviewDevicePropsMap;
}
