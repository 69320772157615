import React from 'react';

import UserRow from './UserRow.jsx';
import { withDataFetching } from '../Common/withDataFetching.jsx';
import TableHeader from '../Common/ListElements/TableHeader.jsx';
import Spinner from '../Common/Spinner.jsx';
import compareRows from '../Utils/compareRows.jsx';
import { timestampToDate } from '../utils.jsx';
import { isColumnEmpty } from '../utils';

function getHeader(showContactPerson) {
    let headers = [
        { label: 'Name', value: 'name', transformation: truncateString },
        { label: 'User Email', value: 'email', transformation: truncateString },
        { label: 'User Roles', value: 'userRoles', transformation: truncateString },
        { label: 'Contact Person', value: 'contactPerson', transformation: truncateString },
        { label: 'Status', value: 'status', },
        {
            label: 'Last Login',
            value: 'lastLogin',
            transformation: timestampToDate,
        },
    ];
    return showContactPerson
        ? headers
        : headers.filter(header => header.value !== 'contactPerson');
}

function truncateString(input) {
    if (input && input.length > 30)
        return input.substring(0, 30) + "...";
    return input;
}

const UsersListRaw = ({
    data,
    loadingElements,
    sorting,
    acl,
    sortByAttributeName,
    canEditProfile,
}) => {
    const showContactPerson = isColumnEmpty(data, 'contactPerson');
    return (
        <div className="tab-pane" id="logs">
            {loadingElements ? (
                <Spinner loadingLabel={'Loading User List'} />
            ) : (
                <table id="userslist-table" className="table table-hover">
                    <TableHeader
                        headers={getHeader(showContactPerson)}
                        sortAttributeName={sortByAttributeName}
                        sorting={sorting}
                    />
                    <tbody>
                        {data.sort(compareRows(sorting)).map((user, i) => (
                            <UserRow
                                headers={getHeader(showContactPerson)}
                                key={i}
                                data={user}
                                canEditProfile={canEditProfile}
                            />
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default withDataFetching(UsersListRaw);
