import { combineReducers } from 'redux';
import activeTab from './appVersionTabs';
import deviceInstallationData, {
    DEVICE_INSTALLATION_STATUS,
} from './deviceInstallationData';

const ADD_CONTEXT = 'AppVersion/ADD_CONTEXT ';

export const addContext = (context, name) => {
    return {
        type: ADD_CONTEXT,
        context,
        name,
    };
};

const appContext = (state = { deviceInstallationDataStatus: null }, action) => {
    switch (action.type) {
        case ADD_CONTEXT:
            let context = {
                ...state,
            };
            context[action.name] = action.context;
            return context;
        case DEVICE_INSTALLATION_STATUS:
            return {
                ...state,
                deviceInstallationDataStatus: action.payload,
            };
        default:
            return state;
    }
};

const appVersion = combineReducers({
    appContext,
    activeTab,
    deviceInstallationData,
});

export default appVersion;
