// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import EditAppDataModal from './EditAppDataModal';

export default class AppDataListElement extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showModal: false, currentValue: this.props.children };
        this.handleClick = this.handleClick.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
    };

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        } else {
            this.handleToggleModal();
        }
    }

    handleToggleModal(shouldRefresh, newValue) {
        this.setState({
            showModal: !this.state.showModal,
            currentValue: shouldRefresh ? newValue : this.state.currentValue,
        });
    }

    render() {
        const hasLabel = this.props.label && !this.props.isBig;
        const isClickable = this.props.onClick || this.props.data;
        return (
            <li>
                {isClickable ? (
                    <a
                        href="#"
                        className={this.props.isBig ? 'name' : undefined}
                        onClick={this.handleClick}>
                        <span className="details-label">
                            {hasLabel && `${this.props.label}: `}
                        </span>
                        {this.state.currentValue}
                    </a>
                ) : (
                    <span className={this.props.isBig ? 'name' : undefined}>
                        <span className="details-label">
                            {hasLabel && `${this.props.label}: `}
                        </span>
                        <span className="black-text">
                            {this.state.currentValue}
                        </span>
                    </span>
                )}
                {this.props.data && this.state.showModal && (
                    <EditAppDataModal
                        handleToggleModal={this.handleToggleModal}
                        title={this.props.label}
                        initialAppDetailValue={this.state.currentValue}
                        appDetailKey={this.props.data.post_key}
                        selectOptions={this.props.data.options}
                        endpoint={this.props.data.endpoint}
                        autocompleteEndpoint={
                            this.props.data.autocomplete_endpoint
                        }
                    />
                )}
            </li>
        );
    }
}
