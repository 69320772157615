import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import Section from '../layout/Section.jsx';
import FieldRow from '../layout/FieldRow.jsx';
import TextField from '../inputs/TextField.jsx';
import Header from '../Header.jsx';
import HelpTooltip from '../HelpTooltip';

const GooglePlayContactDetails = ({
    contactDetails,
    validation,
    readonly,
    diff,
    ...props
}) => {
    return (
        <Grid fluid>
            <Header>Contact Details<HelpTooltip message='This information is shown to users on Google Play'/></Header>
            <Section id="contact-details-section">
                <Row>
                    <FieldRow
                        id="contact_email"
                        label="Contact e-mail"
                        required
                        boldLabel>
                        <TextField
                            name="contact_email"
                            md={5}
                            error={validation.errors.contact_email}
                            progress={validation.isLoading.contact_email}
                            dirty={validation.dirty.contact_email}
                            value={contactDetails.contact_email}
                            previousValue={diff.contact_email}
                            type="text"
                            readonly={readonly}
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="contact_phone"
                        label="Contact phone"
                        boldLabel>
                        <TextField
                            name="contact_phone"
                            md={5}
                            error={validation.errors.contact_phone}
                            progress={validation.isLoading.contact_phone}
                            dirty={validation.dirty.contact_phone}
                            value={contactDetails.contact_phone}
                            previousValue={diff.contact_phone}
                            type="text"
                            readonly={readonly}
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="contact_website"
                        label="Contact website"
                        boldLabel>
                        <TextField
                            name="contact_website"
                            md={9}
                            error={validation.errors.contact_website}
                            progress={validation.isLoading.contact_website}
                            dirty={validation.dirty.contact_website}
                            value={contactDetails.contact_website}
                            previousValue={diff.contact_website}
                            type="text"
                            readonly={readonly}
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
            <Section id="test-data">
                <Row>
                    <FieldRow id="test_data_userid" label="Test data userID">
                        <TextField
                            name="test_data_userid"
                            md={9}
                            error={validation.errors.test_data_userid}
                            progress={validation.isLoading.test_data_userid}
                            dirty={validation.dirty.test_data_userid}
                            value={contactDetails.test_data_userid}
                            previousValue={diff.test_data_userid}
                            type="text"
                            readonly={readonly}
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="test_data_password"
                        label="Test data password">
                        <TextField
                            name="test_data_password"
                            md={9}
                            error={validation.errors.test_data_password}
                            progress={validation.isLoading.test_data_password}
                            dirty={validation.dirty.test_data_password}
                            value={contactDetails.test_data_password}
                            previousValue={diff.test_data_password}
                            type="text"
                            readonly={readonly}
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
        </Grid>
    );
};

export default GooglePlayContactDetails;
