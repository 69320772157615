import React from 'react';
import FieldRow from '../layout/FieldRow.jsx';
import TextArea from '../inputs/TextArea.jsx';

const WhatsNew = ({
    metadata,
    validation,
    options,
    diff,

    readonly,
    languageCode,
    csrf_token,
    app_version,

    handleChangeTextInput,
    handleBlurTextInput,
}) => {
    return (
        <FieldRow
            id="whats-new"
            label="What's new"
            required={!metadata.is_first_version}>
            <TextArea
                name="whats_new"
                rows="8"
                md={12}
                error={
                    validation.errors[languageCode] &&
                    validation.errors[languageCode].whats_new
                }
                progress={
                    validation.isLoading[languageCode] &&
                    validation.isLoading[languageCode].whats_new
                }
                dirty={
                    validation.dirty[languageCode] &&
                    validation.dirty[languageCode].whats_new
                }
                value={metadata.whats_new}
                length={metadata.whats_new && metadata.whats_new.length}
                max_length={options.whats_new && options.whats_new.max_length}
                readonly={readonly}
                previousValue={
                    typeof diff === 'object' ? diff.whats_new : undefined
                }
                onChange={event => handleChangeTextInput(event, languageCode)}
                onBlur={event =>
                    handleBlurTextInput(
                        event,
                        csrf_token,
                        app_version,
                        languageCode
                    )
                }
            />
        </FieldRow>
    );
};

export default WhatsNew;
