import {
    NewAndroidCertificateForm,
    initialState,
} from './NewAndroidCertificateForm.jsx';
import submitAndroidCertificateForm from './SubmitNewAndroidCertificate.jsx';
import { Modal, Button } from 'react-bootstrap';
import React from 'react';

export default class NewAndroidCertificateButton extends React.Component {
    /* This component is just a button.  When you click the button a
     * modal opens where the user can upload a new android
     * certificate */

    state = {
        show: false,
        submitting: false,
        errors: {},
        formState: initialState,
    };

    toggleModal = () => {
        this.setState(state => {
            return { show: !state.show };
        });
    };

    submit = () => {
        this.setState({ submitting:true });
        submitAndroidCertificateForm(this.state.formState).then(errors => {
            if (errors) {
                this.setState({ errors: errors, submitting: false });
            } else {
                this.setState({ errors: {}, submitting:false });
                this.toggleModal();
            }
        });
    };

    change = formState => {
        this.setState(oldState => {
            return {
                formState: {
                    ...oldState.formState,
                    ...formState,
                },
            };
        });
    };

    render = () => (
        <span>
            <Button onClick={this.toggleModal} bsStyle="primary">
                Add Keystore
            </Button>
            <Modal show={this.state.show} onHide={this.toggleModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload New Android Keystore</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewAndroidCertificateForm
                        errors={this.state.errors}
                        onChange={this.change}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        type="button"
                        bsStyle={'primary'}
                        onClick={this.toggleModal}>
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        disabled={this.state.submitting}
                        bsStyle={'primary'}
                        onClick={this.submit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>
        </span>
    );
}
