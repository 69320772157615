import React from 'react';
import AddNewAppRow from './AddNewAppRow.jsx';
import Spinner from '../Common/Spinner.jsx';
import SortedAppRows from './SortedAppRows.jsx';
import DisplayNoAppsText from './DisplayNoAppsText.jsx';
import DisplayRetiredAppHeader from './DisplayRetiredAppHeader.jsx';
import NewAppModal from './NewAppModal.jsx';
import { withDataFetching } from '../Common/withDataFetching';
import TableHeader from '../Common/ListElements/TableHeader';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import {postJSON} from "../Utils/postJSON";
import { Button, Modal, Glyphicon } from 'react-bootstrap';
import FilterCheckboxGroup from "./FilterCheckboxGroup";


const constants = {
  ANDROID: "Android",
  IOS: "iOS",
  APPLE_APP_STORE: "Apple App Store",
  GOOGLE_PLAY: "Google Play",
  MICROSOFT_INTUNE: "Microsoft Intune",
  WORKSPACE_ONE: "VMware Workspace ONE",
  IVANTI_NEURONS_MDM: "Ivanti Neurons for MDM",
  IVANTI_EPMM: "Ivanti EPMM",
  ENV_TEST: "Test",
  ENV_PROD: "Production",
  DRAFT: "Draft",
  SIGNED: "Signed",
  PUBLISHED: "Published",
  FAILED: "Failed",
  EXPIRED: 1,
  EXPIRING_IN_A_MONTH: 31,
  EXPIRING_IN_THREE_MONTHS: 93,
  EXPIRING_IN_SIX_MONTHS: 183,
  LAST_WEEK: 7,
  LAST_FORTNIGHT: 15,
  LAST_MONTH: 31,
  LAST_THREE_MONTHS: 93
}
const stateFilterConstants = {
  ANDROID: "platformAndroid",
  IOS: "platformIos",
  APPLE_APP_STORE: "appleAppStore",
  GOOGLE_PLAY: "googlePlay",
  MICROSOFT_INTUNE: "microsoftIntune",
  WORKSPACE_ONE: "vmwareWorkspaceOne",
  IVANTI_NEURONS_MDM: "ivantiNeuronsForMdm",
  IVANTI_EPMM: "ivantiEpmm",
  ENV_TEST: "envTest",
  ENV_PROD: "envProd",
  DRAFT: "draft",
  SIGNED: "signed",
  PUBLISHED: "published",
  FAILED: "failed",
  EXPIRED: "expired",
  EXPIRING_IN_A_MONTH: "expiringInAMonth",
  EXPIRING_IN_SIX_MONTHS: "expiringInSixMonths",
  EXPIRING_IN_THREE_MONTHS: "expiringInThreeMonths",
  LAST_WEEK: "lastWeek",
  LAST_FORTNIGHT: "lastFortnight",
  LAST_MONTH: "lastMonth",
  LAST_THREE_MONTHS: "lastThreeMonths"
}
const SESSION_FILTERS = 'sessionFilters';
const globalPlatformFilters = [
  {
    option : constants.IOS,
    field : stateFilterConstants.IOS
  },
  {
    option : constants.ANDROID,
    field : stateFilterConstants.ANDROID
  },
]
const globalTargetTypeFilters = [
  {
    option : constants.APPLE_APP_STORE,
    field : stateFilterConstants.APPLE_APP_STORE
  },
  {
    option : constants.GOOGLE_PLAY,
    field : stateFilterConstants.GOOGLE_PLAY
  },
  {
    option : constants.MICROSOFT_INTUNE,
    field : stateFilterConstants.MICROSOFT_INTUNE
  },
  {
    option : constants.WORKSPACE_ONE,
    field : stateFilterConstants.WORKSPACE_ONE
  },
  {
    option : constants.IVANTI_NEURONS_MDM,
    field : stateFilterConstants.IVANTI_NEURONS_MDM
  },
  {
    option : constants.IVANTI_EPMM,
    field : stateFilterConstants.IVANTI_EPMM
  },
]
const globalEnvironmentFilters = [
  {
    option : constants.ENV_TEST,
    field : stateFilterConstants.ENV_TEST
  },
  {
    option : constants.ENV_PROD,
    field : stateFilterConstants.ENV_PROD
  },
]
const globalStatusFilters = [
  {
    option : constants.DRAFT,
    field : stateFilterConstants.DRAFT
  },
  {
    option : constants.SIGNED,
    field : stateFilterConstants.SIGNED
  },
  {
    option : constants.PUBLISHED,
    field : stateFilterConstants.PUBLISHED
  },
  {
    option : constants.FAILED,
    field : stateFilterConstants.FAILED
  },
]
const globalExpirationFilters = [
  {
    option : constants.EXPIRED,
    field : stateFilterConstants.EXPIRED,
    extra: 'Expired'
  },
  {
    option : constants.EXPIRING_IN_A_MONTH,
    field : stateFilterConstants.EXPIRING_IN_A_MONTH,
    extra: 'In a month'
  },
  {
    option : constants.EXPIRING_IN_THREE_MONTHS,
    field : stateFilterConstants.EXPIRING_IN_THREE_MONTHS,
    extra: 'In 3 months'
  },
  {
    option : constants.EXPIRING_IN_SIX_MONTHS,
    field : stateFilterConstants.EXPIRING_IN_SIX_MONTHS,
    extra: 'In 6 months'
  },
]
const globalLastActivityFilters = [
  {
    option : constants.LAST_WEEK,
    field : stateFilterConstants.LAST_WEEK,
    extra: 'Within last 7 days'
  },
  {
    option : constants.LAST_FORTNIGHT,
    field : stateFilterConstants.LAST_FORTNIGHT,
    extra: 'Within last 15 days'
  },
  {
    option : constants.LAST_MONTH,
    field: stateFilterConstants.LAST_MONTH,
    extra : 'Within last month'
  },
  {
    option : constants.LAST_THREE_MONTHS,
    field: stateFilterConstants.LAST_THREE_MONTHS,
    extra : 'Within last 3 months'
  },
]

function getHeaders(data, displayEnv) {
    const headers = [
        { label: 'App Name', value: 'application_name' },
        { label: 'Bundle ID/Package Name', value: 'bundle_id' },
        { label: 'Platform', value: 'platform_verbose' },
        { label: 'Target Type', value: 'target_kind_verbose' },
        { label: 'Targets', value: 'targets' },
        { label: 'Status', value: 'latest_version_status_text' },
        { label: 'Responsibility', value: 'responsibility' },
        { label: 'Expiration', value: 'expiring_in' },
        { label: 'Last Activity', value: 'last_activity' },
        { label: 'Publishing Account', value: 'certificate' },
    ];
    // remove all columns that are not included in the data
  let filteredHeaders = headers.filter(header =>
      data.every(app => app.hasOwnProperty(header.value))
  );
  if(displayEnv) {
    filteredHeaders = [
      ...filteredHeaders.slice(0, 3),
      {label: 'Environment', value: 'environment'},
      ...filteredHeaders.slice(3)
    ];
  }
  filteredHeaders.unshift({ label: 'Checkbox', value: '' },)
  return filteredHeaders;
}
const initialClearedFilters = {
  platformVerbose: {
    platformIos: '',
    platformAndroid: ''
  },
  targetKindVerbose: {
    appleAppStore: '',
    googlePlay: '',
    vmwareWorkspaceOne: '',
    microsoftIntune: '',
    ivantiNeuronsForMdm: '',
    ivantiEpmm: ''
  },
  environment: {
    envTest: '',
    envProd: ''
  },
  status: {
    draft: '',
    signed: '',
    published: '',
    failed: ''
  },
  expiringIn: {
    expired: '',
    expiringInAMonth: '',
    expiringInThreeMonths: '',
    expiringInSixMonths: ''
  },
  lastActivity: {
    lastWeek: '',
    lastFortnight: '',
    lastMonth: '',
    lastThreeMonths: ''
  }
};
const initialFilters = JSON.parse(sessionStorage.getItem(SESSION_FILTERS)) || initialClearedFilters;


/** This is the main component that displays the Apps in rows on the home page.
 * Props:
 * - data {Array}: An array of JSON objects. Comes from withData wrapper.
 * **/
class AppListRaw extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredApps: [],
            headers: [],
            showDrawer: false,
            selectedApps: [],
            filters: initialFilters,
            action: '',
            showRetiredModal: false,
            showDeleteModal: false,
            showRestoreModal: false,
            filterCount: 0,
        };
    }

    setSelectedAppsRoot = (apps) => {
      this.setState({
        ...this.state,
        selectedApps: apps,
      });
    }

    setSelectAllApps = (event) => {
      if(event.target.checked){
        let selectAll = this.state.filteredApps.map(filteredApp => ( { id : filteredApp.id, name: filteredApp.application_name} ));
        this.state.selectedApps = selectAll;
      }
      else{
        this.state.selectedApps = [];
      }
      this.forceUpdate();
    }


    filterApps = () => {
        let query = document
            .getElementById('apps-search-input')
            .value.toLowerCase();
        if (this.props.data.length) {
            let filteredApps = this.props.data.filter(
                app => {
                    return app.search_query.indexOf(query) !== -1
                }
            );
          var filterCount = 0;
          function cleanFilters(filterField) {
            for (let filterOption in filterField) {
              if (!filterField[filterOption] || typeof filterField[filterOption] !== "object") {
                if(filterField[filterOption] === '' || filterField[filterOption] === 0)
                  delete filterField[filterOption];
                else
                  filterCount++;
                continue;
              }
              cleanFilters(filterField[filterOption]);
              if (Object.keys(filterField[filterOption]).length === 0) {
                delete filterField[filterOption];
              }
            }
            return filterField;
          }
          let stateFilters = JSON.parse(JSON.stringify(this.state.filters));
          stateFilters = cleanFilters(stateFilters);
          if (Object.keys(stateFilters).length > 0)
            sessionStorage.setItem(SESSION_FILTERS, JSON.stringify(this.state.filters));
          else
            sessionStorage.removeItem(SESSION_FILTERS);
          this.state.filterCount = filterCount;
          let platformFilters = stateFilters?.platformVerbose;
          let targetTypeFilters = stateFilters?.targetKindVerbose;
          let environmentFilters = stateFilters?.environment;
          let expiringFilters = stateFilters?.expiringIn;
          let statusFilters = stateFilters?.status;
          let activityFilters = stateFilters?.lastActivity;
          if (filteredApps.length) {
            filteredApps = filteredApps.filter(
              app => {
                let platformRes = false, targetRes = false, envRes = false, expRes = false, statusRes = false, activityRes = false;
                if(platformFilters && Object.keys(platformFilters).length){
                  for (let platformFilter in platformFilters){
                    platformRes ||= app.platform_verbose === platformFilters[platformFilter];
                  }
                }
                else
                  platformRes = true;
                if(targetTypeFilters && Object.keys(targetTypeFilters).length){
                  for (let targetTypeFilter in targetTypeFilters){
                    targetRes ||= app.target_kind_verbose === targetTypeFilters[targetTypeFilter];
                  }
                }
                else
                  targetRes = true;
                if(environmentFilters && Object.keys(environmentFilters).length){
                  for (let envFilter in environmentFilters){
                    envRes ||= app.environment === environmentFilters[envFilter];
                  }
                }
                else
                  envRes = true;
                if(statusFilters && Object.keys(statusFilters).length){
                  for (let statusFilter in statusFilters){
                    statusRes ||= (app.latest_version_status_text !== null)
                      &&
                      app.latest_version_status_text.toLowerCase().indexOf(statusFilters[statusFilter].toLowerCase()) !== -1;
                  }
                }
                else
                  statusRes = true;
                if(expiringFilters && Object.keys(expiringFilters).length){
                  for (let expFilter in expiringFilters) {
                    expRes ||= (app.expiring_in !== null) && app.expiring_in < expiringFilters[expFilter];
                  }
                }
                else
                  expRes = true;
                if(activityFilters && Object.keys(activityFilters).length){
                  for (let actFilter in activityFilters){
                    let last_activity = moment(String(app.last_activity), 'X'), now = moment(new Date(), 'X');
                    activityRes ||= now.diff(last_activity, 'days') < activityFilters[actFilter];
                  }
                }
                else
                  activityRes = true;
                return platformRes && targetRes && envRes && statusRes && expRes && activityRes;
              }
            )
          }
            this.setState({
                ...this.state,
                headers: getHeaders(filteredApps, this.props.displayEnv),
                filteredApps: filteredApps,
            });
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.data !== this.props.data) {
            this.filterApps();
        }
    }

    componentDidMount() {
        document.getElementById('apps-search-input').onkeyup = this.filterApps;
        if (this.props.showRetiredApps) {
            document.title = 'Retired Apps';
        }
    }

    /** Shows the Components that Display the Header, the New App Button and the App Rows, or the spinner.**/
    render() {
        const { filteredApps, headers } = this.state;
        const {
            showRetiredApps,
            newAppButtonVisible,
            createAppEndpoint,
            loadingElements,
            sorting,
            sortByAttributeName,
            isAdmin
        } = this.props;
        const showAddAppButton = newAppButtonVisible && !showRetiredApps;
        const emptyColumns = headers.length > 0 ? headers.length - 1 : 0;
        const showNoAppsText = filteredApps.length === 0 && !loadingElements;

        const handleFiltersSaved = (event) => {
          event.preventDefault();
          this.filterApps();
          this.forceUpdate();
        }

        const handleClearFilters = () => {
          sessionStorage.removeItem(SESSION_FILTERS);
          this.setState({
            ...this.state,
            filters: initialClearedFilters,
            filterCount: 0
          })
          toggleDrawer();
        }

        const handlePlatformChange = (event, name) => {
          const field = name === constants.ANDROID ? stateFilterConstants.ANDROID : stateFilterConstants.IOS;
          this.setState({
            ...this.state,
            filters: {
              ...this.state.filters,
              platformVerbose: {
                ...this.state.filters.platformVerbose,
                [field]: event.target.checked ? name : ''
              }
            }
          });
        }

        const handleTargetTypeChange = (event, name) => {
          let field = ''
          switch(name){
            case constants.APPLE_APP_STORE:
              field = stateFilterConstants.APPLE_APP_STORE;
              break;
            case constants.GOOGLE_PLAY:
              field = stateFilterConstants.GOOGLE_PLAY;
              break;
            case constants.MICROSOFT_INTUNE:
              field = stateFilterConstants.MICROSOFT_INTUNE;
              break;
            case constants.WORKSPACE_ONE:
              field = stateFilterConstants.WORKSPACE_ONE;
              break;
            case constants.IVANTI_NEURONS_MDM:
              field = stateFilterConstants.IVANTI_NEURONS_MDM;
              break;
            case constants.IVANTI_EPMM:
              field = stateFilterConstants.IVANTI_EPMM;
              break;
          }
          this.setState({
            ...this.state,
            filters: {
              ...this.state.filters,
              targetKindVerbose: {
                ...this.state.filters.targetKindVerbose,
                [field]: event.target.checked ? name : ''
              }
            }
          });
        }

        const handleEnvironmentChange = (event, name) => {
          const field = name === constants.ENV_TEST ? stateFilterConstants.ENV_TEST : stateFilterConstants.ENV_PROD;
          this.setState({
            ...this.state,
            filters: {
              ...this.state.filters,
              environment: {
                ...this.state.filters.environment,
                [field]: event.target.checked ? name : ''
              }
            }
          });
        }

        const handleStatusChange = (event, name) => {
          let field = '';
          switch(name) {
            case constants.DRAFT:
              field = stateFilterConstants.DRAFT;
              break;
            case constants.SIGNED:
              field = stateFilterConstants.SIGNED;
              break;
            case constants.PUBLISHED:
              field = stateFilterConstants.PUBLISHED;
              break;
            case constants.FAILED:
              field = stateFilterConstants.FAILED;
          }
          this.setState({
            ...this.state,
            filters: {
              ...this.state.filters,
              status: {
                ...this.state.filters.status,
                [field]: event.target.checked ? name : ''
              }
            }
          });
        }

        const handleExpirationChange = (event, name) => {
          let field = '';
          switch (name){
            case constants.EXPIRED:
              field = stateFilterConstants.EXPIRED;
              break;
            case constants.EXPIRING_IN_A_MONTH:
              field = stateFilterConstants.EXPIRING_IN_A_MONTH;
              break;
            case constants.EXPIRING_IN_SIX_MONTHS:
              field = stateFilterConstants.EXPIRING_IN_SIX_MONTHS;
              break;
            case constants.EXPIRING_IN_THREE_MONTHS:
              field = stateFilterConstants.EXPIRING_IN_THREE_MONTHS;
              break;
          }
          this.setState({
            ...this.state,
            filters: {
              ...this.state.filters,
              expiringIn: {
                ...this.state.filters.expiringIn,
                [field]: event.target.checked ? name : ''
              }
            }
          });
        }

        const handleLastActivityChange = (event, name) => {
          let field = '';
          switch (name){
            case constants.LAST_WEEK:
              field = stateFilterConstants.LAST_WEEK;
              break;
            case constants.LAST_FORTNIGHT:
              field = stateFilterConstants.LAST_FORTNIGHT;
              break;
            case constants.LAST_MONTH:
              field = stateFilterConstants.LAST_MONTH;
              break;
            case constants.LAST_THREE_MONTHS:
              field = stateFilterConstants.LAST_THREE_MONTHS;
              break;
          }
          this.setState({
            ...this.state,
            filters: {
              ...this.state.filters,
              lastActivity: {
                ...this.state.filters.lastActivity,
                [field]: event.target.checked ? name : ''
              }
            }
          });
        }

        const list = () => (
          <div
            className="filtersList"
            role="presentation"
          >
            <div className='filterHeader'>
              <div className="h4">Apply Filters</div>
              <button className="btn btn-danger clearFilter" onClick={handleClearFilters}>Clear All</button>
            </div>
            <hr className="filterDivider"/>
            <div className="container">
              <form id="filtersForm" className="form">
                <FilterCheckboxGroup
                  title="Platform"
                  stateFilters={this.state.filters.platformVerbose}
                  listOfOptions={globalPlatformFilters}
                  handleChange={handlePlatformChange}
                />
                <FilterCheckboxGroup
                  title="Target Type"
                  listOfOptions={globalTargetTypeFilters}
                  stateFilters={this.state.filters.targetKindVerbose}
                  handleChange={handleTargetTypeChange}
                />
                <FilterCheckboxGroup
                  title="Environment"
                  stateFilters={this.state.filters.environment}
                  listOfOptions={globalEnvironmentFilters}
                  handleChange={handleEnvironmentChange}
                />
                <FilterCheckboxGroup
                  title="Status"
                  stateFilters={this.state.filters.status}
                  listOfOptions={globalStatusFilters}
                  handleChange={handleStatusChange}
                />
                <FilterCheckboxGroup
                  title="Expiration"
                  listOfOptions={globalExpirationFilters}
                  stateFilters={this.state.filters.expiringIn}
                  handleChange={handleExpirationChange}
                />
                <FilterCheckboxGroup
                  title="Last Activity"
                  listOfOptions={globalLastActivityFilters}
                  stateFilters={this.state.filters.lastActivity}
                  handleChange={handleLastActivityChange}
                />
              </form>
            </div>
            <hr className="filterDivider"/>
            <div className='filterButtons'>
              <button className="btn btn-primary filterCancel" onClick={toggleDrawer}>Cancel</button>
              <button className="btn btn-primary filterSave" onClick={() => { toggleDrawer(); handleFiltersSaved(event); }}>Apply</button>
            </div>
          </div>
          );

        const toggleDrawer = () => {
            this.state.showDrawer = !this.state.showDrawer;
            // if(this.state.showDrawer === true) {
            //   document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
            // }
            // else {
            //   document.getElementsByTagName('body')[0].style.overflowY = '';
            // }
            this.forceUpdate();
        };

        const handleBulkActionClicked = (name) => {
          this.state.action = name;
          switch(name) {
            case 'Retire':
              this.state.showRetiredModal = true;
              break;
            case 'Restore':
              this.state.showRestoreModal = true;
              break;
            case 'Delete':
              this.state.showDeleteModal = true;
          }
          this.forceUpdate();
        }
        const handleBulkActionClosed = () => {
          this.state.showDeleteModal = false;
          this.state.showRestoreModal = false;
          this.state.showRetiredModal = false;
          this.state.action = '';
          this.forceUpdate();
        }
        const handleBulkActionConfirmed = () => {
          const action = this.state.action.toLowerCase();
          const url = `bulk_${action}_apps/`;
          const data = this.state.selectedApps;
          postJSON(url, data)
            .then(() => {
              this.state.selectedApps = [];
              handleBulkActionClosed();
              switch (action) {
                case 'retire':
                  this.props.enqueueSnackbar('Retired multiple applications successfully', {
                    variant: 'success',
                    className: 'snackbarFont'
                  });
                  break;
                case 'restore':
                  this.props.enqueueSnackbar('Restored multiple applications successfully', {
                    variant: 'success',
                    className: 'snackbarFont'
                  });
                  break;
                case 'delete':
                  this.props.enqueueSnackbar('Deleted multiple applications successfully', {
                    variant: 'success',
                    className: 'snackbarFont'
                  });
              }
              location.reload();
            })
            .catch((err) => {
              console.error(err);
            })
        }
        
        return (
            <div id="apps" className={`content ${this.state.showDrawer ? 'showDrawer' : ''}`}>
                {showRetiredApps && <DisplayRetiredAppHeader />}
                {loadingElements ? (
                    <Spinner loadingLabel={'Loading Apps'} />
                ) : (
                    <div>
                        <>
                          <div className={`buttonContainer ${showRetiredApps ? 'marginTop' : ''}`}>
                            {
                              isAdmin &&
                              <div>
                              <button onClick={() => handleBulkActionClicked('Delete')}
                                      disabled={!this.state.selectedApps.length}
                                      className='btn btn-danger marginRight'>Delete
                              </button>
                              {
                                showRetiredApps ?
                                  <button onClick={() => handleBulkActionClicked('Restore')}
                                          disabled={!this.state.selectedApps.length}
                                          className='btn btn-primary'>Restore</button> :
                                  <button onClick={() => handleBulkActionClicked('Retire')}
                                          disabled={!this.state.selectedApps.length}
                                          className='btn btn-primary'>Retire</button>
                              }
                            </div>
                            }
                            <button className={`btn btn-primary ${isAdmin ? '' : 'filterButtonRight'}`} onClick={toggleDrawer}>
                              <Glyphicon glyph="filter"/> Filters<strong>{
                              this.state.filterCount > 0 ? `(${this.state.filterCount})` : ''
                            }</strong>
                            </button>
                          </div>
                          {
                            this.state.showDrawer === true &&
                            <>
                              <div id="drawer">
                                {list()}
                              </div>
                              <div id="overlay" onClick={toggleDrawer}></div>
                            </>
                          }
                          {showAddAppButton && (
                            <AddNewAppRow
                              empty_colums={emptyColumns}
                              createAppEndpoint={createAppEndpoint}
                            />
                          )}
                        </>
                        <table
                            id="apps-table"
                            className="table table-hover tablesorter">
                            <TableHeader
                                selectedApps={this.state.selectedApps}
                                setSelectAllApps={this.setSelectAllApps}
                                headers={headers}
                                sortAttributeName={sortByAttributeName}
                                sorting={sorting}
                            />
                            <tbody>
                                <SortedAppRows
                                    apps={filteredApps}
                                    headers={headers}
                                    sorting={sorting}
                                    selectedApplications={this.state.selectedApps}
                                    setSelectedAppsRoot={this.setSelectedAppsRoot}
                                />
                            </tbody>
                        </table>
                    </div>
                )}
                {showNoAppsText && (
                    <DisplayNoAppsText showRetiredApps={showRetiredApps} />
                )}
              {
                <Modal show={this.state.showRetiredModal || this.state.showRestoreModal || this.state.showDeleteModal}
                       onHide={handleBulkActionClosed}>
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm {this.state.action}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h4>You are trying to {this.state.action} the
                      following {this.state.selectedApps.length} app(s).</h4>
                    <ul>
                      {
                        this.state.selectedApps.map((app) => (
                          <li className="listItem">{app.name}</li>
                        ))
                      }
                    </ul>
                    <h4>Do you really want to {this.state.action}?</h4>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button bsStyle='primary' onClick={handleBulkActionClosed}>Cancel</Button>
                    <Button bsStyle='danger' onClick={handleBulkActionConfirmed}>{this.state.action} All</Button>
                  </Modal.Footer>
                </Modal>
              }
                {newAppButtonVisible && <NewAppModal />}
            </div>
        );
    }
}

export default withSnackbar(withDataFetching(AppListRaw));
