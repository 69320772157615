import axios from 'axios';

const postFormData = (url, formData) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }

    return axios.post(url, formData, config);
}

export default postFormData;
