import { put } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import axios from 'axios';
import { parse } from 'url';

export const getUsernameError = store =>
    store.login.errors.username.length > 0
        ? store.login.errors.username[0]
        : null;
export const getPasswordError = store =>
    store.login.errors.password.length > 0
        ? store.login.errors.password[0]
        : null;
export const getNonFieldError = store =>
    store.login.errors.non_field_errors.length > 0
        ? store.login.errors.non_field_errors[0]
        : null;

const SUBMIT = 'Login/SUBMIT';
const TOGGLE_SUBMIT = 'Login/TOGGLE_SUBMIT';
const CLEAR_ERRORS = 'Login/CLEAR_ERRORS';
const INITIALIZE_LOGIN = 'Login/INITIALIZE_LOGIN';
const INITIALIZE_LOGIN_FULFILLED = 'Login/INITIALIZE_LOGIN_FULFILLED';
const POST_LOGIN_DATA_FULFILLED = 'Login/POST_LOGIN_DATA_FULFILLED';
const POST_LOGIN_DATA_FAILED = 'Login/POST_LOGIN_DATA_FAILED';
const CHANGE_USERNAME = 'Login/CHANGE_USERNAME';
const CHANGE_PASSWORD = 'Login/CHANGE_PASSWORD';

export const clearErrors = () => {
    return { type: CLEAR_ERRORS };
};
export const toggleSubmit = () => {
    return { type: TOGGLE_SUBMIT };
};
export const initializeLogin = () => {
    return { type: INITIALIZE_LOGIN };
};
export const initializeLoginFulfilled = data => {
    return { type: INITIALIZE_LOGIN_FULFILLED, payload: data };
};
export const postLoginDataFulfilled = data => {
    return { type: POST_LOGIN_DATA_FULFILLED, payload: data };
};
export const postLoginDataFailed = data => {
    return { type: POST_LOGIN_DATA_FAILED, payload: data };
};
export const changeUsername = username => {
    return { type: CHANGE_USERNAME, payload: username };
};
export const changePassword = password => {
    return { type: CHANGE_PASSWORD, payload: password };
};

export const submit = credentials => {
    return { type: SUBMIT, credentials: credentials };
};

const initialState = {
    credentials: {
        ssoLogin: false,
    },
    errors: {
        password: [],
        username: [],
        non_field_errors: [],
    },
    isSubmitting: false,
};

const login = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE_LOGIN_FULFILLED:
            return {
                ...state,
                ...action.payload,
            };
        case CLEAR_ERRORS:
            return {
                ...state,
                errors: {
                    ...initialState.errors,
                },
            };
        case TOGGLE_SUBMIT:
            return {
                ...state,
                isSubmitting: !state.isSubmitting,
            };
        case POST_LOGIN_DATA_FULFILLED:
            window.location.href = action.payload.next_page;
            return state;
        case POST_LOGIN_DATA_FAILED:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    ...action.payload,
                },
            };
        case CHANGE_USERNAME:
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    username: action.payload,
                },
            };
        case CHANGE_PASSWORD:
            return {
                ...state,
                credentials: {
                    ...state.credentials,
                    password: action.payload,
                },
            };
        default:
            return state;
    }
};

function* submitLoginDataAsync(action) {
    yield put(clearErrors());
    yield put(toggleSubmit());
    const next_page = parse(window.location.href, true).query;
    try {
        let response;
        if (action.credentials.ssoLogin) {
            response = yield axios.post('/auth/sso-login', next_page);
        } else {
            window.console.log(window.location.href);
            const data = {
                ...action.credentials,
                ...next_page,
            };
            response = yield axios.post('/api/v1/login-api', data);
        }
        let data = response.data;
        yield put(postLoginDataFulfilled(data));
        yield put(toggleSubmit());
    } catch (error) {
        if (error.response.status === 400) {
            yield put(postLoginDataFailed(error.response.data));
            yield put(toggleSubmit());
        }
    }
}

function* initializeLoginDataAsync() {
    let response = yield axios.get('/api/v1/login-api');
    yield put(initializeLoginFulfilled(response.data));
}

export function* watchSubmitLoginDataAsync() {
    yield takeEvery(SUBMIT, submitLoginDataAsync);
}

export function* watchInitializeLoginDataAsync() {
    yield takeEvery(INITIALIZE_LOGIN, initializeLoginDataAsync);
}

export const sagas = [watchSubmitLoginDataAsync, watchInitializeLoginDataAsync];

export default login;
