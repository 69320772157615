import React from 'react';
import Modal from 'react-bootstrap/es/Modal';
import Button from 'react-bootstrap/es/Button';

export default class HelpAndSupport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    handleClose() {
        this.setState({
            showModal: false,
        });
    }

    handleTextClick() {
        this.setState({
            showModal: true,
        });
    }

    render() {
        const { showModal } = this.state;
        const { modalBody } = this.props;
        return (
            <div>
                <a className="clickable" onClick={() => this.handleTextClick()}>
                    {' '}
                    Help & Support{' '}
                </a>
                <Modal
                    backdrop={true}
                    show={showModal}
                    onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Help & Support</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{ __html: modalBody }} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="primary"
                            onClick={() => this.handleClose()}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
