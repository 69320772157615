import { compose, combineReducers, createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import reducers from '../components/AppDraft/reducers/index';
import rootSaga from './sagas';
import customField from './modules/customField';
import validation from './modules/fieldsValidation';
import navigation from './modules/navigation';
import diff from './modules/diff.js';
import appHeaderData from './modules/appHeaderData';
import axios from 'axios';
import appVersion from './modules/appVersionOverview/appVersionPage';

axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.xsrfCookieName = 'csrftoken';

const reducer = combineReducers({
    ...reducers,
    customField,
    validation,
    diff,
    appHeaderData,
    navigation,
    appVersion,
});

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['navigation'],
};

const persistedReducer = persistReducer(persistConfig, reducer);
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let middelware = applyMiddleware(sagaMiddleware, createLogger());

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test') {
    middelware = applyMiddleware(sagaMiddleware);
}
export const store = createStore(
    persistedReducer,
    composeEnhancers(middelware)
);

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
