import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import AppDetails from './AppDetails.jsx';
import AppStoreFormTabs from './AppStoreFormTabs.jsx';
import GooglePlayFormTabs from './GooglePlayFormTabs.jsx';

const FormTabsType = ({ draftFormType }) => {
    if (draftFormType == 'app-store') {
        return <AppStoreFormTabs />;
    }
    if (draftFormType == 'google-play') {
        return <GooglePlayFormTabs />;
    }
};

const VersionDraftForm = ({ context, draftFormType }) => {
    return (
        <div>
            <div className="header">
                <Grid>
                    <a className="back" href={context.backHref}>
                        {context.header.project_name}
                    </a>

                    <AppDetails
                        acl={context.acl}
                        appIconUrl={context.header.app_icon_url}
                        projectName={context.header.project_name}
                        platformVerbose={context.header.platform_verbose}
                        version={context.header.version}
                        buildNumber={context.header.build_number}
                        uploaded_binary={
                            context.specification.general.uploaded_binary
                        }
                    />
                </Grid>
            </div>
            <FormTabsType draftFormType={draftFormType} />
        </div>
    );
};

export default VersionDraftForm;
