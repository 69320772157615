import React, { PureComponent } from 'react';
import { Row, Col, PanelGroup, Panel } from 'react-bootstrap';
import Chevron from './icons/Chevron.jsx';

export default class PanelGroupController extends PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            activeKey: 0,
        };
    }

    render() {
        const { sections } = this.props;
        const { activeKey } = this.state;
        return (
            <PanelGroup
                accordion
                className="localizable-language-metadata-panel"
                id="localizable-language-metadata-panel"
                activeKey={activeKey}
                onSelect={this.handleSelect}>
                {sections.map((section, id) => (
                    <Panel
                        key={`localizable-language-${id}`}
                        eventKey={id}
                        id={section.id}>
                        <Panel.Heading>
                            <Panel.Toggle className="localizable-language-metadata-toggle">
                                <Row className="section-header">
                                    <Row>
                                        <Col sm={11}>
                                            <h3>{section.title}</h3>
                                        </Col>
                                        <Col sm={1}>
                                            <Chevron
                                                className={
                                                    activeKey !== id
                                                        ? 'collapsed pull-right'
                                                        : 'pull-right'
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Row>
                            </Panel.Toggle>
                        </Panel.Heading>
                        {activeKey === id && (
                            <Panel.Body collapsible>
                                {section.content}
                            </Panel.Body>
                        )}
                    </Panel>
                ))}
            </PanelGroup>
        );
    }

    handleSelect = activeKey => this.setState({ activeKey });
}
