import React from 'react';
import FieldRow from '../../../layout/FieldRow';
import MultiRadioField, {
    mapToNumericBooleanOptions,
    numericBooleanOptions,
} from '../../../inputs/MultiRadioField';
import Expandable from '../../../Expandable';
import GovernmentNotification from './GovernmentNotification';
import { noSelected, noIsSelected } from '../ResetOptions';
import XcodeNotification from './XcodeNotification';

const handleButtonToggle = (event, props) => {
    let reset = null;
    if (noSelected(event)) {
        // If no is selected, the french app store button will be set to null
        reset = ['available_on_french_store'];
        props.nullifyOption('export_compliance_available_on_french_store');
    }
    props.handleChangeRadio(
        event,
        null,
        props.csrf_token,
        props.app_version,
        reset
    );
};

const ThirdPartyCryptography = ({
    validation,
    reviewInformation,
    readonly,
    props,
}) => {
    return (
        <Expandable
            expanded={
                reviewInformation.export_compliance_uses_encryption &&
                noIsSelected(reviewInformation.export_compliance_is_exempt) &&
                noIsSelected(
                    reviewInformation.export_compliance_contains_proprietary_cryptography
                )
            }>
            <FieldRow label="" required>
                <div>
                    Does your app implement any standard encryption algorithms
                    instead of, or in addition to, using or accessing the
                    encryption within Apple’s operating system?
                </div>
                <MultiRadioField
                    name="export_compliance_contains_third_party_cryptography"
                    id="export-compliance-contains-third-party-cryptography"
                    error={
                        validation.errors
                            .export_compliance_contains_third_party_cryptography
                    }
                    progress={
                        validation.isLoading
                            .export_compliance_contains_third_party_cryptography
                    }
                    dirty={
                        validation.dirty
                            .export_compliance_contains_third_party_cryptography
                    }
                    selected={mapToNumericBooleanOptions(
                        reviewInformation.export_compliance_contains_third_party_cryptography
                    )}
                    readonly={readonly}
                    onChange={event => handleButtonToggle(event, props)}
                    options={numericBooleanOptions}
                />
                {noIsSelected(
                    reviewInformation.export_compliance_contains_third_party_cryptography
                ) && <><XcodeNotification/><GovernmentNotification/></>}
            </FieldRow>
        </Expandable>
    );
};

export default ThirdPartyCryptography;
