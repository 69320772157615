import React from 'react';
import VmWareAirWatchOrganizationGroupTree from '../Targets/VmWareAirWatchOrganizationGroupTree.jsx';
import axios from 'axios';

export default class PublishToVmWareAirWatchModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupsSelected: true,
        };
    }

    onGroupSelected = selectedGroups => {
        this.setState({
            selectedGroups: selectedGroups,
            groupsSelected: selectedGroups.length > 0,
        });
    };

    publish = () => {
        if (this.props.publishInProgress) {
            return;
        }

        let url = this.props.publish_endpoint;

        if (this.props.selectOrganizationalGroupsWhenPublishing) {
            url = `${url}?airwatch_groups=${this.state.selectedGroups.join(
                ','
            )}`;
            let groupsSelected =
                this.state.selectedGroups &&
                this.state.selectedGroups.length > 0;

            //There is actually a reason why groupsSelected is set here and publishInProgress is set after the if clause
            //If I set publishInProgress above, it causes a bug that always ends the publishing because publishInProgress is true
            //And if I set groupsSelected below, it causes a bug that makes the NoGroupsSelected warning text to never show.

            this.setState({
                groupsSelected: groupsSelected,
            });

            if (!groupsSelected) {
                return;
            }
        }

        this.props.publishStarted();

        this.forceUpdate();
        axios.get(url)
            .then(() => {
                location.reload();
            })
            .catch(() => {
                location.reload();
            });
    };

    handleHideModal = () => {
        this.setState({
            groupsSelected: true,
        });
    };

    render() {
        return (
            <div
                className="modal fade"
                id={`publish-to-vmware_airwatch-modal-${this.props.target_id}`}>
                <div className="modal-dialog">
                    <div
                        id="vmware_airwatch-loader"
                        className="loader"
                        style={{
                            visibility: this.props.publishInProgress
                                ? 'visible'
                                : 'hidden',
                        }}>
                        <img
                            className="spinner-alone"
                            src={`${staticsPath}/img/spinner.gif`}
                        />
                    </div>
                    <div className="modal-content">
                        <div className="modal-header">
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close">
                                <span
                                    aria-hidden="true"
                                    onClick={this.handleHideModal}>
                                    &times;
                                </span>
                            </button>
                            <h4 className="modal-title">
                                Publishing
                            </h4>
                        </div>
                        <div className="modal-body">
                            <p>
                                You are about to publish{' '}
                                <b>{this.props.platform}</b> application{' '}
                                <b>{this.props.app_name}</b> version{' '}
                                <b>{this.props.version}</b> (
                                <b>{this.props.build}</b>) in VMWare AirWatch '
                                <b>{this.props.target_name}</b>'.
                            </p>

                            <p>This action cannot be undone.</p>

                            {this.props
                                .selectOrganizationalGroupsWhenPublishing && (
                                <VmWareAirWatchOrganizationGroupTree
                                    endpoint={this.props.endpoint}
                                    readonly={true}
                                    showCheckboxes={true}
                                    showSelectAllButton={true}
                                    groupsSelected={this.state.groupsSelected}
                                    onItemSelected={this.onGroupSelected}
                                />
                            )}
                            {this.props
                                .selectOrganizationalGroupsWhenPublishing &&
                                !this.state.groupsSelected && (
                                <p>
                                    <span className="text-danger">
                                            Select at least one Organization
                                            Group for publishing!
                                    </span>
                                </p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.handleHideModal}
                                data-dismiss="modal">
                                Cancel
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.publish}>
                                Yes, publish
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
