import React, { Component } from 'react';
import { Tab, Row, Col, Nav, NavItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import ToggleContainer from '../containers/ToggleContainer.jsx';
import { navigateToTab } from '../../../redux/modules/navigation';
import TabLabel from '../components/TabLabel.jsx';
import { setNotDirty } from '../actions/validation.js';
import scrollTop from '../util/scrollTop.js';
import { selectCurrentTab } from '../selectors/navigation';

class TabController extends Component {
    componentWillMount() {
        this.props.setNotDirty();
    }

    render() {
        const { currentTab, tabs, navigateToTab } = this.props;
        return (
            <Tab.Container
                id="form-steps"
                activeKey={currentTab}
                onSelect={navigateToTab}>
                <Row className="clearfix">
                    <Col sm={3}>
                        <Nav bsStyle="pills" stacked>
                            {tabs.map((tab, index) => (
                                <NavItem key={`tab-${index}`} eventKey={index}>
                                    <TabLabel
                                        title={tab.title}
                                        error={tab.hasError === true}
                                        done={tab.hasError === false}
                                    />
                                </NavItem>
                            ))}
                        </Nav>
                        <ToggleContainer />
                    </Col>
                    <Col sm={9}>
                        <Tab.Content className="form-steps-content" animation>
                            {tabs.map((tab, index) => (
                                <Tab.Pane key={`tab-${index}`} eventKey={index}>
                                    {tab.component}
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        );
    }
}

const select = store => ({
    currentTab: selectCurrentTab(store),
    appId: store.context.app_id,
    versionId: store.context.app_version,
});

const mergeDispatchWithProps = (
    { currentTab, appId, versionId, ...props },
    { navigateToTab },
    { tabs }
) => {
    return {
        ...props,
        ...actions,
        currentTab,
        tabs,
        navigateToTab: key => {
            // scrollTop();
            return navigateToTab(appId, versionId, key);
        },
    };
};

const actions = { navigateToTab, setNotDirty };

export default connect(
    select,
    actions,
    mergeDispatchWithProps
)(TabController);
