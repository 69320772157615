//TODO: get expectedWidth & expectedHeight from backend dictionary
//TODO: Move each *DevicePropsMap to external catalog and handle by abstraction class
const ipadPropsList = [
    {
        inputName: 'ipad_pro_3rd',
        name: 'iPad 12.9 (3rd)',
        expectedWidth: 2048,
        expectedHeight: 2732,
        required: true,
    },
    {
        inputName: 'ipad_pro',
        name: 'iPad 12.9 (2nd)',
        expectedWidth: 2048,
        expectedHeight: 2732,
        required: true,
    },
    {
        inputName: 'ipad_105',
        name: 'iPad 10.5"',
        expectedWidth: 1668,
        expectedHeight: 2224,
        required: false,
    },
    {
        inputName: 'ipad_11',
        name: 'iPad 11"',
        expectedWidth: 1200,
        expectedHeight: 1600,
        required: false,
    },
    {
        inputName: 'ipad',
        name: 'iPad 9.7"',
        expectedWidth: 768,
        expectedHeight: 1024,
        required: false,
    },
];

const iphonePropsList = [
    {
        inputName: 'iphone_6_7_in',
        name: 'iPhone 6.7"',
        expectedWidth: 1284,
        expectedHeight: 2778,
        required: false,
    },
    {
        inputName: 'iphone_6_5_in',
        name: 'iPhone 6.5"',
        expectedWidth: 1242,
        expectedHeight: 2688,
        required: true,
    },
    {
        inputName: 'iphone_5_8_in',
        name: 'iPhone 5.8"',
        expectedWidth: 1125,
        expectedHeight: 2436,
        required: false,
        hint: '5.8-inch screenshots are optional but highly recommended.'
    },
    {
        inputName: 'iphone_5_5_in',
        name: 'iPhone 5.5"',
        expectedWidth: 1242,
        expectedHeight: 2208,
        required: true,
    },
    {
        inputName: 'iphone_4_7_in',
        name: 'iPhone 4.7"',
        expectedWidth: 750,
        expectedHeight: 1334,
        required: false,
    },
    {
        inputName: 'iphone_4_0_in',
        name: 'iPhone 4"',
        expectedWidth: 640,
        expectedHeight: 1136,
        required: false,
    },
    {
        inputName: 'iphone_3_5_in',
        name: 'iPhone 3.5"',
        expectedWidth: 640,
        expectedHeight: 960,
        required: false,
    },
];

const watchPropsList = [
    {
        inputName: 'watch',
        name: 'Apple watch"',
        expectedWidth: 312,
        expectedHeight: 390,
        required: false,
    }
];

export function getScreenshotDevicePropsMap(localizable){
    let screenshotDevicePropsMap = {};
    screenshotDevicePropsMap['iphone'] = localizable.supports_iphone && iphonePropsList;
    screenshotDevicePropsMap['ipad'] = localizable.supports_ipad && ipadPropsList;
    screenshotDevicePropsMap['watch'] = localizable.supports_watch && watchPropsList;
    return screenshotDevicePropsMap;
}
