import React from 'react';

export default class VmWareAirWatchOrganizationGroupTreeItem extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const flatPadding = 5;
        const paddingPerLevel = 30;
        const levelPadding = this.props.level * paddingPerLevel;
        const style = {
            paddingLeft: `${flatPadding + levelPadding}px`,
            display: this.props.item.visible ? 'flex' : 'none',
        };

        const checkboxStyle = {};

        if (this.props.item.disabled) {
            checkboxStyle.cursor = 'not-allowed';
            checkboxStyle.color = '#999';
            style.color = '#999';
        }

        const isLeaf = !(
            this.props.item.children && this.props.item.children.length > 0
        );
        const expandButtonStyle = {
            visibility: isLeaf ? 'hidden' : 'visible',
        };

        return (
            <div className={'item-wrapper'} style={style}>
                <div
                    className={`expand-button ${
                        this.props.item.expanded ? 'expanded' : 'collapsed'
                    }`}
                    onMouseDown={this.props.expandButtonClick}
                    style={expandButtonStyle}
                />
                <div
                    style={checkboxStyle}
                    className="checkbox-wrapper"
                    onMouseDown={this.props.checkItem}>
                    {this.props.showCheckboxes && (
                        <div
                            style={checkboxStyle}
                            className={`og-tree-checkbox${
                                this.props.item.checked ? ' checked' : ''
                            }`}
                        />
                    )}
                    <div style={checkboxStyle} className="text">
                        <span className="text-inner">
                            {this.props.item.label}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
