import React, { PureComponent } from 'react';
import Dropzone from 'react-dropzone';
import AssetAdd from './AssetAdd.jsx';
import AssetPlaceholder from './AssetPlaceholder.jsx';
import VideoPreview from './AssetVideoPreview';
import ImagePreview from './ImagePreview';

export const ASSET_TYPE_IMAGE = 'image';
export const ASSET_TYPE_VIDEO = 'video';

const getRatio = (width, height) => {
    return width < height ? width / height : height / width;
};

export default class AssetDropzone extends PureComponent {
    static defaultProps = {
        expectedWidth: 320,
        expectedHeight: 568,
        assets: [],
        readonly: false,
    };

    canAddAssets = assets => {
        const { maxAssetsCount } = this.props;
        const assetsCount = this.countAssets(assets);

        return assetsCount <= maxAssetsCount;
    };

    isNotMaxAssetsCount = () => {
        const { assets, maxAssetsCount } = this.props;
        return assets.length < maxAssetsCount;
    };

    isAssetsEmpty = () => {
        return this.props.assets.length === 0;
    };

    isFileAssetType = file => file.type && file.type.startsWith(`${this.props.assetType}/`);

    countAssets = (assets = []) => {
        return assets.length + this.props.assets.length;
    };

    getPreviewStyle = () => {
        const { expectedWidth, expectedHeight } = this.props;
        const width = getRatio(expectedWidth, expectedHeight) * 300;

        return {
            width: width,
            height: (expectedHeight * width) / expectedWidth,
        };
    };

    handleDelete = asset => () => {
        if (asset.url && asset.id) {
            this.props.onDelete(asset.id);
        }
    };

    handleDrop = files => {
        const assets = files.filter(this.isFileAssetType);

        if (this.canAddAssets(assets)) {
            this.props.onChange(assets);
        } else {
            this.props.onError();
        }
    };

    renderPreview = () => {
        const { assets, readonly, assetType } = this.props;
        const previewStyle = this.getPreviewStyle();

        return assets.map(asset => {
            const AssetPreview = assetType === ASSET_TYPE_VIDEO ? VideoPreview : ImagePreview;
            return (
                <AssetPreview
                    key={asset.id}
                    readonly={readonly}
                    style={previewStyle}
                    src={asset.url}
                    onDelete={this.handleDelete(asset)}
                />
            );
        });
    };

    renderAdd = () => {
        const { readonly, placeholderMessage } = this.props;
        const previewStyle = this.getPreviewStyle();

        if (!readonly && this.isNotMaxAssetsCount()){
            return <AssetAdd style={previewStyle} />;
        } else if (readonly && this.isAssetsEmpty()){
            return <AssetPlaceholder style={previewStyle} placeholderMessage={placeholderMessage}/>;
        }
    };

    render() {
        const { readonly, assetType } = this.props;
        return (
            <div className="asset-dropzone-list">
                {this.renderPreview()}
                <Dropzone
                    className="asset-dropzone"
                    multiple
                    accept={`${assetType}/*`}
                    disabled={readonly}
                    onDrop={this.handleDrop}>
                    {this.renderAdd()}
                </Dropzone>
            </div>
        );
    }
}
