// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import axios from 'axios';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';


class EditableAppBaseDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            loading: false,
            currentValue: this.props.initialAppDetailValue,
        };
        this.handleSave = this.handleSave.bind(this);
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.onEntered = this.onEntered.bind(this);
        this.onSelectRadioButton = this.onSelectRadioButton.bind(this);
        this.input = React.createRef();
        this.isSelectType = this.props.selectOptions
    }

    handleSave() {
        let currentValue = this.input.current.value;

        this.setState(
            {
                loading: true,
                errors: [],
                currentValue: this.isSelectType ?
                  this.state.currentValue : currentValue,
            },
            () => {
                const data = {};
                data[this.props.appDetailKey] = currentValue;
                axios.patch(this.props.endpoint, data).then((response) => {
                    if (response.status === 200) {
                        this.handleToggleModal();
                    }
                }).catch(error => {
                    this.setState({
                        errors: error.response.data[this.props.appDetailKey],
                        loading: false
                    })
                });
            }
        );
    }

    handleToggleModal(event) {
        this.props.handleToggleModal(event == null, this.state.currentValue);
    }

    onEntered() {
        if (this.props.autocompleteEndpoint) {
            setTimeout(
                function () {
                    autoCompleteBusinessUnits();
                }.bind(this),
                1
            );
        }
    }

    onSelectRadioButton(event) {
        this.setState({
            currentValue: event.target.name
        });
        this.input.current.value = event.target.value
    }


    render() {
        const hasAutocomplete = this.props.autocompleteEndpoint;
        const divId = hasAutocomplete ? 'business-unit-container' : null;
        const inputId = hasAutocomplete ? 'id_business_unit' : 'edit-name';
        return (
            <Modal
                show={true}
                onEntered={this.onEntered()}
                restoreFocus={false}>
                <div
                    id="edit-app-data-loader"
                    className="loader"
                    style={{
                        visibility: this.state.loading ? 'visible' : 'hidden',
                    }}>
                    <img
                        className="spinner-alone"
                        src={`${staticsPath}/img/spinner.gif`}
                    />
                </div>
                <Modal.Header>
                    <Modal.Title>Editing {this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div
                            className={`form-group${this.state.errors.length
                                && ' has-error'}`}
                            id={divId}
                            endpoint={this.props.autocompleteEndpoint}>
                            {this.isSelectType
                                ? <div ref={this.input}>
                                    {this.props.selectOptions.map(option =>
                                        <div className="radio">
                                            <input
                                                id={option.api_value}
                                                name={option.name}
                                                type="radio"
                                                value={option.api_value}
                                                checked={this.state.currentValue === option.name}
                                                onChange={this.onSelectRadioButton}
                                            />
                                            <label htmlFor={option.api_value}>
                                                {option.name}
                                            </label>
                                        </div>
                                    )}
                                </div>
                                : (
                                    <label htmlFor={inputId}>
                                        <input
                                            id={inputId}
                                            type="text"
                                            className="form-control"
                                            defaultValue={this.state.currentValue}
                                            ref={this.input}
                                        />
                                    </label>
                                )}

                            {this.state.errors.map((error, index) =>
                                <span className="help-block" key={index}>
                                    {error}
                                </span>
                            )}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button bsStyle="primary" onClick={this.handleToggleModal}>
                        Cancel
                    </Button>
                    <Button bsStyle="primary" onClick={this.handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal >
        );
    }
}


export default EditableAppBaseDetail;
