import { createSelector } from 'reselect';
import _ from 'lodash';

export const previousValuesSelector = createSelector(
    store => store.diff,
    diff =>
        diff.application_data && diff.showDiff
            ? _.mapValues(diff.application_data, 'previous_value')
            : {}
);

export const previousValueListSelector = createSelector(
    store => store.diff,
    diff => {
        return diff.showDiff && diff.all_countries_metadata
            ? _.mapValues(diff.all_countries_metadata, lang =>
                _.mapValues(lang, 'previous_value')
            )
            : {};
    }
);
