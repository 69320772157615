import React, { Fragment } from 'react';
import ContainsThirdPartyContent from './ContentRights/ContainsThirdPartyContent';

const ContentRights = ({ validation, reviewInformation, readonly, props }) => {
    return (
        <Fragment>
            <ContainsThirdPartyContent
                validation={validation}
                reviewInformation={reviewInformation}
                readonly={readonly}
                props={props}
            />
        </Fragment>
    );
};

export default ContentRights;
