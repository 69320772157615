import * as actionType from './types';

export const addContextToRedux = context => ({
    type: actionType.ADD_CONTEXT,
    context,
});

export const updateContext = (tabName, context) => ({
    type: actionType.UPDATE_CONTEXT,
    payload: {
        tabName,
        context,
    },
});

export const updateContextWithNewContentRating = data => ({
    type: actionType.CHANGE_CONTENT_RATING_CATEGORY,
    data,
});
