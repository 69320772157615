import React, { Fragment } from 'react';
import { Row, Col, Tab, Nav, NavItem } from 'react-bootstrap';
import TabLabel from './TabLabel.jsx';
import GooglePlayProductDetailsTab from './GooglePlayProductDetailsTab.jsx';
import GooglePlayGraphicAssetsTab from './GooglePlayGraphicAssetsTab.jsx';
import ButtonWithModal from './inputs/ButtonWithModal.jsx';

const GooglePlayLocalizableLanguageMetadata = ({
    metadata,
    multiple_languages,
    validation,
    readonly,
    diff,
    options,
    handleSetCorrect,
    ...props
}) => {
    const languageCode = metadata.language.code;
    const productDetailsFields = [
        'title',
        'short_description',
        'full_description',
        'whats_new',
    ];
    const graphicAssetsFields = [
        'hi_res_icon',
        'feature_graphic',
        'promo_graphic',
        'banner_asset',
        'daydream360',
        'promo_video',
        'phone',
    ];
    const productDetailsHasErrors = productDetailsFields.some(
        fieldName =>
            validation.errors[languageCode] &&
            validation.errors[languageCode][fieldName]
    );
    const graphicAssetsHasErrors = graphicAssetsFields.some(
        fieldName =>
            validation.errors[languageCode] &&
            validation.errors[languageCode][fieldName]
    );
    const productDetailsIsDone = productDetailsFields.some(
        fieldName =>
            validation.dirty[languageCode] &&
            validation.dirty[languageCode][fieldName]
    );
    const graphicAssetsIsDone = graphicAssetsFields.some(
        fieldName =>
            validation.dirty[languageCode] &&
            validation.dirty[languageCode][fieldName]
    );
    return (
        <Fragment>
            {!readonly && !metadata.is_default && (
                <ButtonWithModal
                    buttonText="Copy from default language"
                    modalText="This change will overwrite all settings for this language"
                    onClick={() =>
                        props.handleLoadFromDefaultLanguage(
                            props.app_version,
                            languageCode
                        )
                    }
                />
            )}
            {!readonly && metadata.is_default && multiple_languages && (
                <ButtonWithModal
                    buttonText="Copy from default language to other languages"
                    modalText="This change will overwrite all settings for all added languages"
                    onClick={() => props.handleLoadFromDefaultLanguageToOthers(props.app_version)}
                />
            )}
            <Tab.Container
                id="localizable-metadata-tabs"
                defaultActiveKey="first">
                <Row className="clearfix">
                    <Col sm={12}>
                        <Nav bsStyle="pills" justified>
                            <NavItem eventKey="first">
                                <TabLabel
                                    title="Product details"
                                    error={productDetailsHasErrors}
                                    done={
                                        !productDetailsHasErrors &&
                                        productDetailsIsDone
                                    }
                                />
                            </NavItem>
                            <NavItem eventKey="second">
                                <TabLabel
                                    title="Graphic assets"
                                    error={graphicAssetsHasErrors}
                                    done={
                                        !graphicAssetsHasErrors &&
                                        graphicAssetsIsDone
                                    }
                                />
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content
                            animation
                            className="localizable-metadata-sub-tab">
                            <Tab.Pane eventKey="first">
                                <GooglePlayProductDetailsTab
                                    metadata={metadata}
                                    validation={validation}
                                    readonly={readonly}
                                    diff={diff}
                                    options={options}
                                    {...props}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <GooglePlayGraphicAssetsTab
                                    metadata={metadata}
                                    validation={validation}
                                    readonly={readonly}
                                    diff={diff}
                                    rangeDimensions={options.screenshots}
                                    handleSetCorrect={handleSetCorrect}
                                    {...props}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Fragment>
    );
};

export default GooglePlayLocalizableLanguageMetadata;
