// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { postJSON } from '../Utils/postJSON.jsx';

class MessageForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: '' };
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState(
            {
                value: event.target.value,
            },
            () => {
                this.props.onChange(this.state.value);
            }
        );
    }

    render() {
        return (
            <div className="form-group">
                <label className="control-label" htmlFor="id_message">
                    Message
                </label>
                <textarea
                    name="message"
                    cols="40"
                    rows="10"
                    className="form-control user-error"
                    id="id_message"
                    aria-invalid="true"
                    value={this.state.value}
                    onChange={this.handleChange}
                />
            </div>
        );
    }
}

export default class ReplyToRejectionButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            saveButtonDisabled: true,
        };
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleToggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    handleChange(change) {
        this.setState({
            text: change,
            saveButtonDisabled: change.trim().length == 0,
        });
    }

    handleSubmit() {
        this.setState({
            saveButtonDisabled: true
        });
        postJSON(this.props.endpoint, this.state.text).then(() => {
            this.handleToggleModal();
            window.location.reload();
        });
    }

    render() {
        return (
            <div>
                <Button bsStyle="primary" onClick={this.handleToggleModal}>
                    Reply
                </Button>
                <Modal
                    show={this.state.showModal}
                    onClose={this.handleToggleModal}>
                    <Modal.Header>
                        <Modal.Title>Reply</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MessageForm onChange={this.handleChange} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="primary"
                            onClick={this.handleToggleModal}>
                            Cancel
                        </Button>
                        <Button
                            bsStyle="primary"
                            onClick={this.handleSubmit}
                            disabled={this.state.saveButtonDisabled}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
