import { connect } from 'react-redux';
import axios from 'axios';
import { changeTextInput, changeSelectInput } from '../actions/form.js';
import {
    setDirty,
    setError,
    setCorrect,
    isLoading,
    isNotLoading,
} from '../actions/validation.js';
import { previousValuesSelector } from '../selectors/diff';
import TestflightForm from '../components/tabs/TestflightForm.jsx';

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    testflightDetails: store.context.testflight_details,
    diff: previousValuesSelector(store),
    validation: store.validation,
    csrf_token: store.context.csrf_token,
    app_version: store.context.app_version,
    isBetaTestingEnabled: store.context.is_beta_testing_enabled,
    isVersionSigned: store.context.is_version_signed,
    isFirstVersion: store.context.is_first_version,
});

const mapDispatchToProps = dispatch => ({
    handleChangeTextInput: event => {
        dispatch(
            changeTextInput(
                event.target.value,
                event.target.name,
                'testflight_details'
            )
        );
    },

    handleBlurTextInput: (event, csrf_token, app_version) => {
        let name = event.target.name;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/testflight-details`,
                { [event.target.name]: event.target.value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },

    handleSwitchChange: (event, csrf_token, app_version) => {
        let name = event.target.name;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/testflight-details`,
                { [event.target.name]: event.target.checked },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleChangeCheckbox: (event, csrf_token, app_version) => {
        dispatch(
            changeSelectInput(
                event.target.checked,
                event.target.name,
                'testflight_details'
            )
        );
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/testflight-details`,
                { [event.target.name]: event.target.checked },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                // dispatch success
            })
            .catch(error => {
                // dispatch failure
            });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestflightForm);
