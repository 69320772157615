import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReadOnlyDiffValue from './ReadOnlyDiffValue.jsx';

class ReadOnlyValueList extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false,
        };
    }

    static defaultProps = {
        ...React.Component.defaultProps,
        selected: [],
    };

    handleClose() {
        this.setState({ show: false });
    }

    handleShow() {
        this.setState({ show: true });
    }

    render() {
        return (
            <React.Fragment>
                <Button onClick={this.handleShow}>Show list</Button>

                <Modal
                    show={this.state.show}
                    onHide={this.handleClose}
                    className="custom-modal-dialog">
                    <Modal.Header closeButton>
                        <Modal.Title>{this.props.label}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.props.previousValue ? (
                            <ul>
                                {this.props.previousValue.length > 0 ? (
                                    this.props.previousValue.map(
                                        (item, index) => (
                                            <li key={`readonly-list-${index}`}>
                                                <span className="readonly-value">
                                                    <ReadOnlyDiffValue
                                                        value={item.value}
                                                        previousValue={
                                                            item.previousValue ||
                                                            ''
                                                        }
                                                    />
                                                </span>
                                            </li>
                                        )
                                    )
                                ) : (
                                    <li>
                                        <span className="readonly-value">
                                            There are no changes and no items
                                            are selected.
                                        </span>
                                    </li>
                                )}
                            </ul>
                        ) : (
                            <ul>
                                {this.props.selected.length > 0 ? (
                                    this.props.selected.map((item, index) => (
                                        <li key={`readonly-list-${index}`}>
                                            <span className="readonly-value">
                                                <ReadOnlyDiffValue
                                                    value={item.label}
                                                    previousValue={undefined}
                                                />
                                            </span>
                                        </li>
                                    ))
                                ) : (
                                    <li>
                                        <span className="readonly-value">
                                            There are no items selected.
                                        </span>
                                    </li>
                                )}
                            </ul>
                        )}
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default ReadOnlyValueList;
