import React, { Fragment } from 'react';
import { Button, Col, Nav, NavItem, Row, Tab } from 'react-bootstrap';
import AppStoreGraphicAssetsTab from './AppStoreGraphicAssetsTab.jsx';
import AppStoreProductDetailsTab from './AppStoreProductDetailsTab.jsx';
import ButtonWithModal from './inputs/ButtonWithModal.jsx';
import TabLabel from './TabLabel.jsx';

const AppStoreLocalizableLanguageMetadata = ({
    metadata,
    validation,
    multiple_languages,
    supports_iphone,
    supports_ipad,
    supports_watch,
    readonly,
    diff,
    options,
    handleSetCorrect,
    ...props
}) => {
    const languageCode = metadata.language.code;
    const productDetailsFields = [
        'app_title',
        'subtitle',
        'description',
        'whats_new',
        'keywords',
        'promotional_text',
        'support_url',
        'marketing_url',
        'privacy_policy_url',
    ];
    const graphicAssetsFields = [
        'iphone_6_7_in',
        'iphone_6_5_in',
        'iphone_5_5_in',
        'ipad_pro',
        'ipad_pro_3rd',
        'watch',
        'promotion_video_attachment',
    ];
    const productDetailsHasErrors = productDetailsFields.some(
        fieldName =>
            validation.errors[languageCode] &&
            validation.errors[languageCode][fieldName]
    );
    const graphicAssetsHasErrors = graphicAssetsFields.some(
        fieldName =>
            validation.errors[languageCode] &&
            validation.errors[languageCode][fieldName]
    );
    const productDetailsIsDone = productDetailsFields.some(
        fieldName =>
            validation.dirty[languageCode] &&
            validation.dirty[languageCode][fieldName]
    );
    const graphicAssetsIsDone = graphicAssetsFields.some(
        fieldName =>
            validation.dirty[languageCode] &&
            validation.dirty[languageCode][fieldName]
    );
    return (
        <Fragment>
            {!readonly && !metadata.is_default && (
                <div>
                    <ButtonWithModal
                        buttonText="Copy from default language"
                        modalText="This change will overwrite all settings for this language"
                        onClick={() =>
                            props.handleLoadFromDefaultLanguage(
                                props.app_version,
                                languageCode
                            )
                        }
                    />
                    <Button
                        bsStyle="link"
                        onClick={() =>
                            props.handleSetDefaultLanguage(
                                props.csrf_token,
                                props.app_version,
                                languageCode
                            )
                        }>
                        Set as default language
                    </Button>
                </div>
            )}
            {!readonly && metadata.is_default && multiple_languages && (
                <ButtonWithModal
                    buttonText="Copy from default language to other languages"
                    modalText="This change will overwrite all settings for all added languages"
                    bsStyle="link"
                    onClick={() => props.handleLoadFromDefaultLanguageToOthers(props.app_version)}
                />
            )}
            <Tab.Container
                id="localizable-metadata-tabs"
                defaultActiveKey="first">
                <Row className="clearfix">
                    <Col sm={12}>
                        <Nav bsStyle="pills" justified>
                            <NavItem eventKey="first">
                                <TabLabel
                                    title="Product details"
                                    error={productDetailsHasErrors}
                                    done={
                                        !productDetailsHasErrors &&
                                        productDetailsIsDone
                                    }
                                />
                            </NavItem>
                            <NavItem eventKey="second">
                                <TabLabel
                                    title="Graphic assets"
                                    error={graphicAssetsHasErrors}
                                    done={
                                        !graphicAssetsHasErrors &&
                                        graphicAssetsIsDone
                                    }
                                />
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content
                            animation
                            className="localizable-metadata-sub-tab">
                            <Tab.Pane eventKey="first">
                                <AppStoreProductDetailsTab
                                    metadata={metadata}
                                    validation={validation}
                                    readonly={readonly}
                                    diff={diff && diff[languageCode]}
                                    options={options}
                                    {...props}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <AppStoreGraphicAssetsTab
                                    metadata={metadata}
                                    validation={validation}
                                    supports_ipad={supports_ipad}
                                    supports_iphone={supports_iphone}
                                    supports_watch={supports_watch}
                                    readonly={readonly}
                                    diff={diff && diff[languageCode]}
                                    options={options}
                                    handleSetCorrect={handleSetCorrect}
                                    {...props}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </Fragment>
    );
};

export default AppStoreLocalizableLanguageMetadata;
