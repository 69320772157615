import * as actionType from './types';

export const receivedDiff = data => ({
    type: actionType.RECEIVED_DIFF,
    data,
});

export const toggle = () => ({
    type: actionType.TOGGLE,
});
