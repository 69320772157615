import React from 'react';

export default class AddNewAppRow extends React.Component {
    handleClick = () => {
        $('.modal-dialog').load(this.props.createAppEndpoint, () => {
            $('#app-modal').modal({
                backdrop: 'static',
                keyboard: false,
            });
        });
    };

    render() {
        return (
            <div className="static addNewButton" id="app" onClick={this.handleClick}>
                <div id="add-new-version">
                    <div className="plus-element">
                        <img src={`${staticsPath}/img/add.png`} />
                        <span>New App</span>
                    </div>
                </div>
            </div>
        );
    }
}
