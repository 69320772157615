import React from 'react';
import PreviewField from './PreviewField';
import TabsSection from './TabsSection';
import TitleWithError from '../icons/TitleWithError';
import Asterisk from '../Asterisk';

export default function PreviewTabsSectionList({
    assetsMap,
    dirty,
    progress,
    devicePropsMap,
    readonly,
    dimensionsMap,
    handleSelect,
    handleChange,
    handleDelete,
    handleError,
    errors
}) {
    const getActiveTabAssetError = (id) => {
        return errors[id] && errors[id].message;
    };

    return (
        <>
            <h3 className="tab-page-sub-header">App preview</h3>
            {Object.keys(devicePropsMap).map((key) => {
                const sectionId = `${key}_app_preview`;
                return (
                    <TabsSection
                        id={sectionId}
                        key={sectionId}
                        dirty={dirty}
                        progress={progress}
                        handleSelect={() => handleSelect(sectionId)}>
                        {devicePropsMap[key] && devicePropsMap[key].map(({ inputName, ...deviceProps }) => {
                            const activeTabAssetError = getActiveTabAssetError(sectionId);
                            const name = deviceProps.required ? <Asterisk>{deviceProps.name}</Asterisk> : deviceProps.name;
                            const activeTabTitle = TitleWithError(name, activeTabAssetError);
                            return (
                                <PreviewField
                                    key={inputName}
                                    title={name}
                                    activeTabTitle={activeTabTitle}
                                    maxAssetsCount={3}
                                    readonly={readonly}
                                    assets={assetsMap[inputName]}
                                    dimensions={dimensionsMap[inputName]}
                                    onChange={files => handleChange(inputName, files, sectionId)}
                                    onDelete={(assetId) => handleDelete(assetId,sectionId)}
                                    onError={(error) => handleError(error,sectionId)}
                                    {...deviceProps}
                                />
                            );
                        })
                        }
                    </TabsSection>
                );})}
        </>
    );
}
