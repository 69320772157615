// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { postJSON } from '../Utils/postJSON.jsx';

export default class ApproveVersionButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            loading: false,
        };
    }

    handleToggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    handleSubmit() {
        this.setState({
            loading: true,
        });

        postJSON(this.props.endpoint).then(() => {
            this.setState(
                {
                    loading: false,
                },
                () => {
                    this.handleToggleModal();
                    window.location.reload();
                }
            );
        });
    }

    render() {
        const { showModal, loading } = this.state;
        const { text } = this.props;
        return (
            <div>
                <div>
                    <li>
                        <div>
                            <Button
                                bsStyle="primary"
                                onClick={() => this.handleToggleModal()}>
                                Approve publishing
                            </Button>
                        </div>
                    </li>
                </div>
                <Modal
                    show={showModal}
                    onClose={() => this.handleToggleModal()}
                    restoreFocus={false}>
                    <div
                        id="approve-version-loader"
                        className="loader"
                        style={{ visibility: loading ? 'visible' : 'hidden' }}>
                        <img
                            className="spinner-alone"
                            src={`${staticsPath}/img/spinner.gif`}
                        />
                    </div>
                    <Modal.Header>
                        <Modal.Title>Approve publishing</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {text !== 'None' ? (
                            <div dangerouslySetInnerHTML={{ __html: text }} />
                        ) : (
                            'Are you sure you want to approve publishing?'
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="primary"
                            onClick={() => this.handleToggleModal()}>
                            Cancel
                        </Button>
                        <Button
                            bsStyle="primary"
                            onClick={() => this.handleSubmit()}>
                            Approve Publishing
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
