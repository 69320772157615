import React, { Component } from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import { updateContext } from '../../actions/addContextToRedux';
import {
    setError,
    setCorrect,
    isLoading,
    isNotLoading,
} from '../../actions/validation.js';
import Spinner from '../../../Common/Spinner';
import DeleteRecipientConfirmModal from './DeleteRecipientConfirmModal';
import ErrorFeedback from '../inputs/ErrorFeedback';

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    details: store.context.app_details,
    validation: store.validation,
    app_version: store.context.app_version,
});

const mapDispatchToProps = dispatch => ({

    addAppRecipient: async (appRecipient, appId) => {
        dispatch(isLoading('addAppRecipient'));
        try {
            const response = await axios.post(
                `/api/v1/application/${appId}/appstore/custom-apps-recipients/`,
                appRecipient
            );
            dispatch(updateContext('app_details', { private_custom_apps: response.data.private_custom_apps }));
            dispatch(isNotLoading('addAppRecipient'));
            dispatch(setCorrect('addAppRecipient'));
            return { success: true };
        } catch (error) {
            dispatch(
                setError(
                    'addAppRecipient',
                    error.response.data ?
                      Object.keys(error.response.data).map((key) => error.response.data[key]):
                      error.response.statusText
                ));
            dispatch(isNotLoading('addAppRecipient'));
            return { success: false };
        }
    },

    updateAppRecipient: async (appRecipient, appRecipientId, appId) => {
        dispatch(isLoading('updatingAppRecipient'));
        try {
            const response = await axios.patch(
                `/api/v1/application/${appId}/appstore/custom-apps-recipients/${appRecipientId}/`,
                appRecipient
            );
            dispatch(updateContext('app_details', { private_custom_apps: response.data.private_custom_apps }));
            dispatch(isNotLoading('updatingAppRecipient'));
            dispatch(setCorrect('updatingAppRecipient'));
            return { success: true };
        } catch (error) {
            dispatch(
                setError(
                    'updatingAppRecipient',
                    error.response.data ?
                      Object.keys(error.response.data).map((key) => error.response.data[key]):
                      error.response.statusText
                ));
            dispatch(isNotLoading('updatingAppRecipient'));
            return { success: false };
        }
    },

    cancelUpdateAppRecipient: () => {
        dispatch(setCorrect('updatingAppRecipient'));
    },

    deleteAppRecipient: async (appVersionId, appRecipientId, appId) => {
        dispatch(isLoading('deletingAppRecipient'));

        try {
            const response = await axios.delete(
                `/api/v1/application/${appId}/appstore/custom-apps-recipients/${appRecipientId}/`,
                { data:{ appVersionId } }
            );
            dispatch(updateContext('app_details', { private_custom_apps: response.data.private_custom_apps }));
            dispatch(isNotLoading('deletingAppRecipient'));
            dispatch(setCorrect('deletingAppRecipient'));
            return { success: true };
        } catch (error) {
            dispatch(
                setError(
                    'deletingAppRecipient',
                  error.response.data ?
                      Object.keys(error.response.data).map((key) => error.response.data[key]):
                      error.response.statusText
                ));
            dispatch(isNotLoading('deletingAppRecipient'));
            return { success: false };
        }
    },
});

class AppStorePrivateAppRecipients extends Component {
    constructor(props) {
        super(props);
        this.appleIdInput = React.createRef();
        this.organizationIdInput = React.createRef();
    }

  state = {
      addRecipient: {
          recipientType: 'appleId',
          appleId: '',
          organizationId: '',
          organizationName: ''
      },
      editingRecipient: {
          id: '',
          recipientType: '',
          appleId: '',
          organizationId: '',
          organizationName: ''
      },
      showActions: null,
      showDeleteModal: false,
      deleteRecipientId: null
  };

    handleRecipientTypeChange = (actionType) => (event) => {
        this.setState({ [actionType]: { ...this.state[actionType], recipientType: event.target.value } });
    };

    handleChangeTextInput = (actionType) => (event) => {
        this.setState({ [actionType]: { ...this.state[actionType], [event.target.name]: event.target.value } });
    };

    addRecipient = () => async () => {
        const newRecipient = this.state.addRecipient.recipientType === 'appleId' ?
          {
              appleId: this.state.addRecipient.appleId,
              appVersionId: this.props.details.version_id,
              appSettings: this.props.details.app_settings_id
          } : {
              organizationId: this.state.addRecipient.organizationId,
              organizationName: this.state.addRecipient.organizationName,
              appVersionId: this.props.details.version_id,
              appSettings: this.props.details.app_settings_id
          };
        const result = await this.props.addAppRecipient(newRecipient, this.props.details.application_id);
        if (result.success) {
            this.setState({
                addRecipient: {
                    recipientType: this.state.addRecipient.recipientType,
                    appleId: '',
                    organizationId: '',
                    organizationName: ''
                }
            }, this.focusInput());
        }
    };

    updateRecipient = async () => {
        const updatedRecipient =
          {
              appleId: this.state.editingRecipient.recipientType === 'appleId' ?
                this.state.editingRecipient.appleId : null,
              organizationId: this.state.editingRecipient.recipientType === 'organizationId' ?
                this.state.editingRecipient.organizationId : null,
              organizationName: this.state.editingRecipient.recipientType === 'organizationId' ?
                this.state.editingRecipient.organizationName : null,
              appVersionId: this.props.details.version_id
          };
        const result = await this.props.updateAppRecipient(
            updatedRecipient,
            this.state.editingRecipient.id,
            this.props.details.application_id);

        if (result.success) {
            this.cancelEditRecipient();
        }
    };

    editRecipient = (recipient) => () => {
        this.setState({
            editingRecipient: {
                id: recipient.id,
                recipientType: recipient.apple_id ? 'appleId' : 'organizationId',
                appleId: recipient.apple_id ? recipient.apple_id : '',
                organizationId: recipient.organization_id ? recipient.organization_id : '',
                organizationName: recipient.organization_name ? recipient.organization_name : ''
            },
            showActions: null
        });
    };

    cancelEditRecipient = () => {
        this.props.cancelUpdateAppRecipient();
        this.setState({
            editingRecipient: {
                id: '',
                recipientType: '',
                appleId: '',
                organizationId: '',
                organizationName: ''
            },
        });
    };

    deleteRecipient = async () => {
        const result = await this.props.deleteAppRecipient(
            this.props.details.version_id,
            this.state.deleteRecipientId,
            this.props.details.application_id);

        if (result.success) {
            this.setState({
                showDeleteModal: false,
                deleteRecipientId: null
            });
        }
    };

    setDeleteModal = (showModal, recipientId) => () => {
        this.setState({
            showDeleteModal: showModal,
            deleteRecipientId: recipientId
        });
    };

    isRecipientValid = (actionType) => {
        return (this.state[actionType].recipientType === 'appleId' && this.state[actionType].appleId) ||
          (this.state[actionType].recipientType === 'organizationId' &&
            this.state[actionType].organizationId && this.state[actionType].organizationName);
    };

    focusInput = () => {
       this.state.addRecipient.recipientType === 'appleId' ?
         this.appleIdInput.current.focus() :
         this.organizationIdInput.current.focus();
    };

    toggleActions = (recipientId, showActions) => () => {
        if (!this.props.readonly) {
            this.setState({
                showActions: showActions && !this.state.editingRecipient.id ? recipientId : null
            });
        }
    };

    render() {
        const { validation, readonly, details: { private_custom_apps: privateAppRecipients } } = this.props;
        const appRecipients = privateAppRecipients.sort((a, b) => a.id - b.id);
        return (
            <>
                {readonly ?
                    <Col sm={12}>
                        <p className='readonly-value'>App recipients</p>
                    </Col> :
                    <Col sm={12}>
                      Provide at least one Organization ID. For organizations still using the legacy Volume Purchase
                      Program, enter their volume purchasing Apple ID.
                        <a
                            href='https://help.apple.com/app-store-connect/#/dev275598f16'
                            target='blank'
                            rel='noopener noreferrer'> Learn more</a>
                    </Col>
                }
                <Col sm={12}>
                    <hr className='separator'/>
                </Col>
                <Col sm={4}>
                  Type
                </Col>
                <Col sm={4}>
                  Id
                </Col>
                <Col sm={4}>
                  Organization name
                </Col>
                <Col sm={12}>
                    <hr className='separator'/>
                </Col>
                {appRecipients && appRecipients.length > 0 &&
                <>
                    {appRecipients.map(recipient => (
                        <Row
                            key={recipient.id}
                            className='recipient-row'
                            onMouseEnter={this.toggleActions(recipient.id, true)}
                            onMouseLeave={this.toggleActions(recipient.id, false)}>
                            { this.state.editingRecipient.id === recipient.id && !readonly ?
                                <>
                                    <Col sm={4}>
                                        <FormControl
                                            name='recipientType'
                                            value={this.state.editingRecipient.recipientType}
                                            onChange={this.handleRecipientTypeChange('editingRecipient')}
                                            componentClass='select'>
                                            <option value='appleId'>
                                                Apple Id
                                            </option>
                                            <option value='organizationId'>
                                                Organization Id
                                            </option>
                                        </FormControl>
                                    </Col>
                                    <Col sm={4}>
                                        {this.state.editingRecipient.recipientType === 'appleId' ?
                                            <FormControl
                                                name='appleId'
                                                value={this.state.editingRecipient.appleId}
                                                onChange={this.handleChangeTextInput('editingRecipient')}
                                                type='text'
                                            /> :
                                            <FormControl
                                                name='organizationId'
                                                value={this.state.editingRecipient.organizationId}
                                                onChange={this.handleChangeTextInput('editingRecipient')}
                                                type='text'
                                            />
                                        }
                                    </Col>
                                    <Col sm={4}>
                                        {this.state.editingRecipient.recipientType === 'organizationId' ?
                                            <FormControl
                                                name='organizationName'
                                                value={this.state.editingRecipient.organizationName}
                                                onChange={this.handleChangeTextInput('editingRecipient')}
                                                type='text'
                                            /> : null
                                        }
                                    </Col>
                                    <Col
                                        sm={12}
                                        className='recipient-actions'>
                                        {validation.isLoading.updatingAppRecipient ?
                                            <Spinner className='spinner-small' removeMarginTop/> :
                                            <>
                                                <button
                                                    type='button'
                                                    className='btn btn-default btn-sm recipient-actions-button'
                                                    onClick={this.updateRecipient}
                                                    disabled={!this.isRecipientValid('editingRecipient')}>
                                                    <span className='glyphicon glyphicon-ok' aria-hidden='true'/> Update
                                                </button>
                                                <button
                                                    type='button'
                                                    className='btn btn-default btn-sm recipient-actions-button'
                                                    onClick={this.cancelEditRecipient}>
                                                    <span className='glyphicon glyphicon-remove' aria-hidden='true'/> Cancel
                                                </button>
                                                {validation.errors && validation.errors.updatingAppRecipient &&
                                                <ErrorFeedback
                                                    message={validation.errors.updatingAppRecipient}
                                                    additionalClassName='error-app-distribution'
                                                />}
                                            </>
                                        }
                                    </Col>
                                </> :
                                <>
                                    <Col sm={4}>
                                        <FormControl
                                            name={`recipientType${recipient.id}`}
                                            value={recipient.apple_id ? 'Apple Id' : 'Organization Id'}
                                            disabled={readonly}
                                            readonly={!readonly}
                                            type='text'
                                        />
                                    </Col>
                                    <Col sm={4}>
                                        <FormControl
                                            name={`recipientId${recipient.id}`}
                                            value={recipient.apple_id ? recipient.apple_id : recipient.organization_id}
                                            disabled={readonly}
                                            readonly={!readonly}
                                            type='text'
                                        />
                                    </Col>
                                    {recipient.organization_name &&
                                    <Col sm={4}>
                                        <FormControl
                                            name={`organizationName${recipient.id}`}
                                            value={recipient.organization_name}
                                            disabled={readonly}
                                            readonly={!readonly}
                                            type='text'
                                        />
                                    </Col>}
                                    <Col
                                        sm={12}
                                        className='recipient-actions'
                                        hidden={!(this.state.showActions === recipient.id || this.state.editingRecipient.id === recipient.id)}>
                                        <button
                                            type='button'
                                            className='btn btn-default btn-sm recipient-actions-button'
                                            onClick={this.editRecipient(recipient)}>
                                            <span className='glyphicon glyphicon-pencil' aria-hidden='true'/> Edit
                                        </button>
                                        <button
                                            type='button'
                                            className='btn btn-danger btn-sm recipient-actions-button'
                                            onClick={this.setDeleteModal(true, recipient.id)}>
                                            <span className='glyphicon glyphicon-trash' aria-hidden='true'/> Delete
                                        </button>
                                    </Col>
                                </>
                            }
                        </Row>
                    ))}
                    <Col sm={12}>
                        <hr className='separator'/>
                    </Col>
                    </>
                }
                {!readonly &&
                <>
                    <Col sm={4}>
                        <FormControl
                            name='recipientType'
                            value={this.state.addRecipient.recipientType}
                            onChange={this.handleRecipientTypeChange('addRecipient')}
                            componentClass='select'>
                            <option value='appleId'>
                                Apple Id
                            </option>
                            <option value='organizationId'>
                                Organization Id
                            </option>
                        </FormControl>
                    </Col>
                    <Col sm={4}>
                        {this.state.addRecipient.recipientType === 'appleId' ?
                            <FormControl
                                name='appleId'
                                value={this.state.addRecipient.appleId}
                                onChange={this.handleChangeTextInput('addRecipient')}
                                type='text'
                                inputRef={this.appleIdInput}
                            /> :
                            <FormControl
                                name='organizationId'
                                value={this.state.addRecipient.organizationId}
                                onChange={this.handleChangeTextInput('addRecipient')}
                                type='text'
                                inputRef={this.organizationIdInput}
                            />
                        }
                    </Col>
                    <Col sm={4}>
                        {this.state.addRecipient.recipientType === 'organizationId' ?
                            <FormControl
                                name='organizationName'
                                value={this.state.addRecipient.organizationName}
                                onChange={this.handleChangeTextInput('addRecipient')}
                                type='text'
                            /> : null
                        }
                    </Col>
                    <Col sm={12} className='recipient-actions'>
                        {validation.isLoading.addAppRecipient ?
                            <Spinner className='spinner-small' removeMarginTop/> :
                            <>
                                <button
                                    type='button'
                                    className='btn btn-primary btn-sm'
                                    onClick={this.addRecipient()}
                                    disabled={!this.isRecipientValid('addRecipient')}>
                                    <span className='glyphicon glyphicon-ok' aria-hidden='true'/> Add
                                </button>
                                {validation.errors && validation.errors.addAppRecipient &&
                                <ErrorFeedback
                                    message={validation.errors.addAppRecipient}
                                    additionalClassName='error-app-distribution'
                                />}
                            </>
                        }
                    </Col>
                    <DeleteRecipientConfirmModal
                        showModal={this.state.showDeleteModal}
                        closeModal={this.setDeleteModal}
                        confirmDelete={this.deleteRecipient}
                        isDeleting={validation.isLoading.deletingAppRecipient}
                        error={validation.errors.deletingAppRecipient}
                    />
                </>}
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppStorePrivateAppRecipients);
