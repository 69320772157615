import React, { Fragment } from 'react';
import FieldRow from '../../../layout/FieldRow';
import MultiRadioField, {
    mapToNumericBooleanOptions,
    numericBooleanOptions,
} from '../../../inputs/MultiRadioField';
import Expandable from '../../../Expandable';
import UploadManually from './UploadManually';
import { noIsSelected, yesIsSelected } from '../ResetOptions';

const handleButtonToggle = (event, props) => {
    const reset = [
        'contains_third_party_cryptography',
        'available_on_french_store',
    ];
    props.handleChangeRadio(
        event,
        null,
        props.csrf_token,
        props.app_version,
        reset
    );
    reset.map(name => props.nullifyOption(`export_compliance_${name}`));
};

const ProprietaryCryptography = ({
    validation,
    reviewInformation,
    readonly,
    props,
}) => {
    return (
        <Fragment>
            <Expandable
                expanded={
                    reviewInformation.export_compliance_uses_encryption &&
                    noIsSelected(reviewInformation.export_compliance_is_exempt)
                }>
                <FieldRow
                    id="export-compliance-contains-proprietary-cryptography"
                    label=""
                    required>
                    <div>
                        Does your app implement any encryption algorithms that
                        are proprietary or not accepted as standards by
                        international standard bodies (IEEE, IETF, ITU, etc.)?
                    </div>
                    <MultiRadioField
                        name="export_compliance_contains_proprietary_cryptography"
                        error={
                            validation.errors
                                .export_compliance_contains_proprietary_cryptography
                        }
                        progress={
                            validation.isLoading
                                .export_compliance_contains_proprietary_cryptography
                        }
                        dirty={
                            validation.dirty
                                .export_compliance_contains_proprietary_cryptography
                        }
                        selected={mapToNumericBooleanOptions(
                            reviewInformation.export_compliance_contains_proprietary_cryptography
                        )}
                        readonly={readonly}
                        onChange={event => handleButtonToggle(event, props)}
                        options={numericBooleanOptions}
                    />
                </FieldRow>
            </Expandable>
        </Fragment>
    );
};

export default ProprietaryCryptography;
