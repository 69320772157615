import React, { Fragment } from 'react';
import ErrorFeedback from '../inputs/ErrorFeedback.jsx';

const inlineFeedbackStyle = {
    right: '16px',
    height: '20px',
};

const errorIconStyle = {
    height: '20px',
    margin: '0',
};

const TitleWithError = (title, errorMessage) => {
    return (
        <Fragment>
            {title}
            {errorMessage ? (
                <ErrorFeedback
                    message={errorMessage}
                    style={inlineFeedbackStyle}
                    id={`${title}_title`}
                    additionalClassName={'inline-error-feedback'}
                    errorIconStyle={errorIconStyle}
                    preLine
                />
            ) : null}
        </Fragment>
    );
};

export default TitleWithError;
