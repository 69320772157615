import React, { Fragment } from 'react';
import Section from './layout/Section.jsx';
import { Row, Col } from 'react-bootstrap';
import FieldRow from './layout/FieldRow.jsx';
import GraphicAssets from './layout/GraphicAssets.jsx';
import TextField from './inputs/TextField.jsx';
import CustomDropzone from './inputs/CustomDropzone.jsx';
import ScreenshotTabsSectionList from './inputs/ScreenshotTabsSectionList';
import { getAndroidScreenshotDevicePropsMap } from '../util/getAndroidScreenshotDevicePropsMap';

export default function GooglePlayGraphicAssetsTab({
    metadata,
    validation,
    readonly,
    rangeDimensions,
    handleSetCorrect,
    diff,
    ...props
}) {
    const languageCode = metadata.language.code;
    const screenshotsMap = metadata.screenshots || {};
    const isDefault = metadata.is_default;
    const androidScreenshotDevicePropsMap = getAndroidScreenshotDevicePropsMap();
    const errors = validation.errors[languageCode] || {};

    return (
        <Fragment>
            <Section id="graphic-assets" title="Add graphic assets">
                <Row>
                    <GraphicAssets id="high-res">
                        <CustomDropzone
                            label="Hi-Res icon"
                            help='Your app icon as shown on Google Play. Don’t use badges or text that suggest
                            store ranking, price or Google Play categories (such as "top", "new", or "sale").
                            Transparent PNG or JPEG
                            512 px by 512 px
                            Up to 1 MB'
                            description="512 x 512 px"
                            required={isDefault}
                            firstRow
                            metadata={metadata}
                            validation={validation}
                            fieldName="hi_res_icon"
                            csrf_token={props.csrf_token}
                            app_version={props.app_version}
                            onChange={props.handleChangeGraphicAsset}
                            onDelete={props.handleDeleteGraphicAsset}
                            expectedWidth={115}
                            expectedHeight={110}
                            languageCode={languageCode}
                            readonly={readonly}
                        />
                        <CustomDropzone
                            label="Feature Graphic"
                            description="1024 x 500 px"
                            help='This will help promote your app in different places on Google Play.
                            In case of cropping, avoid placing text near the edges, and center key visuals.
                            PNG or JPEG
                            1,024 px by 500 px
                            Up to 1 MB'
                            required={isDefault}
                            firstRow
                            metadata={metadata}
                            validation={validation}
                            fieldName="feature_graphic"
                            csrf_token={props.csrf_token}
                            app_version={props.app_version}
                            onChange={props.handleChangeGraphicAsset}
                            onDelete={props.handleDeleteGraphicAsset}
                            expectedWidth={225}
                            expectedHeight={110}
                            languageCode={languageCode}
                            readonly={readonly}
                        />
                    </GraphicAssets>
                </Row>
                <Row>
                    <GraphicAssets id="promo-graphics">
                        <CustomDropzone
                            label="Promo graphic (optional)"
                            description="180 x 120 px"
                            help='180 w x 120 h
                            JPG or 24-bit PNG (no alpha)'
                            metadata={metadata}
                            validation={validation}
                            fieldName="promo_graphic"
                            csrf_token={props.csrf_token}
                            app_version={props.app_version}
                            onChange={props.handleChangeGraphicAsset}
                            onDelete={props.handleDeleteGraphicAsset}
                            expectedWidth={165}
                            expectedHeight={110}
                            languageCode={languageCode}
                            readonly={readonly}
                        />
                        <CustomDropzone
                            label="TV graphic (optional)"
                            description="1280 x 720 px"
                            help='1280 w x 720 h
                            JPG or 24-bit PNG (no alpha)'
                            metadata={metadata}
                            validation={validation}
                            fieldName="banner_asset"
                            csrf_token={props.csrf_token}
                            app_version={props.app_version}
                            onChange={props.handleChangeGraphicAsset}
                            onDelete={props.handleDeleteGraphicAsset}
                            expectedWidth={195}
                            expectedHeight={110}
                            languageCode={languageCode}
                            readonly={readonly}
                        />
                        <CustomDropzone
                            label="360&#176; stereoscopic image"
                            description="4096 x 4096 px"
                            help='4096 w x 4096 h
                            JPG or 24-bit PNG (no alpha)'
                            metadata={metadata}
                            validation={validation}
                            fieldName="daydream360"
                            csrf_token={props.csrf_token}
                            app_version={props.app_version}
                            onChange={props.handleChangeGraphicAsset}
                            onDelete={props.handleDeleteGraphicAsset}
                            expectedWidth={115}
                            expectedHeight={110}
                            customContainerWidth={210}
                            languageCode={languageCode}
                            readonly={readonly}
                        />
                    </GraphicAssets>
                </Row>
            </Section>
            <Section id="promo-video-section">
                <Row>
                    <FieldRow id="promo_video" label="Promo video URL" help='Add a video by entering a YouTube URL'>
                        <TextField
                            name="promo_video"
                            md={9}
                            error={
                                errors &&
                                errors.promo_video
                            }
                            progress={
                                errors &&
                                errors.promo_video
                            }
                            dirty={
                                errors &&
                                errors.promo_video
                            }
                            value={metadata.promo_video}
                            previousValue={
                                typeof diff === 'object'
                                    ? diff.promo_video
                                    : undefined
                            }
                            type="text"
                            readonly={readonly}
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
            <ScreenshotTabsSectionList
                errors={errors}
                readonly={readonly}
                maxAssetsCount={8}
                assetsMap={screenshotsMap}
                devicePropsMap={androidScreenshotDevicePropsMap}
                sectionErrorKey={'android_screenshots'}
                rangeDimensions={rangeDimensions}
                dirty={validation.dirty[languageCode]}
                progress={validation.isLoading[languageCode]}
                handleChange={(inputName, files, sectionId) =>
                    props.handleChangeScreenshots(
                        sectionId,
                        inputName,
                        files,
                        props.app_version,
                        languageCode
                    )
                }
                handleDelete={(assetId, sectionId) =>
                    props.handleDeleteScreenshot({
                        languageCode,
                        screenshot_id: assetId,
                        categoryName: sectionId,
                        app_version: props.app_version,
                    })
                }
                handleError={(error, sectionId) =>
                    props.handleScreenshotsError(
                        sectionId,
                        error,
                        languageCode
                    )
                }
            />
        </Fragment>
    );
}
