// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import {Modal, Button} from 'react-bootstrap';

export default function DeleteEntitlementsModal({
        showModal,
        handleToggleModal,
        handleConfirmModal,
    }) {


    return <Modal
        show={showModal}
        onClose={handleToggleModal}
        bsSize="small">
        <Modal.Header closeButton onHide={handleToggleModal}>
            <Modal.Title>Delete custom entitlements?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
            <p>
                The changes will be applied to the next resigned version
            </p>
            <Button
                bsStyle="primary"
                onClick={handleToggleModal}>
                Cancel
            </Button>
            <Button
                bsStyle="danger"
                onClick={handleConfirmModal}>
                Delete
            </Button>
        </Modal.Footer>
    </Modal>
}
