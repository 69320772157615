import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RadioField from './RadioField.jsx';

export default function RadioFieldWithDescription({ description, hideDescription, fullWidth, ...props }) {
    return (
        <Row className="radio-with-description">
            <Col md={fullWidth ? 12 : 8}>
                <RadioField {...props} />
                {!hideDescription &&
                <div className="description">{description}</div>
                }
            </Col>
        </Row>
    );
}
