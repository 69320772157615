import React from 'react';
import FieldRow from '../../../layout/FieldRow';
import MultiRadioField, {
    mapToNumericBooleanOptions,
    numericBooleanOptions,
} from '../../../inputs/MultiRadioField';
import Expandable from '../../../Expandable';
import GovernmentNotification from './GovernmentNotification';
import { yesIsSelected } from '../ResetOptions';
import XcodeNotification from './XcodeNotification';

const handleButtonToggle = (event, props) => {
    const reset = [
        'available_on_french_store',
        'contains_third_party_cryptography',
        'contains_proprietary_cryptography',
    ];
    props.handleChangeRadio(
        event,
        null,
        props.csrf_token,
        props.app_version,
        reset
    );
    reset.map(name => props.nullifyOption(`export_compliance_${name}`));
};

const IsExempt = ({ validation, reviewInformation, readonly, props }) => {
    return (
        <Expandable
            expanded={reviewInformation.export_compliance_uses_encryption}>
            <FieldRow label="" required>
                <p>
                    Does your app meet any of the following:
                    <br />
                    (a) Qualifies for one or more exemptions provided under
                    Category 5 Part 2
                    <br />
                    (b) Use of encryption is limited to encryption within
                    Apple’s operating system
                    <br />
                    (c) Only makes calls over HTTPS
                    <br />
                    (d) App is made available only in the U.S. and/or Canada
                </p>
                <p>
                    Make sure that your app meets the criteria of the exemption
                    listed here. You are responsible for the proper
                    classification of your product. Incorrectly classifying your
                    app may lead to you being in violation of U.S. export laws
                    and could make you subject to penalties, including your app
                    being removed from the App Store.
                </p>
                <p>
                    You can select Yes if the encryption of your app is:
                    <br />
                    (a) Specially designed for medical end-use
                    <br />
                    (b) Limited to intellectual property and copyright
                    protection
                    <br />
                    (c) Limited to authentication, digital signature, or the
                    decryption of data or files
                    <br />
                    (d) Specially designed and limited for banking use or "money
                    transactions"; or
                    <br />
                    (e) Limited to "fixed" data compression or coding techniques
                </p>
                <p>
                    You can also select Yes if your app meets the descriptions
                    provided in Note 4 for Category 5 Part 2 of the U.S. Export
                    Administration Regulations.
                </p>
                <p>
                    <a
                        href="https://help.apple.com/itunes-connect/developer/#/devc3f64248f"
                        target="_blank">
                        Learn More
                    </a>
                </p>
                <MultiRadioField
                    name="export_compliance_is_exempt"
                    id="export-compliance-is-exempt"
                    error={validation.errors.export_compliance_is_exempt}
                    progress={validation.isLoading.export_compliance_is_exempt}
                    dirty={validation.dirty.export_compliance_is_exempt}
                    selected={mapToNumericBooleanOptions(
                        reviewInformation.export_compliance_is_exempt
                    )}
                    readonly={readonly}
                    onChange={event => handleButtonToggle(event, props)}
                    options={numericBooleanOptions}
                />
                {yesIsSelected(
                    reviewInformation.export_compliance_is_exempt
                ) && <><XcodeNotification/><GovernmentNotification/></>}
            </FieldRow>
        </Expandable>
    );
};

export default IsExempt;
