import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import useImageSize from '@use-hooks/image-size';
import RemoveIconButton from '../RemoveIconButton.jsx';

const ImagePreview = ({
    src,
    onDelete,
    style,
    readonly = false,
}) => {
    const className = classNames('asset-preview-container', { 'asset-preview-container-hover': !readonly });
    const [width, height] = useImageSize(src);
    const [widthStyle, setWidthStyle] = useState(null);

    useEffect(() => {
        setWidthStyle(width > height ? style.height : style.width);
    }, [width, height]);

    return (
        <div
            className={className}>
            {!readonly && (
                <div className="asset-preview-header">
                    <RemoveIconButton onClick={onDelete}/>
                </div>
            )}
            <img src={src} style={{ width: widthStyle }}/>
        </div>
    );
}

export default ImagePreview;
