//TODO: get expectedWidth & expectedHeight from backend dictionary
//TODO: Move each *DevicePropsMap to external catalog and handle by abstraction class
const androidScreenshotDeviceList = [
    {
        inputName: 'phone',
        name: 'Phone',
        expectedWidth: 1125,
        expectedHeight: 2436,
        required: false,
    },
    {
        inputName: 'tablet_7_in',
        name: 'Tablet 7"',
        expectedWidth: 1242,
        expectedHeight: 2208,
        required: false,
    },
    {
        inputName: 'tablet_10_in',
        name: 'Tablet 10"',
        expectedWidth: 640,
        expectedHeight: 1136,
        required: false,
    },
    {
        inputName: 'tv',
        name: 'Android TV',
        expectedWidth: 960,
        expectedHeight: 640,
        required: false,
    },
    {
        inputName: 'wear',
        name: 'Android Wear',
        expectedWidth: 200,
        expectedHeight: 200,
        required: false,
    },
];

export function getAndroidScreenshotDevicePropsMap(){
    let androidScreenshotDevicePropsMap = {};
    androidScreenshotDevicePropsMap['android'] = androidScreenshotDeviceList;
    return androidScreenshotDevicePropsMap;
}
