export const renderBoolean = value => (value ? 'YES' : 'NO');

export const categoriesList = categories => {
    return categories.map(category => ({
        value: category.id,
        label: category.name,
    }));
};

export const countriesList = countries => {
    return countries.map(country => ({
        value: country.code,
        label: country.name,
    }));
};

export const languagesList = languages => {
    return languages.map(language => ({
        value: language.name_short,
        label: language.name,
    }));
};

export const localizableLanguagesList = languages => {
    return languages.map(language => ({
        value: language.code,
        label: language.name,
    }));
};

export const priceTierList = pricetiers => {
    return pricetiers.map(pricetier => ({
        value: pricetier.id,
        label: 'USD ' + pricetier.usd_value + ' (' + pricetier.name + ')',
    }));
};

export const contentRatingMultiSelect = options => {
    return options.map(option => ({
        value: option.id.toString(),
        label: option.name,
    }));
};

export const removeNullOptions = options => {
    return options.filter(option => option.id !== null);
};

export function countriesValueRenderer(selected, options) {
    if (selected.length === 0) {
        return 'Select countries...';
    }
    if (selected.length === options.length) {
        return 'All countries selected';
    }
    return `Selected ${selected.length} countries`;
}

export function languagesValueRenderer(selected, options) {
    if (selected.length === 0) {
        return 'Select languages...';
    }
    if (selected.length === options.length) {
        return 'All languages selected';
    }
    return `Selected ${selected.length} languages`;
}

export function additionalLanguagesValueRenderer(selected, options) {
    if (selected.length === 0) {
        return 'Select additional languages...';
    }
    if (selected.length === options.length) {
        return 'All additional languages selected';
    }
    if (selected.length === 1) {
        return 'Selected 1 additional language';
    }
    return `Selected ${selected.length} additional languages`;
}

export function customOptionsRenderer(selected, options) {
    if (selected.length === 0) {
        return 'Select options...';
    }
    if (selected.length === options.length) {
        return 'All options selected';
    }
    return `Selected ${selected.length} options`;
}

export const androidVersionsList = versions => {
  const sortedVersions = versions.sort(( a, b ) => (parseFloat(a.version_number)- parseFloat(b.version_number)));
  return sortedVersions.map(version => ({
      value: version.version_number,
      label: version.text,
  }));
};

export const financialFeaturesList = features => {
  return features?.map(feature => ({
      value: feature.id,
      label: feature.name,
  }));
};

export function androidVersionValueRenderer(selected, options) {
    if (selected.length === 0) {
        return 'Select Android version...';
    }
    if (selected.length === options.length) {
        return 'All versions selected';
    }
    return `Selected ${selected.length} versions`;
}

// _stripLastWord returns provided string without last word. It assumes that words are separated by underscore '_'.
// _stripLastWord('Short_little_sentence') returns 'Short_little'
const _stripLastWord = str => {
    let lastIndex = str.lastIndexOf('_');
    let withoutLastWord = str.substring(0, lastIndex);
    return withoutLastWord;
};

// It assumes words are separated by underscore '_'
const _stripLastTwoWords = str =>
    str
        .split('_')
        .slice(0, -2)
        .join('_');

const _arrayOfObjectsToObject = (arr, key, strippingFunction) => {
    return arr.reduce(
        (obj, item) => ((obj[strippingFunction(item[key])] = item), obj),
        {}
    );
};

const _formatCountriesMetadata = data => {
    return Object.keys(data)
        .map(key => {
            return {
                [key]: _arrayOfObjectsToObject(
                    data[key],
                    'element_id',
                    _stripLastTwoWords
                ),
            };
        })
        .reduce((merged, item) => Object.assign({}, item, merged));
};

const _formatApplicationData = data =>
    _arrayOfObjectsToObject(data, 'element_id', _stripLastWord);

const sortArrayOfStrings = propertyName => (a, b) => {
    var nameA = a[propertyName].toLowerCase(),
        nameB = b[propertyName].toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
};

// _zipTwoArrays takes two arrays of objects that are alphabetically sorted by a property `propertyName`. `arr1` array consists of objects
// containing previous values, and `arr2` array consists of objects containing current values.
// It returns array of objects `{ previousValue, value }`, where each object represent previous value
// and current value of a given propertyName. If there is no value for respective propertyName, undefined value will be provided instead.
// for example:
// propertyName = 'name'
// arr1: [{name: 'Algeria', index: 'a'}, {name: 'Cipr', index: 'c'}, {name: 'France', index: 'f'}]
// arr2: [{name: 'Algeria', index: 'a'}, {name: 'Belarus', index: 'b'}, {name: 'France', index: 'f'}]
// _zipTwoArrays(arr1, arr2, 'name') returns:
// [{ previousValue: 'Algeria', value: 'Algeria' }, { previousValue: undefined, value: 'Belarus' }, { previousValue: 'Cipr', value: undefined }, { previousValue: 'France', value: 'France'}]
const _zipTwoArrays = (arr1, arr2, propertyName = 'name') => {
    // arr1 represents previous values, arr2 current values
    let i,
        j = (i = 0);
    let _zippedArr = [];
    arr1.sort(sortArrayOfStrings(propertyName));
    arr2.sort(sortArrayOfStrings(propertyName));

    while (i < arr1.length && j < arr2.length) {
        if (arr1[i][propertyName] == arr2[j][propertyName]) {
            _zippedArr.push({
                previousValue: arr1[i][propertyName],
                value: arr2[j][propertyName],
            });
            i++, j++;
        } else {
            if (arr1[i][propertyName] < arr2[j][propertyName]) {
                _zippedArr.push({
                    previousValue: arr1[i][propertyName],
                    value: undefined,
                });
                i++;
            } else {
                _zippedArr.push({
                    previousValue: undefined,
                    value: arr2[j][propertyName],
                });
                j++;
            }
        }
    }
    while (i < arr1.length) {
        _zippedArr.push({
            previousValue: arr1[i][propertyName],
            value: undefined,
        });
        i++;
    }
    while (j < arr2.length) {
        _zippedArr.push({
            previousValue: undefined,
            value: arr2[j][propertyName],
        });
        j++;
    }
    return _zippedArr;
};

export const formatDiff = data => ({
    ...data,
    all_countries_metadata: data.show_difference
        ? _formatCountriesMetadata(data.all_countries_metadata)
        : null,
    application_data: data.show_difference
        ? _formatApplicationData(data.application_data)
        : null,
});

export const zippDiffList = data => {
    return data.application_data === null
        ? data
        : {
            ...data,
            application_data: {
                ...data.application_data,
                supported_countries: {
                    ...data.application_data.supported_countries,
                    previous_value: _zipTwoArrays(
                        data.application_data.supported_countries
                            .previous_value,
                        data.application_data.supported_countries
                            .current_value
                    ),
                },
                eula_countries: {
                    ...data.application_data.eula_countries,
                    previous_value: _zipTwoArrays(
                          data.application_data.eula_countries
                              ? data.application_data.eula_countries
                                  .previous_value
                              : [],
                          data.application_data.eula_countries
                              ? data.application_data.eula_countries
                                  .current_value
                              : []
                    ),
                },
                android_versions_supported: {
                    ...data.application_data.android_versions_supported,
                    previous_value: _zipTwoArrays(
                          data.application_data.android_versions_supported
                              ? data.application_data.android_versions_supported
                                  .previous_value
                              : [],
                          data.application_data.android_versions_supported
                              ? data.application_data.android_versions_supported
                                  .current_value
                              : [],
                          'text'
                    ),
                },
            },
        };
};
export const mapValuesToOptions = property => options => value =>
    options.find(item => item[property] === value);
