import React from 'react';
import RemoveIconButton from '../RemoveIconButton.jsx';

export default function SquareIconPreview({
    src,
    onDelete,
    style,
    readonly = false,
}) {
    return (
        <div className="dropzone-icon-container">
            {!readonly && (
                <div className="dropzone-header">
                    <RemoveIconButton onClick={onDelete} />
                </div>
            )}
            <div className="dropzone-icon-preview" style={style}>
                <img src={src} className="dropzone-img-preview" />
            </div>
        </div>
    );
}
