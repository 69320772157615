import React from 'react';
import Spinner from '../Common/Spinner.jsx';
import RefreshInstallationData from './RefreshInstallationData';

const SpinnerOrReinitialize = ({
    initializeDeviceInstallationData,
    deviceInstallationEndpoint,
    deviceInstallationStatus,
}) => {
    // If no data has been loaded, a refresh button will be displayed. If the data is loading a spinner will be shown
    switch (deviceInstallationStatus) {
        case 'loading':
            return <Spinner />;
        default:
            return (
                <RefreshInstallationData
                    initializeDeviceInstallationData={
                        initializeDeviceInstallationData
                    }
                    deviceInstallationEndpoint={deviceInstallationEndpoint}
                />
            );
    }
};

export default SpinnerOrReinitialize;
