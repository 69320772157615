// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import {
    Button,
    ControlLabel,
    FormControl,
    FormGroup,
    Modal,
    Checkbox,
    HelpBlock
} from 'react-bootstrap';

const initialState = {
    reason: '',
    attachment: '',
    newBinaryRequired: false,
    maxAllowedAttachmentSize: 5,
    error: false
}
import postFormData from '../Utils/postFormData.jsx';
import getJSON from "../Utils/getJSON";

class MessageForm extends React.Component {

    state = initialState;
    fileValidationMessage;

    constructor(props) {
        super(props);
        getJSON('/rejection/attachment/size')
            .then((response) => {
                this.state.maxAllowedAttachmentSize = response.max_allowed_attachment_size;
                this.fileValidationMessage = <HelpBlock>Attachment size should not exceed {this.state.maxAllowedAttachmentSize}MB.
                        This can be changed using admin panel</HelpBlock>
            });
    }

    change = event => {
        const new_state = {
            ...this.state
        };

        switch (event.target.type) {
            case 'checkbox':
                new_state[event.target.name] = event.target.checked;
                break;
            case 'file':
                if (event.target.files[0].size / 1000000 >= this.state.maxAllowedAttachmentSize) {
                    new_state['error'] = true;
                    this.fileValidationMessage = <HelpBlock style={{ color: 'red' }}>
                        File Size Exceeded max size of {this.state.maxAllowedAttachmentSize}MB</HelpBlock>;
                } else {
                    new_state['error'] = false;
                    new_state[event.target.name] = event.target.files[0];
                    new_state[`${event.target.name}Path`] = event.target.value;
                    this.fileValidationMessage =
                        <HelpBlock>Uploaded Attachment Size {event.target.files[0].size / 1000000}MB</HelpBlock>
                }
                break;
            default:
                new_state[event.target.name] = event.target.value;
        }
        this.setState(new_state, () => this.props.onChange(new_state));
    };

    render() {
        return (
            <form>
                <FormGroup controlId="reason">
                    <ControlLabel>Reason</ControlLabel>
                    <FormControl
                        componentClass="textarea"
                        rows={10}
                        value={this.state.reason}
                        onChange={this.change}
                        name="reason"
                    />

                    <ControlLabel>Attachment</ControlLabel>
                    <ControlLabel><HelpBlock>Accepted Formats: [pdf, png, jpg, jpeg, doc, docx, zip, mp4, tar, mov]</HelpBlock></ControlLabel>
                    <input
                        type="file"
                        onChange={this.change}
                        name="attachment"
                        accept=".pdf, .png, .jpg, .jpeg, .doc, .docx, .zip, .mp4, .tar, .mov"
                    />
                    <ControlLabel>
                        {this.fileValidationMessage}
                    </ControlLabel>

                    <Checkbox
                        onChange={this.change}
                        checked={this.state.newBinaryRequired}
                        name="newBinaryRequired">
                        New binary is required
                    </Checkbox>

                </FormGroup>
            </form>
        );
    }
}

export default class RejectVersionButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            data: {},
            submitButtonDisabled: true,
            loading: false,
        };
        this.handleToggleModal = this.handleToggleModal.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleToggleModal() {
        this.setState({
            showModal: !this.state.showModal,
        });
    }

    handleChange(change) {
        const prev = this.state;
        this.setState({            
            data: change,
            submitButtonDisabled: change?.reason?.length === 0 || change?.error
        });
    }

    handleSubmit() {
        this.setState({
            loading: true,
        });

        const formData = new FormData();
        formData.append('attachment', this.state.data.attachment);
        formData.append('reason', this.state.data.reason);
        formData.append('new_binary_required', this.state.data.newBinaryRequired);

        postFormData(this.props.endpoint, formData).then(() => {
            this.setState(
                {
                    loading: false,
                },
                () => {
                    this.handleToggleModal();
                    window.location.href = this.props.redirectUrl;
                }
            );
        });
    }

    render() {
        return (
            <div>
                <Button bsStyle="danger" onClick={this.handleToggleModal}>
                    Reject
                </Button>
                <Modal
                    show={this.state.showModal}
                    onClose={this.handleToggleModal}
                    restoreFocus={false}
                    bsSize="large">
                    <div
                        id="reject-version-loader"
                        className="loader"
                        style={{
                            visibility: this.state.loading
                                ? 'visible'
                                : 'hidden',
                        }}>
                        <img
                            className="spinner-alone"
                            src={`${staticsPath}/img/spinner.gif`}
                        />
                    </div>
                    <Modal.Header>
                        <Modal.Title>Reject version</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <MessageForm onChange={this.handleChange} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            bsStyle="primary"
                            onClick={this.handleToggleModal}>
                            Cancel
                        </Button>
                        <Button
                            bsStyle="primary"
                            onClick={this.handleSubmit}
                            disabled={this.state.submitButtonDisabled}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
