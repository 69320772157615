// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import {Button} from 'react-bootstrap';
import axios from 'axios';

import EditEntitlementsModal from './EditEntitlementsModal.jsx';
import getJSON from "../../Utils/getJSON";
import DeleteEntitlementsModal from "./DeleteEntitlementsModal";

export default class EditEntitlementsButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeleteModal: false,
            showEditModal: false,
            customEntitlementsInitialised: null,
        };
    }

    componentDidMount() {
        this.getCustomEntitlementsStatus()
    }

    getCustomEntitlementsStatus = () => {
        let {endpoint} = this.props;
        endpoint = endpoint + 'get_status'
        getJSON(endpoint).then(response => {
            this.setState({...response})
        })
    }

    deleteCustomEntitlements = () => {
        axios.delete(this.props.endpoint).then(
            (response) => {
                this.handleToggleModal('showDeleteModal');
                this.getCustomEntitlementsStatus()
            }
        )
    }

    handleToggleModal = (field) => {
        this.setState({
            [field]: !this.state[field]
        });
    }

    render() {
        const {endpoint} = this.props;
        const {customEntitlementsInitialised} = this.state;
        return (
            <div>
                <Button bsStyle="primary"
                        style={{marginRight: '10px'}}
                        onClick={() => this.handleToggleModal('showEditModal')}>
                    {customEntitlementsInitialised ? 'Edit' : 'Create'}
                </Button>
                {customEntitlementsInitialised &&
                <Button bsStyle="danger"
                        onClick={() => this.handleToggleModal('showDeleteModal')}>
                    Delete
                </Button>
                }
                {this.state.showEditModal && (
                    <EditEntitlementsModal
                        showModal={this.state.showEditModal}
                        endpoint={endpoint}
                        getCustomEntitlementsStatus={this.getCustomEntitlementsStatus}
                        handleToggleModal={() => this.handleToggleModal('showEditModal')}
                    />
                )}
                {this.state.showDeleteModal && (
                    <DeleteEntitlementsModal
                        showModal={this.state.showDeleteModal}
                        handleToggleModal={() => this.handleToggleModal('showDeleteModal')}
                        handleConfirmModal={() => {
                            this.deleteCustomEntitlements()
                        }}
                    />
                )}
            </div>
        );
    }
}
