import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addContextToRedux } from '../actions';

class AddDjangoContextToRedux extends Component {
    constructor(props) {
        super(props);
    }

    componentWillMount() {
        this.props.addContextToRedux(this.props.context);
    }

    render() {
        return this.props.children;
    }
}

export default connect(
    null,
    { addContextToRedux }
)(AddDjangoContextToRedux);
