import FormData from 'form-data';
import { post } from 'axios';

const handleError = error => {
    if (error.response) {
        return error.response.data;
    } else {
        return { non_field_errors: 'Internal error' };
    }
};
const handleResponse = () => window.location.reload();

const submitAndroidCertificateForm = async form_data => {
    /* This function expects a json object with the appropriate
     * members (see below) and tries to submit it against a rest
     * endpoint to submit a new certificate. */
    var form = new FormData();
    form.append('name', form_data.name);
    form.append('alias', form_data.keyAlias);
    form.append('key_store', form_data.keystoreFile);
    form.append('default', form_data.isDefault);
    form.append('key_store_password', form_data.keystorePassword);
    form.append('private_key_password', form_data.privateKeyPassword);
    return await post('/administrator/android-certificate/', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
    })
        .then(handleResponse)
        .catch(handleError);
};

export default submitAndroidCertificateForm;
