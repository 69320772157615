import React from 'react';
import AssetField from './AssetField';
import { ASSET_TYPE_IMAGE } from './AssetDropzone';

export default class ScreenshotField extends React.Component {

    render() {
        const {
            ...props
        } = this.props;

        return (
            <AssetField
                assetType={ASSET_TYPE_IMAGE}
                {...props}
            />
        );
    }
}
