import React from 'react';

export default class AddNewVersionRow extends React.Component {
    render() {
        let colspan = this.props.can_edit_signing_details === true ? 9 : 8;

        return (
            <tr>
                <td id="add-new-version" colSpan={colspan}>
                    <div className="plus-element">
                        <img src={`${staticsPath}/img/add.png`} />
                        <span>New Version</span>
                    </div>
                </td>
            </tr>
        );
    }
}
