import React from 'react';
import { Grid } from 'react-bootstrap';
import TabController from '../containers/TabController.jsx';
import FormNavigation from '../containers/FormNavigation.jsx';

const FormTabs = ({ tabs }) => (
    <Grid>
        <FormNavigation totalTabCount={tabs.length} />
        <TabController tabs={tabs} />
    </Grid>
);

export default FormTabs;
