import React from 'react';
import {Modal} from 'react-bootstrap';

const UserDeleteModal = ({closeModal, confirmAction, showModal}) => (
  <div className="modal-container">
    <Modal show={showModal}>
      <div className="panel-heading">
        <h3>User Deletionn</h3>
      </div>
      <div className="panel-body">
        If you delete the user account, all assignment are also removed. Please
        note that this action cannot been undone. The user might be created
        again later.
        <br/>
        Are you really sure you want to delete this user?
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-primary"
          onClick={() => closeModal()}>
          Cancel
        </button>
        <button
          className="btn btn-danger"
          onClick={() => confirmAction()}>
          Confirm
        </button>
      </div>
    </Modal>
  </div>
);

export default UserDeleteModal;
