import React from 'react';
import AdminError from './AdminError.jsx';
import PublicErrors from './PublicError.jsx';

const TracebackAdminErrors = ({
    publicErrorText,
    adminErrorText,
    showAdminError,
}) => {
    if (showAdminError == 'True') {
        return (
            <div style={{padding: 15}}>
                <PublicErrors publicErrorText={publicErrorText} />
                {!!adminErrorText && <AdminError adminTracebackText={adminErrorText} /> }
            </div>
        );
    } else {
        return <PublicErrors publicErrorText={publicErrorText} />;
    }
};

export default TracebackAdminErrors;
