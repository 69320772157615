import { buffers } from 'redux-saga';
import { actionChannel, call, put, take, select } from 'redux-saga/effects';
import * as API from '../../components/AppDraft/util/API';
import * as externalActionTypes from '../../components/AppDraft/actions/types';
import {
    SUBMIT_FORM_SUCCESS,
    SUBMIT_FORM_VALIDATION_FAILURE,
} from './fieldsValidation';
import {isAnyAttributeTrue} from "../../components/Utils/helpers";

const UPDATE_VALUE = 'customField/UPDATE_VALUE';
export const SYNC_VALUE = 'customField/SYNC_VALUE';
const SYNC_VALUE_SUCCESS = 'customField/SYNC_VALUE_SUCCESS';
const SYNC_VALUE_FAILURE = 'customField/SYNC_VALUE_FAILURE';

const initialState = {
    name: null,
    fields: [],
    values: {},
    errors: {},
    progress: {},
    dirty: {},
    activeRequests: 0,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case externalActionTypes.ADD_CONTEXT:
            if (action.context.custom_fields) {
                return {
                    name: action.context.custom_fields.name,
                    fields: action.context.custom_fields.fields,
                    values: action.context.custom_fields.values.reduce(
                        (values, fieldValue) => ({
                            ...values,
                            [fieldValue.id]: fieldValue.value,
                        }),
                        {}
                    ),
                    previousValues: action.context.custom_fields.previous_values.reduce(
                        (values, fieldValue) => ({
                            ...values,
                            [fieldValue.id]: fieldValue.value,
                        }),
                        {}
                    ),
                    errors: action.context.custom_fields.errors || {},
                    progress: {},
                    dirty: {},
                };
            }
            return state;
        case UPDATE_VALUE:
            return {
                ...state,
                values: {
                    ...state.values,
                    [action.payload.id]: action.payload.value,
                },
            };
        case SYNC_VALUE: {
          let newProgress = {
            ...state.progress,
            [action.payload.id]: true,
          }
          updateDjangoTemplateButtons(newProgress)
          return {
            ...state,
            values: {
              ...state.values,
              [action.payload.id]: action.payload.value,
            },
            progress: newProgress,
            dirty: {
              ...state.dirty,
              [action.payload.id]: true,
            },
          };
        }
        case SYNC_VALUE_SUCCESS: {
          let newProgress = {
            ...state.progress,
            [action.payload.id]: false,
          }
          updateDjangoTemplateButtons(newProgress)
          return {
            ...state,
            errors: {
              ...state.errors,
              [action.payload.id]: undefined,
            },
            progress: newProgress,
          };
        }
        case SYNC_VALUE_FAILURE: {
          let newProgress = {
            ...state.progress,
            [action.payload.id]: false,
          }
          updateDjangoTemplateButtons(newProgress)
          return {
            ...state,
            errors: {
              ...state.errors,
              [action.payload.id]: action.payload.error,
            },
            progress: newProgress,
          };
        }
        case externalActionTypes.SET_NOT_DIRTY:
            return {
                ...state,
                dirty: {},
            };
        case SUBMIT_FORM_SUCCESS:
            return {
                ...state,
                errors: {},
            };
        case SUBMIT_FORM_VALIDATION_FAILURE: {
            const fieldErrors = action.payload.errors.custom_fields.reduce(
                (errors, error) => ({ ...errors, ...error }),
                {}
            );
            return {
                ...state,
                errors: fieldErrors,
            };
        }
      default:
          return state;
    }
}

function updateDjangoTemplateButtons(progress) {
  const disabled = isAnyAttributeTrue(progress);
  let submitButton = document.getElementById("submit-draft-button");
  if (submitButton) {
    submitButton.disabled = disabled;
  }
  let saveDraftButton = document.getElementById("save-draft-button");
  if (saveDraftButton) {
    saveDraftButton.disabled = disabled;
  }
}

export const updateValue = (id, value) => ({
    type: UPDATE_VALUE,
    payload: {
        id,
        value,
    },
});

export const syncValue = (id, value) => ({
    type: SYNC_VALUE,
    payload: {
        id,
        value,
    },
});

export const syncValueSuccess = id => ({
    type: SYNC_VALUE_SUCCESS,
    payload: { id },
});

export const syncValueFailure = (id, error) => ({
    type: SYNC_VALUE_FAILURE,
    payload: {
        id,
        error,
    },
    error: true,
});

function* watchSyncValue() {
    const syncActionChannel = yield actionChannel(SYNC_VALUE);

    while (true) {
        const { payload } = yield take(
            syncActionChannel,
            buffers.expanding(10)
        );
        yield call(syncValueSaga, payload);
    }
}

function* syncValueSaga(payload) {
    try {
        const csrfToken = yield select(store => store.context.csrf_token);
        const appVersion = yield select(store => store.context.app_version);

        if (payload.value instanceof File) {
            yield call(API.sendFilePostRequest, {
                key: payload.id,
                file: payload.value,
                tabName: 'custom-fields',
                appVersion,
                csrfToken,
            });
        } else if (payload.value instanceof Array) {
            yield call(API.sendPatchRequest, {
                body: { [payload.id]: payload.value.map(item => item.value) },
                tabName: 'custom-fields',
                appVersion,
                csrfToken,
            });
        } else {
            yield call(API.sendPatchRequest, {
                body: { [payload.id]: payload.value },
                tabName: 'custom-fields',
                appVersion,
                csrfToken,
            });
        }
        yield put(syncValueSuccess(payload.id));
    } catch (e) {
        yield put(
            syncValueFailure(payload.id, new Error(e.response.data.error))
        );
    }
}

export const sagas = [watchSyncValue];
