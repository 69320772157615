import * as actionType from './types.js';

export const setError = (fieldName, errorMessage, languageCode) => ({
    type: actionType.SET_ERROR,
    fieldName,
    errorMessage,
    languageCode,
});

export const setCorrect = (fieldName, languageCode) => ({
    type: actionType.SET_CORRECT,
    fieldName,
    languageCode,
});

export const isLoading = (fieldName, languageCode) => ({
    type: actionType.IS_LOADING,
    fieldName,
    languageCode,
});

export const isNotLoading = (fieldName, languageCode) => ({
    type: actionType.IS_NOT_LOADING,
    fieldName,
    languageCode,
});

export const setDirty = (fieldName, languageCode) => ({
    type: actionType.SET_DIRTY,
    fieldName,
    languageCode,
});

export const setPristine = (fieldName, languageCode) => ({
    type: actionType.SET_PRISTINE,
    fieldName,
    languageCode,
});

export const setNotDirty = () => ({
    type: actionType.SET_NOT_DIRTY,
});
