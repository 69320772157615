import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from '../../../redux/configureStore';
import AddDjangoContextToRedux from '../containers/AddDjangoContextToRedux.jsx';
import VersionDraftFormContainer from '../containers/VersionDraftFormContainer.jsx';
import GooglePlayContainer from '../containers/GooglePlayContainer.jsx';

const DraftFormContainer = ({ draftFormType }) => {
    if (draftFormType == 'google-play') {
        return <GooglePlayContainer />;
    }
    if (draftFormType == 'app-store') {
        return <VersionDraftFormContainer />;
    }
};

const Index = props => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <AddDjangoContextToRedux context={props.context}>
                    <DraftFormContainer draftFormType={props.draftFormType} />
                </AddDjangoContextToRedux>
            </PersistGate>
        </Provider>
    );
};

export default Index;
