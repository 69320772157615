import _ from 'lodash';
import * as externalActionTypes from '../../components/AppDraft/actions/types';

export const NAVIGATE_TO_TAB = 'navigation/NAVIGATE_TO_TAB';
export const SHOW_CATEGORY_FORM = 'navigation/SHOW_CATEGORY_FORM';
export const ACTIVE_CATEGORY_FORM = 'navigation/ACTIVE_CATEGORY_FORM';

export const navigateToTab = (appId, versionId, tab) => ({
    type: NAVIGATE_TO_TAB,
    appId,
    versionId,
    tab,
});

export const showCategoryForm = (appId, versionId, id) => ({
    type: SHOW_CATEGORY_FORM,
    appId,
    versionId,
    id,
});

export const activeCategoryForm = (appId, versionId, id) => ({
    type: ACTIVE_CATEGORY_FORM,
    appId,
    versionId,
    id,
});

const initialState = {};

export default function navigation(state = initialState, action) {
    switch (action.type) {
        case externalActionTypes.ADD_CONTEXT:
            const selectedCategory =
                action.context.content_rating &&
                action.context.content_rating.category
                    ? _.findKey(
                        action.context.content_rating.category,
                        category => (category ? category.selected : null)
                    )
                    : null;
            if (selectedCategory) {
                return _.set(
                    { ...state },
                    [
                        action.context.app_id,
                        action.context.app_version,
                        'selectedCategory',
                    ],
                    Number(selectedCategory)
                );
            } else {
                return state;
            }
        case 'persist/REHYDRATE':
            // clean up legacy state shape
            if (!action.payload) {
                return state;
            }
            if (action.payload.navigation.activeCategory) {
                delete action.payload.navigation.activeCategory;
            }
            if (action.payload.navigation.selectedCategory) {
                delete action.payload.navigation.selectedCategory;
            }
            if (action.payload.navigation.tab) {
                delete action.payload.navigation.tab;
            }
            return _.merge({}, action.payload.navigation, state);
        case NAVIGATE_TO_TAB:
            return _.set(
                { ...state },
                [action.appId, action.versionId, 'tab'],
                action.tab
            );
        case SHOW_CATEGORY_FORM:
            return _.set(
                { ...state },
                [action.appId, action.versionId, 'selectedCategory'],
                action.id
            );
        case ACTIVE_CATEGORY_FORM:
            return _.set(
                { ...state },
                [action.appId, action.versionId, 'activeCategory'],
                action.id
            );
        default:
            return state;
    }
}
