import React from 'react';
import { Button, Modal, Alert } from 'react-bootstrap';
import Spinner from '../Common/Spinner.jsx';
import { postJSON } from '../Utils/postJSON.jsx';

export default class CreateNewProvisioningProfile extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: false,
            errorMessage: '',
            loading: true,
        };
    }

    handleButtonClick = () => {
        this.setState({ showModal: true });
        postJSON(this.props.endpoint, {})
            .then(() => {
                this.setState({
                    loading: false,
                });
            })
            .catch(error => {
                this.setState({
                    errorMessage: error.response.data,
                    loading: false,
                });
            });
    };

    handleClose = () => {
        this.setState({ showModal: false });
        location.reload();
    };

    render() {
        const { showModal, errorMessage, loading } = this.state;
        return (
            <div>
                <div className="col-md-4">
                    <div className="entry">
                        <Button
                            className="btn btn-primary action-button"
                            onClick={this.handleButtonClick}>
                            Create Provisioning Profile
                        </Button>
                    </div>
                </div>
                <Modal
                    backdrop={false}
                    show={showModal}
                    onHide={this.handleClose}>
                    <Modal.Header>
                        <Modal.Title>Creating Provisioning Profile</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {loading ? (
                            <Spinner
                                loadingLabel={
                                    'Creating new Provisioning Profile'
                                }
                                className={'no-margin-top'}
                            />
                        ) : errorMessage ? (
                            <Alert bsStyle="danger">
                                <h4>
                                    Failed to create new Provisioning Profile
                                </h4>
                                <div>Error: {errorMessage}</div>
                            </Alert>
                        ) : (
                            <Alert bsStyle="success">
                                <h4>Provisioning Profile created</h4>
                            </Alert>
                        )}
                    </Modal.Body>
                    {!loading && (
                        <Modal.Footer>
                            <Button
                                bsStyle="primary"
                                onClick={this.handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    )}
                </Modal>
            </div>
        );
    }
}
