import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RadioField from './RadioField.jsx';
import FieldFeedback from './FieldFeedback.jsx';
import ReadOnlyValue from './ReadOnlyValue.jsx';

export const BooleanOptions = [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
];

export const numericBooleanOptions = [
    { id: 0, name: 'No' },
    { id: 1, name: 'Yes' },
];

export const mapToNumericBooleanOptions = value => {
    if (value == null) {
        return -1;
    } else {
        return Number(value);
    }
};

const OPTIONS_ORDER = ['no', 'yes'];

const optionsOrder = (a, b) => {
    const aValue = OPTIONS_ORDER.indexOf(a.name.toLowerCase());
    const bValue = OPTIONS_ORDER.indexOf(b.name.toLowerCase());
    if (aValue < bValue) {
        return 1;
    } else if (aValue > bValue) {
        return -1;
    } else return 0;
};

export default function MultiRadioField({
    name,
    options,
    selected,
    progress,
    itemSm = 2,
    error,
    dirty,
    onChange,
    collapse,
    id,
    readonly = false,
}) {
    const OptionAndLabel = () => {
        return options.sort(optionsOrder).map(({ name: label, id }) => (
            <Col sm={collapse ? 12 : itemSm} key={`radio-${id}`}>
                <RadioField
                    name={name}
                    label={label}
                    checked={id == selected}
                    onChange={onChange}
                    value={id === null ? 0 : id}
                />
            </Col>
        ));
    };
    const selectedOption = options.find(option => option.id === selected);
    return (
        <Row>
            {readonly ? (
                <Col sm={itemSm}>
                    <ReadOnlyValue>
                        {selectedOption ? selectedOption.name : '-'}
                    </ReadOnlyValue>
                </Col>
            ) : (
                <Col sm={10} style={{ padding: 0 }}>
                    <OptionAndLabel />
                </Col>
            )}
            <Col sm={2}>
                <FieldFeedback
                    progress={progress}
                    error={error}
                    dirty={dirty}
                    id={id}
                />
            </Col>
        </Row>
    );
}
