import React from 'react';
import { connect } from 'react-redux';
import hasTabError from '../util/hasTabError.js';
import FormTabs from './FormTabs.jsx';
import {
    GooglePlayAppDetailsFormContainer,
    GooglePlayContactFormContainer,
    GooglePlayContentRatingContainer,
    GooglePlayLocalizableFormContainer,
    GooglePlaySpecificationForm,
    AdditionalInfoFormContainer,
    GooglePlayAssetsFormContainer,
    GooglePlayDataSafetyContainer,
    GooglePlayFinancialFeaturesContainer
} from './tabs/index.jsx';
import { makeTabsSelector } from '../selectors/tabs';
import GooglePlayAdvertisingIdContainer from "../containers/GooglePlayAdvertisingIdContainer";

const TABS = [
    {
        key: 'specification',
        title: 'Binary metadata',
        component: <GooglePlaySpecificationForm />,
    },
    {
        key: 'app_details',
        title: 'App details',
        component: <GooglePlayAppDetailsFormContainer />,
    },
    {
        key: 'data_safety_questionnaire',
        title: 'Data Safety',
        component: <GooglePlayDataSafetyContainer />,
    },
    {
        key: 'financial_features',
        title: 'Financial Features',
        component: <GooglePlayFinancialFeaturesContainer />,
    },
    {
        key: 'advertising_id',
        title: 'Advertising ID',
        component: <GooglePlayAdvertisingIdContainer />,
    },
    {
        key: 'content_rating',
        title: 'Content Rating',
        component: <GooglePlayContentRatingContainer />,
    },
    {
        key: 'contact_details',
        title: 'Contact Details',
        component: <GooglePlayContactFormContainer />,
    },
    {
        key: 'localizable_metadata',
        title: 'Localizable Metadata',
        component: <GooglePlayLocalizableFormContainer />,
    },
];

const ASSETS_ONLY_TABS = [
    {
        key: 'specification',
        title: 'Binary Metadata',
        component: <GooglePlaySpecificationForm />,
    },
    {
        key: 'assets',
        title: 'Assets',
        component: <GooglePlayAssetsFormContainer />,
    },
];

export const tabsSelector = makeTabsSelector({
    assetsOnlyTabs: ASSETS_ONLY_TABS,
    tabs: TABS,
    customTabComponent: AdditionalInfoFormContainer,
});

const mapStateToProps = store => ({
    tabs: tabsSelector(store).map(tab => ({
        ...tab,
        hasError: hasTabError(tab.key, store.validation.tabErrors),
    })),
});

export default connect(mapStateToProps)(FormTabs);
