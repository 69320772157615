import React from 'react';

const DeleteUserButton = ({ openModal, disableDeleteButton }) => (
    <span
        className="btn btn-danger"
        disabled={disableDeleteButton}
        onClick={() => openModal()}>
        Delete user
    </span>
);

export default DeleteUserButton;
