import React from 'react';
import FieldFeedback from './FieldFeedback.jsx';
import TabsContainer from './TabsContainer';
import Section from '../layout/Section';
import { Col, Row } from 'react-bootstrap';

const feedbackStyle = {
    right: 16,
};

export default class TabsSection extends React.Component {
    constructor(props, context) {
        super(props, context);
    }
    render() {
        const {
            id,
            dirty,
            progress,
            handleSelect,
            children
        } = this.props;
        return (
          //TODO: remove section dependence
            <Section>
                <Row>
                    <Col sm={12}>
                        <FieldFeedback
                            progress={progress && progress[id]}
                            dirty={dirty}
                            detached
                            style={feedbackStyle}
                            id={id}
                        />
                        <TabsContainer id={id}
                            handleSelect={handleSelect}>
                            {children}
                        </TabsContainer>
                    </Col>
                </Row>
            </Section>
        );
    }
}
