import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import Section from '../layout/Section.jsx';
import FieldRow from '../layout/FieldRow.jsx';
import MultiRadioField, { BooleanOptions } from '../inputs/MultiRadioField.jsx';
import MultiSelectField from '../inputs/MultiSelectField.jsx';
import Header from '../Header.jsx';
import {
  mapValuesToOptions, customOptionsRenderer, financialFeaturesList,
} from '../../util/formatters';


const GooglePlayFinancialFeatures = ({
    financialFeatures,
    validation,
    readonly,
    diff,
    ...props
}) => {
  const mapBankFeaturesToOptions = mapValuesToOptions('id')(financialFeatures.options.banking_and_loans);
  const mapPaymentFeaturesToOptions = mapValuesToOptions('id')(financialFeatures.options.payments_and_transfers);
  const mapPurchaseAgreementFeaturesToOptions = mapValuesToOptions('id')(financialFeatures.options.purchase_agreements);
  const mapTradingFeaturesToOptions = mapValuesToOptions('id')(financialFeatures.options.trading_and_funds);
  const mapSupportFeaturesToOptions = mapValuesToOptions('id')(financialFeatures.options.support_services);
    return (
        <Grid fluid>
            <Header>Financial features in your app</Header>
            <h6 className="financialFeaturesHeader">Select all of the financial features your app provides.
                This helps us to make sure the right teams review your submission.</h6>
            <Section id="google-play-financial-features">
                <Row>
                    <FieldRow
                        id="banking_and_loans"
                        label="Banking and Loans">
                        <MultiSelectField
                            name="banking_and_loans"
                            label="Banking and Loans"
                            md={9}
                            options={financialFeaturesList(financialFeatures.options.banking_and_loans)}
                            error={validation.errors?.banking_and_loans}
                            progress={validation.isLoading?.banking_and_loans}
                            dirty={validation.dirty?.banking_and_loans}
                            readonly={readonly}
                            previousValue={diff?.banking_and_loans}
                            onMultiChange={value =>
                                props.handleChangeMultiSelect(
                                    value.map(mapBankFeaturesToOptions),
                                    'id',
                                    'banking_and_loans',
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            selected={financialFeaturesList(financialFeatures.banking_and_loans)}
                            selectAllLabel={'All options'}
                            disableSearch={true}
                            valueRenderer={customOptionsRenderer}
                            disabled={financialFeatures.no_financial_features}
                        />
                    </FieldRow>
                </Row>
                <Row>
                    <FieldRow
                        id="payments_and_transfers"
                        label="Payments and transfers">
                        <MultiSelectField
                            name="payments_and_transfers"
                            label="Payments and transfers"
                            md={9}
                            options={financialFeaturesList(financialFeatures.options.payments_and_transfers)}
                            error={validation.errors.payments_and_transfers}
                            progress={
                                validation.isLoading.payments_and_transfers
                            }
                            dirty={validation.dirty.payments_and_transfers}
                            readonly={readonly}
                            previousValue={diff.payments_and_transfers}
                            onMultiChange={value =>
                                props.handleChangeMultiSelect(
                                    value.map(mapPaymentFeaturesToOptions),
                                    'id',
                                    'payments_and_transfers',
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            selected={financialFeaturesList(financialFeatures.payments_and_transfers)}
                            selectAllLabel={'All options'}
                            disableSearch={true}
                            valueRenderer={customOptionsRenderer}
                            disabled={financialFeatures.no_financial_features}
                        />
                    </FieldRow>
                </Row>
                <Row>
                    <FieldRow
                        id="purchase_agreements"
                        label="Purchase agreements">
                        <MultiSelectField
                            name="purchase_agreements"
                            label="Purchase agreements"
                            md={9}
                            options={financialFeaturesList(financialFeatures.options.purchase_agreements)}
                            error={validation.errors.purchase_agreements}
                            progress={
                                validation.isLoading.purchase_agreements
                            }
                            dirty={validation.dirty.purchase_agreements}
                            readonly={readonly}
                            previousValue={diff.purchase_agreements}
                            onMultiChange={value =>
                                props.handleChangeMultiSelect(
                                    value.map(mapPurchaseAgreementFeaturesToOptions),
                                    'id',
                                    'purchase_agreements',
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            selected={financialFeaturesList(financialFeatures.purchase_agreements)}
                            selectAllLabel={'All options'}
                            disableSearch={true}
                            valueRenderer={customOptionsRenderer}
                            disabled={financialFeatures.no_financial_features}
                        />
                    </FieldRow>
                </Row>
                <Row>
                    <FieldRow
                        id="trading_and_funds"
                        label="Trading and funds">
                        <MultiSelectField
                            name="trading_and_funds"
                            label="Trading and funds"
                            md={9}
                            options={financialFeaturesList(financialFeatures.options.trading_and_funds)}
                            error={validation.errors.trading_and_funds}
                            progress={
                                validation.isLoading.trading_and_funds
                            }
                            dirty={validation.dirty.trading_and_funds}
                            readonly={readonly}
                            previousValue={diff.trading_and_funds}
                            onMultiChange={value =>
                                props.handleChangeMultiSelect(
                                    value.map(mapTradingFeaturesToOptions),
                                    'id',
                                    'trading_and_funds',
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            selected={financialFeaturesList(financialFeatures.trading_and_funds)}
                            selectAllLabel={'All options'}
                            disableSearch={true}
                            valueRenderer={customOptionsRenderer}
                            disabled={financialFeatures.no_financial_features}
                        />
                    </FieldRow>
                </Row>
                <Row>
                    <FieldRow
                        id="support_services"
                        label="Support services">
                        <MultiSelectField
                            name="support_services"
                            label="Support services"
                            md={9}
                            options={financialFeaturesList(financialFeatures.options.support_services)}
                            error={validation.errors.support_services}
                            progress={
                                validation.isLoading.support_services
                            }
                            dirty={validation.dirty.support_services}
                            readonly={readonly}
                            previousValue={diff.support_services}
                            onMultiChange={value =>
                                props.handleChangeMultiSelect(
                                    value.map(mapSupportFeaturesToOptions),
                                    'id',
                                    'support_services',
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            selected={financialFeaturesList(financialFeatures.support_services)}
                            selectAllLabel={'All options'}
                            disableSearch={true}
                            valueRenderer={customOptionsRenderer}
                            disabled={financialFeatures.no_financial_features}
                        />
                    </FieldRow>
                </Row>
                <Row className={"financialFeaturesMarginBottom"}>
                    <FieldRow
                      id="no_financial_features"
                      label="My app doesn't provide any financial features">
                      <MultiRadioField
                          id="no_financial_features"
                          name="no_financial_features"
                          error={validation.errors.no_financial_features}
                          progress={validation.isLoading.no_financial_features}
                          dirty={validation.dirty.no_financial_features}
                          selected={financialFeatures.no_financial_features}
                          readonly={readonly}
                          onChange={event =>{
                              props.handleChangeRadio(
                                  event,
                                  BooleanOptions,
                                  props.csrf_token,
                                  props.app_version
                              )
                            }
                          }
                          options={BooleanOptions}
                      />
                  </FieldRow>
                </Row>
            </Section>
        </Grid>
    );
};

export default GooglePlayFinancialFeatures;
