import React from 'react';
import BaseLocalizableLanguageMetadataList from './BaseLocalizableLanguageMetadataList.jsx';
import AppStoreLocalizableLanguageMetadata from './AppStoreLocalizableLanguageMetadata.jsx';

const AppStoreLocalizableLanguageMetadataList = ({
    localizable,
    readonly,
    ...props
}) => {
    return (
        <BaseLocalizableLanguageMetadataList localizable={localizable}>
            {metadata => (
                <AppStoreLocalizableLanguageMetadata
                    key={`localizable-language-${metadata.language.code}`}
                    metadata={metadata}
                    supports_ipad={localizable.supports_ipad}
                    multiple_languages={localizable.languages.length > 1}
                    supports_iphone={localizable.supports_iphone}
                    supports_watch={localizable.supports_watch}
                    handleChangeTextInput={props.handleChangeTextInput}
                    handleBlurTextInput={props.handleBlurTextInput}
                    handleChangeScreenshots={props.handleChangeScreenshots}
                    handleDeleteScreenshot={props.handleDeleteScreenshot}
                    handleSetDefaultLanguage={props.handleSetDefaultLanguage}
                    handleLoadFromDefaultLanguage={
                        props.handleLoadFromDefaultLanguage
                    }
                    handleLoadFromDefaultLanguageToOthers={
                        props.handleLoadFromDefaultLanguageToOthers
                    }
                    handleScreenshotsError={props.handleScreenshotsError}
                    app_id={props.app_id}
                    app_version={props.app_version}
                    csrf_token={props.csrf_token}
                    validation={props.validation}
                    readonly={readonly}
                    id={metadata.language.code}
                    diff={props.diff[metadata.language.code]}
                    options={localizable.options}
                    handleSetCorrect={props.handleSetCorrect}
                    {...props}
                />
            )}
        </BaseLocalizableLanguageMetadataList>
    );
};

export default AppStoreLocalizableLanguageMetadataList;
