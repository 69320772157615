import React from 'react';
import { Row, FormGroup } from 'react-bootstrap';

const GraphicAssets = ({ id, children, disabled }) => {
    return (
        <FormGroup
            className={disabled ? 'form-group__disabled' : null}
            controlId={id}>
            <Row>{children}</Row>
        </FormGroup>
    );
};

export default GraphicAssets;
