// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import axios from 'axios';

const makeUrl = (appVersion, context) =>
    `/api/v1/versions/${appVersion}/draft/${context}`;

// begin axios service
export const errHandler = err => {
    console.error(err);
    if (err.response && err.response.data) {
        console.error('API response', err.response.data);
        throw err.response.data.message;
    }
    throw err;
};
// end axios service

export const sendPatchRequest = ({ body, appVersion, tabName, csrfToken }) =>
    axios.patch(makeUrl(appVersion, tabName), body, {
        'X-CSRFToken': csrfToken,
    });

export const sendFilePostRequest = ({
    key,
    file,
    appVersion,
    tabName,
    csrfToken,
}) => {
    const formData = new FormData();
    formData.append(key, file);
    return axios.post(makeUrl(appVersion, tabName), formData, {
        'X-CSRFToken': csrfToken,
    });
};

export const sendSignRequest = ({ appVersion, csrfToken }) =>
    axios.get(makeUrl(appVersion, 'sign'), {
        'X-CSRFToken': csrfToken,
    });

// begin axios get and post method
export const getVPNs = endpoint => {
    return axios
        .get(`${endpoint}`)
        .then(res => res.data)
        .catch(errHandler);
};

export const postVPNs = (endpoint, vpns) => {
    return axios
        .post(`${endpoint}`, vpns)
        .then(res => {
            res.data;
        })
        .catch(errHandler);
};
// end axios get and post method
