import React, { Component } from 'react';
import CheckboxWithAPI from './CheckboxWithAPI.jsx';

export default class WillNextAppBeFeaturedCheckboxList extends Component {
    constructor(props) {
        super(props);
        this.createCheckboxesFromContext = this.createCheckboxesFromContext.bind(
            this
        );
    }

    createCheckboxesFromContext() {
        return this.props.context.map((item, index) => (
            <CheckboxWithAPI
                key={`will_next_app_be_featured_${index}`}
                endpoint={item.featured_url}
                text={`Set next version of the app as featured on ${
                    item.target_name
                }`}
            />
        ));
    }

    render() {
        return (
            <div className="assign-targets">
                <h3>Featured App</h3>
                {this.createCheckboxesFromContext()}
            </div>
        );
    }
}
