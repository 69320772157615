import React, { useEffect, useState, useRef } from 'react';
import useVideoMeta from '@use-hooks/video-meta';
import classNames from 'classnames';
import RemoveIconButton from '../RemoveIconButton.jsx';
import Video from '../icons/Video.jsx';

const AssetVideoPreview = ({
    src,
    type,
    onDelete,
    style,
    readonly = false,
}) => {
    const className = classNames('asset-preview-container', { 'asset-preview-container-hover': !readonly });
    const videoRef = useRef(null);
    useEffect(() => {
        videoRef.current.src = src;
        videoRef.current.load();
    }, []);

    const { width, height } = useVideoMeta(src);
    const [widthStyle, setWidthStyle] = useState(null);
    useEffect(() => {
        setWidthStyle(width > height ? style.height : style.width);
    }, [width, height]);

    return (
        <div className={className}>
            {!readonly && (
                <div className="asset-preview-header">
                    <RemoveIconButton onClick={onDelete}/>
                    <Video/>
                </div>
            )}
            <video controls style={{ width: widthStyle }} ref={videoRef}>
                <source src={src} type={type}/>
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default AssetVideoPreview;