import React from 'react';
import { Grid, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import Section from '../layout/Section.jsx';
import FieldRow from '../layout/FieldRow.jsx';
import TextField from '../inputs/TextField.jsx';
import TextArea from '../inputs/TextArea.jsx';
import SelectField from '../inputs/SelectField.jsx';
import DatePicker from '../inputs/DatePicker.jsx';
import SelectFileField from '../inputs/SelectFileField.jsx';
import DropzoneField from '../inputs/DropzoneField.jsx';
import { mapValuesToOptions } from '../../util/formatters';
import MultiRadioField from '../inputs/MultiRadioField.jsx';
import Header from '../Header.jsx';
import MultiSelectField from '../inputs/MultiSelectField.jsx';

const booleanOptions = [{ id: true, name: 'Yes' }, { id: false, name: 'No' }];

const mapCustomValuesToOptions = mapValuesToOptions('value');

export const RenderCustomField = ({
    id,
    name,
    description,
    is_mandatory,
    max_length,
    options,
    fieldType,
    type,
    value,
    error,
    progress,
    dirty,
    readonly,
    ...props
}) => {
    switch (fieldType) {
        case 'TextField':
            return (
                <TextField
                    name={id}
                    md={5}
                    value={value}
                    type={type}
                    maxLength={max_length}
                    error={error}
                    progress={progress}
                    dirty={dirty}
                    readonly={readonly}
                    previousValue={props.previousValue}
                    onChange={event =>
                        props.updateValue(id, event.target.value)
                    }
                    onBlur={event => props.syncValue(id, event.target.value)}
                    id={id}
                />
            );
        case 'TextArea':
            return (
                <TextArea
                    name={id}
                    rows="8"
                    md={12}
                    maxLength={max_length}
                    value={value}
                    error={error}
                    progress={progress}
                    dirty={dirty}
                    readonly={readonly}
                    previousValue={props.previousValue}
                    onChange={event =>
                        props.updateValue(id, event.target.value)
                    }
                    onBlur={event => props.syncValue(id, event.target.value)}
                    id={id}
                />
            );
        case 'SingleSelect':
            return (
                <SelectField
                    name={id}
                    md={5}
                    options={options}
                    value={value}
                    error={error}
                    progress={progress}
                    previousValue={props.previousValue}
                    dirty={dirty}
                    id={id}
                    readonly={readonly}
                    onChange={event => props.syncValue(id, event.target.value)}
                />
            );
        case 'MultiSelect':
            const mapCustomMultiSelectValuesToOptions = mapCustomValuesToOptions(
                options
            );
            return (
                <MultiSelectField
                    label={id}
                    md={5}
                    options={options}
                    selected={value || []}
                    error={error}
                    progress={progress}
                    dirty={dirty}
                    id={id}
                    readonly={readonly}
                    previousValue={props.previousValue}
                    onMultiChange={value =>
                        props.syncValue(
                            id,
                            value.map(mapCustomMultiSelectValuesToOptions)
                        )
                    }
                />
            );
        case 'BooleanField':
            return (
                <MultiRadioField
                    name={id}
                    error={error}
                    options={booleanOptions}
                    progress={progress}
                    dirty={dirty}
                    selected={value}
                    previousValue={props.previousValue}
                    id={id}
                    readonly={readonly}
                    onChange={event =>
                        props.syncValue(id, event.target.value === 'true')
                    }
                />
            );
        case 'DatePicker':
            return (
                <DatePicker
                    id={id}
                    minDate={
                        props.date_min ? moment(props.date_min) : undefined
                    }
                    maxDate={
                        props.date_max ? moment(props.date_max) : undefined
                    }
                    placeholderText="Select date"
                    selected={value ? moment(value) : undefined}
                    error={error}
                    progress={progress}
                    dirty={dirty}
                    previousValue={props.previousValue}
                    readonly={readonly}
                    onChange={date => props.syncValue(id, date || '')}
                />
            );
        case 'Header':
            return (
                <Row className="section-header">
                    <Col sm={6}>
                        <h3>{name}</h3>
                    </Col>
                </Row>
            );
        case 'FileField':
            return (
                <SelectFileField
                    id={id}
                    xs={12}
                    sm={12}
                    md={12}
                    accept={props.file_mime_type}
                    maxFileSize={props.max_file_size}
                    initialFile={value}
                    error={error}
                    progress={progress}
                    dirty={dirty}
                    previousValue={props.previousValue}
                    readonly={readonly}
                    onChange={file => props.syncValue(id, file)}
                    onDelete={() => props.syncValue(id, null)}
                />
            );
        case 'ImageField':
            return (
                <DropzoneField
                    id={id}
                    initialFile={value}
                    error={error}
                    progress={progress}
                    dirty={dirty}
                    accept={props.file_mime_type}
                    maxFileSize={props.max_file_size}
                    previousValue={props.previousValue}
                    readonly={readonly}
                    onChange={file => props.syncValue(id, file)}
                    onDelete={() => props.syncValue(id, null)}
                />
            );
        default:
            return <div>This field type ({fieldType}) does not exist.</div>;
    }
};

const AdditionalInfoForm = props => {
    return (
        <Grid fluid>
            <Header>{props.tabName}</Header>
            <Section id="additional-info-section">
                <Row>
                    {props.fields.map(field =>
                        field.widget !== 'Header' ? (
                            <FieldRow
                                key={field.id}
                                id={field.id}
                                label={field.name}
                                description={field.description}
                                required={field.is_mandatory}>
                                <RenderCustomField
                                    key={field.id}
                                    id={field.id}
                                    options={field.options}
                                    fieldType={field.widget}
                                    {...field}
                                    previousValue={props.showDiff ? props.previousValues[field.id] : null}
                                    value={props.values[field.id]}
                                    error={props.errors[field.id]}
                                    progress={props.progress[field.id]}
                                    dirty={props.dirty[field.id]}
                                    updateValue={props.updateValue}
                                    syncValue={props.syncValue}
                                    readonly={props.readonly}
                                />
                            </FieldRow>
                        ) : (
                            <RenderCustomField
                                key={field.id}
                                id={field.id}
                                options={field.options}
                                fieldType={field.widget}
                                {...field}
                                value={props.values[field.id]}
                                error={props.errors[field.id]}
                                progress={props.progress[field.id]}
                                previousValue={props.showDiff ? props.previousValues[field.id] : null}
                                dirty={props.dirty[field.id]}
                                updateValue={props.updateValue}
                                syncValue={props.syncValue}
                                readonly={props.readonly}
                            />
                        )
                    )}
                </Row>
            </Section>
        </Grid>
    );
};

export default AdditionalInfoForm;
