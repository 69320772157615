import React from 'react';

export default function Trash({ color = '#EB2F27' }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22">
            <path
                fill={color}
                fillRule="evenodd"
                d="M15.592 3.883h4.25c.103 0 .193.038.27.115a.37.37 0 0 1 .116.27v.768a.374.374 0 0 1-.108.277.377.377 0 0 1-.278.108H3.615a.377.377 0 0 1-.279-.108.376.376 0 0 1-.108-.277v-.769a.37.37 0 0 1 .116-.27.371.371 0 0 1 .27-.114h4.25V2.344c0-.42.153-.782.456-1.085A1.492 1.492 0 0 1 9.41.806h4.636c.423 0 .787.151 1.09.453.304.303.456.665.456 1.085v1.539zM4.774 6.959h13.909v12.308c0 .42-.152.785-.456 1.092a1.478 1.478 0 0 1-1.09.462H6.32c-.422 0-.785-.154-1.09-.462a1.502 1.502 0 0 1-.455-1.092V6.96zm1.545 12.308h1.546V8.497H6.319v10.77zM9.41 3.883h4.636V2.344H9.41v1.539zm0 15.384h1.545V8.497H9.41v10.77zm3.09 0h1.546V8.497h-1.545v10.77zm3.092 0h1.545V8.497h-1.545v10.77z"
            />
        </svg>
    );
}
