import React from 'react';
import { Grid, Row } from 'react-bootstrap';
import FieldRow from '../layout/FieldRow.jsx';
import Section from '../layout/Section.jsx';
import MultiSelectField from '../inputs/MultiSelectField.jsx';
import AppStoreAssetsOnlyLocalizableLanguageMetadataList from '../AppStoreAssetsOnlyLocalizableLanguageMetadataList.jsx';
import Header from '../Header.jsx';

import {
    localizableLanguagesList,
    languagesValueRenderer,
} from '../../util/formatters';

const AppStoreAssetsForm = ({ localizable, validation, ...props }) => {
    return (
        <Grid fluid>
            <Header>Assets</Header>
            <Section
                id="choose-language-section"
                title="App Store Localizable Metadata">
                <Row>
                    <FieldRow
                        id="choose_languages"
                        label="Choose languages"
                        required>
                        <MultiSelectField
                            md={9}
                            options={localizableLanguagesList(
                                localizable.options.languages
                            )}
                            onMultiChange={value =>
                                props.handleChangeMultiSelect(
                                    value,
                                    'languages',
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            selected={localizableLanguagesList(
                                localizable.languages
                            )}
                            selectAllLabel={'All languages'}
                            valueRenderer={languagesValueRenderer}
                        />
                    </FieldRow>
                </Row>
            </Section>
            <AppStoreAssetsOnlyLocalizableLanguageMetadataList
                localizable={localizable}
                expandLocalizableLanguage={props.expandLocalizableLanguage}
                handleChangeTextInput={props.handleChangeTextInput}
                handleBlurTextInput={props.handleBlurTextInput}
                handleChangeScreenshots={props.handleChangeScreenshots}
                handleDeleteScreenshot={props.handleDeleteScreenshot}
                handleLoadFromDefaultLanguage={
                    props.handleLoadFromDefaultLanguage
                }
                handleSetDefaultLanguage={props.handleSetDefaultLanguage}
                handleLoadFromDefaultLanguageToOthers={
                    props.handleLoadFromDefaultLanguageToOthers
                }
                handleScreenshotsError={props.handleScreenshotsError}
                app_id={props.app_id}
                app_version={props.app_version}
                csrf_token={props.csrf_token}
                validation={validation}
                diff={props.diff}
                handleSetCorrect={props.handleSetCorrect}
                options={localizable.options}
                {...props}
            />
        </Grid>
    );
};

export default AppStoreAssetsForm;
