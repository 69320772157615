import React from 'react';
import UploadFileField from './UploadFileField';

const UploadManually = ({
    show,
    validation,
    reviewInformation,
    readonly,
    props,
}) => {
    if (show) {
        return (
            <div className="submission-information-info-box export-compliance-grid">
                <i className="glyphicon glyphicon-exclamation-sign submission-information-exclamation" />
                <div className="submission-information-text">
                    The current version can be uploaded to Appstore connect using
                    the Incapptic Platform.  It cannot however be automatically
                    submitted at this time because the build does not have the
                    associated export compliance documentation.
                    Please upload this documentation manually
                    to <a
                        href="https://appstoreconnect.apple.com/"
                        target="_blank">
                        Appstore Connect
                    </a>.
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default UploadManually;
