import React from 'react';
import { Row, Col, Glyphicon } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import SquareIconPreview from './SquareIconPreview.jsx';
import FieldFeedback from './FieldFeedback.jsx';
import VideoPreview from './VideoPreview.jsx';
import NoImagePreview from './NoImagePreview.jsx';

//TODO: deprecated, change to AssetDropzone component
class DropzoneField extends React.Component {
    static defaultProps = {
        expectedWidth: 130,
        scale: 1,
        readonly: false,
    };

    constructor(props) {
        super(props);
        this.state = { selectedFile: props.initialFile, error: null, imageChangeTimestamp: Date.now() };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.initialFile !== this.state.selectedFile) {
            this.setState({ selectedFile: nextProps.initialFile, imageChangeTimestamp: Date.now() });
        }
    }

    onDropAccepted = files => {
        if (files.length !== 1) {
            this.setState({
                error: new Error('This field accepts only a single file'),
            });
        } else {
            const selectedFile = files[0];
            this.setState({ selectedFile, error: null }, () =>
                this.props.onChange(selectedFile)
            );
        }
    };

    onDropRejected = rejectedFiles => {
        if (rejectedFiles.length === 1) {
            this.setState({
                error: new Error('Invalid image size or file format'),
            });
        }
    };

    render() {
        const {
            id,
            accept,
            progress,
            dirty,
            maxFileSize,
            readonly,
        } = this.props;
        const { error } = this.state;
        return (
            <Row>
                <Col md={12}>
                    <section>
                        <Dropzone
                            disabled={readonly}
                            multiple={false}
                            accept={accept}
                            maxSize={maxFileSize}
                            onDropAccepted={this.onDropAccepted}
                            onDropRejected={this.onDropRejected}
                            className="dropzone"
                            activeClassName="dropzone-active"
                            disabledClassName="dropzone-disabled">
                            {this.renderPreview()}
                        </Dropzone>
                        <FieldFeedback
                            id={id}
                            error={error || this.props.error}
                            progress={progress}
                            dirty={dirty}
                        />
                    </section>
                </Col>
            </Row>
        );
    }

    renderPreview = () => {
        const { selectedFile, imageChangeTimestamp } = this.state;
        const { initialFile, allowVideos, readonly } = this.props;
        const previewStyle = this.getPreviewStyle();
        if (allowVideos && selectedFile && selectedFile.preview) {
            return (
                <VideoPreview
                    style={previewStyle}
                    src={selectedFile.preview}
                    onDelete={this.handleDelete}
                />
            );
        } else if (allowVideos && selectedFile) {
            return (
                <VideoPreview
                    readonly={readonly}
                    style={previewStyle}
                    src={initialFile ? `${initialFile}?${imageChangeTimestamp}`: null}
                    onDelete={this.handleDelete}
                />
            );
        } else if (selectedFile && selectedFile.preview) {
            return (
                <SquareIconPreview
                    readonly={readonly}
                    style={previewStyle}
                    src={selectedFile.preview}
                    onDelete={this.handleDelete}
                />
            );
        } else if (selectedFile) {
            return (
                <SquareIconPreview
                    readonly={readonly}
                    style={previewStyle}
                    src={initialFile ? `${initialFile}?${imageChangeTimestamp}`: null}
                    onDelete={this.handleDelete}
                />
            );
        } else if (readonly) {
            return <NoImagePreview style={previewStyle} video={allowVideos} />;
        } else {
            return (
                <div className="dropzone-inside-box" style={previewStyle}>
                    <Glyphicon
                        glyph="glyphicon glyphicon-plus"
                        bsClass="glyphicon glyphicon-plus dropzone-glyphicon"
                    />
                </div>
            );
        }
    };

    getPreviewStyle = () => {
        const { expectedWidth, expectedHeight, scale } = this.props;
        return expectedWidth || expectedHeight
            ? {
                width: expectedWidth ? `${expectedWidth * scale}px` : 'auto',
                height: expectedHeight
                      ? `${expectedHeight * scale}px`
                      : 'auto',
            }
            : undefined;
    };

    handleDelete = event => {
        event.stopPropagation();
        this.setState({ selectedFile: null, error: null });
        this.props.onDelete();
    };
}

export default DropzoneField;
