import React from 'react';
import CurrentValue from './CurrentValue.jsx';
import PreviousValue from './PreviousValue.jsx';
import ReadOnlyValue from './ReadOnlyValue.jsx';

export default function ReadonlyDiffValue({ value, previousValue }) {
    if (
        previousValue == undefined ||
        previousValue == value ||
        (previousValue === '' && value === undefined) ||
        (previousValue === '' && value === null) ||
        (previousValue === '' && value === '') ||
        (previousValue === '' && value === 'None') ||
        (previousValue === undefined && value === '') ||
        (previousValue === undefined && value === 'None') ||
        (previousValue === undefined && value === undefined) ||
        (previousValue === undefined && value === null) ||
        (previousValue === null && value === '') ||
        (previousValue === null && value === 'None') ||
        (previousValue === null && value === undefined) ||
        (previousValue === null && value === null) ||
        (previousValue === 'None' && value === 'None') ||
        (previousValue === 'None' && value === undefined) ||
        (previousValue === 'None' && value === null) ||
        (previousValue === 'None' && value === '')
    ) {
        return <ReadOnlyValue>{value || '-'}</ReadOnlyValue>;
    } else {
        return (
            <ReadOnlyValue>
                <PreviousValue>{(previousValue === undefined ||
                    previousValue === null || previousValue === '' ||
                    previousValue === 'None' ? '-' : previousValue)
                }</PreviousValue>
                <CurrentValue>{value || '-'}</CurrentValue>
            </ReadOnlyValue>
        );
    }
}
