// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import SortableList from './SortableList';
import SearchBar from '../Misc/SearchBar';
import * as api from '../AppDraft/util/API';
import Spinner from '../Common/Spinner';

class CardsInLanes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filterText: '',
            all: [],
            applied: [],
            isLoading: true,
        };
    }

    onChangeSearch = event => {
        const text = event.target.value;
        this.setState({
            filterText: text,
        });
    };

    onChangeApplied = order => {
        const applied = this.state.all.filter(
            item => order.indexOf(String(item.id)) !== -1
        );
        const sorted = applied.sort(
            (a, b) => order.indexOf(String(a.id)) - order.indexOf(String(b.id))
        );
        this.setState({
            applied: sorted,
        });
        const mobileId = { vpns: sorted.map(item => item.mobileiron_id) };
        api.postVPNs(this.props.endpoint, mobileId);
    };

    componentDidMount = () => {
        api.getVPNs(this.props.endpoint).then(res =>
            this.setState({
                all: res.all,
                applied: res.applied,
                isLoading: false,
            })
        );
    };

    render() {
        const filterText = this.state.filterText;
        const filtered = this.state.all.filter(function(element) {
            return (
                element.name.toLowerCase().indexOf(filterText.toLowerCase()) >=
                0
            );
        });

        return this.state.isLoading ? <Spinner loadingLabel={'Loading'}/> : (
            <Row className="container-with-tables ">
                <SearchBar onChange={event => this.onChangeSearch(event)} />
                <div className="row-flex-wrapper">
                    <Col className="column-with-vpns" xs={6}>
                        <h5>All</h5>
                        <SortableList
                            id='table-container-vpns'
                            options={{ group: 'shared' }}
                            items={filtered}
                        />
                    </Col>
                    <div className="empty-spacer"/>
                    <Col className="column-with-vpns" xs={6}>
                        <h5>Applied to next version</h5>
                        <SortableList
                            id='table-container-vpns'
                            onChange={order => this.onChangeApplied(order)}
                            options={{ group: 'shared' }}
                            items={this.state.applied}
                        />
                    </Col>
                </div>
            </Row>
        );
    }
}

export default CardsInLanes;
