import React from 'react';
import {
    FormGroup,
    FormControl,
    ControlLabel,
    Checkbox,
    Button,
    HelpBlock,
} from 'react-bootstrap';

const initialState = {
    name: '',
    keystoreFile: '',
    keystoreFilePath: '',
    keystorePassword: '',
    showKeystorePassword: false,
    keyAlias: '',
    privateKeyPassword: '',
    showPrivateKeyPassword: false,
    isDefault: false,
    errors: {},
};

class NewAndroidCertificateForm extends React.Component {
    state = initialState;

    change = event => {
        const new_state = {};

        switch (event.target.type) {
            case 'checkbox':
                new_state[event.target.name] = event.target.checked;
                break;
            case 'file':
                new_state[event.target.name] = event.target.files[0];
                new_state[`${event.target.name}Path`] = event.target.value;
                break;
            default:
                new_state[event.target.name] = event.target.value;
        }
        this.setState(new_state);
        this.props.onChange(new_state);
    };

    errors = fieldname => this.props.errors[fieldname] || [];

    validationState = fieldname => {
        if (this.errors(fieldname).length > 0) {
            return 'error';
        } else {
            return null;
        }
    };

    render() {
        return (
            <form>
                <FormGroup
                    validationState={this.validationState('non_field_errors')}>
                    {this.errors('non_field_errors').map(item => (
                        <HelpBlock>{item}</HelpBlock>
                    ))}
                </FormGroup>
                <FormGroup validationState={this.validationState('name')}>
                    <ControlLabel>Name</ControlLabel>
                    <FormControl
                        type="text"
                        value={this.state.name}
                        onChange={this.change}
                        name="name"
                    />
                    {this.errors('name').map(item => (
                        <HelpBlock>{item}</HelpBlock>
                    ))}
                </FormGroup>
                <FormGroup validationState={this.validationState('key_store')}>
                    <ControlLabel>Key Store</ControlLabel>
                    <FormControl
                        type="file"
                        value={this.state.keystoreFilePath}
                        onChange={this.change}
                        name="keystoreFile"
                    />
                    {this.errors('key_store').map(item => (
                        <HelpBlock>{item}</HelpBlock>
                    ))}
                </FormGroup>
                <FormGroup
                    validationState={this.validationState('key_store_password')}>
                    <ControlLabel>Key Store Password</ControlLabel>
                    <FormControl
                        type={
                            this.state.showKeystorePassword
                                ? 'text'
                                : 'password'
                        }
                        value={this.state.keystorePassword}
                        onChange={this.change}
                        name="keystorePassword"
                    />
                    {this.errors('key_store_password').map(item => (
                        <HelpBlock>{item}</HelpBlock>
                    ))}
                    <Checkbox
                        onChange={this.change}
                        name="showKeystorePassword"
                        checked={this.state.showKeystorePassword}>
                        Show Key Store Password
                    </Checkbox>
                </FormGroup>
                <FormGroup validationState={this.validationState('alias')}>
                    <ControlLabel>Alias</ControlLabel>
                    <FormControl
                        type="text"
                        value={this.state.keyAlias}
                        onChange={this.change}
                        name="keyAlias"
                    />
                    {this.errors('alias').map(item => (
                        <HelpBlock>{item}</HelpBlock>
                    ))}
                </FormGroup>
                <FormGroup
                    validationState={this.validationState(
                        'private_key_password'
                    )}>
                    <ControlLabel>Private Key Password</ControlLabel>
                    <FormControl
                        type={
                            this.state.showPrivateKeyPassword
                                ? 'text'
                                : 'password'
                        }
                        value={this.state.privateKeyPassword}
                        onChange={this.change}
                        name="privateKeyPassword"
                    />
                    {this.errors('private_key_password').map(item => (
                        <HelpBlock>{item}</HelpBlock>
                    ))}
                    <Checkbox
                        onChange={this.change}
                        name="showPrivateKeyPassword"
                        checked={this.state.showPrivateKeyPassword}>
                        Show Private Key Password
                    </Checkbox>
                </FormGroup>
                <FormGroup validationState={this.validationState('default')}>
                    <Checkbox
                        onChange={this.change}
                        checked={this.state.isDefault}
                        name="isDefault">
                        Default
                    </Checkbox>
                    {this.errors('default').map(item => (
                        <HelpBlock>{item}</HelpBlock>
                    ))}
                </FormGroup>
            </form>
        );
    }
}

export { initialState, NewAndroidCertificateForm };
