import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import FieldRow from '../layout/FieldRow.jsx';
import Section from '../layout/Section.jsx';
import TextField from '../inputs/TextField.jsx';
import CheckboxField from '../inputs/CheckboxField.jsx';
import SelectField from '../inputs/SelectField.jsx';
import { countriesList } from '../../util/formatters';
import Header from '../Header.jsx';

const ContactForm = ({
    contactDetails,
    validation,
    readonly,
    diff,
    ...props
}) => {
    const countryListOptions = countriesList(
        contactDetails.options.trade_representative_country
    );
    return (
        <Grid fluid>
            <Header>Contact Details</Header>
            <Section id="contact-details-section">
                <Row>
                    <FieldRow
                        id="contact_first_name"
                        label="Contact first name"
                        help="The person in your organization who should be contacted if the App Review team has any questions or needs additional information."
                        required>
                        <TextField
                            name="contact_first_name"
                            md={5}
                            error={validation.errors.contact_first_name}
                            progress={validation.isLoading.contact_first_name}
                            dirty={validation.dirty.contact_first_name}
                            value={contactDetails.contact_first_name}
                            previousValue={diff.contact_first_name}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="contact_last_name"
                        label="Contact last name"
                        required>
                        <TextField
                            name="contact_last_name"
                            md={5}
                            error={validation.errors.contact_last_name}
                            progress={validation.isLoading.contact_last_name}
                            dirty={validation.dirty.contact_last_name}
                            value={contactDetails.contact_last_name}
                            previousValue={diff.contact_last_name}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="contact_email"
                        label="Contact e-mail"
                        required>
                        <TextField
                            name="contact_email"
                            md={5}
                            error={validation.errors.contact_email}
                            progress={validation.isLoading.contact_email}
                            dirty={validation.dirty.contact_email}
                            value={contactDetails.contact_email}
                            previousValue={diff.contact_email}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow id="contact_phone" label="Contact phone" required>
                        <TextField
                            name="contact_phone"
                            md={5}
                            error={validation.errors.contact_phone}
                            progress={validation.isLoading.contact_phone}
                            dirty={validation.dirty.contact_phone}
                            value={contactDetails.contact_phone}
                            previousValue={diff.contact_phone}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
            <Section
                id="trade-representative-contact-information"
                title="Trade representative contact information">
                <Row>
                    <CheckboxField
                        md={12}
                        name="display_trade_representative_for_korean_store"
                        value={
                            contactDetails.display_trade_representative_for_korean_store
                        }
                        title="Display Trade Representative Contact Information on the Korean App Store"
                        readonly={readonly}
                        onChange={event =>
                            props.handleChangeCheckbox(
                                event,
                                props.csrf_token,
                                props.app_version
                            )
                        }
                    />
                    <FieldRow
                        id="trade_representative_first_name"
                        label="First name"
                        required={
                            contactDetails.display_trade_representative_for_korean_store
                        }>
                        <TextField
                            name="trade_representative_first_name"
                            md={5}
                            error={
                                validation.errors
                                    .trade_representative_first_name
                            }
                            progress={
                                validation.isLoading
                                    .trade_representative_first_name
                            }
                            dirty={
                                validation.dirty.trade_representative_first_name
                            }
                            value={
                                contactDetails.trade_representative_first_name
                            }
                            previousValue={diff.trade_representative_first_name}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="trade_representative_last_name"
                        label="Last name"
                        required={
                            contactDetails.display_trade_representative_for_korean_store
                        }>
                        <TextField
                            name="trade_representative_last_name"
                            md={5}
                            error={
                                validation.errors.trade_representative_last_name
                            }
                            progress={
                                validation.isLoading
                                    .trade_representative_last_name
                            }
                            dirty={
                                validation.dirty.trade_representative_last_name
                            }
                            value={
                                contactDetails.trade_representative_last_name
                            }
                            previousValue={diff.trade_representative_last_name}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
            <Section id="trade-representative-contact-information-address">
                <Row>
                    <FieldRow
                        id="trade_representative_address"
                        label="Address"
                        required={
                            contactDetails.display_trade_representative_for_korean_store
                        }>
                        <TextField
                            name="trade_representative_address"
                            md={5}
                            error={
                                validation.errors.trade_representative_address
                            }
                            progress={
                                validation.isLoading
                                    .trade_representative_address
                            }
                            dirty={
                                validation.dirty.trade_representative_address
                            }
                            value={contactDetails.trade_representative_address}
                            previousValue={diff.trade_representative_address}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="trade_representative_address2"
                        label="Apartment, suite, building">
                        <TextField
                            name="trade_representative_address2"
                            md={5}
                            error={
                                validation.errors.trade_representative_address2
                            }
                            progress={
                                validation.isLoading
                                    .trade_representative_address2
                            }
                            dirty={
                                validation.dirty.trade_representative_address2
                            }
                            value={contactDetails.trade_representative_address2}
                            previousValue={diff.trade_representative_address2}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="trade_representative_city"
                        label="City"
                        required={
                            contactDetails.display_trade_representative_for_korean_store
                        }>
                        <TextField
                            name="trade_representative_city"
                            md={5}
                            error={validation.errors.trade_representative_city}
                            progress={
                                validation.isLoading.trade_representative_city
                            }
                            dirty={validation.dirty.trade_representative_city}
                            value={contactDetails.trade_representative_city}
                            previousValue={diff.trade_representative_city}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow id="trade_representative_state" label="State">
                        <TextField
                            name="trade_representative_state"
                            md={5}
                            error={validation.errors.trade_representative_state}
                            progress={
                                validation.isLoading.trade_representative_state
                            }
                            dirty={validation.dirty.trade_representative_state}
                            value={contactDetails.trade_representative_state}
                            previousValue={diff.trade_representative_state}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="trade_representative_postal_code"
                        label="Postal code"
                        required={
                            contactDetails.display_trade_representative_for_korean_store
                        }>
                        <TextField
                            name="trade_representative_postal_code"
                            md={5}
                            error={
                                validation.errors
                                    .trade_representative_postal_code
                            }
                            progress={
                                validation.isLoading
                                    .trade_representative_postal_code
                            }
                            dirty={
                                validation.dirty
                                    .trade_representative_postal_code
                            }
                            value={
                                contactDetails.trade_representative_postal_code
                            }
                            previousValue={
                                diff.trade_representative_postal_code
                            }
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="trade_representative_country"
                        label="Country"
                        required={
                            contactDetails.display_trade_representative_for_korean_store
                        }>
                        <SelectField
                            md={5}
                            name="trade_representative_country"
                            options={countryListOptions}
                            error={
                                validation.errors.trade_representative_country
                            }
                            progress={
                                validation.isLoading
                                    .trade_representative_country
                            }
                            dirty={
                                validation.dirty.trade_representative_country
                            }
                            value={
                                contactDetails.trade_representative_country &&
                                contactDetails.trade_representative_country.code
                            }
                            previousValue={diff.trade_representative_country}
                            readonly={readonly}
                            onChange={event =>
                                props.handleChangeSelect(
                                    event,
                                    contactDetails.options
                                        .trade_representative_country,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
            <Section id="trade-representative-contact-information-contact">
                <Row>
                    <FieldRow
                        id="trade_representative_phone"
                        label="Phone"
                        required={
                            contactDetails.display_trade_representative_for_korean_store
                        }>
                        <TextField
                            name="trade_representative_phone"
                            md={5}
                            error={validation.errors.trade_representative_phone}
                            progress={
                                validation.isLoading.trade_representative_phone
                            }
                            dirty={validation.dirty.trade_representative_phone}
                            value={contactDetails.trade_representative_phone}
                            previousValue={diff.trade_representative_phone}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="trade_representative_email"
                        label="E-mail"
                        required={
                            contactDetails.display_trade_representative_for_korean_store
                        }>
                        <TextField
                            name="trade_representative_email"
                            md={5}
                            error={validation.errors.trade_representative_email}
                            progress={
                                validation.isLoading.trade_representative_email
                            }
                            dirty={validation.dirty.trade_representative_email}
                            value={contactDetails.trade_representative_email}
                            previousValue={diff.trade_representative_email}
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
        </Grid>
    );
};

export default ContactForm;
