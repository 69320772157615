import React from 'react';
import {Grid, Row} from 'react-bootstrap';
import Section from '../layout/Section.jsx';
import FieldRow from '../layout/FieldRow.jsx';
import MultiRadioField, {BooleanOptions} from '../inputs/MultiRadioField.jsx';
import MultiSelectField from '../inputs/MultiSelectField.jsx';
import Header from '../Header.jsx';
import {
  mapValuesToOptions, customOptionsRenderer, financialFeaturesList,
} from '../../util/formatters';


const GooglePlayAdvertisingId = ({
                                   advertisingId,
                                   validation,
                                   readonly,
                                   diff,
                                   ...props
                                 }) => {
  const mapAdvertisingIdUsageToOptions = mapValuesToOptions('id')(advertisingId.options.advertising_id_usage);
  return (
    <Grid fluid>
      <Header>Advertising ID</Header>
      <Section id="android_13" title="Android 13 (API 33) introduces changes to advertising ID">
        <p className={"advertisingIdTypography"}>
          Apps that use advertising ID and target Android 13 or later must declare the
          com.google.android.gms.permission.AD_ID permission in
          their app manifest. If you don't include this permission, your advertising identifier will be zeroed out, any
          attempts to access the
          identifier will receive a string of zeros instead of the identifier.
          <a target="_blank" href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en">Learn more</a>
        </p>
      </Section>
      <Section id="safeguards" title="We'll use this declaration to provide safeguards in Play Console">
        <p className={"advertisingIdTypography"}>
          If you say that your app uses advertising ID, we will block releases that don't include the
          com.google.android.gms.permission.AD_ID permission in the manifest file when targeting Android 13. When we
          block these releases, we will remind you to add the permission. If your release doesn't need advertising ID,
          you'll be able to skip the error and release. You can also update the declaration to turn off advertising ID
          release errors.
        </p>
      </Section>
      <Section id="declaration" title="Declaration">
        <p className={"advertisingIdTypography"}>
          You can't rollout releases with artifacts targeting Android 13 until you have completed this declaration.
        </p>
      </Section>
      <Section id="google-play-advertising-id" title="Does Your app use advertising ID?">
        <Row>
          <p className={"advertisingIdTypography"}>
            This includes any SDKs that your app imports that use advertising ID
          </p>
          <FieldRow
            id="uses_advertising_id"
            label="Does Your app use advertising ID?">
            <MultiRadioField
              id="uses_advertising_id"
              name="uses_advertising_id"
              error={validation.errors.uses_advertising_id}
              progress={validation.isLoading.uses_advertising_id}
              dirty={validation.dirty.uses_advertising_id}
              selected={advertisingId.uses_advertising_id}
              readonly={readonly}
              onChange={event => {
                props.handleChangeRadio(
                  event,
                  BooleanOptions,
                  props.csrf_token,
                  props.app_version
                )
              }
              }
              options={BooleanOptions}
            />
          </FieldRow>
        </Row>
        {
          advertisingId.uses_advertising_id &&
          <Row className={"advertisingIdMarginBottom"}>
            <p className="advertisingIdTypography">
              When you answer this question, make sure to verify if any third-party SDK code in your app uses
              advertising
              ID. If so, you must declare that your app uses it. Some SDKs, such as the Google Mobile Ads SDK (Play
              Services-ads) may already declare the com.google.android.gms.permission.AD_ID permission in the SDK's
              library
              manifest. If your app uses these SDKs as dependencies, the com.google.android.gms.permission.AD_ID
              permission
              from the SDK's library manifest will be merged with your app's main manifest by default, even if you don't
              explicitly declare the permission in your app's main manifest.
              <a target="_blank" href="https://developer.android.com/build/manage-manifests#merge-manifests">Learn
                more</a>
            </p>
            <p className={"advertisingIdTypography"}>
              <strong>Why does your app need to use advertising ID? This includes any SDKs your app imports that use
                advertising IDs.</strong>
            </p>
            <FieldRow
              id="advertising_id_usage"
              label="Select all that apply">
              <MultiSelectField
                name="advertising_id_usage"
                label="Why does your app need to use advertising ID? This includes any SDKs your app imports that use advertising IDs."
                md={9}
                options={financialFeaturesList(advertisingId.options.advertising_id_usage)}
                error={validation.errors?.advertising_id_usage}
                progress={validation.isLoading?.advertising_id_usage}
                dirty={validation.dirty?.advertising_id_usage}
                readonly={readonly}
                previousValue={diff?.advertising_id_usage}
                onMultiChange={value =>
                  props.handleChangeMultiSelect(
                    value.map(mapAdvertisingIdUsageToOptions),
                    'id',
                    'advertising_id_usage',
                    props.csrf_token,
                    props.app_version
                  )
                }
                selected={financialFeaturesList(advertisingId.advertising_id_usage)}
                selectAllLabel={'All options'}
                disableSearch={true}
                valueRenderer={customOptionsRenderer}
                disabled={!advertisingId.uses_advertising_id}
              />
            </FieldRow>
          </Row>
        }
      </Section>
    </Grid>
  );
};

export default GooglePlayAdvertisingId;
