import React from 'react';
import axios from 'axios';
import UserDeleteModal from './UserDeleteModal';
import DeleteUserButton from './DeleteUserButton';

export default class DeleteUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false,
            showDeleteButton: false,
            disableDeleteButton: props.isOwnProfile,
        };
    }

    componentWillMount() {
        axios.get('/api/v1/permissions').then(permissionsResponse =>
            this.setState({
                showDeleteButton: permissionsResponse.data.can_delete_user,
            })
        );
    }

    deleteUser() {
        axios.delete(this.props.endpoint);
    }

    confirmAction() {
        this.deleteUser();
        this.closeModal();
        this.redirect();
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    openModal() {
        this.setState({ showModal: true });
    }

    redirect() {
        window.location.href = this.props.redirectUrl;
    }

    render() {
        return (
            <span
                className={this.state.disableDeleteButton ? 'btn-disabled' : ''}>
                <UserDeleteModal
                    closeModal={() => this.closeModal()}
                    confirmAction={() => this.confirmAction()}
                    showModal={this.state.showModal}
                />
                {this.state.showDeleteButton && (
                    <DeleteUserButton
                        openModal={() => this.openModal()}
                        disableDeleteButton={this.state.disableDeleteButton}
                    />
                )}
            </span>
        );
    }
}
