import React, {useEffect, useState} from 'react';
import compareRows from '../Utils/compareRows.jsx';
import AppListRow from './AppListRow.jsx';
import { forceCheck } from 'react-lazyload';

/** This component returns a sorted list of Apps. If you click twice, the sorting will be reversed. **/
const SortedAppRows = ({ apps, headers, sorting, selectedApplications, setSelectedAppsRoot }) => {
    //Presort according to id
    sorting = sorting.sortBy
        ? sorting
        : {
            sortBy: 'id',
            inverse: -1,
        };
    setTimeout(() => {
        forceCheck();
    }, 0);
    return apps
        .sort(compareRows(sorting))
        .map((app) => <AppListRow key={app.id} headers={headers} app={app} selectedApps={selectedApplications} setSelectedApps={setSelectedAppsRoot} />);
};

export default SortedAppRows;
