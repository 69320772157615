import React from 'react';
import AirWatchPublishingSettings from './AirWatchPublishingSettings.jsx';

export default class AirWatchPublishingSettingsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publishingSettings: props.publishingSettings,
        };
        this.onPushModeChanged = this.onPushModeChanged.bind(this);
        this.onAutoUpdateVersionChanged = this.onAutoUpdateVersionChanged.bind(
            this
        );
    }

    onPushModeChanged(targetId) {
        return function(event) {
            const publishingSettings = this.state.publishingSettings;
            publishingSettings[targetId].pushMode = event.target.value;
            this.setState({
                publishingSettings: publishingSettings,
            });
        };
    }

    onAutoUpdateVersionChanged(targetId) {
        return function(event) {
            const publishingSettings = this.state.publishingSettings;
            publishingSettings[targetId].autoUpdateVersion = event.target.value;
            this.setState({
                publishingSettings: publishingSettings,
            });
        };
    }

    render() {
        return Object.keys(this.state.publishingSettings).map(targetId => (
            <AirWatchPublishingSettings
                key={targetId}
                appName={this.props.appName}
                targetName={this.state.publishingSettings[targetId].targetName}
                selectOGsInPublishModal={
                    this.state.publishingSettings[targetId]
                        .selectOGsInPublishModal
                }
                selectOGsInPublishModalToggleUrl={
                    this.state.publishingSettings[targetId]
                        .selectOGsInPublishModalToggleUrl
                }
                autoPublishDataUrl={
                    this.state.publishingSettings[targetId].autoPublishDataUrl
                }
                autoPublishChecked={
                    this.state.publishingSettings[targetId].autoPublishChecked
                }
                endpoint={this.state.publishingSettings[targetId].endpoint}
            />
        ));
    }
}
