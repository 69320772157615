import React from 'react';
import ErrorFeedback from './ErrorFeedback.jsx';
import ProgressFeedback from './ProgressFeedback.jsx';
import SuccessFeedback from './SuccessFeedback.jsx';

const detachedStyle = {
    position: 'absolute',
    top: 0,
    right: -16,
};

export default function FieldFeedback({
    error,
    progress,
    dirty,
    detached,
    style,
    id,
}) {
    const feedbackStyle = detached ? { ...detachedStyle, ...style } : style;
    if (progress) {
        return <ProgressFeedback style={feedbackStyle} />;
    }
    else if (error) {
        return (
            <ErrorFeedback
                message={error.message || error}
                style={feedbackStyle}
                id={id}
            />
        );
    } else if (dirty) {
        // TBD: disable success feedback
        // return <SuccessFeedback style={feedbackStyle} />;
        return null;
    }
    return null;
}
