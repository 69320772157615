import React from 'react';
import { connect } from 'react-redux';
import { activateTab } from '../../redux/modules/appVersionOverview/appVersionTabs';
import {
    getDeviceInstallationDataEndpoint,
    initializeDeviceInstallationData,
} from '../../redux/modules/appVersionOverview/deviceInstallationData';

import { DEVICE_INSTALLATION_TAB } from './constants';

const mapStateToProps = store => {
    return {
        deviceInstallationEndpoint: getDeviceInstallationDataEndpoint(store),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        activateTab: tab => dispatch(activateTab(tab)),
        initializeDeviceInstallationData: endpoint =>
            dispatch(initializeDeviceInstallationData(endpoint)),
    };
};

const MobileIronDeviceInstallationTab = ({
    activateTab,
    initializeDeviceInstallationData,
    deviceInstallationEndpoint,
}) => {
    return (
        // Eventually we should move all the Tabs to reac and use "Tab" from react Bootstrap
        <a
            className="tab-link"
            data-target="#installation-status"
            data-toggle="tab"
            onClick={() => {
                activateTab(DEVICE_INSTALLATION_TAB);
                initializeDeviceInstallationData(deviceInstallationEndpoint);
            }}>
            Installation Status
        </a>
    );
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MobileIronDeviceInstallationTab);
