import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Grid, Row } from 'react-bootstrap';
import Section from '../layout/Section.jsx';
import FieldRow from '../layout/FieldRow.jsx';
import ReadOnlyValue from '../inputs/ReadOnlyValue.jsx';
import Header from '../Header.jsx';
import { renderBoolean } from '../../util/formatters';
import UploadBinaryButton from '../UploadBinaryButton';

const GooglePlaySpecificationForm = ({
    acl,
    specification,
    collect,
    readonly,
    versionStatus
}) => {
    return (
        <Grid fluid>
            <Header>Binary Metadata</Header>
            <Section id="general-specification">
                <Row>
                    <FieldRow id="bundle-id" label="Bundle Identifier">
                        <ReadOnlyValue>
                            {specification.general.bundle_id}
                        </ReadOnlyValue>
                    </FieldRow>
                    {collect.business_unit && (
                        <FieldRow id="business-unit" label="Business Unit">
                            <ReadOnlyValue>
                                {specification.general.business_unit}
                            </ReadOnlyValue>
                        </FieldRow>
                    )}
                    {collect.environment && (
                        <FieldRow id="environment" label="Environment">
                            <ReadOnlyValue>
                                {specification.general.environment}
                            </ReadOnlyValue>
                        </FieldRow>
                    )}
                    {specification.binary_metadata.is_copied && (
                        <FieldRow label="Cloned from">
                            <ReadOnlyValue>
                                Version{' '}
                                {
                                    specification.binary_metadata
                                        .original_version_number
                                }{' '}
                                (
                                {
                                    specification.binary_metadata
                                        .original_build_number
                                }
                                )
                            </ReadOnlyValue>
                        </FieldRow>
                    )}
                </Row>
            </Section>
            <Section id="binary-data" title="Binary metadata">
                {!readonly && acl.upload_binary_permitted &&
                    <UploadBinaryButton
                        versionStatus={versionStatus}
                        specification={specification}
                    />
                }
                <Row>
                    <FieldRow id="build-number" label="Build number">
                        <ReadOnlyValue>
                            {specification.binary_metadata.build_number}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow id="minimum-api-level" label="Minimum API level">
                        <ReadOnlyValue>
                            {specification.binary_metadata.min_os_version}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow id="target-api-level" label="Target API level">
                        <ReadOnlyValue>
                            {specification.binary_metadata.target_api_level}
                        </ReadOnlyValue>
                    </FieldRow>
                </Row>
            </Section>
            <Section id="device-support">
                <Row>
                    <FieldRow
                        id="designed-for-phones"
                        label="Designed for phones">
                        <ReadOnlyValue>
                            {renderBoolean(
                                specification.binary_metadata.phones_support
                            )}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="designed-for-7-tablets"
                        label='Designed for 7" Tablets'>
                        <ReadOnlyValue>
                            {renderBoolean(
                                specification.binary_metadata[
                                    '7in_tablets_support'
                                ]
                            )}
                        </ReadOnlyValue>
                    </FieldRow>
                    <FieldRow
                        id="designed-for-10-tablets"
                        label='Designed for 10" Tablets'>
                        <ReadOnlyValue>
                            {renderBoolean(
                                specification.binary_metadata[
                                    '10in_tablets_support'
                                ]
                            )}
                        </ReadOnlyValue>
                    </FieldRow>
                </Row>
            </Section>
        </Grid>
    );
};

const select = store => ({
    acl: store.context.acl,
    specification: store.context.specification,
    collect: store.context.collect,
    versionStatus: store.context.version_status
});

export default connect(select)(GooglePlaySpecificationForm);
