export function getNumberOfDaysToExpiryText(numberOfDaysToExpiry) {
    switch (true) {
        case numberOfDaysToExpiry === null ||
            numberOfDaysToExpiry === undefined:
            return '';
        case numberOfDaysToExpiry <= 0:
            return 'Expired';
        case numberOfDaysToExpiry > 365:
            return '> 1 Year';
        case numberOfDaysToExpiry > 1:
            return `${numberOfDaysToExpiry} days`;
        case numberOfDaysToExpiry === 1:
            return '1 day';
        default:
            return 'None';
    }
}
