import React from 'react';
import { Glyphicon } from 'react-bootstrap';

//TODO: deprecated, change to AssetAdd component
export default function AddScreenshot({ style }) {
    return (
        <div className="screenshot-dropzone-screenshot-container">
            <div className="screenshot-dropzone-inside-box" style={style}>
                <Glyphicon
                    glyph="glyphicon glyphicon-plus"
                    bsClass="glyphicon glyphicon-plus dropzone-glyphicon"
                />
            </div>
        </div>
    );
}
