import React from 'react';

export default function Video({ color = '#454545', ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="17"
            viewBox="0 0 37 34"
            {...props}>
            <path
                fill={color}
                fillRule="evenodd"
                d="M.443.621h36v33h-36v-33zm3 6h3v-3h-3v3zm0 6h3v-3h-3v3zm0 6h3v-3h-3v3zm0 6h3v-3h-3v3zm0 6h3v-3h-3v3zm6-15h18v-12h-18v12zm0 15h18v-12h-18v12zm21-24h3v-3h-3v3zm0 6h3v-3h-3v3zm0 6h3v-3h-3v3zm0 6h3v-3h-3v3zm0 6h3v-3h-3v3z"
            />
        </svg>
    );
}
