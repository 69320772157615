import React from 'react';

const XcodeNotification = () => {
  return (
    <div className="submission-information-info-box export-compliance-grid">
      <i className="glyphicon glyphicon-exclamation-sign submission-information-exclamation"/>
      <p className="submission-information-text">
        <span>
          It is your responsibility to comply with export regulations, and
          you should revisit these questions if your encryption or exemption
          status changes. If your encryption and exemption eligibility
          stay the same, specify this in the target properties table in Xcode.&nbsp;
          <a href="https://help.apple.com/xcode/mac/current/#/dev0dc15d044" target="_blank">Learn More</a>
        </span>
        <br/>
        <br/>
        <p>
          <strong>App Uses Non-Exempt Encryption : No</strong>
        </p>
      </p>

    </div>
  );
};

export default XcodeNotification;
