import React from 'react';
import PublishToVmWareAirWatchModal from './PublishToVmWareAirWatchModal.jsx';

export default class PublishToVmWareAirWatchModalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            publishInProgress: false,
            targetData: this.props.target_data,
        };
    }

    publishStarted = () => {
        this.setState({
            publishInProgress: true,
        });
    };

    render() {
        return Object.keys(this.props.target_data).map(targetId => (
            <PublishToVmWareAirWatchModal
                target_id={targetId}
                key={targetId}
                publishInProgress={this.state.publishInProgress}
                version={this.props.version}
                build={this.props.build}
                platform={this.props.platform}
                app_name={this.props.app_name}
                target_name={this.state.targetData[targetId].target_name}
                endpoint={this.state.targetData[targetId].endpoint}
                publish_endpoint={
                    this.state.targetData[targetId].publish_endpoint
                }
                selectOrganizationalGroupsWhenPublishing={
                    this.state.targetData[targetId]
                        .select_organizational_groups_in_publish_modal
                }
                publishStarted={this.publishStarted}
            />
        ));
    }
}
