import React from 'react';
import { Row, Col, ControlLabel } from 'react-bootstrap';
import DropzoneField from './DropzoneField.jsx';
import Asterisk from '../Asterisk';
import HelpTooltip from '../HelpTooltip';

const CustomDropzone = ({
    label,
    description,
    required,
    metadata,
    validation,
    fieldName,
    csrf_token,
    app_version,
    onChange,
    onDelete,
    firstRow,
    languageCode,
    expectedWidth,
    expectedHeight,
    readonly,
    help,
    customContainerWidth
}) => {
    return (
        <div
            style={
                firstRow
                    ? {
                        display: 'inline-block',
                        verticalAlign: 'top',
                        marginBottom: '15px',
                        width: customContainerWidth ? `${customContainerWidth}px` : 'initial'
                    }
                    : {
                        display: 'inline-block',
                        verticalAlign: 'top',
                        width: customContainerWidth ? `${customContainerWidth}px` : 'initial'
                    }
            }>
            <Row>
                <Col md={12}>
                    <ControlLabel>
                        {required ? <Asterisk>{label}</Asterisk> : label}
                        {help && <HelpTooltip message={help}/> }
                    </ControlLabel>
                    {description && (
                        <span className="form-group-label-description">
                            {description}
                        </span>
                    )}
                </Col>
            </Row>
            <div style={{ marginTop: '18px' }}>
                <Row>
                    <DropzoneField
                        id={fieldName}
                        initialFile={metadata[fieldName]}
                        expectedWidth={expectedWidth}
                        expectedHeight={expectedHeight}
                        accept="image/png, image/jpeg"
                        readonly={readonly}
                        error={
                            validation.errors[languageCode] &&
                            validation.errors[languageCode][fieldName]
                        }
                        progress={
                            validation.isLoading[languageCode] &&
                            validation.isLoading[languageCode][fieldName]
                        }
                        dirty={
                            validation.dirty[languageCode] &&
                            validation.dirty[languageCode][fieldName]
                        }
                        onChange={file =>
                            onChange(
                                fieldName,
                                file,
                                csrf_token,
                                app_version,
                                languageCode
                            )
                        }
                        onDelete={() =>
                            onDelete(
                                fieldName,
                                csrf_token,
                                app_version,
                                languageCode
                            )
                        }
                    />
                </Row>
            </div>
        </div>
    );
};

export default CustomDropzone;
