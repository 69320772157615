import { connect } from 'react-redux';
import axios from 'axios';
import DetailsForm from '../components/tabs/DetailsForm.jsx';
import { updateContext } from '../actions/addContextToRedux';
import { changeTextInput, changeSelectInput } from '../actions/form.js';
import {
    setDirty,
    setError,
    setCorrect,
    isLoading,
    isNotLoading,
} from '../actions/validation.js';
import { previousValuesSelector } from '../selectors/diff';

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    details: store.context.app_details,
    diff: previousValuesSelector(store),
    validation: store.validation,
    csrf_token: store.context.csrf_token,
    app_version: store.context.app_version,
});

const mapDispatchToProps = dispatch => ({
    handleChangeTextInput: event => {
        dispatch(
            changeTextInput(
                event.target.value,
                event.target.name,
                'app_details'
            )
        );
    },
    handleBlurTextInput: (event, csrf_token, app_version) => {
        event.persist();
        let name = event.target.name;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [name]: event.target.value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleChangeSelect: (event, options, csrf_token, app_version) => {
        let selectValue = options.filter(
            obj => obj.id == event.target.value
        )[0];
        dispatch(
            changeSelectInput(selectValue, event.target.name, 'app_details')
        );
        let name = event.target.name;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [event.target.name]: event.target.value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },

    handleChangeMultiSelect: (
        value,
        property,
        fieldName,
        csrf_token,
        app_version
    ) => {
        dispatch(changeSelectInput(value, fieldName, 'app_details'));
        dispatch(setDirty(fieldName));
        dispatch(isLoading(fieldName));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [fieldName]: value.map(item => item[property]) },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(fieldName));
                dispatch(setCorrect(fieldName));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(fieldName));
            });
    },

    handleChangeCheckbox: (event, csrf_token, app_version) => {
        let value = event.target.checked;
        let fieldName = event.target.name;
        dispatch(changeTextInput(value, fieldName, 'app_details'));
        let name = event.target.name;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [fieldName]: value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(fieldName));
                dispatch(setCorrect(fieldName));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(fieldName));
            });
    },
    handleChangeFile: (key, file, csrf_token, app_version) => {
        const formData = new FormData();
        formData.append(key, file);
        let name = key;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .post(
                `/api/v1/versions/${app_version}/draft/app-details`,
                formData,
                {
                    'X-CSRFToken': csrf_token,
                }
            )
            .then(response => {
                dispatch(updateContext('app_details', response.data));
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
    handleDeleteFile: (key, csrf_token, app_version) => {
        let name = key;
        dispatch(setDirty(name));
        dispatch(isLoading(name));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/app-details`,
                { [key]: null },
                {
                    'X-CSRFToken': csrf_token,
                }
            )
            .then(response => {
                dispatch(updateContext('app_details', response.data));
                dispatch(isNotLoading(name));
                dispatch(setCorrect(name));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(name));
            });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailsForm);
