import React from 'react';
import FieldRow from '../../../layout/FieldRow';
import MultiRadioField, {
    mapToNumericBooleanOptions,
    numericBooleanOptions,
} from '../../../inputs/MultiRadioField';
import GovernmentNotification from './GovernmentNotification';
import XcodeNotification from './XcodeNotification';
import { noIsSelected } from '../ResetOptions';

const handleButtonToggle = (event, props) => {
    const reset = [
        'is_exempt',
        'contains_proprietary_cryptography',
        'contains_third_party_cryptography',
        'available_on_french_store',
    ];
    props.handleChangeRadio(event, null, props.csrf_token, props.app_version, reset);
    reset.map(name => props.nullifyOption(`export_compliance_${name}`));
};

const UsesEncryption = ({ validation, reviewInformation, readonly, props }) => {
    return (
        <FieldRow label="Export Compliance" required>
            <div>
                Does your app use encryption? Select Yes even if your app only
                uses the standard encryption within Apple’s operating system.
            </div>
            <MultiRadioField
                name="export_compliance_uses_encryption"
                id="export-compliance-uses-encryption"
                error={validation.errors.export_compliance_uses_encryption}
                progress={
                    validation.isLoading.export_compliance_uses_encryption
                }
                dirty={validation.dirty.export_compliance_uses_encryption}
                selected={mapToNumericBooleanOptions(
                    reviewInformation.export_compliance_uses_encryption
                )}
                readonly={readonly}
                onChange={event => handleButtonToggle(event, props)}
                options={numericBooleanOptions}
            />
            <span>
              Export laws require that products containing encryption must
              be properly authorized for export. Failure to comply could result
              in severe penalties.
            </span>
            {noIsSelected(reviewInformation.export_compliance_uses_encryption)
              && <><XcodeNotification/><GovernmentNotification/></>}
        </FieldRow>
    );
};

export default UsesEncryption;
