import React from 'react';
import DeviceInstallationsChart from './DeviceInstallationsChart.jsx';
import { connect } from 'react-redux';
import { Row } from 'react-bootstrap';
import {
    getDeviceInstallationDataEndpoint,
    getDeviceInstallationStatus,
    initializeDeviceInstallationData,
} from '../../redux/modules/appVersionOverview/deviceInstallationData';
import SpinnerOrReinitialize from './SpinnerOrReinitialize';

const mapStateToProps = store => {
    return {
        deviceInstallationData: store.appVersion.deviceInstallationData,
        deviceInstallationEndpoint: getDeviceInstallationDataEndpoint(store),
        deviceInstallationStatus: getDeviceInstallationStatus(store),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        initializeDeviceInstallationData: endpoint =>
            dispatch(initializeDeviceInstallationData(endpoint)),
    };
};

const DeviceInstallationsContainer = ({
    deviceInstallationData,
    initializeDeviceInstallationData,
    deviceInstallationEndpoint,
    deviceInstallationStatus,
}) => (
    <div>
        {Object.keys(deviceInstallationData).map(target => (
            <Row key={target}>
                <h3>{target}</h3>
                {deviceInstallationData[target].meta['total_installations'] >
                0 ? (
                    <DeviceInstallationsChart
                        versions={deviceInstallationData[target].data}
                    />
                ) : (
                    <p>No installations yet for this target</p>
                )}
            </Row>
        ))}
        <SpinnerOrReinitialize
            initializeDeviceInstallationData={initializeDeviceInstallationData}
            deviceInstallationEndpoint={deviceInstallationEndpoint}
            deviceInstallationStatus={deviceInstallationStatus}
        />
    </div>
);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeviceInstallationsContainer);
