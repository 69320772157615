import AdditionalInfoForm from '../components/tabs/AdditionalInfoForm.jsx';
import { connect } from 'react-redux';
import { updateValue, syncValue } from '../../../redux/modules/customField';

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    fields: store.customField.fields,
    values: store.customField.values,
    previousValues: store.customField.previousValues,
    errors: store.customField.errors,
    progress: store.customField.progress,
    dirty: store.customField.dirty,
    showDiff: store.diff.showDiff,
});

const mapDispatchToProps = {
    updateValue,
    syncValue,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdditionalInfoForm);
