import { connect } from 'react-redux';
import VersionDraftForm from '../components/VersionDraftForm.jsx';

const mapStateToProps = state => {
    return {
        context: state.context,
        draftFormType: 'google-play',
    };
};

export default connect(mapStateToProps)(VersionDraftForm);
