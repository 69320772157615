import React from 'react';

const Checkbox = ({ id, value, checked, changeInput, checkboxText }) => {
    return (
        <label>
            <input
                id={id}
                value={value}
                checked={checked}
                type="checkbox"
                onChange={changeInput}
            />
            <div className="generic-checkbox-text"> {checkboxText} </div>
        </label>
    );
};

export default Checkbox;
