import React from 'react';

export default function RadioField({
    name,
    label,
    checked,
    onChange,
    value,
    id,
    boldLabel,
    disabled,
}) {
    let htmlFor = `${name}_${label}_${id || ''}`;
    return (
        <div className="radio">
            <input
                id={htmlFor}
                name={name}
                title=""
                type="radio"
                checked={checked}
                onChange={onChange}
                value={value}
                disabled={disabled}
            />
            <label htmlFor={htmlFor}>
                {boldLabel ? <b>{label}</b> : label}
            </label>
        </div>
    );
}
