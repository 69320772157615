import React from 'react';
import getJSON from '../Utils/getJSON.jsx';
import Spinner from '../Common/Spinner.jsx';

export default class AppVersionUploadButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            uploading_to_target: true,
        };
        this.uploading_interval = setTimeout(
            this.uploadStatusCheck,
            20000
        );
    }

    uploadStatusCheck = async () => {
        const data = await getJSON(
            `/api/v1/app-version-uploading-status?version=${this.props.appVersionID}`
        );
        // Once the App has finished uploading, we reload the page this will remove the uploading spinner
        if (data.uploading_to_target) {
            this.setState(data);
            this.uploading_interval = setTimeout(
                this.uploadStatusCheck,
                20000
            );
        } else {
            location.reload();
        }
    };

    render() {
        return (
            <li>
                <button
                    type="button"
                    disabled="disabled"
                    className="btn btn-primary">
                    <Spinner className="uploading-to-target-spinner" />
                    <div className="target-upload-spinner">Uploading</div>
                </button>
            </li>
        );
    }
}
