import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const UserRow = ({data, headers, canEditProfile}) => {
    const onClick = canEditProfile ? () => (location = data['userUrl']) : null;
    const rowStyle = canEditProfile ? {cursor: 'pointer'} : {};
    return (
      <tr onClick={onClick} style={rowStyle}>
        {headers.map((header, i) => {
          return (
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip
                  placement="bottom">
                  {header.label === 'Last Login' ?
                    header.transformation(data[header.value]) :
                    data[header.value]}
                </Tooltip>
              }>
              <td key={i}>
                {header.transformation
                  ? header.transformation(data[header.value])
                  : data[header.value]}

                {header.value === 'name' && !data['emailConfirmed'] && (
                  <span className="text-danger email-not-confirmed">
                                (email address not confirmed)
                </span>
                )}
              </td>
            </OverlayTrigger>
          );
        })}
      </tr>
    );
  }
;

export default UserRow;
