import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../redux/configureStore';
import AddDjangoContextToRedux from '../AppDraft/containers/AddDjangoContextToRedux.jsx';
import AppOverview from './components/AppOverview.jsx';

const Index = props => {
    return (
        <Provider store={store}>
            <AddDjangoContextToRedux context={props.context}>
                <AppOverview draftFormType={props.draftFormType} />
            </AddDjangoContextToRedux>
        </Provider>
    );
};

export default Index;
