import React, { PureComponent } from 'react';
import { Row, Col } from 'react-bootstrap';

export default class Section extends PureComponent {
    render() {
        const {
            id,
            title,
            buttonComponent,
            buttonComponentBelow,
            headerComponent,
            children,
            md = 3,
            additionalClassName = '',
        } = this.props;
        return (
            <section id={id} className="form-section">
                {title && (
                    <Row
                        className={buttonComponentBelow ? '' : 'section-header'}>
                        <Row>
                            <Col md={buttonComponent ? md : 12}>
                                <h3>{title}</h3>
                                {headerComponent}
                            </Col>
                            {buttonComponent && (
                                <Col md={md ? 12 - md : 9}>
                                    {buttonComponent}
                                </Col>
                            )}
                            {buttonComponentBelow && (
                                <Col md={12}>{buttonComponentBelow}</Col>
                            )}
                        </Row>
                    </Row>
                )}
                <div className={`section-content ${additionalClassName}`}>
                    {children}
                </div>
            </section>
        );
    }
}
