import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Spinner from '../../../Common/Spinner';

export default function DeleteRecipientConfirmModal({
    showModal,
    closeModal,
    confirmDelete,
    isDeleting,
    error
}) {

    return (
        <Modal
            show={showModal}
            onClose={closeModal(false)}
            bsSize="small">
            <Modal.Header closeButton onHide={closeModal(false)}>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            {error &&
            <Modal.Body>
                {error}
            </Modal.Body>}
            <Modal.Footer className='modal-footer-center'>
                {isDeleting ?
                    <Spinner className='spinner-small' removeMarginTop/>:
                    <>
                        <Button
                            bsStyle="primary"
                            onClick={closeModal(false)}>
                            Cancel
                        </Button>
                        <Button
                            bsStyle="danger"
                            onClick={confirmDelete}>
                            Delete
                        </Button>
                    </>
                }
            </Modal.Footer>
        </Modal>
    );
}