import { connect } from 'react-redux';
import axios from 'axios';
import GooglePlayLocalizableForm from '../components/tabs/GooglePlayLocalizableForm.jsx';
import { updateContext } from '../actions/addContextToRedux';
import { previousValueListSelector } from '../selectors/diff.js';
import {
    changeLocalizableTextInput,
    changeLocalizableMetadata,
} from '../actions/form.js';
import {
    setDirty,
    setError,
    setCorrect,
    isLoading,
    isNotLoading,
} from '../actions/validation.js';
import * as Promises from '../util/Promises';

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    localizable: store.context.localizable_metadata,
    diff: previousValueListSelector(store),
    validation: store.validation,
    csrf_token: store.context.csrf_token,
    app_version: store.context.app_version,
    app_id: store.context.app_id,
});

const handleChangeScreenshot = (
    inputName,
    file,
    app_version,
    languageCode
) => {
    const formData = new FormData();
    formData.append(inputName, file);
    return axios
        .post(
            `/api/v1/versions/${app_version}/draft/localizable-metadata/${languageCode}`,
            formData,
        )
        .then(response => ({
            success: response.data,
        }))
        .catch(error => ({
            error: error.response.data.error,
        }));
};

const mapDispatchToProps = dispatch => ({
    handleChangeMultiSelect: (value, fieldName, csrf_token, app_version) => {
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                { [fieldName]: value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(changeLocalizableMetadata(response.data));
            })
            .catch(error => {
                // dispatch failure
            });
    },

    handleChangeTextInput: (event, languageCode) => {
        dispatch(
            changeLocalizableTextInput(
                event.target.value,
                event.target.name,
                languageCode
            )
        );
    },
    handleBlurTextInput: (event, csrf_token, app_version, languageCode) => {
        let name = event.target.name;
        dispatch(setDirty(name, languageCode));
        dispatch(isLoading(name, languageCode));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                {
                    metadata: {
                        [languageCode]: {
                            [event.target.name]: event.target.value,
                        },
                    },
                },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name, languageCode));
                dispatch(setCorrect(name, languageCode));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText,
                        languageCode
                    )
                );
                dispatch(isNotLoading(name, languageCode));
            });
    },
    handleSetCorrect: (categoryName, languageCode) => {
        dispatch(setCorrect(categoryName, languageCode));
    },
    handleChangeScreenshots: (
        categoryName,
        inputName,
        files,
        app_version,
        languageCode,
        categoryNameForRequiredFields
    ) => {
        dispatch(setDirty(categoryName, languageCode));
        dispatch(isLoading(categoryName, languageCode));
        return Promises.seq(
            files.map(file => () =>
                handleChangeScreenshot(
                    inputName,
                    file,
                    app_version,
                    languageCode
                )
            )
        ).then(results => {
            dispatch(isNotLoading(categoryName, languageCode));
            const successes = results.filter(result => result.success);
            if (successes.length > 0) {
                const last = successes.reverse()[0];
                dispatch(updateContext('localizable_metadata', last.success));
            }
            const failures = results.filter(result => result.error);
            if (failures.length > 0) {
                const errorMessage = new Error(
                    Array.from(new Set(failures.map(fail => fail.error))).join(
                        '\n'
                    )
                );
                if (
                    categoryNameForRequiredFields &&
                    errorMessage.message.startsWith(
                        'Screenshot has bad dimensions'
                    )
                ) {
                    dispatch(
                        setError(
                            categoryNameForRequiredFields,
                            errorMessage,
                            languageCode
                        )
                    );
                } else {
                    dispatch(
                        setError(categoryName, errorMessage, languageCode)
                    );
                }
            } else {
                dispatch(setCorrect(categoryName, languageCode));
                if (categoryNameForRequiredFields) {
                    dispatch(
                        setCorrect(categoryNameForRequiredFields, languageCode)
                    );
                }
            }
        });
    },
    handleDeleteScreenshot: ({
        screenshot_id,
        app_version,
        languageCode,
        categoryName,
    }) => {
        dispatch(setDirty(categoryName, languageCode));
        dispatch(isLoading(categoryName, languageCode));
        axios
            .delete(
                `/api/v1/versions/${app_version}/draft/screenshots/${screenshot_id}`,
            )
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
                dispatch(setCorrect(categoryName, languageCode));
                dispatch(isNotLoading(categoryName, languageCode));
            })
            .catch(error => {
                dispatch(setError(categoryName, error, languageCode));
                dispatch(isNotLoading(categoryName, languageCode));
            });
    },
    handleLoadFromDefaultLanguage: (app_version, languageCode) => {
        return axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                {
                    metadata: {
                        [languageCode]: { copy_from_default: true },
                    },
                }
            )
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
            })
            .catch(error => {
                // dispatch failure
            });
    },
    handleLoadFromDefaultLanguageToOthers: (
        app_version
    ) => {
        return axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                { copy_default_to_all_other: true }
            )
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
            });
    },
    handleScreenshotsError: (categoryName, error, languageCode) =>
        dispatch(setError(categoryName, error, languageCode)),
    handleChangePromoVideo: (file, csrf_token, app_version, languageCode) => {
        dispatch(setDirty('promotion_video_attachment', languageCode));
        dispatch(isLoading('promotion_video_attachment', languageCode));
        const formData = new FormData();
        formData.append('promotion_video_attachment', file);
        return axios
            .post(
                `/api/v1/versions/${app_version}/draft/localizable-metadata/${languageCode}`,
                formData,
                {
                    'X-CSRFToken': csrf_token,
                }
            )
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
                dispatch(
                    setCorrect('promotion_video_attachment', languageCode)
                );
                dispatch(
                    isNotLoading('promotion_video_attachment', languageCode)
                );
            })
            .catch(error => {
                dispatch(
                    setError('promotion_video_attachment', error, languageCode)
                );
                dispatch(
                    isNotLoading('promotion_video_attachment', languageCode)
                );
            });
    },
    handleDeletePromoVideo: (csrf_token, app_version, languageCode) => {
        dispatch(setDirty('promotion_video_attachment'));
        dispatch(isLoading('promotion_video_attachment'));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                {
                    metadata: {
                        [languageCode]: { promotion_video_attachment: null },
                    },
                },
                {
                    'X-CSRFToken': csrf_token,
                }
            )
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
                dispatch(isNotLoading('promotion_video_attachment'));
                dispatch(setCorrect('promotion_video_attachment'));
            })
            .catch(error => {
                dispatch(
                    setError(
                        'promotion_video_attachment',
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading('promotion_video_attachment'));
            });
    },
    handleChangeGraphicAsset: (
        fileName,
        file,
        csrf_token,
        app_version,
        languageCode
    ) => {
        dispatch(setDirty(fileName, languageCode));
        dispatch(isLoading(fileName, languageCode));
        const formData = new FormData();
        formData.append(fileName, file);
        return axios
            .post(
                `/api/v1/versions/${app_version}/draft/localizable-metadata/${languageCode}`,
                formData,
                {
                    'X-CSRFToken': csrf_token,
                }
            )
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
                dispatch(setCorrect(fileName, languageCode));
                dispatch(isNotLoading(fileName, languageCode));
            })
            .catch(error => {
                dispatch(
                    setError(
                        fileName,
                        error.response.data.error || error.response.statusText,
                        languageCode
                    )
                );
                dispatch(isNotLoading(fileName, languageCode));
            });
    },
    handleDeleteGraphicAsset: (
        fileName,
        csrf_token,
        app_version,
        languageCode
    ) => {
        dispatch(setDirty(fileName));
        dispatch(isLoading(fileName));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                {
                    metadata: {
                        [languageCode]: { [fileName]: null },
                    },
                },
                {
                    'X-CSRFToken': csrf_token,
                }
            )
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
                dispatch(isNotLoading(fileName));
                dispatch(setCorrect(fileName));
            })
            .catch(error => {
                dispatch(
                    setError(
                        fileName,
                        error.response.data.error || error.response.statusText
                    )
                );
                dispatch(isNotLoading(fileName));
            });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GooglePlayLocalizableForm);
