import React from 'react';

const PublicErrors = ({ publicErrorText }) => {
    return (
        <h4 className="traceback-info">
            An error occurred:&nbsp;
            <span className="text-danger">{publicErrorText}</span>
        </h4>
    );
};

export default PublicErrors;
