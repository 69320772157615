import React from 'react';

export default function({ color, ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="13"
            viewBox="0 0 36 26"
            {...props}>
            <path
                fill={color}
                fillRule="evenodd"
                d="M17.914 14.511L7.353 25.101.543 18.29 17.943.92l17.34 17.37-6.779 6.811-10.59-10.59"
            />
        </svg>
    );
}
