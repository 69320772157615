import { connect } from 'react-redux';
import axios from 'axios';
import LocalizableForm from '../components/tabs/LocalizableForm.jsx';
import { updateContext } from '../actions/addContextToRedux';
import { previousValueListSelector } from '../selectors/diff.js';
import {
    changeLocalizableTextInput,
    changeLocalizableMetadata,
} from '../actions/form.js';
import {
    setDirty,
    setError,
    setCorrect,
    isLoading,
    isNotLoading,
} from '../actions/validation.js';
import * as Promises from '../util/Promises';

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    localizable: store.context.localizable_metadata,
    diff: previousValueListSelector(store),
    validation: store.validation,
    csrf_token: store.context.csrf_token,
    app_version: store.context.app_version,
    app_id: store.context.app_id,
});

const handleChangeScreenshot = (
    inputName,
    file,
    app_version,
    languageCode
) => {
    const formData = new FormData();
    formData.append(inputName, file);
    return axios
        .post(
            `/api/v1/versions/${app_version}/draft/localizable-metadata/${languageCode}`,
            formData
        )
        .then(response => ({
            success: response.data,
        }))
        .catch(error => ({
            error: error.response.data.error,
        }));
};

//TODO: extend abstract service for AppStoreAssetsFormContainer & LocalizableFormContainer
const handleChangeAssets = (dispatch, categoryName,
    inputName,
    files,
    app_version,
    languageCode) => {

    dispatch(setDirty(categoryName, languageCode));
    dispatch(isLoading(categoryName, languageCode));
    return Promises.seq(
        files.map(file => () =>
            handleChangeScreenshot(
                inputName,
                file,
                app_version,
                languageCode
            )
        )
    ).then(results => {
        dispatch(isNotLoading(categoryName, languageCode));
        const successes = results.filter(result => result.success);
        if (successes.length > 0) {
            const last = successes.reverse()[0];
            dispatch(updateContext('localizable_metadata', last.success));
        }
        const failures = results.filter(result => result.error);
        if (failures.length > 0) {
            const errorMessage = new Error(
                Array.from(new Set(failures.map(fail => fail.error))).join(
                    '\n'
                )
            );
            dispatch(setError(categoryName, errorMessage, languageCode))
        } else {
            dispatch(setCorrect(categoryName, languageCode));
        }
    });
};

const mapDispatchToProps = dispatch => ({
    handleChangeMultiSelect: (value, fieldName, csrf_token, app_version) => {
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                { [fieldName]: value },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(changeLocalizableMetadata(response.data));
            })
            .catch(error => {
                // dispatch failure
            });
    },

    handleChangeTextInput: (event, index, languageCode) => {
        dispatch(
            changeLocalizableTextInput(
                event.target.value,
                event.target.name,
                index,
                languageCode
            )
        );
    },
    handleBlurTextInput: (event, csrf_token, app_version, languageCode) => {
        let name = event.target.name;
        dispatch(setDirty(name, languageCode));
        dispatch(isLoading(name, languageCode));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                {
                    metadata: {
                        [languageCode]: {
                            [event.target.name]: event.target.value,
                        },
                    },
                },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name, languageCode));
                dispatch(setCorrect(name, languageCode));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText,
                        languageCode
                    )
                );
                dispatch(isNotLoading(name, languageCode));
            });
    },
    handleSetCorrect: (categoryName, languageCode) => {
        dispatch(setCorrect(categoryName, languageCode));
    },
    handleChangeScreenshots: (
        categoryName,
        inputName,
        files,
        app_version,
        languageCode,
        categoryNameForRequiredFields
    ) => {
        handleChangeAssets(dispatch, categoryName,
            inputName,
            files,
            app_version,
            languageCode,
            categoryNameForRequiredFields);
    },
    handleChangeAppPreviews: (
        categoryName,
        inputName,
        files,
        app_version,
        languageCode
    ) => {
        handleChangeAssets(dispatch, categoryName,
            inputName,
            files,
            app_version,
            languageCode);
    },
    handleDeleteScreenshot: ({
        screenshot_id,
        app_version,
        languageCode,
        categoryName,
    }) => {
        dispatch(setDirty(categoryName, languageCode));
        dispatch(isLoading(categoryName, languageCode));
        axios
            .delete(`/api/v1/versions/${app_version}/draft/screenshots/${screenshot_id}`)
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
                dispatch(setCorrect(categoryName, languageCode));
                dispatch(isNotLoading(categoryName, languageCode));
            })
            .catch(error => {
                dispatch(setError(categoryName, error, languageCode));
                dispatch(isNotLoading(categoryName, languageCode));
            });
    },
    handleDeleteAppPreview: ({
        id,
        app_version,
        languageCode,
        categoryName,
    }) => {
        dispatch(setDirty(categoryName, languageCode));
        dispatch(isLoading(categoryName, languageCode));
        axios
            .delete(`/api/v1/versions/${app_version}/draft/app-previews/${id}`)
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
                dispatch(setCorrect(categoryName, languageCode));
                dispatch(isNotLoading(categoryName, languageCode));
            })
            .catch(error => {
                dispatch(setError(categoryName, error, languageCode));
                dispatch(isNotLoading(categoryName, languageCode));
            });
    },
    handleSetDefaultLanguage: (csrf_token, app_version, languageCode) => {
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                {
                    set_default_language: {
                        language_code: languageCode,
                    },
                },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
            })
            .catch(error => {
                // dispatch failure
            });
    },
    handleLoadFromDefaultLanguage: (app_version, languageCode) => {
        return axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                {
                    metadata: {
                        [languageCode]: { copy_from_default: true },
                    },
                }
            )
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
            })
            .catch(error => {
                // dispatch failure
            });
    },
    handleLoadFromDefaultLanguageToOthers: (
        app_version
    ) => {
        return axios
            .patch(
                `/api/v1/versions/${app_version}/draft/localizable-metadata`,
                {
                    copy_default_to_all_other: true,
                }
            )
            .then(response => {
                dispatch(updateContext('localizable_metadata', response.data));
            });
    },
    handleScreenshotsError: (categoryName, error, languageCode) =>
        dispatch(setError(categoryName, error, languageCode)),
    handleAppPreviewsError: (categoryName, error, languageCode) =>
        dispatch(setError(categoryName, error, languageCode)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocalizableForm);
