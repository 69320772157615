const sanitize = value => {
    let result = value !== null && value !== undefined ? value : '';
    // If the value is a string, convert it to lowercase
    if (typeof result === 'string') {
        result = result.toLowerCase();
    }
    return result;
};

/** This function is used to sort the rows of data. If you click twice, the sorting will
 *  be reversed, using the "inverse" argument. **/
const compareRows = ({ sortBy, inverse }) => (a, b) => {
    let valueA = sanitize(a[sortBy]);
    let valueB = sanitize(b[sortBy]);

    // Compare the values, and return 1, -1, 0, based on if the Attribute is greater than, less than or equal to
    // the next attribute. This will be used by the builtin JS sort function.
    if (valueA > valueB) {
        return Number(inverse);
    } else if (valueB > valueA) {
        return -1 * inverse;
    } else {
        return 0;
    }
};

export default compareRows;
