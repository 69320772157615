import React from 'react';
import TitleWithError from '../icons/TitleWithError';
import Section from '../layout/Section';
import { Col, Row } from 'react-bootstrap';
import AssetDropzone, { ASSET_TYPE_VIDEO } from './AssetDropzone';

export default function LegacyPromotionalVideo({
    assets,
    readonly,
    handleDelete,
    handleError,
    errors
}) {
    const getAssetIsNotRequiredError = (assets, inputName) => {
        return assets.length !== 0 && errors && errors[inputName];
    };

    return (
        <>
            <h3 className="tab-page-sub-header no-margin-bottom">{TitleWithError('Legacy Promotional Video', getAssetIsNotRequiredError(assets, 'legacy_video'))}</h3>
            <Section>
                <Row>
                    <Col sm={12}>
                        <AssetDropzone
                            assets={assets}
                            readonly={readonly}
                            expectedWidth={512}
                            expectedHeight={288}
                            scale={1}
                            maxAssetsCount={1}
                            dropzoneStyle={{ margin: 0 }}
                            placeholderMessage="Video already removed. Please use App Previews instead."
                            onDelete={handleDelete}
                            onError={handleError}
                            assetType={ASSET_TYPE_VIDEO}
                        />
                    </Col>
                </Row>
            </Section>
        </>
    );
}
