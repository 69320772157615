import axios from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';

// Action Types
const GET_APP_DATA = 'appHeaderData/GET_APP_DATA';
const GET_APP_DATA_FAILED = 'appHeaderData/GET_APP_DATA_FAILED';
const GET_APP_DATA_FULFILLED = 'appHeaderData/GET_APP_DATA_FULFILLED';

// Actions
export const getAppDataFulfilled = response => ({
    type: GET_APP_DATA_FULFILLED,
    payload: response,
});

export const getAppData = endpoint => ({
    type: GET_APP_DATA,
    endpoint: endpoint,
});

// Reducers
const appHeaderData = (state = {}, action) => {
    switch (action.type) {
        case GET_APP_DATA_FULFILLED:
            return action.payload.data;
        default:
            return state;
    }
};

// Sagas
function* watchGetAppData() {
    yield takeEvery(GET_APP_DATA, fetchAppData);
}

function* fetchAppData(action) {
    try {
        const response = yield call(axios, {
            method: 'get',
            url: action.endpoint,
        });
        yield put(getAppDataFulfilled(response));
    } catch (error) {
        yield put({ type: GET_APP_DATA_FAILED, error });
    }
}

export const sagas = [watchGetAppData];
export default appHeaderData;
