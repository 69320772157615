import React from 'react';

const DifferenceToggle = ({ show_difference }) => {
    const toggleButtonDisplay = show_difference => {
        if (show_difference) {
            return 'col-md-6 difference-toggle';
        } else {
            return 'col-md-6 difference-toggle hide_toggle';
        }
    };
    return (
        <div className={toggleButtonDisplay(show_difference)}>
            <input
                id="toggle-difference"
                type="checkbox"
                defaultChecked={true}
                data-toggle="toggle"
                data-onstyle="default"
                data-offstyle="primary"
                data-size="small"
                data-on="&nbsp;"
                data-off="&nbsp;"
            />
            <label id="difference-label">
                Show difference with previous version
            </label>
        </div>
    );
};

export default DifferenceToggle;
