import React from 'react';
import { Grid, Row, Button } from 'react-bootstrap';
import FieldRow from '../layout/FieldRow.jsx';
import Section from '../layout/Section.jsx';
import MultiRadioField, {
    numericBooleanOptions,
    mapToNumericBooleanOptions,
} from '../inputs/MultiRadioField.jsx';
import MultiSelectField from '../inputs/MultiSelectField.jsx';
import {
    customOptionsRenderer,
    removeNullOptions,
    contentRatingMultiSelect,
} from '../../util/formatters.js';
import Header from '../Header.jsx';
import HelpTooltip from '../HelpTooltip';

const categoryDescription = id => {
    switch (id) {
        case 4:
            return 'The app is a game or betting app. Examples include: Candy Crush Saga, Temple Run, Mario Kart, The Sims, Angry Birds, casino games, or daily fantasy sports.';
        case 2:
            return 'The primary purpose of this app is to meet or communicate with people. Examples include Facebook, Twitter, Skype, and SMS.';
        case 7:
            return 'Any app that isn\'t a game, social networking app, or communication app. Examples include entertainment products, consumer stores, news apps, lifestyle apps, streaming services, utilities, tools, emoji sets, fitness apps, magazines, and customizations.';
        default:
            return `There is no category with id: "${id}"`;
    }
};

const CategorySelect = ({
    category,
    id,
    onClick,
    activeCategory,
    csrf_token,
    app_id,
    app_version,
}) => (
    <div
        className={`google-play-category-select ${
            activeCategory == id ? 'active' : ''
        }`}
        onClick={() => onClick(id, csrf_token, app_id, app_version)}>
        <Row>
            <h5 className="google-play-category-name">{category}</h5>
        </Row>
        <Row>{categoryDescription(id)}</Row>
    </div>
);

const CategoriesList = ({
    content_rating,
    onClick,
    activeCategory,
    csrf_token,
    app_id,
    app_version,
}) => {
    return (
        <Section
            id="google-play-app-categories"
            title="Select your app category">
            <Row>
                {content_rating.options.category.map(category => {
                  if(category.id===2 || category.id===4 || category.id===7)
                    return (
                      <CategorySelect
                          key={`category-${category.id}`}
                          category={category.name}
                          activeCategory={activeCategory}
                          id={category.id}
                          onClick={onClick}
                          csrf_token={csrf_token}
                          app_id={app_id}
                          app_version={app_version}
                      />
                  )
                })}
            </Row>
        </Section>
    );
};

const MultiSingleOrBooleanSelectField = ({
    id,
    handleChangeRadio,
    csrf_token,
    app_version,
    validation,
    handleChangeMultiSelect,
    question,
    readonly,
}) => {
    switch (question.select) {
        case 'multi':
            return (
                <MultiSelectField
                    name={question.id}
                    md={9}
                    label={question.name}
                    options={contentRatingMultiSelect(question.options)}
                    error={
                        validation.errors[`rating_${id}`] &&
                        validation.errors[`rating_${id}`][question.id]
                    }
                    progress={
                        validation.isLoading[`rating_${id}`] &&
                        validation.isLoading[`rating_${id}`][question.id]
                    }
                    dirty={
                        validation.dirty[`rating_${id}`] &&
                        validation.dirty[`rating_${id}`][question.id]
                    }
                    readonly={readonly}
                    onMultiChange={value =>
                        handleChangeMultiSelect(
                            value,
                            question.id,
                            csrf_token,
                            app_version,
                            id
                        )
                    }
                    selected={
                        contentRatingMultiSelect(
                            question.value.map(id => ({
                                id,
                                name: (
                                    question.options.find(
                                        option => option.id === parseInt(id)
                                    ) || {}
                                ).name,
                            }))
                        ) || []
                    }
                    selectAllLabel={'All options'}
                    valueRenderer={customOptionsRenderer}
                />
            );
        case 'single':
            return (
                <MultiRadioField
                    name={question.id}
                    id={question.id}
                    error={
                        validation.errors[`rating_${id}`] &&
                        validation.errors[`rating_${id}`][question.id]
                    }
                    progress={
                        validation.isLoading[`rating_${id}`] &&
                        validation.isLoading[`rating_${id}`][question.id]
                    }
                    dirty={
                        validation.dirty[`rating_${id}`] &&
                        validation.dirty[`rating_${id}`][question.id]
                    }
                    selected={question.value}
                    readonly={readonly}
                    onChange={event =>
                        handleChangeRadio(event, csrf_token, app_version, id)
                    }
                    itemSm={5}
                    collapse={
                        removeNullOptions(question.options).length == 2
                            ? false
                            : true
                    }
                    options={removeNullOptions(question.options)}
                />
            );
        case undefined:
            return (
                <MultiRadioField
                    id={question.id}
                    name={question.id}
                    error={
                        validation.errors[`rating_${id}`] &&
                        validation.errors[`rating_${id}`][question.id]
                    }
                    progress={
                        validation.isLoading[`rating_${id}`] &&
                        validation.isLoading[`rating_${id}`][question.id]
                    }
                    dirty={
                        validation.dirty[`rating_${id}`] &&
                        validation.dirty[`rating_${id}`][question.id]
                    }
                    selected={mapToNumericBooleanOptions(question.value)}
                    readonly={readonly}
                    onChange={event =>
                        handleChangeRadio(event, csrf_token, app_version, id)
                    }
                    itemSm={5}
                    options={numericBooleanOptions}
                />
            );
    }
};

const CategoryForm = ({
    id,
    categoryTitle,
    edit,
    sections,
    handleChangeRadio,
    csrf_token,
    app_id,
    app_version,
    validation,
    readonly,
    handleChangeMultiSelect,
}) => {
    return (
        <React.Fragment>
            {readonly ? (
                <Section id={`category-${id}`} title={categoryTitle} />
            ) : (
                <Section
                    id={`category-${id}`}
                    title={categoryTitle}
                    buttonComponentBelow={
                        <Button onClick={() => edit(app_id, app_version, id)}>
                            Change category
                        </Button>
                    }
                />
            )}
            {sections.map(section => (
                <Section
                    key={section.name}
                    id={`section-${section.name}`}
                    title={section.name}
                    additionalClassName="overflow-visible">
                    <Row>
                        {section.questions.map(question => (
                            <FieldRow
                                key={question.id}
                                id={question.id}
                                label={question.name}
                                md={5}
                                description={question.hint}
                                required>
                                <MultiSingleOrBooleanSelectField
                                    id={id}
                                    handleChangeRadio={handleChangeRadio}
                                    csrf_token={csrf_token}
                                    app_version={app_version}
                                    validation={validation}
                                    handleChangeMultiSelect={
                                        handleChangeMultiSelect
                                    }
                                    question={question}
                                    readonly={readonly}
                                />
                            </FieldRow>
                        ))}
                    </Row>
                </Section>
            ))}
        </React.Fragment>
    );
};

const CategoriesOrCategoryForm = ({
    content_rating,
    onCategoryClick,
    selectedCategory,
    edit,
    activeCategory,
    csrf_token,
    app_id,
    app_version,
    validation,
    readonly,
    handleChangeRadio,
    handleChangeMultiSelect,
}) => {
    const selectedCategoryIndex = Number(selectedCategory) - 1;
    switch (selectedCategory) {
        case undefined:
            return (
                <CategoriesList
                    content_rating={content_rating}
                    onClick={onCategoryClick}
                    activeCategory={activeCategory}
                    csrf_token={csrf_token}
                    app_id={app_id}
                    app_version={app_version}
                />
            );
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
            return (
                <CategoryForm
                    id={selectedCategory}
                    sections={
                        (content_rating.category[selectedCategory] &&
                            content_rating.category[selectedCategory]
                                .sections) ||
                        []
                    }
                    categoryTitle={
                        content_rating.options.category[
                            selectedCategoryIndex
                        ] &&
                        content_rating.options.category[selectedCategoryIndex]
                            .name
                    }
                    edit={edit}
                    validation={validation}
                    readonly={readonly}
                    handleChangeRadio={handleChangeRadio}
                    app_id={app_id}
                    app_version={app_version}
                    handleChangeMultiSelect={handleChangeMultiSelect}
                />
            );
        default:
            return `There is no CategoryForm for id: "${selectedCategory}"`;
    }
};

const GooglePlayContentRating = ({
    content_rating,
    selectedCategory,
    onCategoryClick,
    edit,
    activeCategory,
    csrf_token,
    app_id,
    app_version,
    validation,
    readonly,
    handleChangeRadio,
    handleChangeMultiSelect,
}) => {
    return (
        <Grid fluid>
            <Header>Content Rating<HelpTooltip message='Let Google about the content of your app.
            This is to make sure your app complies with Google Play policies.'/>
            </Header>
            <CategoriesOrCategoryForm
                content_rating={content_rating}
                selectedCategory={selectedCategory}
                onCategoryClick={onCategoryClick}
                edit={edit}
                activeCategory={activeCategory}
                csrf_token={csrf_token}
                app_id={app_id}
                app_version={app_version}
                validation={validation}
                readonly={readonly}
                handleChangeRadio={handleChangeRadio}
                handleChangeMultiSelect={handleChangeMultiSelect}
            />
        </Grid>
    );
};

export default GooglePlayContentRating;
