import React from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import RemoveIconButton from '../RemoveIconButton.jsx';
import FieldFeedback from './FieldFeedback.jsx';

export default class SelectFileField extends React.Component {
    static defaultProps = {
        readonly: false,
    };

    constructor(props) {
        super(props);
        this.state = {selectedFile: props.initialFile, error: null};
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.error) {
            return
        }
        if (nextProps.error) {
            this.setState({selectedFile: null, error: nextProps.error});
        }
        if (nextProps.initialFile !== this.state.selectedFile) {
            this.setState({selectedFile: nextProps.initialFile, error: null});
        }
    }

    onDropAccepted = files => {
        if (files.length !== 1) {
            this.setState({
                error: new Error('This field accepts only a single file'),
            });
        } else {
            const selectedFile = files[0];
            this.setState({selectedFile, error: null}, () =>
                this.props.onChange(selectedFile)
            );
        }
    };

    onDropRejected = rejectedFiles => {
        if (rejectedFiles.length === 1) {
            this.setState({
                error: new Error('Invalid size'),
            });
        }
    };

    render() {
        const {
            accept,
            maxFileSize,
            progress,
            readonly,
            initialFile,
            xs = 0,
            sm = 0,
            md = 0,
        } = this.props;
        const {error} = this.state;
        return (
            <Row>
                <Col
                    xs={xs}
                    sm={sm}
                    md={md}
                    className="select-file-field-container">
                    {this.hasSelectedFile() && !progress &&
                    (readonly ? (
                        <Button href={initialFile}>Download</Button>
                    ) : (
                        <p className="select-file-field-filename">
                            <RemoveIconButton onClick={this.handleDelete}/>{' '}
                            {this.renderCaption()}
                        </p>
                    ))}
                    {!this.hasSelectedFile() && readonly && <span className="readonly-value">-</span>}
                    {!readonly && !progress && (
                        <Dropzone
                            className="select-file-dropzone"
                            multiple={false}
                            accept={accept}
                            maxSize={maxFileSize}
                            onDropAccepted={this.onDropAccepted}
                            onDropRejected={this.onDropRejected}>
                            <Button>
                                {this.hasSelectedFile()
                                    ? 'Re-upload file'
                                    : 'Upload file'}
                            </Button>
                        </Dropzone>
                    )}
                    <FieldFeedback
                        error={error || this.props.error}
                        progress={progress}
                        dirty={this.props.dirty}
                        id={this.props.id}
                    />
                </Col>
            </Row>
        );
    }

    hasSelectedFile = () => {
        return this.state.selectedFile;
    };

    handleDelete = () => {
        this.setState({selectedFile: null});
        this.props.onDelete();
    };

    renderCaption = () => {
        const {selectedFile} = this.state;
        const {initialFile} = this.props;
        if (selectedFile && selectedFile.name) {
            return selectedFile.name;
        } else if (selectedFile) {
            return <a href={initialFile}>{initialFile}</a>;
        }
    };
}
