import React from 'react';

export default function({ color = '#EB2F27', ...props }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10">
            <path
                fill={color}
                fillRule="evenodd"
                d="M5 3.586L8.265.32A.507.507 0 0 1 8.97.33l.7.7c.206.206.2.513.008.706L6.414 5 9.68 8.265c.184.184.19.509-.008.706l-.7.7a.492.492 0 0 1-.706.008L5 6.414 1.735 9.68a.507.507 0 0 1-.706-.008l-.7-.7a.492.492 0 0 1-.008-.706L3.586 5 .32 1.735A.507.507 0 0 1 .33 1.03l.7-.7A.492.492 0 0 1 1.735.32L5 3.586z"
            />
        </svg>
    );
}
