import { connect } from 'react-redux';
import axios from 'axios';
import { updateContextWithNewContentRating } from '../actions';
import {
    showCategoryForm,
    activeCategoryForm,
} from '../../../redux/modules/navigation';
import GooglePlayContentRating from '../components/tabs/GooglePlayContentRating.jsx';
import { changeSelectInput } from '../actions/form.js';
import {
    setDirty,
    setError,
    setCorrect,
    isLoading,
    isNotLoading,
} from '../actions/validation.js';
import {
    selectSelectedCategory,
    selectActiveCategory,
} from '../selectors/navigation';

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    content_rating: store.context.content_rating,
    validation: store.validation,
    selectedCategory: selectSelectedCategory(store),
    activeCategory: selectActiveCategory(store),
    csrf_token: store.context.csrf_token,
    app_id: store.context.app_id,
    app_version: store.context.app_version,
});

const mapDispatchToProps = dispatch => ({
    onCategoryClick: (id, csrf_token, app_id, app_version) => {
        dispatch(showCategoryForm(app_id, app_version, id));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/content-rating`,
                { category: id },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(updateContextWithNewContentRating(response.data));
            })
            .catch(error => {
                console.log('something went wrong');
                console.log(error.response.data.error);
            });
    },
    edit: (app_id, app_version, id) => {
        dispatch(showCategoryForm(app_id, app_version, undefined));
        dispatch(activeCategoryForm(app_id, app_version, id));
    },
    handleChangeRadio: (event, csrf_token, app_version, categoryId) => {
        let name = event.target.name;
        const value = event.target.value;
        const _convertStringToBool = val => {
            switch (val) {
                case 'true':
                    return true;
                case 'false':
                    return false;
                case 'null':
                    return null;
            }
        };
        let intOrBool =
            value === 'true' || value === 'false' || value === 'null'
                ? _convertStringToBool(value)
                : Number.parseInt(value);
        dispatch(setDirty(name, categoryId));
        dispatch(isLoading(name, categoryId));
        dispatch(changeSelectInput(intOrBool, name, 'content_rating'));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/content-rating`,
                { [categoryId]: { [name]: intOrBool } },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(name, categoryId));
                dispatch(setCorrect(name, categoryId));
                dispatch(updateContextWithNewContentRating(response.data));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText,
                        categoryId
                    )
                );
                dispatch(isNotLoading(name, categoryId));
            });
    },

    handleChangeMultiSelect: (
        value,
        fieldName,
        csrf_token,
        app_version,
        categoryId
    ) => {
        dispatch(changeSelectInput(value, fieldName, 'content_rating'));
        dispatch(setDirty(fieldName, categoryId));
        dispatch(isLoading(fieldName, categoryId));
        axios
            .patch(
                `/api/v1/versions/${app_version}/draft/content-rating`,
                { [categoryId]: { [fieldName]: value } },
                { 'X-CSRFToken': csrf_token }
            )
            .then(response => {
                dispatch(isNotLoading(fieldName, categoryId));
                dispatch(setCorrect(fieldName, categoryId));
                dispatch(updateContextWithNewContentRating(response.data));
            })
            .catch(error => {
                dispatch(
                    setError(
                        name,
                        error.response.data.error || error.response.statusText,
                        categoryId
                    )
                );
                dispatch(isNotLoading(fieldName, categoryId));
            });
    },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GooglePlayContentRating);
