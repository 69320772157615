import React from 'react';

export default function Checkmark({ color }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="24"
            viewBox="0 0 26 24">
            <path
                fill={color}
                fillRule="evenodd"
                d="M4.192 7.31V5.06c0-.21.075-.387.224-.532a.753.753 0 0 1 .546-.217h2.31v-1.5H8.81v1.5h7.697v-1.5h1.54v1.5h2.308c.216 0 .399.072.547.216a.717.717 0 0 1 .223.534v2.25H4.192zm16.934 1.5v11.25a.72.72 0 0 1-.223.525.738.738 0 0 1-.547.226H4.962a.737.737 0 0 1-.546-.226.719.719 0 0 1-.224-.524V8.81h16.934zm-15.394 4.5h1.54v-1.5h-1.54v1.5zm0 3h1.54v-1.5h-1.54v1.5zm0 3h1.54v-1.5h-1.54v1.5zm3.079-6h1.54v-1.5H8.81v1.5zm0 3h1.54v-1.5H8.81v1.5zm0 3h1.54v-1.5H8.81v1.5zm3.079-6h1.539v-1.5h-1.54v1.5zm0 3h1.539v-1.5h-1.54v1.5zm0 3h1.539v-1.5h-1.54v1.5zm3.078-6h1.54v-1.5h-1.54v1.5zm0 3h1.54v-1.5h-1.54v1.5zm0 3h1.54v-1.5h-1.54v1.5zm3.08-6h1.539v-1.5h-1.54v1.5zm0 3h1.539v-1.5h-1.54v1.5zm0 3h1.539v-1.5h-1.54v1.5z"
            />
        </svg>
    );
}
