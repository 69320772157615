import React from 'react';
import AppStoreFormTabs from '../../AppDraft/components/AppStoreFormTabs.jsx';
import GooglePlayFormTabs from '../../AppDraft/components/GooglePlayFormTabs.jsx';

const VersionDraftForm = ({ draftFormType }) => {
    if (draftFormType == 'app-store') {
        return <AppStoreFormTabs />;
    }
    if (draftFormType == 'google-play') {
        return <GooglePlayFormTabs />;
    }
};

export default VersionDraftForm;
