import React, { Fragment, useState } from 'react';
import { getScreenshotDevicePropsMap } from '../util/getScreenshotDevicePropsMap';
import { getAppPreviewDevicePropsMap } from '../util/getAppPreviewDevicePropsMap';
import ScreenshotTabsSectionList from './inputs/ScreenshotTabsSectionList';
import PreviewTabsSectionList from './inputs/PreviewTabsSectionList';
import LegacyPromotionalVideo from './inputs/LegacyPromotionalVideo';

export default function AppStoreGraphicAssetsTab({
    metadata,
    validation,
    supports_iphone,
    supports_ipad,
    supports_watch,
    readonly,
    options,
    handleSetCorrect,
    ...props
}) {
    const languageCode = metadata.language.code;
    const screenshotsMap = metadata.screenshots || {};
    const appPreviewsMap = metadata.app_previews || {};
    const screenshotsDimensionsMap = options.screenshots || {};
    const appPreviewsDimensionsMap = options.app_previews || {};
    const screenshotDevicePropsMap = getScreenshotDevicePropsMap({ supports_iphone, supports_ipad, supports_watch });
    const appPreviewDevicePropsMap = getAppPreviewDevicePropsMap({ supports_iphone, supports_ipad });
    const errors = validation.errors[languageCode] || {};

    const [legacyVideoReadonly, setLegacyVideoReadonly] = useState(false);

    const shouldShowLegacyVideo = appPreviewsMap.legacy_video && appPreviewsMap.legacy_video.length !== 0 || legacyVideoReadonly;

    return (
        <Fragment>
            <ScreenshotTabsSectionList
                errors={errors}
                readonly={readonly}
                maxAssetsCount={10}
                assetsMap={screenshotsMap}
                devicePropsMap={screenshotDevicePropsMap}
                dimensionsMap={screenshotsDimensionsMap}
                dirty={validation.dirty[languageCode]}
                progress={validation.isLoading[languageCode]}
                handleSelect={(sectionId) => handleSetCorrect(sectionId, languageCode)}
                handleChange={(inputName, files, sectionId) =>
                    props.handleChangeScreenshots(
                        sectionId,
                        inputName,
                        files,
                        props.app_version,
                        languageCode
                    )
                }
                handleDelete={(assetId, sectionId) =>
                    props.handleDeleteScreenshot({
                        languageCode,
                        screenshot_id: assetId,
                        categoryName: sectionId,
                        app_version: props.app_version,
                    })
                }
                handleError={(error, sectionId) =>
                    props.handleScreenshotsError(
                        sectionId,
                        error,
                        languageCode
                    )
                }
            />
            <PreviewTabsSectionList
                errors={errors}
                readonly={readonly}
                maxAssetsCount={10}
                assetsMap={appPreviewsMap}
                devicePropsMap={appPreviewDevicePropsMap}
                dimensionsMap={appPreviewsDimensionsMap}
                dirty={validation.dirty[languageCode]}
                progress={validation.isLoading[languageCode]}
                handleSelect={(sectionId) => handleSetCorrect(sectionId, languageCode)}
                handleChange={(inputName, files, sectionId) =>
                    props.handleChangeAppPreviews(
                        sectionId,
                        `app_preview,${inputName}`,
                        files,
                        props.app_version,
                        languageCode
                    )
                }
                handleDelete={(assetId, sectionId) =>
                    props.handleDeleteAppPreview({
                        languageCode,
                        id: assetId,
                        categoryName: sectionId,
                        app_version: props.app_version,
                    })
                }
                handleError={(error, sectionId) =>
                    props.handleAppPreviewsError(
                        sectionId,
                        error,
                        languageCode
                    )
                }
            />
            {
                shouldShowLegacyVideo && (
                    <LegacyPromotionalVideo
                        errors={errors}
                        readonly={legacyVideoReadonly}
                        assets={appPreviewsMap.legacy_video}
                        devicePropsMap={screenshotDevicePropsMap}
                        dimensionsMap={screenshotsDimensionsMap}
                        dirty={validation.dirty[languageCode]}
                        progress={validation.isLoading[languageCode]}
                        handleSelect={(sectionId) => handleSetCorrect(sectionId, languageCode)}
                        handleChange={(inputName, files, sectionId) =>
                            props.handleChangeScreenshots(
                                sectionId,
                                inputName,
                                files,
                                props.app_version,
                                languageCode
                            )
                        }
                        handleDelete={assetId => {
                            setLegacyVideoReadonly(true);
                            props.handleDeleteAppPreview({
                                app_version: props.app_version,
                                id: assetId,
                                languageCode,
                                categoryName: 'legacy_video_preview',
                            });
                        }}
                        handleError={error => props.handleAppPreviewsError('legacy_video', error, languageCode)}
                    />
                )
            }
        </Fragment>
    );
}
