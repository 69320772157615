// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

import getJSON from '../../Utils/getJSON.jsx';
import EntitlementTable from './EntitlementTable.jsx';

export default class EditEntitlementsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            entitlements: [],
            loading: true,
            saveButtonDisabled: true,
        };
        this.modifiedEntitlements = [];
    }

    async componentDidMount() {
        const data = await getJSON(this.props.endpoint);
        this.setState(
            {
                entitlements: data,
                loading: false,
            },
            () => {
                this.modifiedEntitlements = JSON.parse(
                    JSON.stringify(
                        this.state.entitlements.filter(entitlement => {
                            return entitlement.selectedOption === 'custom';
                        })
                    )
                );
            }
        );
    }

    handleChange = (change) => {
        let modifiedEntitlements = this.modifiedEntitlements;

        let modifiedEntitlement = modifiedEntitlements.find(
            element => element.name === change.name
        );
        if (modifiedEntitlement) {
            modifiedEntitlement.selectedOption = change.selectedOption;
            modifiedEntitlement.customValue = change.customValue;
        } else {
            let entitlement = this.state.entitlements.find(
                element => element.name === change.name
            );
            let newModifiedEntitlement = JSON.parse(
                JSON.stringify(entitlement)
            );
            newModifiedEntitlement.selectedOption = change.selectedOption;
            newModifiedEntitlement.customValue = change.customValue;
            modifiedEntitlements.push(newModifiedEntitlement);
        }

        this.modifiedEntitlements = modifiedEntitlements;
        this.setState({
            saveButtonDisabled: this.modifiedEntitlements.length === 0,
        });
    }

    handleSave = () => {
        this.setState({
            loading: true,
        });

        axios.post(this.props.endpoint, this.modifiedEntitlements).then(() => {
            this.setState(
                {
                    loading: false,
                },
                () => {
                    this.props.getCustomEntitlementsStatus();
                    this.props.handleToggleModal();
                }
            );
        });
    }

    render() {
        const table = (
            <EntitlementTable
                entitlements={this.state.entitlements}
                onChange={this.handleChange}
            />
        );
        return (
            <Modal
                show={this.props.showModal}
                onClose={this.props.handleToggleModal}
                bsSize="large">
                <Modal.Header closeButton onHide={this.props.handleToggleModal}>
                    <Modal.Title>Edit entitlements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        id="edit-entitlements-loader"
                        className="loader"
                        style={{
                            transform: 'translateX(-15px)',
                            visibility: this.state.loading
                                ? 'visible'
                                : 'hidden',
                        }}>
                        <img
                            className="spinner-alone"
                            src={`${staticsPath}/img/spinner.gif`}
                        />
                    </div>
                    <p className="text-danger">
                        This action is NOT recommended.
                    </p>
                    {table}
                    <p className="text-info">
                        For array types input comma-separated values.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <p>
                        The changes will be applied to the next resigned version
                    </p>
                    <Button
                        bsStyle="primary"
                        onClick={this.props.handleToggleModal}>
                        Cancel
                    </Button>
                    <Button
                        bsStyle="danger"
                        onClick={this.handleSave}
                        disabled={this.state.saveButtonDisabled}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
