import React from 'react';
import { Button } from 'react-bootstrap';

const UploadBinaryButton = ({ specification, versionStatus }) => {
    if (!specification.binary_metadata.is_copied && versionStatus === 'Draft') {
        return (
            <div className="upload-binary-button">
                <Button id="re-upload-binary">Re-upload binary</Button>
            </div>
        );
    } else if (specification.binary_metadata.is_copied) {
        return (
            <div className="upload-binary-button">
                <Button disabled>Can't upload binary for cloned version</Button>
            </div>
        );
    }
    return null;
};

export default UploadBinaryButton;
