import React from 'react';
import {connect} from "react-redux";
import AdditionalInfoFormContainer from "../AppDraft/containers/AdditionalInfoFormContainer";

const CustomFieldContainer = props => {
    return (
        <div>
            <div className="row">
                <div className="col-md-12">
                    <hr/>
                    <h4>{props.name}</h4>
                </div>
            </div>
            <AdditionalInfoFormContainer/>
        </div>
    )
}

const mapStateToProps = store => ({
    name: store.customField.name,
});

export default connect(mapStateToProps)(CustomFieldContainer);