import React from 'react';
import { Row, Col, FormControl } from 'react-bootstrap';
import FieldFeedback from './FieldFeedback.jsx';
import ReadOnlyDiffValue from './ReadOnlyDiffValue.jsx';

const optionField = (label, value, index) => (
    <option value={value} key={`option-${index}`}>
        {label}
    </option>
);
const BlankField = ({ allowBlank }) => (
    <option disabled={!allowBlank} value="" key={'option--1'}>
        {' '}
        -- select an option --{' '}
    </option>
);

const SelectField = ({
    xs,
    sm,
    md,
    lg,
    options,
    value,
    onChange,
    name,
    progress,
    error,
    dirty,
    readonly = false,
    allowBlank = false,
    previousValue,
}) => {
    const selectedOption = options.find(option => option.value === value);
    return (
        <Row>
            <Col xs={xs} sm={sm} md={md} lg={lg}>
                {readonly ? (
                    <ReadOnlyDiffValue
                        value={selectedOption && selectedOption.label}
                        previousValue={previousValue}
                    />
                ) : (
                    <FormControl
                        value={value || ''}
                        componentClass="select"
                        onChange={onChange}
                        name={name}>
                        <BlankField allowBlank={allowBlank} />
                        {options.map((option, index) =>
                            optionField(option.label, option.value, index)
                        )}
                    </FormControl>
                )}
            </Col>
            <FieldFeedback
                progress={progress}
                error={error}
                dirty={dirty}
                id={name}
            />
        </Row>
    );
};

export default SelectField;
