import React, { Fragment } from 'react';
import Section from './layout/Section.jsx';
import { Row } from 'react-bootstrap';
import FieldRow from './layout/FieldRow.jsx';
import TextField from './inputs/TextField.jsx';
import TextArea from './inputs/TextArea.jsx';

export default function GooglePlayProductDetailsTab({
    metadata,
    validation,
    readonly,
    diff,
    options,
    ...props
}) {
    const languageCode = metadata.language.code;

    return (
        <Fragment>
            <Section id="localizable-app-metadata-section">
                <Row>
                    <FieldRow id="title"
                        label="App title"
                        help="This is how your app will appear on Google Play.
                        It should be concise and not include price, rank, any emoji or repetitive symbols."
                        required>
                        <TextField
                            name="title"
                            md={9}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode].title
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode].title
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].title
                            }
                            value={metadata.title}
                            length={metadata.title && metadata.title.length}
                            max_length={
                                options.title && options.title.max_length
                            }
                            type="text"
                            readonly={readonly}
                            previousValue={
                                typeof diff === 'object'
                                    ? diff.title
                                    : undefined
                            }
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="short_description"
                        label="Short description"
                        help="A short description for your app. Users can expand to view your full description."
                        required>
                        <TextField
                            name="short_description"
                            md={12}
                            detachedFeedback
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode]
                                    .short_description
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode]
                                    .short_description
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].short_description
                            }
                            value={metadata.short_description}
                            length={
                                metadata.short_description &&
                                metadata.short_description.length
                            }
                            max_length={
                                options.short_description &&
                                options.short_description.max_length
                            }
                            type="text"
                            readonly={readonly}
                            previousValue={
                                typeof diff === 'object'
                                    ? diff.short_description
                                    : undefined
                            }
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>

            <Section id="trade-representative-contact-information-address">
                <Row>
                    <FieldRow
                        id="full_description"
                        label="Full description"
                        required>
                        <TextArea
                            name="full_description"
                            rows="8"
                            md={12}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode].full_description
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode]
                                    .full_description
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].full_description
                            }
                            value={metadata.full_description}
                            length={
                                metadata.full_description &&
                                metadata.full_description.length
                            }
                            max_length={
                                options.full_description &&
                                options.full_description.max_length
                            }
                            readonly={readonly}
                            previousValue={
                                typeof diff === 'object'
                                    ? diff.full_description
                                    : undefined
                            }
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow id="release-notes" label="Release notes">
                        <TextArea
                            name="release_notes"
                            rows="8"
                            md={12}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode].release_notes
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode].release_notes
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].release_notes
                            }
                            value={metadata.release_notes}
                            length={
                                metadata.release_notes && metadata.release_notes.length
                            }
                            max_length={
                                options.release_notes &&
                                options.release_notes.max_length
                            }
                            readonly={readonly}
                            previousValue={
                                typeof diff === 'object'
                                    ? diff.release_notes
                                    : undefined
                            }
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
        </Fragment>
    );
}
