// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import Sortable from 'react-sortablejs';

const SortableList = ({ items, options, onChange }) => {
    const listItems = items.map(item => (
        <ListGroupItem key={item.id} data-id={item.id}>
            {item.name}
        </ListGroupItem>
    ));

    return (
        <ListGroup>
            <Sortable
                options={options}
                onChange={order => {
                    onChange ? onChange(order) : null;
                }}>
                {listItems}
            </Sortable>
        </ListGroup>
    );
};

export default SortableList;
