import React from 'react';
import progress from './progress.gif';

export default function ProgressFeedback({ style }) {
    return (
        <div className="progress-feedback-container" style={style}>
            <img className="progress-feedback-icon" src={progress} />
        </div>
    );
}
