import { fork, all } from 'redux-saga/effects';
import { sagas as customFields } from './modules/customField';
import { sagas as fieldsValidation } from './modules/fieldsValidation';
import { sagas as appDataSaga } from './modules/appHeaderData';
import { sagas as deviceInstallationData } from './modules/appVersionOverview/deviceInstallationData';
import { sagas as login } from './modules/login';

export default function* root() {
    yield all([
        ...customFields.map(fork),
        ...fieldsValidation.map(fork),
        ...appDataSaga.map(fork),
        ...deviceInstallationData,
        ...login.map(fork),
    ]);
}
