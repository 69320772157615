import * as actionType from '../actions/types';
import context from './context';

const tokenInitialState = null;
const token = (state = tokenInitialState, action) => {
    switch (action.type) {
        case actionType.SET_TOKEN:
            return action.data;
        default:
            return state;
    }
};

export default {
    token,
    context,
};
