export const SET_TOKEN = 'SET_TOKEN';
export const ADD_CONTEXT = 'ADD_CONTEXT';
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT';
export const CHANGE_TEXT_INPUT = 'CHANGE_TEXT_INPUT';
export const CHANGE_SELECT_INPUT = 'CHANGE_SELECT_INPUT';
export const CHANGE_RADIO_TABLE_INPUT = 'CHANGE_RADIO_TABLE_INPUT';
export const CHANGE_LOCALIZABLE_METADATA = 'CHANGE_LOCALIZABLE_METADATA';
export const CHANGE_LOCALIZABLE_TEXT_INPUT = 'CHANGE_LOCALIZABLE_TEXT_INPUT';
export const CHANGE_CONTENT_RATING_CATEGORY =
    'CHANGE_CONTENT_RATING_CATEGORY_DATA';
export const RECEIVED_DIFF = 'RECEIVED_DIFF';
export const TOGGLE = 'TOGGLE';

export const SET_ERROR = 'SET_ERROR';
export const IS_LOADING = 'IS_LOADING';
export const IS_NOT_LOADING = 'IS_NOT_LOADING';
export const SET_CORRECT = 'SET_CORRECT';
export const SET_DIRTY = 'SET_DIRTY';
export const SET_PRISTINE = 'SET_PRISTINE';
export const SET_NOT_DIRTY = 'SET_NOT_DIRTY';
