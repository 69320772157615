import React from 'react';

const jsdiff = require('diff');

const fnMap = {
    chars: jsdiff.diffChars,
    words: jsdiff.diffWords,
    sentences: jsdiff.diffSentences,
    json: jsdiff.diffJson,
};

export default class ShowDiff extends React.Component {
    render() {
        let { inputA, inputB, type, debug } = this.props;
        const diff = fnMap[type](inputA, inputB);

        let result = diff.map(function(part, index) {
            let spanStyle = {
                backgroundColor: part.added
                    ? '#e6ffed'
                    : part.removed
                    ? '#ffeef0'
                    : '',
                textDecoration: part.added
                    ? ''
                    : part.removed
                    ? 'line-through'
                    : '',
                color: part.added ? '' : part.removed ? 'salmon' : '',
                padding: part.added ? '3px' : part.removed ? '3px' : '',
                borderRadius: '5px',
            };
            const difference_exists = part.value !== '';
            if (!difference_exists) {
                return <div key={index} className="no-content" />;
            }
            // If "None" was removed or added, dont show it.
            else if (part.value === 'None') {
                return <div key={index} className="no-content" />;
            } else {
                let text = part.value === 'False' ? 'No' : part.value;
                return (
                    <span key={index}>
                        <span style={spanStyle}>{text}</span>{' '}
                    </span>
                );
            }
        });
        // If the data or the difference is None, it will not be shown.
        if (result.length === 1 && !result[0]) {
            return (
                <div className="diff-result metadata-difference no-content" />
            );
        } else {
            return (
                <div className="diff-result metadata-difference">{result}</div>
            );
        }
    }
}
