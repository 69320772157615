import React from 'react';
import VmWareAirWatchOrganizationGroupTree from '../Targets/VmWareAirWatchOrganizationGroupTree.jsx';
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

export default class AirWatchPublishingSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedGroups: [],
            saved: false,
            error: false,
        };

        this.onGroupSelected = this.onGroupSelected.bind(this);
    }

    onGroupSelected(selectedGroups) {
        this.setState({
            selectedGroups: selectedGroups,
        });
    }

    render() {
        return (
            <div className="airwatch-publishing-settings">
                <div className="col-md-12">
                    <hr />
                    <h4>{this.props.targetName}</h4>
                    <hr />
                </div>
                <div className="col-md-12 clear">
                    <p>
                        If auto-publish will be enabled,{' '}
                        <b>{this.props.appName}</b> will be published to
                        following Organization Groups
                    </p>
                    <VmWareAirWatchOrganizationGroupTree
                        endpoint={this.props.endpoint}
                        showCheckboxes={true}
                        showSelectAllButton={false}
                        onItemSelected={this.onGroupSelected}
                    />
                </div>
                <div className="col-md-12">
                    <hr />
                </div>
                <div className="col-md-12 clear">
                    <label className="info-header">
                        Select Organizational Groups When Publishing
                        <br />
                        <input
                            checked={this.props.selectOGsInPublishModal}
                            type="checkbox"
                            className="ajax-toggle"
                            data-style="slowest"
                            id="auto-publish"
                            data-url={
                                this.props.selectOGsInPublishModalToggleUrl
                            }
                            data-width="100"
                            data-toggle="toggle"
                            data-on="Enabled"
                            data-off="Disabled"
                            data-onstyle="default"
                            data-offstyle="default"
                        />
                    </label>
                </div>
                <div className="col-md-12">
                    <hr />
                </div>
                <div className="col-md-12 clear">
                    <label className="info-header">
                        Publish automatically
                        <br />
                        <input
                            checked={this.props.autoPublishChecked}
                            type="checkbox"
                            className="ajax-toggle"
                            data-style="slowest"
                            id="auto-publish"
                            data-url={this.props.autoPublishDataUrl}
                            data-width="100"
                            data-toggle="toggle"
                            data-on="Enabled"
                            data-off="Disabled"
                            data-onstyle="default"
                            data-offstyle="default"
                            data-field_name="auto_publish"
                            data-method="PATCH"
                        />
                    </label>
                </div>
            </div>
        );
    }
}
