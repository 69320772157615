import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';

export default class TabsContainer extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            key: 0,
        };
    }

    handleSelect = (key) => {
        this.setState({ key });
        this.props.handleSelect();
    }

    render() {
        const {
            id,
            children,
        } = this.props;
        return (
            <Tabs
                activeKey={this.state.key}
                onSelect={this.handleSelect}
                id={`${id}-tabs`}
                className="screenshot-tabs">
                {
                    children && children.map(((child, index) => {
                        const isActive = index === this.state.key;
                        return (
                            <Tab key={`'tabs-${index}`}  eventKey={index} title={isActive && child.props.activeTabTitle ? child.props.activeTabTitle : child.props.title}>
                                {child}
                            </Tab>
                        );
                    }))}
            </Tabs>
        );
    }
}
