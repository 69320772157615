import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import FieldRow from '../layout/FieldRow.jsx';
import Section from '../layout/Section.jsx';
import TextField from '../inputs/TextField.jsx';
import TextArea from '../inputs/TextArea.jsx';
import MultiRadioField, {
    numericBooleanOptions,
    mapToNumericBooleanOptions,
} from '../inputs/MultiRadioField.jsx';
import ReleaseSettingsPicker from '../inputs/ReleaseSettingsPicker.jsx';
import Header from '../Header.jsx';
import ExportCompliance from './SubmissionInformation/ExportCompliance';
import ContentRights from './SubmissionInformation/ContentRights';
import IDFA from './SubmissionInformation/IDFA';
import { calculateMinDate } from '../../util/date';
import SelectFileField from '../inputs/SelectFileField';

const ReviewForm = ({
    reviewInformation,
    validation,
    readonly,
    diff,
    ...props
}) => {
    const advertisingSectionDisabled = !reviewInformation.uses_idfa;
    const dateObject = reviewInformation.options.release_auto_date;
    const minDate = calculateMinDate(dateObject);

    return (
        <Grid fluid>
            <Header>Review Information</Header>
            <Section id="demo-user-and-release-notes">
                <Row>
                    <FieldRow
                        id="demo-account-userid"
                        label="Demo account user id">
                        <TextField
                            name="demo_account_userid"
                            md={5}
                            error={validation.errors.demo_account_userid}
                            progress={validation.isLoading.demo_account_userid}
                            dirty={validation.dirty.demo_account_userid}
                            value={reviewInformation.demo_account_userid}
                            previousValue={diff.demo_account_userid}
                            length={
                                reviewInformation.demo_account_userid &&
                                reviewInformation.demo_account_userid.length
                            }
                            max_length={
                                reviewInformation.options.demo_account_userid &&
                                reviewInformation.options.demo_account_userid
                                    .max_length
                            }
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="demo-account-password"
                        label="Demo account password">
                        <TextField
                            name="demo_account_password"
                            md={5}
                            error={validation.errors.demo_account_password}
                            progress={
                                validation.isLoading.demo_account_password
                            }
                            dirty={validation.dirty.demo_account_password}
                            value={reviewInformation.demo_account_password}
                            previousValue={diff.demo_account_password}
                            length={
                                reviewInformation.demo_account_password &&
                                reviewInformation.demo_account_password.length
                            }
                            max_length={
                                reviewInformation.options
                                    .demo_account_password &&
                                reviewInformation.options.demo_account_password
                                    .max_length
                            }
                            readonly={readonly}
                            type="text"
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow id="review-notes" label="Review notes">
                        <TextArea
                            name="review_notes"
                            rows="8"
                            md={12}
                            error={validation.errors.review_notes}
                            progress={validation.isLoading.review_notes}
                            dirty={validation.dirty.review_notes}
                            value={reviewInformation.review_notes}
                            previousValue={diff.review_notes}
                            length={
                                reviewInformation.review_notes &&
                                reviewInformation.review_notes.length
                            }
                            max_length={
                                reviewInformation.options.review_notes &&
                                reviewInformation.options.review_notes
                                    .max_length
                            }
                            readonly={readonly}
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="attachment-file"
                        label="Attachment file"
                        help={!readonly ? `You can attach specific app documentation, demo videos, and other items to help prevent
                         delays during the app review process. Make sure you use files with the following extensions:
                         .pdf, .doc, .docx, .rtf, .pages, .xls, .xlsx, .numbers, .zip, .rar, .plist, .crash, .jpg,
                         .png, .mp4, or .avi.` : null}>
                        <SelectFileField
                            xs={12}
                            sm={12}
                            md={12}
                            initialFile={reviewInformation.attachment_file}
                            error={validation.errors.attachment_file}
                            progress={
                                validation.isLoading.attachment_file
                            }
                            dirty={validation.dirty.attachment_file}
                            readonly={readonly}
                            onChange={file =>
                                props.handleChangeFile(
                                    'attachment_file',
                                    file,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            onDelete={() =>
                                props.handleDeleteFile(
                                    'attachment_file',
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
            <Section id="version-release" title="Version Release">
                <Row>
                    <FieldRow
                        id="release_settings"
                        label="Choose option"
                        required>
                        <ReleaseSettingsPicker
                            error={
                                validation.errors.release_settings ||
                                validation.errors.release_auto_date
                            }
                            progress={
                                validation.isLoading.release_settings ||
                                validation.isLoading.release_auto_date
                            }
                            dirty={
                                validation.dirty.release_settings ||
                                validation.dirty.release_auto_date
                            }
                            value={reviewInformation.release_settings}
                            previousValue={diff.release_settings}
                            releaseDate={reviewInformation.release_auto_date}
                            previousReleaseDate={diff.release_auto_date}
                            readonly={readonly}
                            minDate={minDate}
                            onChange={event =>
                                props.handleChangeReleaseSettings(
                                    event,
                                    reviewInformation.options.release_settings,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            onDateChange={date => {
                                props.handleChangeDate(
                                    date,
                                    props.csrf_token,
                                    props.app_version
                                );
                            }}
                        />
                    </FieldRow>
                </Row>
            </Section>
            <Section id="submission-info" title="Submission Info">
                <Row>
                    <FieldRow id="copyright" label="Copyright" required>
                        <TextField
                            name="copyright"
                            md={9}
                            value={reviewInformation.copyright}
                            previousValue={diff.copyright}
                            type="text"
                            error={validation.errors.copyright}
                            progress={validation.isLoading.copyright}
                            dirty={validation.dirty.copyright}
                            readonly={readonly}
                            onChange={props.handleChangeTextInput}
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="in-app-purchase"
                        label="In-app purchase"
                        required>
                        <MultiRadioField
                            name="in_app_purchase"
                            id="in-app-purchase"
                            error={validation.errors.in_app_purchase}
                            progress={validation.isLoading.in_app_purchase}
                            dirty={validation.dirty.in_app_purchase}
                            selected={mapToNumericBooleanOptions(
                                reviewInformation.in_app_purchase
                            )}
                            readonly={readonly}
                            onChange={event =>
                                props.handleChangeRadio(
                                    event,
                                    reviewInformation.options.in_app_purchase,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            options={numericBooleanOptions}
                        />
                    </FieldRow>
                    <FieldRow id="is-gps-enabled" label="GPS enabled?" required>
                        <MultiRadioField
                            name="is_gps_enabled"
                            id="is-gps-enabled"
                            error={validation.errors.is_gps_enabled}
                            progress={validation.isLoading.is_gps_enabled}
                            dirty={validation.dirty.is_gps_enabled}
                            selected={mapToNumericBooleanOptions(
                                reviewInformation.is_gps_enabled
                            )}
                            readonly={readonly}
                            onChange={event =>
                                props.handleChangeRadio(
                                    event,
                                    reviewInformation.options.is_gps_enabled,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            options={numericBooleanOptions}
                        />
                    </FieldRow>
                    <ContentRights
                        validation={validation}
                        reviewInformation={reviewInformation}
                        readonly={readonly}
                        props={props}
                    />
                    <IDFA
                        validation={validation}
                        reviewInformation={reviewInformation}
                        readonly={readonly}
                        props={props}
                        advertisingSectionDisabled={advertisingSectionDisabled}
                    />
                    <ExportCompliance
                        validation={validation}
                        reviewInformation={reviewInformation}
                        readonly={readonly}
                        props={props}
                    />
                </Row>
            </Section>
        </Grid>
    );
};

export default ReviewForm;
