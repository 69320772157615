import React from 'react';

const BundleURLTypes = ({ bundle_url_types }) => {
    return (
        <ol className="distribution-certificate-list">
            {bundle_url_types.map((bundle_url_type, index) => {
                return (
                    <li key={index}>
                        <ul>
                            {bundle_url_type.bundle_url_name && (
                                <li>
                                    <span>
                                        URL Name:{' '}
                                        {bundle_url_type.bundle_url_name}
                                    </span>
                                </li>
                            )}
                            <li>
                                <span>
                                    URL Schemes:{' '}
                                    {bundle_url_type.bundle_url_schemes}
                                </span>
                            </li>
                        </ul>
                    </li>
                );
            })}
        </ol>
    );
};

export default BundleURLTypes;
