// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import EntitlementRow from './EntitlementRow.jsx';

export default class EntitlementTable extends React.Component {
    constructor(props) {
        super(props);
    }

    handleChange = (change) => {
        this.props.onChange(change);
    }

    render() {
        const rows = this.props.entitlements
            .map(entitlement => (
                <EntitlementRow
                    entitlement={entitlement}
                    key={entitlement.name}
                    onChange={this.handleChange}
                />
            ));

        return (
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">Entitlement</th>
                        <th scope="col">Delete</th>
                        <th scope="col" style={{ width: '34%' }}>
                            Take values from submitted IPA - values from last
                            version shown
                        </th>
                        <th scope="col">Use custom value</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
        );
    }
}
