import React from 'react';

//TODO: deprecated, change to AssetPlaceholder component
export default function NoImagePreview({ style, video = false }) {
    return (
        <div className="dropzone-no-image-preview" style={style}>
            No {video ? 'videos' : 'pictures'} uploaded
        </div>
    );
}
