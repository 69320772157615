import { put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

const INITIALIZE_DEVICE_INSTALLATION_DATA =
    'AppVersion/INITIALIZE_DEVICE_INSTALLATION_DATA';
const INITIALIZE_DEVICE_INSTALLATION_DATA_FULFILLED =
    'AppVersion/INITIALIZE_DEVICE_INSTALLATION_DATA_FULFILLED';
const CLEAR_DEVICE_INSTALLATION_DATA =
    'AppVersion/CLEAR_DEVICE_INSTALLATION_DATA';
export const DEVICE_INSTALLATION_STATUS =
    'AppVersion/DEVICE_INSTALLATION_LOADED';

export const getDeviceInstallationDataEndpoint = store =>
    store.appVersion.appContext.deviceInstallationsEndpoint;
export const getDeviceInstallationStatus = store =>
    store.appVersion.appContext.deviceInstallationDataStatus;

export const initializeDeviceInstallationData = endpoint => {
    return { type: INITIALIZE_DEVICE_INSTALLATION_DATA, endpoint: endpoint };
};

const setLoadingStatus = status => {
    return { type: DEVICE_INSTALLATION_STATUS, payload: status };
};

const deviceInstallationData = (state = {}, action) => {
    switch (action.type) {
        case INITIALIZE_DEVICE_INSTALLATION_DATA_FULFILLED:
            return action.payload;
        case CLEAR_DEVICE_INSTALLATION_DATA:
            return {};
        default:
            return state;
    }
};

function* initializeDeviceInstallationDataAsync(action) {
    yield put(setLoadingStatus('loading'));
    yield put({ type: CLEAR_DEVICE_INSTALLATION_DATA });
    let response = yield axios.get(action.endpoint);
    let data = response.data;
    yield put({
        type: INITIALIZE_DEVICE_INSTALLATION_DATA_FULFILLED,
        payload: data,
    });
    yield put(setLoadingStatus('loaded'));
}

export function* watchInitializeDeviceInstallationDataAsync() {
    yield takeEvery(
        INITIALIZE_DEVICE_INSTALLATION_DATA,
        initializeDeviceInstallationDataAsync
    );
}

export const sagas = [watchInitializeDeviceInstallationDataAsync()];

export default deviceInstallationData;
