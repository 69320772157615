import React, { Fragment } from 'react';
import Section from './layout/Section.jsx';
import { Row } from 'react-bootstrap';
import FieldRow from './layout/FieldRow.jsx';
import TextField from './inputs/TextField.jsx';
import TextArea from './inputs/TextArea.jsx';
import WhatsNew from './AppStoreComponents/WhatsNew';

export default function AppStoreProductDetailsTab({
    metadata,
    validation,
    readonly,
    diff,
    options,
    ...props
}) {
    const languageCode = metadata.language.code;
    return (
        <Fragment>
            <Section>
                <Row>
                    <FieldRow id="app_title" label="App title" required>
                        <TextField
                            name="app_title"
                            md={9}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode].app_title
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode].app_title
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].app_title
                            }
                            value={metadata.app_title}
                            length={
                                metadata.app_title && metadata.app_title.length
                            }
                            max_length={
                                options.app_title &&
                                options.app_title.max_length
                            }
                            readonly={readonly}
                            previousValue={
                                diff && diff.application_name
                            }
                            type="text"
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow id="subtitle" label="Subtitle">
                        <TextField
                            name="subtitle"
                            md={9}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode].subtitle
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode].subtitle
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].subtitle
                            }
                            value={metadata.subtitle}
                            length={
                                metadata.subtitle && metadata.subtitle.length
                            }
                            max_length={
                                options.subtitle && options.subtitle.max_length
                            }
                            readonly={readonly}
                            previousValue={
                                diff && diff.subtitle
                            }
                            type="text"
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                </Row>
                <Row>
                    <FieldRow id="description" label="Description" required>
                        <TextArea
                            name="description"
                            rows="8"
                            md={12}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode].description
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode].description
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].description
                            }
                            value={metadata.description}
                            length={
                                metadata.description &&
                                metadata.description.length
                            }
                            max_length={
                                options.description &&
                                options.description.max_length
                            }
                            readonly={readonly}
                            previousValue={
                                 diff && diff.description
                            }
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                    <WhatsNew
                        metadata={metadata}
                        validation={validation}
                        options={options}
                        diff={diff}
                        readonly={readonly}
                        languageCode={languageCode}
                        csrf_token={props.csrf_token}
                        app_version={props.app_version}
                        handleChangeTextInput={props.handleChangeTextInput}
                        handleBlurTextInput={props.handleBlurTextInput}
                    />
                    <FieldRow id="keywords" label="Keywords" required>
                        <TextField
                            name="keywords"
                            md={9}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode].keywords
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode].keywords
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].keywords
                            }
                            value={metadata.keywords}
                            length={
                                metadata.keywords && metadata.keywords.length
                            }
                            max_length={
                                options.keywords && options.keywords.max_length
                            }
                            readonly={readonly}
                            previousValue={
                               diff && diff.keywords
                            }
                            type="text"
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow id="promotional-text" label="Promotional text">
                        <TextArea
                            name="promotional_text"
                            rows="3"
                            md={12}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode].promotional_text
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode]
                                    .promotional_text
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].promotional_text
                            }
                            value={metadata.promotional_text}
                            length={
                                metadata.promotional_text &&
                                metadata.promotional_text.length
                            }
                            max_length={
                                options.promotional_text &&
                                options.promotional_text.max_length
                            }
                            readonly={readonly}
                            previousValue={
                                diff && diff.promotional_text
                            }
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>

            <Section id="metadata-urls">
                <Row>
                    <FieldRow id="support_url" label="Support URL" required>
                        <TextField
                            name="support_url"
                            md={9}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode].support_url
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode].support_url
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].support_url
                            }
                            value={metadata.support_url}
                            length={
                                metadata.support_url &&
                                metadata.support_url.length
                            }
                            max_length={
                                options.support_url &&
                                options.support_url.max_length
                            }
                            readonly={readonly}
                            previousValue={
                                diff && diff.support_url
                            }
                            type="text"
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow id="marketing_url" label="Marketing URL">
                        <TextField
                            name="marketing_url"
                            md={9}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode].marketing_url
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode].marketing_url
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode].marketing_url
                            }
                            value={metadata.marketing_url}
                            length={
                                metadata.marketing_url &&
                                metadata.marketing_url.length
                            }
                            max_length={
                                options.marketing_url &&
                                options.marketing_url.max_length
                            }
                            readonly={readonly}
                            previousValue={
                                diff && diff.marketing_url
                            }
                            type="text"
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                    <FieldRow
                        id="privacy_policy_url"
                        label="Privacy Policy URL"
                        required>
                        <TextField
                            name="privacy_policy_url"
                            md={9}
                            error={
                                validation.errors[languageCode] &&
                                validation.errors[languageCode]
                                    .privacy_policy_url
                            }
                            progress={
                                validation.isLoading[languageCode] &&
                                validation.isLoading[languageCode]
                                    .privacy_policy_url
                            }
                            dirty={
                                validation.dirty[languageCode] &&
                                validation.dirty[languageCode]
                                    .privacy_policy_url
                            }
                            value={metadata.privacy_policy_url}
                            length={
                                metadata.privacy_policy_url &&
                                metadata.privacy_policy_url.length
                            }
                            max_length={
                                options.privacy_policy_url &&
                                options.privacy_policy_url.max_length
                            }
                            readonly={readonly}
                            previousValue={
                                diff &&  diff.privacy_policy_url
                            }
                            type="text"
                            onChange={event =>
                                props.handleChangeTextInput(event, languageCode)
                            }
                            onBlur={event =>
                                props.handleBlurTextInput(
                                    event,
                                    props.csrf_token,
                                    props.app_version,
                                    languageCode
                                )
                            }
                        />
                    </FieldRow>
                </Row>
            </Section>
        </Fragment>
    );
}
