import React from 'react';
import {postJSON} from '../Utils/postJSON.jsx';
import getJSON from '../Utils/getJSON.jsx';

/**
 * Higher-Order-Component (HOC) to reuse fetching of certain data
 *
 * Props:
 *  - endpoint: location of data
 *  - sortingEndpoint?: endpoint to save current sorting state
 *  - updateInterval?: interval of fetch requests in milli-seconds default is 5000ms
 *  - sorting?: initial sorting object
 *  - hasPagination? default value: false
 *
 * @param WrappedComponent the component that should receive the data
 */

export const PAGINATION = {
    NEXT: 1,
    PREVIOUS: -1,
};

export function withDataFetching(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                data: [],
                count: 0,
                loadingElements: true,
                sorting: props.sorting
                    ? props.sorting
                    : {
                        sortBy: '',
                        inverse: 1,
                    },
                pagination: {
                    limit: 100,
                    offset: 0,
                },
                timeoutID: null,
            };
        }

        componentDidMount() {
            this.fetchElements();
        }

        componentWillUnmount() {
            if (this.state.timeoutID)
                clearInterval(this.state.timeoutID);
        }

        fetchElements = () => {
            const {endpoint, hasPagination} = this.props;
            const {
                pagination: {offset, limit},
            } = this.state;

            const dataEndpoint = hasPagination
                ? `${endpoint}?limit=${limit}&offset=${offset}`
                : endpoint;
            getJSON(dataEndpoint)
                .then(response => {
                    let data = hasPagination ? response.results : response
                    // The data attribute could possibly be a corrupted array
                    // in string format. This probably is due to network issues.
                    // To solve the symptom of this problem (that the apps list
                    // disapear) we check to see if it is an array.
                    // Bad responses can be ignored, as this component is
                    // refreshed every 5/15 seconds
                    data = Array.isArray(data) ? data : [];

                    this.setState({
                        count: hasPagination ? response.count : 0,
                        data: data,
                        loadingElements: false,
                    });
                })
                .catch(error => {
                    console.error(error);
                })
                .finally(() => {
                    let requestInterval = this.props.updateInterval
                        ? this.props.updateInterval
                        : 5000;
                    let timeoutID = setTimeout(this.fetchElements, requestInterval);
                    this.setState({timeoutID: timeoutID});
                });
        };

        getPage = page => {
            const {
                pagination: { limit, offset },
                count,
            } = this.state;
            const newOffset = offset + limit * page;
            if (newOffset >= count) return;
            this.setState(
                {
                    pagination: {
                        limit: limit,
                        offset: newOffset > 0 ? newOffset : 0,
                    },
                },
                () => this.fetchElements()
            );
        };

        sortByAttributeName = attributeName => {
            const { sorting } = this.state;
            const newSorting = {
                sortBy: attributeName,
                inverse:
                    sorting.sortBy === attributeName
                        ? sorting.inverse * -1
                        : sorting.inverse,
            };
            if (this.props.sortingEndpoint)
                postJSON(this.props.sortingEndpoint, newSorting);

            this.setState({
                sorting: newSorting,
            });
        };

        render() {
            const {
                endpoint,
                sortingEndpoint,
                updateInterval,
                ...passThroughProps
            } = this.props;
            const { data, loadingElements, sorting } = this.state;
            // delete props that are also named like state variables to avoid overwriting the state
            Object.keys(this.state).map(elem => delete passThroughProps[elem]);

            return (
                <WrappedComponent
                    data={data}
                    loadingElements={loadingElements}
                    sorting={sorting}
                    sortByAttributeName={this.sortByAttributeName}
                    getPage={this.getPage}
                    {...passThroughProps}
                />
            );
        }
    };
}
