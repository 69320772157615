// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import {
    Glyphicon,
    ListGroup,
    ListGroupItem,
    Panel,
    Col
} from 'react-bootstrap';
import getBLOB from "../Utils/getBLOB";
import getJSON from '../Utils/getJSON.jsx';
import ReplyToRejectionButton from './ReplyToRejectionModal.jsx';

class Item extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: props.name ? props.name : 'An App Owner'
        };
    }
    handleDownload(rejection_id) {
        if (this.props.endpoint.trim().split('/').length >= 3) {
            getBLOB("/apps/" + this.props.endpoint.trim().split('/')[2] + "/versions/" +
                rejection_id + "/download-rejection-attachment")
           .then((response) => {
                 const url = window.URL.createObjectURL(new Blob([response]));
                 const link = document.createElement('a');
                 link.href = url;
                 link.setAttribute('download', this.props.attachment);
                 document.body.appendChild(link);
                 link.click();
          });
        }
    };


    render() {
        return (<ListGroup className="without-borders">
            <ListGroupItem className="without-borders">
                <Col md={12} style={{ fontSize: '120%' }}>
                    <div className="display-span-inline">
                        {
                            this.props.parentName == null
                                ?
                                    <span>{`${this.state.userName } rejected publishing on  `}
                                        <span style={{ color: 'gray' }}>{this.props.time}</span>
                                    </span>
                                : <span>{`${this.state.userName } replied to ${ this.props.parentName } on `}
                                    <span style={{ color: 'gray' }}>{this.props.time}</span>
                                </span>

                        }
                    </div>
                    <div className="rejection-history-reply-textarea">
                        <div>{ this.props.reason }</div>

                        {
                            this.props.attachment.trim() !== "" ?
                                <div>
                                    Attachment:
                                    <button
                                        className="rejection-download-attachment-button"
                                        onClick={() => this.handleDownload(this.props.id)}
                                    >
                                        Download Attachment
                                    </button>
                                </div>
                                :
                                null
                        }
                    </div>

                </Col>
                <div className="rejection-history-reply-button">
                    <ReplyToRejectionButton endpoint={this.props.reply}/>
                </div>
                {this.props.children}
            </ListGroupItem>
        </ListGroup>);
    }
}

export default class RejectionHistory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rejections: [],
            isExpanded: false,
        };
    }

    async componentDidMount() {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
            rejections: await getJSON(this.props.endpoint),
        });
    }

    handleChange = () => {
        this.setState({
            isExpanded: !this.state.isExpanded,
        });
    };

    replyList = (rejections, parentName) => {
        const children = (comment_id, parentName) => {
            if (comment_id) {
                return this.replyList(comment_id, parentName);
            }
        };

        return rejections.map((node, index) => {
            const commentOwnerName = node.user__user__first_name ? `${node.user__user__first_name} ${node.user__user__last_name}` : null;
            return (<Item key={index}
                parentName={parentName}
                reason={node.reason}
                name={commentOwnerName}
                time={node.timestamp}
                reply={node.reply_endpoint}
                attachment={node.attachment}
                id={node.id}
                endpoint={this.props.endpoint}>
                {children(node.comment_id, commentOwnerName)}
            </Item>);
        });
    };

    render() {
        const glyph = this.state.isExpanded ? 'chevron-down' : 'chevron-right';
        return (
            <div>
                {this.state.rejections.length != 0 &&
                <div className={this.props.indent === 'true'
                    ? 'content version-info' : ''}>
                    <Panel id='rejection-history'
                        className='panel panel-default panel-no-border'
                        onToggle={this.handleChange}>
                        <Panel.Toggle componentClass='a'>
                            <h3 className={'metadata-heading'}>Rejection history </h3>
                            <Glyphicon glyph={glyph}/>
                        </Panel.Toggle>
                        <Panel.Collapse>
                            <Panel.Body>
                                {this.replyList(this.state.rejections)}
                            </Panel.Body>
                        </Panel.Collapse>
                    </Panel>
                </div>
                }
            </div>
        );
    }
}
