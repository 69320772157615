import React from 'react';

const DisplayNoAppsText = ({ showRetiredApps }) => {
    return (
        <div id="no-apps">
            <img src={`${staticsPath}img/no-apps.png`} />
            <br />
            <span>
                You have no {showRetiredApps ? ' retired apps' : 'apps'}
            </span>
        </div>
    );
};

export default DisplayNoAppsText;
