import React from 'react';
import axios from 'axios';
import VmWareAirWatchOrganizationGroupTreeItem from './VmWareAirWatchOrganizationGroupTreeItem.jsx';
import Spinner from "../Common/Spinner";

export default class VmWareAirWatchOrganizationGroupTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            itemDict: {},
            items: [],
            loading: true,
        };
        this.checkItem = this.checkItem.bind(this);
        this.expandButtonClick = this.expandButtonClick.bind(this);
        this.renderItems = this.renderItems.bind(this);
        this.constructItems = this.constructItems.bind(this);
        this.constructItemDict = this.constructItemDict.bind(this);
        this.propagateSelectedItems = this.propagateSelectedItems.bind(this);
        this.deselectAll = this.deselectAll.bind(this);
    }

    componentDidMount() {
        const self = this;
        axios.get(self.props.endpoint).then(response => {
            const items = JSON.parse(response.data.data);
            const itemDict = this.constructItemDict(items, 0);
            self.setState({
                items: items,
            });
            self.propagateSelectedItems(itemDict);
        })
        .finally(() => {
            self.setState({loading: false})
        });
    }

    constructItemDict(items, order) {
        let itemDict = {};
        if (items && items.length > 0) {
            items.forEach((item, index) => {
                itemDict = Object.assign(
                    itemDict,
                    this.constructItemDict(item.children)
                );
                item.order = order + index;
                itemDict[item.value] = item;
            });
        }
        return itemDict;
    }

    setChildrenChecks(itemDict, children, disabled) {
        const setChildrenChecks = this.setChildrenChecks.bind(this);
        if (children && children.length > 0) {
            children.forEach(child => {
                itemDict[child.value].checked = false;
                itemDict[child.value].disabled =
                    itemDict[child.value].predisabled || disabled;
                setChildrenChecks(itemDict, child.children, disabled);
            });
        }
    }

    checkItem(item) {
        const itemDict = this.state.itemDict;
        const self = this;
        return function(event) {
            if (item.disabled) {
                return;
            }
            itemDict[item.value].checked = !item.checked;
            self.setChildrenChecks(
                itemDict,
                item.children,
                itemDict[item.value].checked
            );
            self.propagateSelectedItems(itemDict);
        };
    }

    propagateSelectedItems(itemDict) {
        const self = this;
        const setStateCallback = function() {
            const selectedItems = Object.keys(itemDict).filter(
                item => itemDict[item].checked
            );
            if (self.props.onItemSelected) {
                self.props.onItemSelected(selectedItems);
            }
        };
        this.setState(
            {
                itemDict: itemDict,
            },
            setStateCallback
        );
    }

    expandButtonClick(item) {
        const itemDict = this.state.itemDict;
        const self = this;
        const setChildrenVisibility = function(children) {
            if (children && children.length > 0) {
                children.forEach(child => {
                    itemDict[child.value].visible = !child.visible;
                    setChildrenVisibility(child.children);
                });
            }
        };
        return function(event) {
            itemDict[item.value].expanded = !item.expanded;
            setChildrenVisibility(item.children);
            self.propagateSelectedItems(itemDict);
        };
    }

    constructItems(items, level) {
        const renderItemsList = [];
        if (items && items.length > 0) {
            items.forEach(item => {
                renderItemsList.push(
                    <VmWareAirWatchOrganizationGroupTreeItem
                        key={item.value}
                        item={item}
                        level={level}
                        checkItem={this.checkItem(item)}
                        expandButtonClick={this.expandButtonClick(item)}
                        showCheckboxes={this.props.showCheckboxes}
                    />
                );
                renderItemsList.push(
                    ...this.constructItems(item.children, level + 1)
                );
            });
        }
        return renderItemsList;
    }

    renderItems(itemDict) {
        const items = Object.keys(itemDict)
            .map(key => itemDict[key])
            .filter(item => !isNaN(item.order));
        return this.constructItems(items, 0);
    }

    deselectAll() {
        const setChildrenChecks = this.setChildrenChecks.bind(this);
        const itemDict = this.state.itemDict;
        const currentSelections = Object.keys(itemDict).filter(
            item => itemDict[item].checked
        );
        if (this.props.groupsSelected) {
            Object.keys(itemDict).forEach(key => {
                itemDict[key].checked = false;
                setChildrenChecks(itemDict, itemDict[key].children, false);
            });
        } else {
            this.state.currentSelections.forEach(key => {
                itemDict[key].checked = true;
                setChildrenChecks(itemDict, itemDict[key].children, true);
            });
        }
        this.propagateSelectedItems(itemDict);
        this.setState({
            currentSelections: currentSelections,
        });
    }

    render() {
        return (
            <div>
                {this.props.showSelectAllButton && (
                    <div
                        className={'btn btn-primary deselect-all-button'}
                        onClick={this.deselectAll}>
                        {this.props.groupsSelected ? 'Deselect all' : 'Undo'}
                    </div>
                )}
                {this.state.loading && <Spinner removeMarginTop />}
                {!this.state.loading && this.state.items.length === 0 && <p>No Organization Groups - Please go to the
                    AirWatch target details page in order to synchronize your OGs</p>}
                {this.renderItems(this.state.itemDict)}
            </div>
        );
    }
}
