import React from 'react';
import ShowDiff from './ShowDiff.jsx';
import getJSON from '../../Utils/getJSON.jsx';
import ReactDOM from 'react-dom';
import DifferenceToggle from './DifferenceToggle.jsx';

export default class RenderDifference extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_difference: false,
            loading: true,
        };
    }
    async componentDidMount() {
        const version_id = this.props.version;
        const data = await getJSON(
            `/api/v1/versions/diff?version=${version_id}`
        );

        const {
            all_countries_metadata,
            application_data,
            show_difference,
        } = data;
        this.setState({
            all_countries_metadata: all_countries_metadata,
            application_data: application_data,
            show_difference: show_difference,
            loading: false,
        });
    }

    componentDidUpdate() {
        const {
            application_data,
            all_countries_metadata,
            show_difference,
            loading,
        } = this.state;

        if (!loading && show_difference) {
            for (let country of all_countries_metadata) {
                for (let metadata of country) {
                    try {
                        ReactDOM.render(
                            <ShowDiff
                                inputA={metadata.previous_value}
                                inputB={metadata.current_value}
                                type="sentences"
                            />,
                            document.getElementById(metadata.element_id)
                        );
                    } catch (error) {
                        // If the metadata is deleted, then there will be no DOM element for it to be rendered.
                    }
                }
            }
            for (let item of application_data) {
                try {
                    ReactDOM.render(
                        <ShowDiff
                            inputA={item.previous_value}
                            inputB={item.current_value}
                            type="sentences"
                        />,
                        document.getElementById(item.element_id)
                    );
                } catch (error) {}
            }
        }
    }

    render() {
        return (
            <div>
                <DifferenceToggle
                    show_difference={this.state.show_difference}
                />
            </div>
        );
    }
}
