import React, { Fragment } from 'react';

import FieldRow from '../../layout/FieldRow';
import MultiRadioField, {
    mapToNumericBooleanOptions,
    numericBooleanOptions,
} from '../../inputs/MultiRadioField';
import Expandable from '../../Expandable';
import { Col, Row } from 'react-bootstrap';
import CheckboxField from '../../inputs/CheckboxField';

const handleIDFAChangeRadio = (event, props) => {
    const reset = [
        'serves_ads',
        'tracks_install',
        'tracks_action',
        'limits_tracking',
    ];
    props.handleChangeRadio(event, null, props.csrf_token, props.app_version, reset);
    reset.map(name => props.nullifyCheckbox(`${name}`));
};

const IDFA = ({ validation, reviewInformation, readonly, props, advertisingSectionDisabled }) => {
    return (
        <Fragment>
            <FieldRow
                id="use-advertising-identifier"
                label="Use advertising identified? (IDFA)?"
                required>
                <MultiRadioField
                    name="uses_idfa"
                    id="use-advertising-identifier"
                    error={validation.errors.uses_idfa}
                    progress={validation.isLoading.uses_idfa}
                    dirty={validation.dirty.uses_idfa}
                    selected={mapToNumericBooleanOptions(
                        reviewInformation.uses_idfa
                    )}
                    readonly={readonly}
                    onChange={event =>
                        handleIDFAChangeRadio(
                            event,
                            props
                        )
                    }
                    options={numericBooleanOptions}
                />
            </FieldRow>
            <Expandable expanded={!advertisingSectionDisabled}>
                <Row>
                    <Col mdOffset={3} md={9}>
                        <CheckboxField
                            md={10}
                            name="serves_ads"
                            error={validation.errors.serves_ads}
                            progress={validation.isLoading.serves_ads}
                            dirty={validation.dirty.serves_ads}
                            checked={reviewInformation.serves_ads}
                            readonly={readonly}
                            onChange={event =>
                                props.handleChangeCheckbox(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            title="Serve advertisements within the app"
                            disabled={advertisingSectionDisabled}
                        />
                        <CheckboxField
                            md={10}
                            name="tracks_install"
                            error={validation.errors.tracks_install}
                            progress={validation.isLoading.tracks_install}
                            dirty={validation.dirty.tracks_install}
                            checked={reviewInformation.tracks_install}
                            readonly={readonly}
                            onChange={event =>
                                props.handleChangeCheckbox(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            title="Attribute this app installation to a previously served advertisement"
                            disabled={advertisingSectionDisabled}
                        />
                        <CheckboxField
                            md={10}
                            name="tracks_action"
                            error={validation.errors.tracks_action}
                            progress={validation.isLoading.tracks_action}
                            dirty={validation.dirty.tracks_action}
                            checked={reviewInformation.tracks_action}
                            readonly={readonly}
                            onChange={event =>
                                props.handleChangeCheckbox(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            title="Attribute an action taken within this app to a previously served advertisement"
                            disabled={advertisingSectionDisabled}
                        />
                        <CheckboxField
                            md={10}
                            name="limits_tracking"
                            error={validation.errors.limits_tracking}
                            progress={validation.isLoading.limits_tracking}
                            dirty={validation.dirty.limits_tracking}
                            checked={reviewInformation.limits_tracking}
                            readonly={readonly}
                            onChange={event =>
                                props.handleChangeCheckbox(
                                    event,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            title="Confirm Limit Add tracking setting in iOS *"
                            description="* I confirm that this app, and any third party that interfaces
              with this app, uses the Advertising Identifier checked and
              honors a user’s Limit Ad Tracking setting in iOS and, when it is
              enabled by a user, this app does not use Advertising Identifier,
              and any information obtained through the use of the Advertising
              Identifier, in any way other than for “Limited Advertising
              Purposes” as defined in the iOS Developer Program License
              Agreement."
                            disabled={advertisingSectionDisabled}
                        />
                    </Col>
                </Row>
            </Expandable>
        </Fragment>
    );
};

export default IDFA;
