import React from 'react';
import Date from '../../../Common/Date.jsx';

const CertificateList = ({ certificates }) => {
    return (
        <ol className="distribution-certificate-list">
            {certificates.map((certificate, index) => {
                return (
                    <li key={index}>
                        <ul>
                            <li>{certificate.common_name}</li>
                            <li>
                                Expiration:{' '}
                                <Date timestamp={certificate.expiration_date} />
                            </li>
                        </ul>
                    </li>
                );
            })}
        </ol>
    );
};

export default CertificateList;
