import React, { Fragment } from 'react';
import FieldRow from '../../../layout/FieldRow';
import MultiRadioField, {
    mapToNumericBooleanOptions,
    numericBooleanOptions,
} from '../../../inputs/MultiRadioField';

const EncryptionUpdated = ({
    validation,
    reviewInformation,
    readonly,
    props,
}) => {
    if (!props.is_first_version) {
        return (
            <FieldRow>
                <div>
                    Have you added or made changes to encryption features since
                    your last submission of this app?
                </div>
                <MultiRadioField
                    name="export_compliance_encryption_updated"
                    id="export-compliance-encryption-updated"
                    error={
                        validation.errors.export_compliance_encryption_updated
                    }
                    progress={
                        validation.isLoading
                            .export_compliance_encryption_updated
                    }
                    dirty={
                        validation.dirty.export_compliance_encryption_updated
                    }
                    selected={mapToNumericBooleanOptions(
                        reviewInformation.export_compliance_encryption_updated
                    )}
                    readonly={readonly}
                    onChange={event =>
                        props.handleChangeRadio(
                            event,
                            null,
                            props.csrf_token,
                            props.app_version
                        )
                    }
                    options={numericBooleanOptions}
                />
                <p>
                    Export laws require that products containing encryption must
                    be properly authorized for export. Failure to comply could
                    result in severe penalties.{' '}
                    <a
                        href="http://www.bis.doc.gov/index.php/policy-guidance/encryption"
                        target="_blank">
                        Learn more about export requirements.
                    </a>
                </p>
            </FieldRow>
        );
    } else {
        return <Fragment />;
    }
};

export default EncryptionUpdated;
