import React from 'react';
import { Button } from 'react-bootstrap';
import Remove from './icons/Remove.jsx';

export default function RemoveIconButton({ onClick }) {
    return (
        <Button className="remove-icon-button" bsStyle="link" onClick={onClick}>
            <Remove />
        </Button>
    );
}
