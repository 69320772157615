import React from 'react';
import Expandable from '../../../Expandable';

const ContentRightsWarning = ({ show }) => {
    return (
        <Expandable expanded={show}>
            <div className="submission-information-info-box export-compliance-grid">
                <p className="submission-information-text">
                  Apps that contain, show, or access third-party content must have
                  all the necessary rights to that content or be otherwise permitted
                  to use it under the laws of each App Store country or region
                  in which they're availalbe.
                    <a
                        href="https://developer.apple.com/appstore/mac/resources/approval/guidelines.html#trademarks"
                        target="_blank">
                        Learn more about the App Store guidelines.
                    </a>
                </p>
            </div>
        </Expandable>
    );
};

export default ContentRightsWarning;
