import React from 'react';
import { connect } from 'react-redux';
import hasTabError from '../util/hasTabError.js';
import FormTabs from './FormTabs.jsx';
import {
    SpecificationForm,
    DetailsFormContainer,
    RatingFormContainer,
    ReviewFormContainer,
    ContactFormContainer,
    LocalizableFormContainer,
    AdditionalInfoFormContainer,
    AppStoreAssetsFormContainer,
} from './tabs/index.jsx';
import { makeTabsSelector } from '../selectors/tabs';
import AppPrivacyContainer from '../containers/AppDetailsContainer';
import TestflightContainer from '../containers/TestflightContainer.jsx';

const TABS = [
    {
        key: 'specification',
        title: 'Binary Metadata',
        component: <SpecificationForm />,
    },
    {
        key: 'app_details',
        title: 'App details',
        component: <DetailsFormContainer />,
    },
    {
        key: 'app_privacy_questionnaire',
        title: 'App Privacy',
        component: <AppPrivacyContainer />,
    },
    {
        key: 'testflight_details',
        title: 'Testflight',
        component: <TestflightContainer />,
    },
    {
        key: 'content_rating',
        title: 'Content rating',
        component: <RatingFormContainer />,
    },
    {
        key: 'review_information',
        title: 'Review information',
        component: <ReviewFormContainer />,
    },
    {
        key: 'contact_details',
        title: 'Contact details',
        component: <ContactFormContainer />,
    },
    {
        key: 'localizable_metadata',
        title: 'Localizable metadata',
        component: <LocalizableFormContainer />,
    },
];

const ASSETS_ONLY_TABS = [
    {
        key: 'specification',
        title: 'Binary Metadata',
        component: <SpecificationForm />,
    },
    {
        key: 'assets',
        title: 'Assets',
        component: <AppStoreAssetsFormContainer />,
    },
];

export const tabsSelector = makeTabsSelector({
    assetsOnlyTabs: ASSETS_ONLY_TABS,
    tabs: TABS,
    customTabComponent: AdditionalInfoFormContainer,
});

const mapStateToProps = store => ({
    tabs: tabsSelector(store).map(tab => ({
        ...tab,
        hasError: hasTabError(tab.key, store.validation.tabErrors),
    })),
});

export default connect(mapStateToProps)(FormTabs);
