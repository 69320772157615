import React from 'react';

export default class Spinner extends React.Component {
    render() {
        const className = `center-horizontally react-spinner ${
            this.props.className
        }`;
        const style = this.props.removeMarginTop ? {"margin-top": 0} : {};
        return (
            <div className={className} style={style}>
                <img
                    className="spinner-image"
                    src={`${staticsPath}/img/loading_hq.gif`}
                />
                <div>
                    {this.props.loadingLabel && (
                        <h3 className="saving">
                            {this.props.loadingLabel}
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                        </h3>
                    )}
                </div>
            </div>
        );
    }
}
