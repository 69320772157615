// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';

export default class EntitlementRow extends React.Component {
    constructor(props) {
        super(props);
        let predefinedValue =
            this.props.entitlement.predefinedValues.length > 0
                ? this.props.entitlement.predefinedValues[0]
                : null;
        let customValue =
            this.props.entitlement.customValue || predefinedValue;
        this.state = {
            selectedOption: this.props.entitlement.selectedOption,
            customValue: customValue,
        };
    }

    handleChange = (event) => {
        const target = event.target;
        const option = target.type === 'radio' ? target.value : 'custom';
        const value =
            target.type === 'radio' ? this.state.customValue : target.value;

        this.setState(
            {
                selectedOption: option,
                customValue: value,
            },
            () => {
                this.notifyParent();
            }
        );
    }

    handleOnFocus = () => {
        this.setState(
            {
                selectedOption: 'custom',
            },
            () => {
                this.notifyParent();
            }
        );
    }

    notifyParent = () => {
        this.props.onChange({
            name: this.props.entitlement.name,
            selectedOption: this.state.selectedOption,
            customValue: this.state.customValue,
        });
    }

    render() {
        const entitlement = this.props.entitlement;
        const predefinedValues = entitlement.predefinedValues;
        return (
            <tr className={'edit_entitlements_table_row'}>
                <th
                    scope="row"
                    style={{ width: '20%' }}
                    title={entitlement.plistKeys.join('\r\n')}>
                    {entitlement.name}
                </th>
                <td>
                    {entitlement.canBeDeleted && (
                        <input
                            value="delete"
                            type="radio"
                            name={entitlement.name}
                            checked={this.state.selectedOption === 'delete'}
                            onChange={this.handleChange}
                        />
                    )}
                </td>
                <td>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <input
                                value="copy"
                                type="radio"
                                name={entitlement.name}
                                checked={this.state.selectedOption === 'copy'}
                                onChange={this.handleChange}
                            />
                        </li>
                        <li className="list-inline-item">
                            {entitlement.value}
                        </li>
                    </ul>
                </td>
                <td>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <input
                                value="custom"
                                type="radio"
                                name={entitlement.name}
                                checked={this.state.selectedOption === 'custom'}
                                onChange={this.handleChange}
                            />
                        </li>
                        <li className="list-inline-item">
                            {predefinedValues.length > 0 ? (
                                <select
                                    name={entitlement.name}
                                    onChange={this.handleChange}
                                    onFocus={this.handleOnFocus}
                                    value={this.state.customValue}>
                                    {predefinedValues.map(predefinedValue => (
                                        <option
                                            key={predefinedValue}
                                            value={predefinedValue}>
                                            {predefinedValue}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type="text"
                                    className="form-control"
                                    value={this.state.customValue}
                                    placeholder={entitlement.customValueType}
                                    onChange={this.handleChange}
                                    onFocus={this.handleOnFocus}
                                />
                            )}
                        </li>
                    </ul>
                    <div>
                        {entitlement.automaticValue
                            ? `If not customized will automatically default to ${
                                entitlement.automaticValue
                            }`
                            : ''}
                    </div>
                </td>
            </tr>
        );
    }
}
