import React from 'react';
import { Row, Col, FormControl } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import FieldFeedback from './FieldFeedback.jsx';
import ReadOnlyDiffValue from './ReadOnlyDiffValue.jsx';
import WordCounter from '../WordCounter.jsx';

const detachedStyle = {
    right: -1,
};

export default class TextField extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value,
        };
        this.onChange = debounce(props.onChange, 250);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.value !== nextProps.value) {
            this.setState({ value: nextProps.value });
        }
    }

    handleChange = e => {
        const val = e.target.value;
        e.persist(); // this is important to preserve event to be handled later, otherwise react will nullify it after returning from this function
        this.setState({ value: val }, () => {
            this.onChange(e);
        });
    };

    render() {
        const {
            xs,
            sm,
            md,
            lg,
            value,
            onBlur,
            type,
            placeholder,
            name,
            error,
            progress,
            dirty,
            detachedFeedback = false,
            readonly = false,
            previousValue,
            length,
            max_length,
        } = this.props;

        return (
            <Row>
                <Col xs={xs} sm={sm} md={md} lg={lg}>
                    {readonly ? (
                        <ReadOnlyDiffValue
                            value={value}
                            previousValue={previousValue}
                        />
                    ) : (
                        <FormControl
                            type={type || 'text'}
                            placeholder={placeholder || ''}
                            onChange={this.handleChange}
                            value={this.state.value || ''}
                            name={name}
                            onBlur={onBlur}
                            maxLength={max_length}
                        />
                    )}
                    <WordCounter length={length} max_length={max_length} />
                </Col>
                <FieldFeedback
                    progress={progress}
                    error={error}
                    dirty={dirty}
                    detached={detachedFeedback}
                    id={name}
                    style={detachedFeedback ? detachedStyle : undefined}
                />
            </Row>
        );
    }
}
