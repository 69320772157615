// incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
// including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
// and jurisdictions.

import React from 'react';
import { Form, FormControl } from 'react-bootstrap';

const SearchBar = ({ onChange }) => {
    return (
        <Form inline>
            <FormControl
                type="text"
                placeholder="Search All"
                onChange={onChange}
            />
        </Form>
    );
};

export default SearchBar;
