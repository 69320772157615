import React, { Component } from 'react';
import RemoveIconButton from '../RemoveIconButton.jsx';
import Video from '../icons/Video.jsx';

//TODO: deprecated, change to AssetVideoPreview component
export default class VideoPreview extends Component {
    componentDidUpdate() {
        this.video.src = this.props.src;
        this.video.load();
    }
    render() {
        const { src, type, style, onDelete } = this.props;
        return (
            <div className="screenshot-dropzone-video-container">
                <div className="screenshot-dropzone-header">
                    <RemoveIconButton onClick={onDelete} />
                    <Video className="screenshot-dropzone-video-icon pull-right" />
                </div>
                <video controls style={style} ref={ref => (this.video = ref)}>
                    <source src={src} type={type} />
                    Your browser does not support the video tag.
                </video>
            </div>
        );
    }
}
