import React from 'react';
import _ from 'lodash';
import GooglePlayLocalizableLanguageMetadata from './GooglePlayLocalizableLanguageMetadata.jsx';
import BaseLocalizableLanguageMetadataList from './BaseLocalizableLanguageMetadataList.jsx';

const GooglePlayLocalizableLanguageMetadataList = ({
    localizable,
    readonly,
    ...props
}) => {
    return (
        <BaseLocalizableLanguageMetadataList localizable={localizable}>
            {metadata => (
                <GooglePlayLocalizableLanguageMetadata
                    key={`localizable-language-${metadata.language.code}`}
                    metadata={metadata}
                    multiple_languages={localizable.languages.length > 1}
                    handleChangeTextInput={props.handleChangeTextInput}
                    handleBlurTextInput={props.handleBlurTextInput}
                    handleChangeScreenshots={props.handleChangeScreenshots}
                    handleDeleteScreenshot={props.handleDeleteScreenshot}
                    handleLoadFromDefaultLanguage={
                        props.handleLoadFromDefaultLanguage
                    }
                    handleLoadFromDefaultLanguageToOthers={
                        props.handleLoadFromDefaultLanguageToOthers
                    }
                    handleScreenshotsError={props.handleScreenshotsError}
                    handleChangeGraphicAsset={props.handleChangeGraphicAsset}
                    handleDeleteGraphicAsset={props.handleDeleteGraphicAsset}
                    app_id={props.app_id}
                    app_version={props.app_version}
                    csrf_token={props.csrf_token}
                    validation={props.validation}
                    readonly={readonly}
                    diff={props.diff[metadata.language.code]}
                    options={localizable.options}
                    handleSetCorrect={props.handleSetCorrect}
                />
            )}
        </BaseLocalizableLanguageMetadataList>
    );
};

export default GooglePlayLocalizableLanguageMetadataList;
