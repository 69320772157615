import React from 'react';

/** If the user can see the retired apps the header is displayed.**/
const DisplayRetiredAppHeader = () => {
    return (
        <div className="alert alert-warning h3 text-justify">
            <strong>Retired Apps</strong>
        </div>
    );
};

export default DisplayRetiredAppHeader;
