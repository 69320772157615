import React from 'react';
import { Button } from 'react-bootstrap';
import Trash from './icons/Trash.jsx';

export default function TrashIconButton({ onClick, ...props }) {
    return (
        <Button
            className="trash-icon-button"
            bsStyle="link"
            onClick={onClick}
            {...props}>
            <Trash />
        </Button>
    );
}
