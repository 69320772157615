import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Checkbox from '../App/Checkbox.jsx';
import { postJSON } from '../Utils/postJSON.jsx';

export default class WelcomeModal extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showModal: true,
            showNextTime: true,
        };
    }

    handleClose = () => {
        this.setState({ showModal: false });
        if (this.state.showNextTime === false)
            postJSON(this.props.endpoint, {});
    };

    handleCheckboxChange = e => {
        this.setState({ showNextTime: !e.target.checked });
    };

    render() {
        const { serviceName, modalBody } = this.props;
        const { showModal } = this.state;
        return (
            <div>
                <Modal
                    backdrop={true}
                    show={showModal}
                    onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Welcome to {serviceName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div dangerouslySetInnerHTML={{ __html: modalBody }} />

                        <Checkbox
                            checkboxText={"Don't show this next time"}
                            changeInput={this.handleCheckboxChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="primary" onClick={this.handleClose}>
                            OK
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
