import React from 'react';
import FieldRow from '../../../layout/FieldRow';
import MultiRadioField, {
    mapToNumericBooleanOptions,
} from '../../../inputs/MultiRadioField';
import ContentRightsWarning from './ContentRightsWarning';

const handleButtonToggle = (event, props) => {
    props.handleChangeRadio(
        event,
        null,
        props.csrf_token,
        props.app_version,
    );
    props.handleChangeRadio(
        { target: { name: 'content_rights_has_rights', value:event.target.value } },
        null,
        props.csrf_token,
        props.app_version,
    );
};

const ContainsThirdPartyContent = ({
    validation,
    reviewInformation,
    readonly,
    props,
}) => {
    const options = [
        { id: 1, name: 'Yes, it contains, shows, or accesses third-party content, and I have the necessary rights' },
        { id: 0, name: 'No, it does not contain, show, or access third-party content' }
    ];
    return (
        <FieldRow
            label="Content Rights"
            required>
            <div>
                Does your app contain, display, or access third-party content?
            </div>
            <ContentRightsWarning
                show={true}
            />
            <MultiRadioField
                collapse
                name="content_rights_contains_third_party_content"
                id="content-rights-contains-third-party-content"
                error={
                    validation.errors
                        .content_rights_contains_third_party_content
                }
                progress={
                    validation.isLoading
                        .content_rights_contains_third_party_content
                }
                dirty={
                    validation.dirty.content_rights_contains_third_party_content
                }
                selected={mapToNumericBooleanOptions(
                    reviewInformation.content_rights_contains_third_party_content
                )}
                readonly={readonly}
                onChange={event => handleButtonToggle(event, props)}
                options={options}
                itemSm={12}
            />
        </FieldRow>
    );
};

export default ContainsThirdPartyContent;
