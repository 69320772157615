import React from 'react';
import { Glyphicon } from 'react-bootstrap';

export default function AssetAdd({ style }) {
    return (
        <div className="asset-add-container">
            <div className="asset-add-inner-container" style={style}>
                <Glyphicon
                    glyph="glyphicon glyphicon-plus"
                    bsClass="glyphicon glyphicon-plus dropzone-glyphicon"
                />
            </div>
        </div>
    );
}
