import React, { Fragment } from 'react';
import { Grid, Row } from 'react-bootstrap';
import moment from 'moment';
import Section from '../layout/Section.jsx';
import FieldRow from '../layout/FieldRow.jsx';
import DatePicker from '../inputs/DatePicker.jsx';
import MultiRadioField, { BooleanOptions } from '../inputs/MultiRadioField.jsx';
import MultiSelectField from '../inputs/MultiSelectField.jsx';
import SelectField from '../inputs/SelectField.jsx';
import TextField from '../inputs/TextField.jsx';
import CheckboxField from '../inputs/CheckboxField.jsx';
import ExternalLink from '../ExternalLink.jsx';
import Header from '../Header.jsx';
import {
    categoriesList,
    countriesList,
    languagesList,
    androidVersionsList,
    androidVersionValueRenderer,
    countriesValueRenderer,
    languagesValueRenderer,
    mapValuesToOptions,
} from '../../util/formatters';
import { calculateMinDate } from '../../util/date';
import HelpTooltip from '../HelpTooltip';

const mapCountryValuesToOptions = mapValuesToOptions('code');
const mapLanguageValuesToOptions = mapValuesToOptions('name_short');

const GooglePlayAppDetails = ({
    appDetails,
    validation,
    readonly,
    diff,
    ...props
}) => {
    const mapAndroidVersionValuesToOptions = mapValuesToOptions(
        'version_number'
    )(appDetails.options.android_versions_supported);
    const mapSupportedCountriesValuesToOptions = mapCountryValuesToOptions(
        appDetails.options.supported_countries
    );
    const minDate = calculateMinDate(appDetails.options.availability_date);

    return (
        <Grid fluid>
            <Header>App Details</Header>
            <Section id="google-play-app-categories">
                <Row>
                    <FieldRow
                        id="availability_date"
                        label="Availability day"
                        required>
                        <DatePicker
                            id="availability_date"
                            minDate={minDate}
                            placeholderText="Select date"
                            error={validation.errors.availability_date}
                            progress={validation.isLoading.availability_date}
                            dirty={validation.dirty.availability_date}
                            selected={
                                appDetails.availability_date
                                    ? moment(appDetails.availability_date)
                                    : undefined
                            }
                            previousValue={diff.availability_date}
                            readonly={readonly}
                            onChange={date => {
                                props.handleChangeDate(
                                    date,
                                    props.csrf_token,
                                    props.app_version
                                );
                            }}
                        />
                    </FieldRow>
                    <FieldRow
                        id="android_version_supported"
                        label="Android version supported">
                        <MultiSelectField
                            name="android_version_supported"
                            label="Android version supported"
                            md={9}
                            options={androidVersionsList(
                                appDetails.options.android_versions_supported
                            )}
                            error={validation.errors.android_versions_supported}
                            progress={
                                validation.isLoading.android_versions_supported
                            }
                            dirty={validation.dirty.android_versions_supported}
                            readonly={readonly}
                            previousValue={diff.android_versions_supported}
                            onMultiChange={value =>
                                props.handleChangeMultiSelect(
                                    value.map(mapAndroidVersionValuesToOptions),
                                    'version_number',
                                    'android_versions_supported',
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            selected={androidVersionsList(
                                appDetails.android_versions_supported
                            )}
                            selectAllLabel={'All versions'}
                            valueRenderer={androidVersionValueRenderer}
                        />
                    </FieldRow>
                </Row>
            </Section>
            <Section id="categorization"
                title={<div>Categorization<HelpTooltip customClass='categorization-transform'
                    message="Choose an application type, category, and tags that best describe the content or main
                  function of your app. These help users discover apps on Google Play."
                /></div>}>
                <FieldRow id="app_type" label="Application type" required>
                    <MultiRadioField
                        id="app_type"
                        name="app_type"
                        itemSm={3}
                        error={validation.errors.app_type}
                        progress={validation.isLoading.app_type}
                        dirty={validation.dirty.app_type}
                        selected={appDetails.app_type}
                        readonly={readonly}
                        onChange={event =>
                            props.handleChangeAppType(
                                event,
                                appDetails.options.app_type,
                                props.csrf_token,
                                props.app_version
                            )
                        }
                        options={appDetails.options.app_type}
                    />
                </FieldRow>
                <FieldRow id="category" label="Category" required>
                    <SelectField
                        name="category"
                        md={5}
                        options={categoriesList(appDetails.options.category)}
                        error={validation.errors.category}
                        progress={validation.isLoading.category}
                        dirty={validation.dirty.category}
                        readonly={readonly}
                        onChange={event =>
                            props.handleChangeSelect(
                                event,
                                appDetails.options.category,
                                props.csrf_token,
                                props.app_version
                            )
                        }
                        value={appDetails.category && appDetails.category.id}
                        previousValue={diff.category}
                    />
                </FieldRow>
            </Section>
            <Section id="languages-and-countries" title="Languages & Countries">
                <Row>
                    <FieldRow
                        id="default_language"
                        label="Default language"
                        required>
                        <SelectField
                            name="default_language"
                            md={5}
                            options={languagesList(
                                appDetails.options.default_language
                            )}
                            error={validation.errors.default_language}
                            progress={validation.isLoading.default_language}
                            dirty={validation.dirty.default_language}
                            value={
                                appDetails.default_language &&
                                appDetails.default_language.name_short
                            }
                            previousValue={diff.default_language}
                            readonly={readonly}
                            onChange={event =>
                                props.handleChangeSelect(
                                    event,
                                    appDetails.options.default_language,
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                        />
                    </FieldRow>
                </Row>
                <Row>
                    <FieldRow
                        id="supported-countries"
                        label="Supported countries"
                        required>
                        <MultiSelectField
                            name="supported_countries"
                            md={9}
                            label="Supported countries"
                            options={countriesList(
                                appDetails.options.supported_countries
                            )}
                            error={validation.errors.supported_countries}
                            progress={validation.isLoading.supported_countries}
                            dirty={validation.dirty.supported_countries}
                            readonly={readonly}
                            previousValue={diff.supported_countries}
                            onMultiChange={value =>
                                props.handleChangeMultiSelect(
                                    value.map(
                                        mapSupportedCountriesValuesToOptions
                                    ),
                                    'code',
                                    'supported_countries',
                                    props.csrf_token,
                                    props.app_version
                                )
                            }
                            selected={countriesList(
                                appDetails.supported_countries
                            )}
                            selectAllLabel={'All countries'}
                            valueRenderer={countriesValueRenderer}
                        />
                    </FieldRow>
                </Row>
            </Section>
            <Section
                id="pricing-and-distribution"
                title="Pricing & Distribution">
                <FieldRow id="is_paid_app" label="Is a paid app?" required>
                    <MultiRadioField
                        id="is_paid_app"
                        name="is_paid_app"
                        error={validation.errors.is_paid_app}
                        progress={validation.isLoading.is_paid_app}
                        dirty={validation.dirty.is_paid_app}
                        selected={appDetails.is_paid_app}
                        readonly={readonly}
                        onChange={event =>
                            props.handleChangeRadio(
                                event,
                                BooleanOptions,
                                props.csrf_token,
                                props.app_version
                            )
                        }
                        options={BooleanOptions}
                    />
                </FieldRow>
                <FieldRow
                    id="in_app_purchasing"
                    label="Has in-app purchasing?"
                    required>
                    <MultiRadioField
                        id="in_app_purchasing"
                        name="in_app_purchasing"
                        error={validation.errors.in_app_purchasing}
                        progress={validation.isLoading.in_app_purchasing}
                        dirty={validation.dirty.in_app_purchasing}
                        selected={appDetails.in_app_purchasing}
                        readonly={readonly}
                        onChange={event =>
                            props.handleChangeRadio(
                                event,
                                BooleanOptions,
                                props.csrf_token,
                                props.app_version
                            )
                        }
                        options={BooleanOptions}
                    />
                </FieldRow>
                <FieldRow id="has_ads" label="Has ads?" required>
                    <MultiRadioField
                        id="has_ads"
                        name="has_ads"
                        error={validation.errors.has_ads}
                        progress={validation.isLoading.has_ads}
                        dirty={validation.dirty.has_ads}
                        selected={appDetails.has_ads}
                        readonly={readonly}
                        onChange={event =>
                            props.handleChangeRadio(
                                event,
                                BooleanOptions,
                                props.csrf_token,
                                props.app_version
                            )
                        }
                        options={BooleanOptions}
                    />
                </FieldRow>
                <FieldRow
                    id="primarily_child_directed"
                    label="Primarily Child Directed?"
                    required>
                    <MultiRadioField
                        id="primarily_child_directed"
                        name="primarily_child_directed"
                        error={validation.errors.primarily_child_directed}
                        progress={validation.isLoading.primarily_child_directed}
                        dirty={validation.dirty.primarily_child_directed}
                        selected={appDetails.primarily_child_directed}
                        readonly={readonly}
                        onChange={event =>
                            props.handleChangeRadio(
                                event,
                                BooleanOptions,
                                props.csrf_token,
                                props.app_version
                            )
                        }
                        options={BooleanOptions}
                    />
                </FieldRow>
            </Section>
            <Section id="privacy-policy" title="Privacy Policy">
                <FieldRow id="privacy_policy"
                    label="Privacy Policy URL"
                    help="If you wish to provide a privacy policy URL for this application, please enter it below.
                    Also, please review our User Data policy to avoid common violations.">
                    <TextField
                        name="privacy_policy"
                        md={9}
                        error={validation.errors.privacy_policy}
                        progress={validation.isLoading.privacy_policy}
                        dirty={validation.dirty.privacy_policy}
                        value={appDetails.privacy_policy}
                        previousValue={diff.privacy_policy}
                        type="text"
                        readonly={readonly}
                        onChange={props.handleChangeTextInput}
                        onBlur={event =>
                            props.handleBlurTextInput(
                                event,
                                props.csrf_token,
                                props.app_version
                            )
                        }
                    />
                </FieldRow>
            </Section>
            <Section id="consent" title="Consent">
                <FieldRow id="developer_program_policies" label="Developer Program Policies" required>
                    <CheckboxField
                        name="developer_program_policies"
                        md={12}
                        detachedFeedback
                        error={validation.errors.developer_program_policies}
                        progress={validation.isLoading.developer_program_policies}
                        dirty={validation.dirty.developer_program_policies}
                        checked={appDetails.developer_program_policies}
                        readonly={readonly}
                        title={
                            <Fragment>
                                <p>
                                  The application meets <ExternalLink href='https://play.google.com/about/developer-content-policy/'>Developer Program Policies</ExternalLink>.
                                  Please check out <ExternalLink href="https://play.google.com/about/developer-content-policy/">these tips on how to create policy compliant app descriptions</ExternalLink>
                                  to avoid some common reasons for app suspension. If your app or store listing is <ExternalLink href="https://support.google.com/googleplay/android-developer/answer/6320428">eligible for advance notice</ExternalLink>
                                  to the Google Play App Review team, <ExternalLink href="https://support.google.com/googleplay/android-developer/answer/6320428">contact us</ExternalLink> prior to publishing.
                                </p>
                            </Fragment>
                        }
                        onChange={event =>
                            props.handleChangeCheckbox(
                                event,
                                props.csrf_token,
                                props.app_version
                            )
                        }
                    />
                </FieldRow>
                <FieldRow id="us_export_laws" label="US export laws" required>
                    <CheckboxField
                        name="us_export_laws"
                        md={12}
                        detachedFeedback
                        error={validation.errors.us_export_laws}
                        progress={validation.isLoading.us_export_laws}
                        dirty={validation.dirty.us_export_laws}
                        checked={appDetails.us_export_laws}
                        readonly={readonly}
                        title={
                            <Fragment>
                                <p>
                                    I acknowledge that my software application
                                    may be subject to United States export laws,
                                    regardless of my location or nationality. I
                                    agree that I have complied with all such
                                    laws, including any requirements for
                                    software with encryption functions. I hereby
                                    certify that my application is authorized
                                    for export from the United States under
                                    these laws.
                                </p>
                                <p>
                                    <ExternalLink href="https://support.google.com/googleplay/android-developer/answer/113770">
                                        Learn more
                                    </ExternalLink>
                                </p>
                            </Fragment>
                        }
                        onChange={event =>
                            props.handleChangeCheckbox(
                                event,
                                props.csrf_token,
                                props.app_version
                            )
                        }
                    />
                </FieldRow>
            </Section>
        </Grid>
    );
};

export default GooglePlayAppDetails;
