import React, { Component } from 'react';
import Section from '../layout/Section';
import RadioFieldWithDescription from '../inputs/RadioFieldWithDescription';
import { connect } from 'react-redux';
import axios from 'axios';
import { updateContext } from '../../actions/addContextToRedux';
import {
    setError,
    setCorrect,
    isLoading,
    isNotLoading,
} from '../../actions/validation.js';
import AppStorePrivateAppRecipients from './AppStorePrivateAppRecipients';
import ErrorFeedback from '../inputs/ErrorFeedback';

const mapStateToProps = store => ({
    readonly: store.context.readonly,
    details: store.context.app_details,
    validation: store.validation,
    app_version: store.context.app_version,
    is_first_version: store.context.is_first_version
});

const mapDispatchToProps = dispatch => ({
    handleChangeDistributionType: async (value, appId, appVersionId) => {
        dispatch(isLoading('distribution_option'));
        try {
            const response = await axios.patch(
                `/api/v1/application/${appId}/appstore/distribution-option/`,
                {
                    distributionOption: value,
                    appVersionId: appVersionId
                }
            );
            dispatch(updateContext('app_details', { distribution_option: response.data.distribution_option }));
            dispatch(isNotLoading('distribution_option'));
            dispatch(setCorrect('distribution_option'));
        } catch (error) {
            dispatch(
                setError(
                    'distribution_option',
                    error.response.data.error || error.response.statusText
                ));
            dispatch(isNotLoading('distribution_option'));
        }
    },
});

class AppStoreDistribution extends Component {

  state= {
      hidePublicDiscountOptionDescription: true,
      hidePrivateOptionDescription: true
  };
    render() {
        const { details: { distribution_option: distributionOption }, validation, readonly, is_first_version } = this.props;
        return (<Section
          id='distribution-options'
          title={
              <>
                  Distribution
                  {validation.errors && validation.errors.distribution_option &&
                  <ErrorFeedback
                      message={validation.errors.distribution_option}
                      style={{ top: -15, right: -25, position: 'relative' }}
                      additionalClassName='error-app-distribution'
                  />}
              </>
          }>
          <div className='apple-version' context={
            `{"targetType":"appleAppStore",  
            "tab": "app-details",
            "section": "distribution",  
            "error": "${validation.errors && validation.errors.distribution_option}"}`}/>
            </Section>)
          }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppStoreDistribution);