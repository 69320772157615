import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FieldFeedback from './FieldFeedback.jsx';

const CheckboxField = ({
    id,
    name,
    defaultChecked,
    checked,
    onChange,
    disabled,
    title,
    description,
    progress,
    error,
    dirty,
    xs,
    sm,
    md,
    lg,
    detachedFeedback = false,
    readonly = false,
}) => {
    return (
        <Row>
            <Col xs={xs} sm={sm} md={md} lg={lg}>
                <label
                    className={
                        disabled
                            ? 'checkbox-row-label checkbox-row-label-disabled'
                            : 'checkbox-row-label'
                    }>
                    <input
                        id={id}
                        name={name}
                        checked={checked}
                        disabled={disabled || readonly}
                        type="checkbox"
                        onChange={onChange}
                        defaultChecked={defaultChecked}
                    />
                    <div className="generic-checkbox-text"> {title} </div>
                    {description && (
                        <div className="checkbox-description">
                            {' '}
                            {description}{' '}
                        </div>
                    )}
                </label>
            </Col>
            <FieldFeedback
                progress={progress}
                error={error}
                dirty={dirty}
                detached={detachedFeedback}
                id={id}
            />
        </Row>
    );
};

export default CheckboxField;
