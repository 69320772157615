/*
 * incapptic Connect GmbH is the owner of all rights - to the greatest extent permitted by the applicable laws -
 * including rights to use and exploit this Source Code now or hereafter recognized in any and all territories
 * and jurisdictions.
 */

import moment from 'moment';

export const calculateMinDate = dateObject => {
    if (dateObject.min_date) {
        return moment(dateObject.min_date);
    } else if (dateObject.days_delta) {
        return moment().add(dateObject.days_delta, 'days');
    } else {
        return undefined;
    }
};
