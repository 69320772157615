import React, { Fragment } from 'react';
import { Grid, Row } from 'react-bootstrap';
import FieldRow from '../layout/FieldRow.jsx';
import Section from '../layout/Section.jsx';
import SelectField from '../inputs/SelectField.jsx';
import TextArea from '../inputs/TextArea.jsx';
import MultiSelectField from '../inputs/MultiSelectField.jsx';
import SelectFileField from '../inputs/SelectFileField.jsx';
import DropzoneField from '../inputs/DropzoneField.jsx';
import CheckboxField from '../inputs/CheckboxField.jsx';
import AppleWatchIconField from '../inputs/AppleWatchIconField.jsx';
import Expandable from '../Expandable.jsx';
import Header from '../Header.jsx';
import HelpTooltip from '../HelpTooltip.jsx';
import {
  categoriesList,
  countriesList,
  countriesValueRenderer,
  mapValuesToOptions, priceTierList,
} from '../../util/formatters';
import AppStoreDistribution from './AppStoreDistribution';
import ErrorFeedback from '../inputs/ErrorFeedback';

const mapCountryValuesToOptions = mapValuesToOptions('code');

const DetailsForm = ({ details, validation, readonly, diff, ...props }) => {
  // Helper functions
  const isGame = category => details[category] && details[category].id == 8;
  const isSticker = category =>
    details[category] && details[category].id == 24;
  const shouldShow = category => isGame(category) || isSticker(category);
  const chooseOptions = category => {
    if (isGame(category)) {
      return details.options.games_subcategories;
    } else if (isSticker(category)) {
      return details.options.stickers_subcategories;
    } else {
      return [];
    }
  };

  const previousValuePriceTierDisplay = () => {
    const priceTierListFiltered = priceTierList(details.options.price_tier && details.options.price_tier.filter(item => item.name === diff.price_tier))
    return priceTierListFiltered[0] ? priceTierListFiltered[0].label : diff.price_tier
  }

  const showPrimarySubcategory = shouldShow('primary_category');
  const showSecondarySubcategory = shouldShow('secondary_category');
  const primarySubcategoryOptions = chooseOptions('primary_category');
  const secondarySubcategoryOptions = chooseOptions('secondary_category');

  const mapSpecificEulaValuesToOptions = mapCountryValuesToOptions(
    details.options.specific_eula_countries
  );
  const mapSupportedCountriesValuesToOptions = mapCountryValuesToOptions(
    details.options.supported_countries
  );

  return (
    <Grid fluid>
      <Header>App Details</Header>
      <Section id="app-categories">
        <Row>
          <FieldRow
            id="primary-category"
            label="Primary Category"
            required>
            <SelectField
              name="primary_category"
              md={5}
              options={categoriesList(
                details.options.primary_category
              )}
              error={validation.errors.primary_category}
              progress={validation.isLoading.primary_category}
              dirty={validation.dirty.primary_category}
              value={
                details.primary_category &&
                details.primary_category.id
              }
              previousValue={diff.primary_category}
              readonly={readonly}
              onChange={event =>
                props.handleChangeSelect(
                  event,
                  details.options.primary_category,
                  props.csrf_token,
                  props.app_version
                )
              }
            />
          </FieldRow>
          <Expandable expanded={showPrimarySubcategory}>
            <FieldRow
              id="primary-subcategory-1"
              label="Subcategory">
              <SelectField
                name="primary_category_subcategory_1"
                allowBlank
                md={5}
                options={categoriesList(
                  primarySubcategoryOptions
                )}
                error={
                  validation.errors
                    .primary_category_subcategory_1
                }
                progress={
                  validation.isLoading
                    .primary_category_subcategory_1
                }
                dirty={
                  validation.dirty
                    .primary_category_subcategory_1
                }
                value={
                  details.primary_category_subcategory_1 &&
                  details.primary_category_subcategory_1.id
                }
                previousValue={
                  diff.primary_category_subcategory_1
                }
                readonly={readonly}
                onChange={event =>
                  props.handleChangeSelect(
                    event,
                    primarySubcategoryOptions,
                    props.csrf_token,
                    props.app_version
                  )
                }
              />
            </FieldRow>
            <FieldRow
              id="primary-subcategory-2"
              label="Subcategory">
              <SelectField
                name="primary_category_subcategory_2"
                allowBlank
                md={5}
                options={categoriesList(
                  primarySubcategoryOptions
                )}
                error={
                  validation.errors
                    .primary_category_subcategory_2
                }
                progress={
                  validation.isLoading
                    .primary_category_subcategory_2
                }
                dirty={
                  validation.dirty
                    .primary_category_subcategory_2
                }
                value={
                  details.primary_category_subcategory_2 &&
                  details.primary_category_subcategory_2.id
                }
                previousValue={
                  diff.primary_category_subcategory_1
                }
                readonly={readonly}
                onChange={event =>
                  props.handleChangeSelect(
                    event,
                    primarySubcategoryOptions,
                    props.csrf_token,
                    props.app_version
                  )
                }
              />
            </FieldRow>
          </Expandable>
          <FieldRow
            id="secondary-category"
            label="Secondary Category">
            <SelectField
              name="secondary_category"
              allowBlank
              md={5}
              options={categoriesList(
                details.options.secondary_category
              )}
              error={validation.errors.secondary_category}
              progress={validation.isLoading.secondary_category}
              dirty={validation.dirty.secondary_category}
              value={
                details.secondary_category &&
                details.secondary_category.id
              }
              previousValue={diff.secondary_category}
              readonly={readonly}
              onChange={event =>
                props.handleChangeSelect(
                  event,
                  details.options.secondary_category,
                  props.csrf_token,
                  props.app_version
                )
              }
            />
          </FieldRow>
          <Expandable expanded={showSecondarySubcategory}>
            <FieldRow
              id="secondary-subcategory-1"
              label="Subcategory">
              <SelectField
                name="secondary_category_subcategory_1"
                allowBlank
                md={5}
                options={categoriesList(
                  secondarySubcategoryOptions
                )}
                error={
                  validation.errors
                    .secondary_category_subcategory_1
                }
                progress={
                  validation.isLoading
                    .secondary_category_subcategory_1
                }
                dirty={
                  validation.dirty
                    .secondary_category_subcategory_1
                }
                value={
                  details.secondary_category_subcategory_1 &&
                  details.secondary_category_subcategory_1.id
                }
                previousValue={
                  diff.secondary_category_subcategory_1
                }
                readonly={readonly}
                onChange={event =>
                  props.handleChangeSelect(
                    event,
                    secondarySubcategoryOptions,
                    props.csrf_token,
                    props.app_version
                  )
                }
              />
            </FieldRow>
            <FieldRow
              id="secondary-subcategory-2"
              label="Subcategory">
              <SelectField
                name="secondary_category_subcategory_2"
                allowBlank
                md={5}
                options={categoriesList(
                  secondarySubcategoryOptions
                )}
                error={
                  validation.errors
                    .secondary_category_subcategory_2
                }
                progress={
                  validation.isLoading
                    .secondary_category_subcategory_2
                }
                dirty={
                  validation.dirty
                    .secondary_category_subcategory_2
                }
                value={
                  details.secondary_category_subcategory_2 &&
                  details.secondary_category_subcategory_2.id
                }
                previousValue={
                  diff.secondary_category_subcategory_2
                }
                readonly={readonly}
                onChange={event =>
                  props.handleChangeSelect(
                    event,
                    secondarySubcategoryOptions,
                    props.csrf_token,
                    props.app_version
                  )
                }
              />
            </FieldRow>
          </Expandable>
          <FieldRow
            id="specific-eula"
            label="Specific EULA"
            help={
              <Fragment>
                Apply a custom EULA to all chosen territories.
                If you don’t choose all territories, Apple’s
                standard EULA will apply to all remaining ones.
                Custom EULAs must meet Apple’s minimum terms.
              </Fragment>
            }>
            <TextArea
              name="specific_eula"
              md={12}
              value={details.specific_eula}
              type="text"
              error={validation.errors.specific_eula}
              progress={validation.isLoading.specific_eula}
              length={
                details.specific_eula &&
                details.specific_eula.length
              }
              max_length={
                details.options.specific_eula &&
                details.options.specific_eula.max_length
              }
              dirty={validation.dirty.specific_eula}
              previousValue={diff.specific_eula}
              readonly={readonly}
              onChange={props.handleChangeTextInput}
              onBlur={event =>
                props.handleBlurTextInput(
                  event,
                  props.csrf_token,
                  props.app_version
                )
              }
            />
          </FieldRow>
          <FieldRow
            id="specific-eula-countries"
            label="Specific EULA territories">
            <MultiSelectField
              name="specific_eula_countries"
              label="Specific EULA territories"
              md={9}
              options={countriesList(
                details.options.specific_eula_countries
              )}
              error={validation.errors.specific_eula_countries}
              progress={
                validation.isLoading.specific_eula_countries
              }
              dirty={validation.dirty.specific_eula_countries}
              readonly={readonly}
              previousValue={diff.eula_countries}
              onMultiChange={value =>
                props.handleChangeMultiSelect(
                  value.map(mapSpecificEulaValuesToOptions),
                  'code',
                  'specific_eula_countries',
                  props.csrf_token,
                  props.app_version
                )
              }
              selected={countriesList(
                details.specific_eula_countries
              )}
              selectAllLabel={'All territories'}
              valueRenderer={countriesValueRenderer}
            />
          </FieldRow>
        </Row>
      </Section>

      <Section id="languages-and-countries" title="Languages & Countries">
        <Row>
          <div className="appstore-warning">
            Note: We are currently unable to send this data to Apple. It needs to be entered manually in App Store
            Connect.
          </div>
          <div>
            <FieldRow
              id="supported-countries"
              label="Supported countries"
              required>
            <MultiSelectField
                name="supported_countries"
                md={9}
                label="Supported countries"
                options={countriesList(
                    details.options.supported_countries
                )}
                error={validation.errors.supported_countries}
                progress={validation.isLoading.supported_countries}
                dirty={validation.dirty.supported_countries}
                previousValue={diff.supported_countries}
                readonly={readonly}
                onMultiChange={value =>
                    props.handleChangeMultiSelect(
                        value.map(
                            mapSupportedCountriesValuesToOptions
                        ),
                        'code',
                        'supported_countries',
                        props.csrf_token,
                        props.app_version
                    )
                }
                selected={countriesList(
                    details.supported_countries
                )}
                selectAllLabel={'All countries'}
                valueRenderer={countriesValueRenderer}>
              <CheckboxField
                  md={10}
                  name="new_territories"
                  checked={details.new_territories}
                  readonly={readonly}
                  onChange={event =>
                      props.handleChangeCheckbox(
                          event,
                          props.csrf_token,
                          props.app_version
                      )
                  }
                  title={
                    <Fragment>
                      New Territories
                      <HelpTooltip
                          message="Make your app automatically available in all future App Store territories."
                          placement="right"
                          id="new_territories"
                      />
                    </Fragment>
                  }
              />
            </MultiSelectField>
          </FieldRow>
          </div>

        </Row>
      </Section>

      <Section
          id="pricing-and-availability"
          title="Pricing & Availability">
        <Row>
          <div className="appstore-warning">
          Note: We are currently unable to send this data to Apple. It needs to be entered manually in App Store Connect.
          </div>
          <div>
            <FieldRow
                id="price-tier"
                label="Price tier"
                required
                linkHref="https://help.apple.com/app-store-connect/#/dev9fc06e23d"
              linkText="Read Apple documentation"
              help="The price determines the App Store price and your proceeds. If your app is free, choose Free. If you sell your app, you must have a Paid Application agreement.">
            <SelectField
                name="price_tier"
                md={5}
                options={priceTierList(details.options.price_tier)}
                error={validation.errors.price_tier}
                progress={validation.isLoading.price_tier}
                dirty={validation.dirty.price_tier}
                value={details.price_tier && details.price_tier.id}
                previousValue={previousValuePriceTierDisplay()}
                readonly={readonly}
                onChange={event =>
                    props.handleChangeSelect(
                        event,
                        details.options.price_tier,
                        props.csrf_token,
                        props.app_version
                    )
                }
            />
          </FieldRow>
          </div>
        </Row>
      </Section>

      <AppStoreDistribution/>

      <Section id="additional-files" title="Additional files">
        <Row>
          <FieldRow
              id="application-icon"
              label="Application icon"
              description="1024 x 1024 px">
            <DropzoneField
                id="application_icon"
              initialFile={details.application_icon}
              accept="image/png, image/jpeg"
              error={validation.errors.application_icon}
              progress={validation.isLoading.application_icon}
              dirty={validation.dirty.application_icon}
              readonly={readonly}
              onChange={file =>
                props.handleChangeFile(
                  'application_icon',
                  file,
                  props.csrf_token,
                  props.app_version
                )
              }
              onDelete={() =>
                props.handleDeleteFile(
                  'application_icon',
                  props.csrf_token,
                  props.app_version
                )
              }
            />
          </FieldRow>
          {details.supports_watch && (
            <FieldRow
              id="watch_icon"
              label="Application icon for apple watch"
              description="1024 x 1024 px">
              <AppleWatchIconField
                initialFile={details.watch_icon}
                id="watch_icon"
                error={validation.errors.watch_icon}
                progress={validation.isLoading.watch_icon}
                dirty={validation.dirty.watch_icon}
                readonly={readonly}
                onChange={file =>
                  props.handleChangeFile(
                    'watch_icon',
                    file,
                    props.csrf_token,
                    props.app_version
                  )
                }
                onDelete={() =>
                  props.handleDeleteFile(
                    'watch_icon',
                    props.csrf_token,
                    props.app_version
                  )
                }
              />
            </FieldRow>
          )}
          <FieldRow
            id="routing-coverage-file"
            label="Routing coverage file"
            help="Specify the geographic regions supported by your app. The file must be in the .geojson format and can only contain one multipolygon elements.">
            <SelectFileField
              xs={12}
              sm={12}
              md={12}
              initialFile={details.routing_coverage_file}
              error={validation.errors.routing_coverage_file}
              progress={
                validation.isLoading.routing_coverage_file
              }
              dirty={validation.dirty.primary_category}
              readonly={readonly}
              onChange={file =>
                props.handleChangeFile(
                  'routing_coverage_file',
                  file,
                  props.csrf_token,
                  props.app_version
                )
              }
              onDelete={() =>
                props.handleDeleteFile(
                  'routing_coverage_file',
                  props.csrf_token,
                  props.app_version
                )
              }
            />
          </FieldRow>
        </Row>
      </Section>
      <Section
        id="distribution-options">
        <div className="apple-version" context={
          `{"targetType":"appleAppStore",  
                  "tab": "app-details", 
                  "section": "release-additional", 
                  "error": "${validation.errors && validation.errors.release_additional_comment}"}`
        }/>
      </Section>
    </Grid>
  );
};

export default DetailsForm;
