// COLORS
export const Blue = '#0C4F66';
export const Gray = '#999';
export const LightBlue = '#4DB598';
export const Yellow = '#FFA523';
export const Red = '#BE2C0C';

// DATE FORMAT
export const DateWithDays = 'YYYY-MM-DD';

export const MobileIronTarget = {
    kind: 'mobileiron',
};
