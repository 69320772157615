import React from 'react';
import moment from 'moment';
import { getNumberOfDaysToExpiryText } from '../Utils/label';

const VersionRow = ({ version, can_edit_signing_details }) => {
    const showWarning = version.show_warning;
    const versionUrl = version.version_page;
    return (
        <tr className="version-row" onClick={() => (location = versionUrl)}>
            <td>
                <a href={versionUrl}>
                    <img
                        className="app-icon"
                        src={version.icon}
                        alt={version.binary_info}
                    />
                    {version.binary_info}
                </a>
            </td>
            <td>
                <a href={versionUrl}>
                    <span
                        className={
                            can_edit_signing_details && version.signing_blocked
                                ? 'text-danger'
                                : ''
                        }>
                        {version.status}
                        <span className={showWarning ? 'text-warning' : ''}>
                            {version.target_status}
                        </span>
                    </span>
                </a>
            </td>
            <td>
                <a href={versionUrl}>
                    {/*TODO: When this is moved to the new client, use the function mapTimestampToDatetime*/}
                    {moment(String(version.last_activity), 'X').format('YYYY-MM-DD HH:mm')}
                </a>
            </td>
            <td>
                <a href={versionUrl}>
                    {version.expiring_in === ''
                        ? ''
                        : getNumberOfDaysToExpiryText(version.expiring_in)}
                </a>
            </td>
            <td>
                <a href={versionUrl}>
                    {version.direct_download ? 'Enabled' : 'Disabled'}
                </a>
            </td>
        </tr>
    );
};

export default VersionRow;
